/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */

// With HKT we could eliminate dependencies on our client project (https://github.com/microsoft/TypeScript/issues/1213)
import { GraphQLRequestFactory } from "@ihr-radioedit/gql-client";
import { print } from "graphql";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** References an image from mediaserver through RadioEdit's asset manager */
  ImageRef: any;
  JSON: any;
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  POSTUP_IP: any;
  /** Any value. */
  PWSAny: any;
  /** Date as Timestamp. */
  PWSTimestamp: any;
  PlaylistsDate: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  PostupDateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  PostupEmailAddress: any;
};

export type AmpFunctions = {
  podcast?: Maybe<Podcast>;
  podcastEpisode?: Maybe<PodcastEpisode>;
};

export type AmpFunctionsPodcastArgs = {
  id: Scalars["Int"];
};

export type AmpFunctionsPodcastEpisodeArgs = {
  id: Scalars["Int"];
};

export type AmpLiveMeta = {
  topArtists: LiveMetaTopArtists;
  recentlyPlayed: LiveMetaRecentlyPlayed;
  topTracks: LiveMetaTopTracks;
  currentTrack?: Maybe<PnpTrack>;
  currentlyPlaying: LiveMetaCurrentlyPlaying;
};

export type AmpLiveMetaTopArtistsArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type AmpLiveMetaRecentlyPlayedArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type AmpLiveMetaTopTracksArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type AmpLiveMetaCurrentlyPlayingArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type AmpPageInfo = {
  nextCursor?: Maybe<Scalars["String"]>;
  prevCursor?: Maybe<Scalars["String"]>;
};

export type AmpPageInput = {
  take?: Maybe<Scalars["Int"]>;
  nextCursor?: Maybe<Scalars["String"]>;
};

export type AdBreakpoint = {
  breakpoint: Scalars["Float"];
  sizes: Array<AdSize>;
};

export type AdConfig = {
  positions: Array<AdPosition>;
};

export type AdParams = {
  keywords: Scalars["String"];
  topics: Scalars["String"];
};

export type AdPosition = {
  ad_split: Scalars["Float"];
  force_load: Scalars["Boolean"];
  position_id: Scalars["String"];
  breakpoints: Array<AdBreakpoint>;
};

export type AdSize = {
  height: Scalars["Float"];
  width: Scalars["Float"];
};

export type AdswizzRef = {
  publisher_id?: Maybe<Scalars["String"]>;
  enableAdswizzTargeting?: Maybe<Scalars["Boolean"]>;
  adswizzHost?: Maybe<Scalars["String"]>;
};

export type AdswizzZonesRef = {
  audioFillZone?: Maybe<Scalars["String"]>;
  audioExchangeZone?: Maybe<Scalars["String"]>;
  audioZone?: Maybe<Scalars["String"]>;
  displayZone?: Maybe<Scalars["String"]>;
  optimizedAudioFillZone?: Maybe<Scalars["String"]>;
};

export type AptivadaApp = {
  appType: Scalars["String"];
  appId: Scalars["String"];
  account: Scalars["String"];
  parentAppId: Scalars["String"];
  title: Scalars["String"];
  shareTitle: Scalars["String"];
  tags: Scalars["String"];
  gridStatus: Scalars["String"];
  gridRedirect: Scalars["String"];
  pageUrl: Scalars["String"];
  primaryImage: Scalars["String"];
  mobileImage: Scalars["String"];
  gridImage: Scalars["String"];
  prize: Scalars["String"];
  sort: Scalars["String"];
  startTime: Scalars["String"];
  endTime: Scalars["String"];
  scheduleStatus: Scalars["String"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  description: Scalars["String"];
  active?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  displayImage?: Maybe<Scalars["String"]>;
  accountId?: Maybe<Scalars["String"]>;
  facebookShareImage?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["String"]>;
  shareLocation?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type AptivadaFunctions = {
  apps?: Maybe<Array<AptivadaApp>>;
  app?: Maybe<AptivadaApp>;
  keyword?: Maybe<AptivadaKeywordApp>;
};

export type AptivadaFunctionsAppArgs = {
  appId: Scalars["String"];
};

export type AptivadaFunctionsKeywordArgs = {
  appId: Scalars["String"];
};

export type AptivadaKeywordApp = {
  appType: Scalars["String"];
  appId: Scalars["String"];
  title: Scalars["String"];
  currentKeyword: CurrentKeyword;
  submittableKeyword?: Maybe<CurrentKeyword>;
};

export type Artist = {
  id: Scalars["Int"];
  artistName: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  genreId?: Maybe<Scalars["Int"]>;
  bio?: Maybe<Scalars["String"]>;
  facUpdate: Scalars["Float"];
  roviImages?: Maybe<Array<Scalars["String"]>>;
};

export type ArtistFunctions = {
  /** Lookup Leads cards targeted to the specified artist. */
  leads: Array<Card>;
  /** Lookup articles that related to the artist specified. */
  content: Array<ContentRecord>;
};

export type ArtistFunctionsLeadsArgs = {
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  locale?: Maybe<Scalars["String"]>;
};

export type ArtistFunctionsContentArgs = {
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type BaseConfig = {
  /** pages: [Page]! */
  pages: Scalars["JSON"];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
};

export type BaseIndex = {
  brand_id: Scalars["String"];
  configs: Array<BaseIndexConfigPointer>;
  default: Scalars["String"];
  description: Scalars["String"];
  facets: Array<Scalars["String"]>;
  id: Scalars["String"];
  image?: Maybe<Scalars["ImageRef"]>;
  legacy_id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  own_brand: Scalars["String"];
  slug: Scalars["String"];
  tags: Array<Maybe<Scalars["String"]>>;
};

export type BaseIndexConfigPointer = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type BroadcastFacility = {
  broadcast_frequency: Scalars["String"];
  broadcast_band: Scalars["String"];
  facility_id: Scalars["String"];
  broadcast_call_letters: Scalars["String"];
};

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type CacheResponse = {
  status: Scalars["Float"];
};

export type CalendarContentRef = {
  author: Scalars["String"];
  id: Scalars["String"];
  img: Scalars["String"];
  publish_date?: Maybe<Scalars["Float"]>;
  publish_origin: Scalars["String"];
  summary: Scalars["String"];
  title: Scalars["String"];
  slug: Scalars["String"];
  record?: Maybe<ContentRecord>;
};

export type CalendarEvent = {
  endDateTime?: Maybe<Scalars["Float"]>;
  eventId: Scalars["String"];
  origins: Array<Scalars["String"]>;
  startDateTime: Scalars["Float"];
  tags: Array<Scalars["String"]>;
  content: CalendarContentRef;
  venueName?: Maybe<Scalars["String"]>;
  venueAddress?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type CalendarFunctions = {
  event?: Maybe<CalendarEvent>;
  occurrence?: Maybe<CalendarEvent>;
};

export type CalendarFunctionsEventArgs = {
  eventId?: Maybe<Scalars["String"]>;
};

export type CalendarFunctionsOccurrenceArgs = {
  occurrenceId?: Maybe<Scalars["String"]>;
};

export type Card = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  link?: Maybe<CardLink>;
  background_color?: Maybe<Scalars["String"]>;
  img_uri?: Maybe<Scalars["String"]>;
  catalog?: Maybe<Catalog>;
  img_meta?: Maybe<CardImageMeta>;
  targets?: Maybe<Array<Subscription>>;
  use_catalog_image?: Maybe<Scalars["Boolean"]>;
  locales?: Maybe<Array<Scalars["String"]>>;
  range: CardRange;
};

export type CardImageMeta = {
  base_id?: Maybe<Scalars["String"]>;
  bucket?: Maybe<Scalars["String"]>;
};

export type CardLink = {
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  urls?: Maybe<CardLinkUrls>;
};

export type CardLinkUrls = {
  device?: Maybe<Scalars["String"]>;
  web?: Maybe<Scalars["String"]>;
};

export type CardPayload = {
  position: Scalars["Float"];
  priority: Scalars["Float"];
  range?: Maybe<CardPayloadRange>;
  targets?: Maybe<Array<Subscription>>;
  variants: Array<Card>;
};

export type CardPayloadRange = {
  begin?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export type CardRange = {
  begin?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export type CardRecord = {
  ref_id: Scalars["ID"];
  slug: Scalars["String"];
  summary: PublishSummary;
  payload: CardPayload;
  subscription: Array<Subscription>;
  ad_params: AdParams;
  pub_start: Scalars["Float"];
  pub_until?: Maybe<Scalars["Float"]>;
};

/** The output representation of a carousel. Contains values for a single locale. */
export type Carousel = {
  /** The ID of the carousel. */
  id: Scalars["ID"];
  /** The title of the carousel. Might be used to display as a title or message to the end user. */
  title: Scalars["String"];
  /** The subtitle of the carousel. Might be used to display a brief description of the carousel. */
  subtitle: Scalars["String"];
  /** The description of the carousel. Might be used to display a longer description of the carousel. */
  description: Scalars["String"];
  /** An optional image for the carousel. Might be used to display an image to the end user. */
  image?: Maybe<Scalars["String"]>;
  /** The mobile url link of the carousel */
  mobileUrl?: Maybe<Scalars["String"]>;
  /** The web url link of the carousel */
  webUrl?: Maybe<Scalars["String"]>;
  /** The tags for the carousel. Used to identify the carousel for recommendation. */
  tags: Array<Scalars["String"]>;
  /** The title color of the carousel. Used to determine the color of the title text. */
  titleColor?: Maybe<Scalars["String"]>;
  /** The subtitle color of the carousel. Used to determine the color of the subtitle text. */
  subtitleColor?: Maybe<Scalars["String"]>;
  /** The catalog items in the carousel. */
  catalogItems: Array<CatalogReference>;
  /** Carousels that are linked to this carousel. */
  linkedCarousels: Array<Carousel>;
};

/** The output representation of a carousel. Contains values for a single locale. */
export type CarouselCatalogItemsArgs = {
  query?: Maybe<CarouselCatalogItemsListArgs>;
};

/** Options for the response of catalog items in a carousel. */
export type CarouselCatalogItemsListArgs = {
  /** The number of catalog items to return. */
  limit?: Maybe<Scalars["Int"]>;
  /** The offset of catalog items to return. This will be used in conjunction with the limit to paginate results. Will not be useful with random sort. */
  offset?: Maybe<Scalars["Int"]>;
  /** The sort order of the catalog items. Defaults to the natural order of the catalog items as defined in the carousel. */
  sort?: Maybe<CarouselCatalogItemsSort>;
  /** The kinds of catalog items to return. Defaults to all kinds. */
  filterKinds?: Maybe<Array<CatalogKind>>;
};

export enum CarouselCatalogItemsSort {
  /** Sort catalog items in the order provided in the carousel record */
  Natural = "NATURAL",
  /** Return catalog items in a random order. Note that using this option makes offset useless. */
  Random = "RANDOM",
}

export type CarouselCreateInput = {
  /** The editors of the carousel. Used to determine who can edit the carousel. Should be a list of RadioEdit tags, including namespaces. */
  editors: Array<Scalars["String"]>;
  /** The metadata for the carousel. Contains values for multiple locales. */
  metadata: Array<CarouselMetadataInput>;
  /** The tags for the carousel. Used to identify the carousel for recommendation. */
  tags: Array<Scalars["String"]>;
  /** The title color of the carousel. Used to determine the color of the title text. */
  titleColor?: Maybe<Scalars["String"]>;
  /** The subtitle color of the carousel. Used to determine the color of the subtitle text. */
  subtitleColor?: Maybe<Scalars["String"]>;
  /** A relative time representing the start of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeStartTime?: Maybe<Scalars["Int"]>;
  /** A relative time representing the end of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeEndTime?: Maybe<Scalars["Int"]>;
  /** A timestamp representing the start of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteStartTimestamp?: Maybe<Scalars["Float"]>;
  /** A timestamp representing the end of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteEndTimestamp?: Maybe<Scalars["Float"]>;
  /** The catalog items in the carousel. */
  catalogItems: Array<CatalogReferenceInput>;
  /** The tags assigned to the catalog items in the carousel. */
  catalogItemTags: Array<Array<Scalars["String"]>>;
  /** The IDs of the carousels that are linked to this carousel. */
  linkedCarousels: Array<Scalars["ID"]>;
  /** The weekdays the carousel is active. */
  weekdays?: Maybe<CarouselWeekdaysInput>;
};

/** The input for a carousel query. */
export type CarouselListArgs = {
  /** A partial text query that will be run against all other fields. */
  query?: Maybe<Scalars["String"]>;
  /** The number of results to return for the specified query. */
  take?: Maybe<Scalars["Int"]>;
  /** The number of results to skip for the specified query. */
  skip?: Maybe<Scalars["Int"]>;
  /** Tags to match against the carousel tags. */
  tags?: Maybe<Array<Scalars["String"]>>;
  /** The editors of the carousel. Should be a list of RadioEdit tags, including namespaces. */
  editors?: Maybe<Array<Scalars["String"]>>;
  /** Should search results include inactive carousels. Defaults to false. */
  includeInactive?: Maybe<Scalars["Boolean"]>;
};

/** The result of a carousel query. */
export type CarouselListResult = {
  /** The list of carousels returned by the query. */
  carousels: Array<CarouselRaw>;
  /** The total number of carousels matching the query. */
  total: Scalars["Int"];
};

/** Metadata for a carousel for one or more locales. */
export type CarouselMetadata = {
  /** The title of the carousel. Might be used to display as a title or message to the end user. */
  title: Scalars["String"];
  /** The subtitle of the carousel. Might be used to display a brief description of the carousel. */
  subtitle: Scalars["String"];
  /** The description of the carousel. Might be used to display a longer description of the carousel. */
  description: Scalars["String"];
  /** An optional image for the carousel. Might be used to display an image to the end user. */
  image?: Maybe<Scalars["String"]>;
  /** The locales for which this metadata is used. Allows for translations by locale. The locales should be tags in the format of `locales/language_COUNTRY` */
  locales: Array<Scalars["String"]>;
  /** The mobile url link of the carousel */
  mobileUrl?: Maybe<Scalars["String"]>;
  /** The web url link of the carousel */
  webUrl?: Maybe<Scalars["String"]>;
};

/** The input type for carousel metadata. */
export type CarouselMetadataInput = {
  /** The title of the carousel. Might be used to display as a title or message to the end user. */
  title?: Maybe<Scalars["String"]>;
  /** The subtitle of the carousel. Might be used to display a brief description of the carousel. */
  subtitle?: Maybe<Scalars["String"]>;
  /** The description of the carousel. Might be used to display a longer description of the carousel. */
  description?: Maybe<Scalars["String"]>;
  /** An optional image for the carousel. Might be used to display an image to the end user. */
  image?: Maybe<Scalars["String"]>;
  /** The locales for which this metadata is used. Allows for translations by locale. The locales should be tags in the format of `locales/language_COUNTRY` */
  locales: Array<Scalars["String"]>;
  /** The mobile url link of the carousel */
  mobileUrl?: Maybe<Scalars["String"]>;
  /** The web url link of the carousel */
  webUrl?: Maybe<Scalars["String"]>;
};

export type CarouselMutation = {
  /** Creates a new carousel. */
  create: CarouselRaw;
  /** Updates an existing carousel. */
  update: CarouselRaw;
  /** Publishes a carousel. */
  publish: CarouselRaw;
  /** Unpublishes a carousel. */
  unpublish: CarouselRaw;
  /** Deletes a carousel. */
  delete: Scalars["Boolean"];
};

export type CarouselMutationCreateArgs = {
  input: CarouselCreateInput;
};

export type CarouselMutationUpdateArgs = {
  id: Scalars["ID"];
  input: CarouselUpdateInput;
};

export type CarouselMutationPublishArgs = {
  id: Scalars["ID"];
};

export type CarouselMutationUnpublishArgs = {
  id: Scalars["ID"];
};

export type CarouselMutationDeleteArgs = {
  id: Scalars["ID"];
};

export type CarouselQuery = {
  /** Returns a carousel by ID. */
  byId: CarouselRaw;
  /** Returns a list of carousels. */
  list: CarouselListResult;
  /** Returns a carousel recommended for the current user input. Used by the iHeartYou service. */
  recommend: CarouselRecommendResult;
};

export type CarouselQueryByIdArgs = {
  id: Scalars["ID"];
};

export type CarouselQueryListArgs = {
  query?: Maybe<CarouselListArgs>;
};

export type CarouselQueryRecommendArgs = {
  query: CarouselRecommendArgs;
};

/** The internel representation of a carousel. Stores values for multiple locales. */
export type CarouselRaw = {
  /** The ID of the carousel. */
  id: Scalars["ID"];
  /** The editors of the carousel. Used to determine who can edit the carousel. Should be a list of RadioEdit tags, including namespaces. */
  editors: Array<Scalars["String"]>;
  /** The metadata for the carousel. Contains values for multiple locales. */
  metadata: Array<CarouselMetadata>;
  /** The tags for the carousel. Used to identify the carousel for recommendation. */
  tags: Array<Scalars["String"]>;
  /** The title color of the carousel. Used to determine the color of the title text. */
  titleColor?: Maybe<Scalars["String"]>;
  /** The subtitle color of the carousel. Used to determine the color of the subtitle text. */
  subtitleColor?: Maybe<Scalars["String"]>;
  /** A relative time representing the start of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeStartTime?: Maybe<Scalars["Int"]>;
  /** A relative time representing the end of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeEndTime?: Maybe<Scalars["Int"]>;
  /** A timestamp representing the start of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteStartTimestamp?: Maybe<Scalars["Float"]>;
  /** A timestamp representing the end of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteEndTimestamp?: Maybe<Scalars["Float"]>;
  /** The catalog items in the carousel. */
  catalogItems: Array<CatalogReference>;
  /** The tags assigned to the catalog items in the carousel. */
  catalogItemTags: Array<Array<Scalars["String"]>>;
  /** Carousels that are linked to this carousel. */
  linkedCarousels: Array<CarouselRaw>;
  /** The timestamp of the last time the carousel was modified. */
  lastModifiedTimestamp: Scalars["Float"];
  /** The timestamp of the last time the carousel was published. */
  lastPublishedTimestamp?: Maybe<Scalars["Float"]>;
  /** The weekdays the carousel is active. */
  weekdays?: Maybe<CarouselWeekdays>;
};

/** The internel representation of a carousel. Stores values for multiple locales. */
export type CarouselRawCatalogItemsArgs = {
  query?: Maybe<CarouselCatalogItemsListArgs>;
};

/** The input for a carousel recommendation. */
export type CarouselRecommendArgs = {
  /** An IHR profile ID (Not a RadioEdit User). */
  profileId?: Maybe<Scalars["ID"]>;
  /** The timestamp of the request according to the client. This should be an ISO-8601 timestamp with offset. */
  clientTimestamp: Scalars["String"];
  /** The requested country used to filter results. */
  country?: Maybe<Scalars["String"]>;
  /** The requested locale used to filter results and provide localized text data. The locales should be tags in the format of `locales/language_COUNTRY`. Defaults to `en_US` if not provided. */
  locale?: Maybe<Scalars["String"]>;
  /** Search tags to match against the carousel tags. Search will return records that match ANY of these tags. */
  tags?: Maybe<Array<Scalars["String"]>>;
};

/** The result of a carousel recommendation. */
export type CarouselRecommendResult = {
  /** The recommended carousel(s). */
  carousels: Array<Carousel>;
};

export type CarouselUpdateInput = {
  /** The editors of the carousel. Used to determine who can edit the carousel. Should be a list of RadioEdit tags, including namespaces. */
  editors: Array<Scalars["String"]>;
  /** The metadata for the carousel. Contains values for multiple locales. */
  metadata: Array<CarouselMetadataInput>;
  /** The tags for the carousel. Used to identify the carousel for recommendation. */
  tags: Array<Scalars["String"]>;
  /** The title color of the carousel. Used to determine the color of the title text. */
  titleColor?: Maybe<Scalars["String"]>;
  /** The subtitle color of the carousel. Used to determine the color of the subtitle text. */
  subtitleColor?: Maybe<Scalars["String"]>;
  /** A relative time representing the start of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeStartTime?: Maybe<Scalars["Int"]>;
  /** A relative time representing the end of the carousel. Used to determine if the carousel is active based on the enduser's timezone. Stored as a 4 digit number representing 24 hour time. For example, 1:30 PM would be stored as 1330. */
  relativeEndTime?: Maybe<Scalars["Int"]>;
  /** A timestamp representing the start of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteStartTimestamp?: Maybe<Scalars["Float"]>;
  /** A timestamp representing the end of the carousel. Used to determine if the carousel is active based on the server time. */
  absoluteEndTimestamp?: Maybe<Scalars["Float"]>;
  /** The catalog items in the carousel. */
  catalogItems: Array<CatalogReferenceInput>;
  /** The tags assigned to the catalog items in the carousel. */
  catalogItemTags: Array<Array<Scalars["String"]>>;
  /** The IDs of the carousels that are linked to this carousel. */
  linkedCarousels: Array<Scalars["ID"]>;
  /** The weekdays the carousel is active. */
  weekdays?: Maybe<CarouselWeekdaysInput>;
};

export type CarouselWeekdays = {
  /** The carousel is active on Sunday. */
  sunday: Scalars["Boolean"];
  /** The carousel is active on Monday. */
  monday: Scalars["Boolean"];
  /** The carousel is active on Tuesday. */
  tuesday: Scalars["Boolean"];
  /** The carousel is active on Wednesday. */
  wednesday: Scalars["Boolean"];
  /** The carousel is active on Thursday. */
  thursday: Scalars["Boolean"];
  /** The carousel is active on Friday. */
  friday: Scalars["Boolean"];
  /** The carousel is active on Saturday. */
  saturday: Scalars["Boolean"];
};

export type CarouselWeekdaysInput = {
  /** The carousel is active on Sunday. */
  sunday: Scalars["Boolean"];
  /** The carousel is active on Monday. */
  monday: Scalars["Boolean"];
  /** The carousel is active on Tuesday. */
  tuesday: Scalars["Boolean"];
  /** The carousel is active on Wednesday. */
  wednesday: Scalars["Boolean"];
  /** The carousel is active on Thursday. */
  thursday: Scalars["Boolean"];
  /** The carousel is active on Friday. */
  friday: Scalars["Boolean"];
  /** The carousel is active on Saturday. */
  saturday: Scalars["Boolean"];
};

export type Catalog = {
  id?: Maybe<Scalars["String"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** A catalog album record. */
export type CatalogAlbum = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  amp?: Maybe<CatalogAmpAlbum>;
};

/** Queries available for catalog album records. */
export type CatalogAlbumQuery = {
  /** Retrieve an album by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAlbum>;
};

/** Queries available for catalog album records. */
export type CatalogAlbumQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** An album record from the AMP system. */
export type CatalogAmpAlbum = {
  albumId: Scalars["Int"];
  artistId: Scalars["Int"];
  artistName: Scalars["String"];
  copyright?: Maybe<Scalars["String"]>;
  displayable?: Maybe<Scalars["Boolean"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Float"]>;
  title: Scalars["String"];
  totalSongs: Scalars["Int"];
  tracks: Array<CatalogAmpAlbumTrack>;
};

/** Queries available for AMP album records. */
export type CatalogAmpAlbumQuery = {
  /** Retrieve an album by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpAlbum>;
};

/** Queries available for AMP album records. */
export type CatalogAmpAlbumQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

/** An album search result. Note that this is different than an `AmpAlbum`. */
export type CatalogAmpAlbumResult = {
  id: Scalars["Int"];
  artistId?: Maybe<Scalars["Int"]>;
  artistName?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  title?: Maybe<Scalars["String"]>;
};

/**
 * A track record returned via an album lookup. This record is slightly different
 * than an `AmpTrack`, which is returned by a track lookup.
 */
export type CatalogAmpAlbumTrack = {
  id: Scalars["Int"];
  artistName: Scalars["String"];
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  playbackRights: CatalogAmpTrackPlaybackRights;
  publisher?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  trackNumber: Scalars["Int"];
  version?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["Int"]>;
};

/** A typed album search result. Note that this is different than an `AmpAlbum`. */
export type CatalogAmpAlbumTypedResult = {
  id: Scalars["Int"];
  artistId?: Maybe<Scalars["Int"]>;
  artistName?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  title?: Maybe<Scalars["String"]>;
  typeName: CatalogAmpResultType;
};

/** An artist record from the AMP system. */
export type CatalogAmpArtist = {
  id: Scalars["Int"];
  /**
   * Retrieve the albums assocated with the artist. `cursor` is a string
   * identifier used to mark a "page" of results. `limit` specifies the number of
   * records to return (defaults to 20).
   */
  albums: CatalogAmpArtistAlbumsResult;
  artistName: Scalars["String"];
  bio?: Maybe<Scalars["String"]>;
  facUpdate: Scalars["Float"];
  genreId?: Maybe<Scalars["Int"]>;
  image?: Maybe<Scalars["String"]>;
  roviImages: Array<Scalars["String"]>;
  /**
   * Retrieve the tracks assocated with the artist. `offset` is an int sepcifying
   * the number of results to skipp in the return, useful for paging. `limit`
   * specifies the number of records to return (defaults to 20).
   */
  tracks: CatalogAmpArtistTracksResult;
  _args?: Maybe<CatalogAmpArtistArgs>;
};

/** An artist record from the AMP system. */
export type CatalogAmpArtistAlbumsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

/** An artist record from the AMP system. */
export type CatalogAmpArtistTracksArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

/** An album looked up via an artist. Note that this is different than an `AmpAlbum` */
export type CatalogAmpArtistAlbum = {
  image?: Maybe<Scalars["String"]>;
  copyright?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Float"]>;
  displayable?: Maybe<Scalars["Boolean"]>;
  albumId: Scalars["Int"];
  artistId: Scalars["Int"];
  title: Scalars["String"];
  totalSongs: Scalars["Int"];
  tracks: Array<CatalogAmpArtistAlbumTrack>;
  publisher?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  artistName: Scalars["String"];
};

/** A track looked up via an album via an artist. note that this is different than an `AmpTrack` */
export type CatalogAmpArtistAlbumTrack = {
  id: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  albumInfo?: Maybe<CatalogAmpArtistAlbumTrackAlbumInfo>;
};

export type CatalogAmpArtistAlbumTrackAlbumInfo = {
  trackNumber?: Maybe<Scalars["Int"]>;
};

export type CatalogAmpArtistAlbumsResult = {
  albums: Array<CatalogAmpArtistAlbum>;
  cursor?: Maybe<Scalars["String"]>;
};

export type CatalogAmpArtistArgs = {
  id?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
};

/** Queries available for AMP artist records. */
export type CatalogAmpArtistQuery = {
  /** Retrieve an artist by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpArtist>;
  /** Retrieve a series of artists by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogAmpArtist>>;
};

/** Queries available for AMP artist records. */
export type CatalogAmpArtistQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

/** Queries available for AMP artist records. */
export type CatalogAmpArtistQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
};

/** An artist search result. Note that this is different than an `AmpArtist`. */
export type CatalogAmpArtistResult = {
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  normRank?: Maybe<Scalars["Float"]>;
  rank: Scalars["Int"];
  score: Scalars["Float"];
};

export type CatalogAmpArtistTracksResult = {
  tracks: Array<CatalogAmpTrack>;
};

/** A typed artist search result. Note that this is different than an `AmpArtist`. */
export type CatalogAmpArtistTypedResult = {
  id: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  rank: Scalars["Float"];
  score: Scalars["Float"];
  typeName: CatalogAmpResultType;
};

export type CatalogAmpBestMatch = {
  id: Scalars["Int"];
  format: Scalars["String"];
};

/** A collection record from the AMP system. Also referred to as a "playlist" */
export type CatalogAmpCollection = {
  id: Scalars["String"];
  allowed?: Maybe<Scalars["Int"]>;
  author?: Maybe<Scalars["String"]>;
  backfillTracks?: Maybe<Array<Scalars["Int"]>>;
  childOriented?: Maybe<Scalars["Boolean"]>;
  curated: Scalars["Boolean"];
  dateCreated: Scalars["Float"];
  deletable: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  duration: Scalars["Float"];
  followable: Scalars["Boolean"];
  followed: Scalars["Boolean"];
  lastUpdated: Scalars["Float"];
  name: Scalars["String"];
  playableAsRadio?: Maybe<Scalars["Boolean"]>;
  premium?: Maybe<Scalars["Boolean"]>;
  prepopulated?: Maybe<Scalars["Int"]>;
  renameable: Scalars["Boolean"];
  reportingKey: Scalars["String"];
  shareable: Scalars["Boolean"];
  slug: Scalars["String"];
  supportedRegions?: Maybe<Array<Scalars["String"]>>;
  tracks: Array<CatalogAmpCollectionItem>;
  /** The collection type. One of ["generated", "new4u", "default", "user", "personalized"] */
  type: Scalars["String"];
  urls: CatalogAmpCollectionUrls;
  userId: Scalars["String"];
  writeable: Scalars["Boolean"];
};

export type CatalogAmpCollectionItem = {
  id: Scalars["String"];
  trackId: Scalars["Int"];
};

/** Queries available for AMP collection (playlist) records. */
export type CatalogAmpCollectionQuery = {
  /**
   * Retrieve a collection by userId, id, and country. If a country is not supplied, will default to 'US'.
   * Note that unlike most other AMP data types, ids are strings.
   */
  byId?: Maybe<CatalogAmpCollection>;
  /**
   * Retrieve a collection by ref (userId::id) and country. If a country is not supplied, will default to 'US'.
   * Note that unlike most other AMP data types, ids are strings.
   */
  byRef?: Maybe<CatalogAmpCollection>;
};

/** Queries available for AMP collection (playlist) records. */
export type CatalogAmpCollectionQueryByIdArgs = {
  userId: Scalars["String"];
  id: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
};

/** Queries available for AMP collection (playlist) records. */
export type CatalogAmpCollectionQueryByRefArgs = {
  ref: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
};

export enum CatalogAmpCollectionType {
  Generated = "GENERATED",
  New4U = "NEW4U",
  Default = "DEFAULT",
  User = "USER",
  Personalized = "PERSONALIZED",
}

export type CatalogAmpCollectionUrls = {
  goto: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  play: Scalars["String"];
  web: Scalars["String"];
};

/** A keyword search result. */
export type CatalogAmpKeywordResult = {
  id: Scalars["Int"];
  androidUrl?: Maybe<Scalars["String"]>;
  contentId?: Maybe<Scalars["String"]>;
  contentType?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  iphoneUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reportingKey?: Maybe<Scalars["String"]>;
  webUrl?: Maybe<Scalars["String"]>;
};

/** A typed keyword search result. */
export type CatalogAmpKeywordTypedResult = {
  id: Scalars["Int"];
  androidUrl?: Maybe<Scalars["String"]>;
  contentId?: Maybe<Scalars["String"]>;
  contentType?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  iphoneUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reportingKey?: Maybe<Scalars["String"]>;
  typeName: CatalogAmpResultType;
  webUrl?: Maybe<Scalars["String"]>;
};

/** A playlist (collection) search result. Note that this is different than an `AmpCollection`. */
export type CatalogAmpPlaylistResult = {
  id: Scalars["String"];
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  normRank?: Maybe<Scalars["Float"]>;
  reportingKey: Scalars["String"];
  score: Scalars["Float"];
  urls?: Maybe<CatalogAmpCollectionUrls>;
  userId: Scalars["String"];
};

/** A typed playlist (collection) search result. Note that this is different than an `AmpCollection`. */
export type CatalogAmpPlaylistTypedResult = {
  id: Scalars["String"];
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  reportingKey?: Maybe<Scalars["String"]>;
  score: Scalars["Float"];
  typeName: CatalogAmpResultType;
  urls?: Maybe<CatalogAmpCollectionUrls>;
  userId: Scalars["String"];
};

/** A podcast record from the AMP system. */
export type CatalogAmpPodcast = {
  id: Scalars["Int"];
  brand?: Maybe<Scalars["String"]>;
  customLinks: Array<CatalogAmpPodcastCustomLink>;
  description: Scalars["String"];
  editorialContentQuery?: Maybe<Array<Maybe<Array<Scalars["String"]>>>>;
  follow: Scalars["Boolean"];
  followDate?: Maybe<Scalars["Float"]>;
  heroImage?: Maybe<Scalars["String"]>;
  heroImageColor?: Maybe<Scalars["String"]>;
  hostIds: Array<Scalars["String"]>;
  imageUrl: Scalars["String"];
  isExternal: Scalars["Boolean"];
  isInteractive: Scalars["Boolean"];
  lastUpdated: Scalars["Float"];
  newEpisodeCount?: Maybe<Scalars["Int"]>;
  profileLastViewedDate?: Maybe<Scalars["Float"]>;
  settings?: Maybe<CatalogAmpPodcastSettings>;
  showType?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  socialMediaLinks: Array<CatalogAmpPodcastSocialMediaLink>;
  subtitle?: Maybe<Scalars["String"]>;
  talkbackEnabled: Scalars["Boolean"];
  title: Scalars["String"];
};

export type CatalogAmpPodcastCustomLink = {
  navigationIcon: Scalars["String"];
  link: Scalars["String"];
  title: Scalars["String"];
};

/** A podcast episode record from the AMP system. */
export type CatalogAmpPodcastEpisode = {
  completed?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  duration: Scalars["Int"];
  endDate?: Maybe<Scalars["Float"]>;
  id: Scalars["Int"];
  imageUrl?: Maybe<Scalars["String"]>;
  ingestionDate: Scalars["Float"];
  isExplicit: Scalars["Boolean"];
  isInteractive: Scalars["Boolean"];
  mediaUrl: Scalars["String"];
  new?: Maybe<Scalars["Boolean"]>;
  podcastId: Scalars["Int"];
  podcastSlug: Scalars["String"];
  secondsPlayed?: Maybe<Scalars["Int"]>;
  startDate: Scalars["Float"];
  title: Scalars["String"];
  transcriptionAvailable: Scalars["Boolean"];
};

export type CatalogAmpPodcastEpisodeByPodcastArgs = {
  /** The ID of the podcast to retrieve episodes for. */
  id: Scalars["Int"];
  /** Encoded pagination key. The pageKey can be `next` or `prev` from the `pageInfo` object. */
  pageKey?: Maybe<Scalars["String"]>;
  /** The number of items to return. Default is 20. */
  limit?: Maybe<Scalars["Int"]>;
  /** The sort order (startDate-desc or startDate-asc) */
  sortBy?: Maybe<Scalars["String"]>;
  /** If true, pagination will include episode specified in the pageKey, otherwise it will exclude it. Defaults to false unless fromEpisodeId is specified. */
  pageKeyInclusive?: Maybe<Scalars["Boolean"]>;
  /** Start pagination from this episode ID */
  fromEpisodeId?: Maybe<Scalars["Int"]>;
  /** Include episode progress for this user. If absent, validates the profile ID and session in the headers and uses that profile ID if possible. */
  profileId?: Maybe<Scalars["String"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
};

export type CatalogAmpPodcastEpisodeByPodcastResult = {
  data: Array<CatalogAmpPodcastEpisode>;
  links: CatalogPageInfo;
};

export type CatalogAmpPodcastEpisodeByPodcastResultLinks = {
  next?: Maybe<Scalars["String"]>;
  prev?: Maybe<Scalars["String"]>;
};

export enum CatalogAmpPodcastEpisodeByPodcastSortBy {
  Desc = "DESC",
  Asc = "ASC",
}

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` queries instead") */
export type CatalogAmpPodcastEpisodeInput = {
  /** It can be `US`, `AU`, `CA`, `WW`, `MX`, `NZ`. Default is `US`. */
  country?: Maybe<Scalars["String"]>;
  /** The number of items to return. Default is 20. */
  limit?: Maybe<Scalars["Int"]>;
  /** `startDate-desc`, `startDate-asc`, Default is `startDate-desc` */
  sortBy?: Maybe<Scalars["String"]>;
  /** If true, pagination will include episode specified in the pageKey, otherwise it will exclude it. Defaults to false unless fromEpisodeId is specified. */
  pageKeyInclusive?: Maybe<Scalars["Boolean"]>;
  /** Start pagination from this episode ID */
  fromEpisodeId?: Maybe<Scalars["Int"]>;
  /** Include episode progress for this user. If absent, validates the profile ID and session in the headers and uses that profile ID if possible. */
  profileId?: Maybe<Scalars["Int"]>;
  /** The pageKey can be `next` or `prev` from the `pageInfo` object. */
  pageKey?: Maybe<Scalars["String"]>;
};

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` instead") */
export type CatalogAmpPodcastEpisodeQuery = {
  /**
   * Get the paginated list of podcasts.
   * Default limit is 20.
   * Default sort is by descending.
   * `config` can be same as params from `https://us.api-docs.ihrprod.net/#!/podcast.internal/getFullPodcastEpisodes`
   * @deprecated(reason: "Use `AmpPodcastEpisodesQuery.byPodcastId` instead")
   */
  list: CatalogPodcastEpisodeResult;
  /**
   * Get episodes by episode IDs
   * @deprecated(reason: "Use `AmpPodcastEpisodesQuery.byIds` instead")
   */
  byIds: Array<Maybe<PodcastEpisode>>;
};

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` instead") */
export type CatalogAmpPodcastEpisodeQueryListArgs = {
  showId: Scalars["Int"];
  config?: Maybe<CatalogAmpPodcastEpisodeInput>;
};

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` instead") */
export type CatalogAmpPodcastEpisodeQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
};

export type CatalogAmpPodcastEpisodesQuery = {
  /** Retrieve a podcast episode by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpPodcastEpisode>;
  /** Retrieve a list of podcast episodes by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogAmpPodcastEpisode>>;
  /** Retrieve a list of podcast episodes by podcast id and additional parameters. */
  byPodcast: CatalogAmpPodcastEpisodeByPodcastResult;
};

export type CatalogAmpPodcastEpisodesQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

export type CatalogAmpPodcastEpisodesQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
};

export type CatalogAmpPodcastEpisodesQueryByPodcastArgs = {
  query: CatalogAmpPodcastEpisodeByPodcastArgs;
};

/** Queries available for AMP podcast records. */
export type CatalogAmpPodcastQuery = {
  /** Retrieve a podcast by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpPodcast>;
  /**
   * Queries for Podcast episodes with mediaUrl.
   * @deprecated Use `AmpPodcastEpisodesQuery` queries instead
   */
  episodes: CatalogAmpPodcastEpisodeQuery;
};

/** Queries available for AMP podcast records. */
export type CatalogAmpPodcastQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

/** A podcast search result. Note that this is different than an `AmpPodcast`. */
export type CatalogAmpPodcastResult = {
  id: Scalars["Int"];
  description: Scalars["String"];
  image: Scalars["String"];
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type CatalogAmpPodcastSettings = {
  notifications: Scalars["Boolean"];
};

export type CatalogAmpPodcastSocialMediaLink = {
  link: Scalars["String"];
  name: Scalars["String"];
};

/** A typed podcast search result. Note that this is different than an `AmpPodcast`. */
export type CatalogAmpPodcastTypedResult = {
  id: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  subtitle: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  typeName: CatalogAmpResultType;
};

/**
 * Queries that hit the AMP api directly and return data in the format specified by
 * the AMP documentation.
 */
export type CatalogAmpQuery = {
  /** Queries for AMP album records. */
  albums: CatalogAmpAlbumQuery;
  /** Queries for AMP artist records. */
  artists: CatalogAmpArtistQuery;
  /** Queries for AMP collection (playlist) records. */
  collections: CatalogAmpCollectionQuery;
  /** Queries for AMP podcast records. */
  podcasts: CatalogAmpPodcastQuery;
  /** Queries for AMP podcast episode records. */
  podcastEpisodes: CatalogAmpPodcastEpisodesQuery;
  /** Queries for AMP station (live station, stream) records. */
  stations: CatalogAmpStationQuery;
  /** Queries for AMP track records. */
  tracks: CatalogAmpTrackQuery;
  /** Searches across multiple datatypes via AMP. */
  search: CatalogAmpSearchQuery;
};

export enum CatalogAmpResultType {
  Station = "STATION",
  Artist = "ARTIST",
  Track = "TRACK",
  Album = "ALBUM",
  Podcast = "PODCAST",
  Playlist = "PLAYLIST",
  Keywords = "KEYWORDS",
}

export type CatalogAmpSearchAllArgs = {
  /** The search query. */
  keywords: Scalars["String"];
  /** Optionally boost results for this market. */
  boostMarketId?: Maybe<Scalars["Int"]>;
  /** Start response at this position. */
  startIndex?: Maybe<Scalars["Int"]>;
  /** Fetch no more than this many results. */
  maxRows?: Maybe<Scalars["Int"]>;
  /** Whether to query data science top hit service for a best match. */
  bestMatch?: Maybe<Scalars["Boolean"]>;
  /** Whether to query tracks. */
  track?: Maybe<Scalars["Boolean"]>;
  /** Whether to query albums. */
  bundle?: Maybe<Scalars["Boolean"]>;
  /** Whether to query artists. */
  artist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query live stations. */
  station?: Maybe<Scalars["Boolean"]>;
  /** Whether to query keywords. */
  keyword?: Maybe<Scalars["Boolean"]>;
  /** Whether to query collections. */
  playlist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query podcasts. */
  podcast?: Maybe<Scalars["Boolean"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
};

export type CatalogAmpSearchAllCounts = {
  albums: Scalars["Int"];
  artists: Scalars["Int"];
  keywords: Scalars["Int"];
  playlists: Scalars["Int"];
  podcasts: Scalars["Int"];
  stations: Scalars["Int"];
  tracks: Scalars["Int"];
};

export type CatalogAmpSearchAllItems = {
  albums: Array<CatalogAmpAlbumResult>;
  artists: Array<CatalogAmpArtistResult>;
  keywords: Array<CatalogAmpKeywordResult>;
  playlists: Array<CatalogAmpPlaylistResult>;
  podcasts: Array<CatalogAmpPodcastResult>;
  stations: Array<CatalogAmpStationResult>;
  tracks: Array<CatalogAmpTrackResult>;
};

export type CatalogAmpSearchAllResult = {
  bestMatch?: Maybe<CatalogAmpSearchBestMatchResult>;
  counts: CatalogAmpSearchAllCounts;
  results: CatalogAmpSearchAllItems;
};

export type CatalogAmpSearchBestMatchResult = {
  id?: Maybe<Scalars["Int"]>;
  format: Scalars["String"];
  rankedList?: Maybe<Array<CatalogAmpBestMatch>>;
  reportingKey?: Maybe<Scalars["String"]>;
};

export type CatalogAmpSearchCombinedArgs = {
  /** The search query. */
  keywords: Scalars["String"];
  /** Optionally boost results for this market */
  boostMarketId?: Maybe<Scalars["Int"]>;
  /** Encoded pagination key for next page */
  pageKey?: Maybe<Scalars["String"]>;
  /** Whether to query data science top hit service for a best match */
  bestMatch?: Maybe<Scalars["Boolean"]>;
  /** Whether to query tracks */
  track?: Maybe<Scalars["Boolean"]>;
  /** Whether to query albums */
  bundle?: Maybe<Scalars["Boolean"]>;
  /** Whether to query artists */
  artist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query live stations */
  station?: Maybe<Scalars["Boolean"]>;
  /** Whether to query keywords */
  keyword?: Maybe<Scalars["Boolean"]>;
  /** Whether to query collections */
  playlist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query podcasts */
  podcast?: Maybe<Scalars["Boolean"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
};

export type CatalogAmpSearchCombinedResult = {
  bestMatch?: Maybe<CatalogAmpTypedResult>;
  nextPage?: Maybe<Scalars["String"]>;
  results: Array<CatalogAmpTypedResult>;
  queryId?: Maybe<Scalars["String"]>;
};

export type CatalogAmpSearchQuery = {
  /** Returns AMP search results by content type */
  searchAll: CatalogAmpSearchAllResult;
  /** Returns a single list of search results with mixed content types */
  searchCombined: CatalogAmpSearchCombinedResult;
};

export type CatalogAmpSearchQuerySearchAllArgs = {
  query: CatalogAmpSearchAllArgs;
};

export type CatalogAmpSearchQuerySearchCombinedArgs = {
  query: CatalogAmpSearchCombinedArgs;
};

/** A station record from the AMP system. Also referred to as a "live station" or "stream" */
export type CatalogAmpStation = {
  id: Scalars["Int"];
  callLetters: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  cume?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  esId?: Maybe<Scalars["String"]>;
  freq?: Maybe<Scalars["String"]>;
  genres: Array<CatalogAmpStationGenre>;
  /** Note: AMP docs report this is 'gracenoteEnabled' but it returns as 'gracenote_enabled' */
  gracenote_enabled?: Maybe<Scalars["Boolean"]>;
  isActive: Scalars["Boolean"];
  logo?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<CatalogAmpStationMarket>>;
  modified?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
  provider?: Maybe<Scalars["String"]>;
  streams: Scalars["JSON"];
  talkbackEnabled?: Maybe<Scalars["Boolean"]>;
};

export type CatalogAmpStationGenre = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  isPrimary: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  sortIndex: Scalars["Int"];
};

export type CatalogAmpStationMarket = {
  id: Scalars["Int"];
  city: Scalars["String"];
  cityId: Scalars["Int"];
  country: Scalars["String"];
  countryId: Scalars["Int"];
  isOrigin: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  name: Scalars["String"];
  sortIndex?: Maybe<Scalars["Int"]>;
  state: Scalars["String"];
  stateId: Scalars["Int"];
};

/** Queries available for AMP station (live station, stream) records. */
export type CatalogAmpStationQuery = {
  /** Retrieve a station by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpStation>;
};

/** Queries available for AMP station (live station, stream) records. */
export type CatalogAmpStationQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

/** A station search result. Note that this is different than an `AmpStation`. */
export type CatalogAmpStationResult = {
  id: Scalars["Int"];
  callLetters?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  format?: Maybe<Scalars["String"]>;
  frequency?: Maybe<Scalars["String"]>;
  genre?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  streamingPlatform?: Maybe<Scalars["String"]>;
  talkbackEnabled?: Maybe<Scalars["Boolean"]>;
};

/** A typed station search result. Note that this is different than an `AmpStation`. */
export type CatalogAmpStationTypedResult = {
  id: Scalars["Int"];
  callLetters?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  format?: Maybe<Scalars["String"]>;
  frequency?: Maybe<Scalars["String"]>;
  genre?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  score: Scalars["Float"];
  streamingPlatform?: Maybe<Scalars["String"]>;
  talkbackEnabled?: Maybe<Scalars["Boolean"]>;
  typeName: CatalogAmpResultType;
};

/** A track record from the AMP system. */
export type CatalogAmpTrack = {
  id: Scalars["Int"];
  albumId: Scalars["Int"];
  albumName: Scalars["String"];
  artistId: Scalars["Int"];
  artistName: Scalars["String"];
  duration?: Maybe<Scalars["Int"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  playbackRights: CatalogAmpTrackPlaybackRights;
  publisher?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  trackNumber: Scalars["Int"];
  version?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["Int"]>;
};

export type CatalogAmpTrackPlaybackRights = {
  onDemand?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for AMP track records. */
export type CatalogAmpTrackQuery = {
  /** Retrieve a track by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogAmpTrack>;
  /** Retrieve a series of tracks by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogAmpTrack>>;
};

/** Queries available for AMP track records. */
export type CatalogAmpTrackQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
};

/** Queries available for AMP track records. */
export type CatalogAmpTrackQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
};

/** A track search result. Note that this is different than an `AmpTrack`. */
export type CatalogAmpTrackResult = {
  id: Scalars["Int"];
  albumId: Scalars["Int"];
  albumName: Scalars["String"];
  artistId: Scalars["Int"];
  artistName: Scalars["String"];
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  playbackRights: CatalogAmpTrackPlaybackRights;
  rank: Scalars["Float"];
  score: Scalars["Float"];
  title?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

/** A typed track search result. Note that this is different than an `AmpTrack`. */
export type CatalogAmpTrackTypedResult = {
  id: Scalars["Int"];
  albumId: Scalars["Int"];
  albumName: Scalars["String"];
  artistId?: Maybe<Scalars["Int"]>;
  artistName?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  normRank?: Maybe<Scalars["Float"]>;
  playbackRights: CatalogAmpTrackPlaybackRights;
  rank: Scalars["Float"];
  score: Scalars["Float"];
  title?: Maybe<Scalars["String"]>;
  typeName: CatalogAmpResultType;
  version?: Maybe<Scalars["String"]>;
};

export type CatalogAmpTypedResult =
  | CatalogAmpAlbumTypedResult
  | CatalogAmpTrackTypedResult
  | CatalogAmpArtistTypedResult
  | CatalogAmpPodcastTypedResult
  | CatalogAmpKeywordTypedResult
  | CatalogAmpStationTypedResult
  | CatalogAmpPlaylistTypedResult;

/** A catalog artist record. */
export type CatalogArtist = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  amp?: Maybe<CatalogAmpArtist>;
};

/** Queries available for catalog artist records. */
export type CatalogArtistQuery = {
  /** Retrieve an artist by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogArtist>;
  /** Retrieve a series of artists by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogArtist>>;
};

/** Queries available for catalog artist records. */
export type CatalogArtistQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for catalog artist records. */
export type CatalogArtistQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogByRefArgs = {
  kind: CatalogKind;
  id: Scalars["ID"];
  country?: Maybe<Scalars["String"]>;
};

export type CatalogFunctions = {
  amp: CatalogAmpQuery;
  catalog: CatalogQuery;
};

/** The kind of catalog item. This will be overridden by the catalog service / WebAPI. */
export enum CatalogKind {
  Album = "ALBUM",
  Artist = "ARTIST",
  Track = "TRACK",
  Station = "STATION",
  Podcast = "PODCAST",
  PodcastEpisode = "PODCAST_EPISODE",
  Playlist = "PLAYLIST",
}

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` queries instead") */
export type CatalogPageInfo = {
  next?: Maybe<Scalars["String"]>;
  prev?: Maybe<Scalars["String"]>;
};

/** A catalog playlist record. */
export type CatalogPlaylist = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
  playlistId: Scalars["String"];
  description: Scalars["String"];
  amp?: Maybe<CatalogAmpCollection>;
};

/** Queries available for catalog playlist (also referred to as collection) records. */
export type CatalogPlaylistQuery = {
  /** Retrieve a playlist by userId, id, and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogPlaylist>;
  /** Retrieve a playlist by ref (userId::id) and country. If a country is not supplied, will default to 'US'. */
  byRef?: Maybe<CatalogPlaylist>;
};

/** Queries available for catalog playlist (also referred to as collection) records. */
export type CatalogPlaylistQueryByIdArgs = {
  userId: Scalars["String"];
  id: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
};

/** Queries available for catalog playlist (also referred to as collection) records. */
export type CatalogPlaylistQueryByRefArgs = {
  ref: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
};

/** A catalog podcast record. */
export type CatalogPodcast = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  amp?: Maybe<CatalogAmpPodcast>;
};

/** A catalog podcast record. */
export type CatalogPodcastEpisode = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  podcastId: Scalars["Int"];
  podcastSlug: Scalars["String"];
  description: Scalars["String"];
  amp?: Maybe<CatalogAmpPodcastEpisode>;
};

export type CatalogPodcastEpisodeByPodcastArgs = {
  /** The ID of the podcast to retrieve episodes for. */
  podcastId: Scalars["Int"];
  /** Encoded pagination key. The pageKey can be `nextPage` or `prevPage` from the response object. */
  pageKey?: Maybe<Scalars["String"]>;
  /** The number of items to return. Default is 20. */
  limit?: Maybe<Scalars["Int"]>;
  /** The sort order (startDate-desc or startDate-asc) */
  sortBy?: Maybe<CatalogPodcastEpisodeByPodcastSortBy>;
  /** If true, pagination will include episode specified in the pageKey, otherwise it will exclude it. Defaults to false unless fromEpisodeId is specified. */
  pageKeyInclusive?: Maybe<Scalars["Boolean"]>;
  /** Start pagination from this episode ID */
  fromEpisodeId?: Maybe<Scalars["Int"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
  /** If true, will return the episode with autoplay links. */
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogPodcastEpisodeByPodcastResult = {
  nextPage?: Maybe<Scalars["String"]>;
  prevPage?: Maybe<Scalars["String"]>;
  results: Array<CatalogPodcastEpisode>;
};

export enum CatalogPodcastEpisodeByPodcastSortBy {
  StartDateDesc = "START_DATE_DESC",
  StartDateAsc = "START_DATE_ASC",
}

/** Queries available for catalog podcast episode records. */
export type CatalogPodcastEpisodeQuery = {
  /** Retrieve a podcast episode by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogPodcastEpisode>;
  /** Retrieve a series of podcast episodes by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogPodcastEpisode>>;
  /** Retrieve a series of podcast episodes by podcast id and additional parameters. */
  byPodcast: CatalogPodcastEpisodeByPodcastResult;
};

/** Queries available for catalog podcast episode records. */
export type CatalogPodcastEpisodeQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for catalog podcast episode records. */
export type CatalogPodcastEpisodeQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for catalog podcast episode records. */
export type CatalogPodcastEpisodeQueryByPodcastArgs = {
  query: CatalogPodcastEpisodeByPodcastArgs;
};

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` queries instead") */
export type CatalogPodcastEpisodeResponse = {
  data: Array<Maybe<PodcastEpisode>>;
  links: CatalogPageInfo;
};

/** @deprecated(reason: "Use `AmpPodcastEpisodesQuery` queries instead") */
export type CatalogPodcastEpisodeResult = {
  items: Array<Maybe<PodcastEpisode>>;
  count: Scalars["Int"];
  pageInfo: CatalogPageInfo;
};

/** Queries available for catalog podcast records. */
export type CatalogPodcastQuery = {
  /** Retrieve a podcast by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogPodcast>;
};

/** Queries available for catalog podcast records. */
export type CatalogPodcastQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogPointerRef = {
  id: Scalars["ID"];
  kind: Scalars["String"];
  summary?: Maybe<CatalogServiceSummary>;
};

/** Queries that wrap native AMP responses in a more unified structure. */
export type CatalogQuery = {
  /** Queries for catalog albums */
  albums: CatalogAlbumQuery;
  /** Queries for catalog artists */
  artists: CatalogArtistQuery;
  /** Queries for catalog playlists (collections) */
  playlists: CatalogPlaylistQuery;
  /** Queries for catalog podcasts */
  podcasts: CatalogPodcastQuery;
  /** Queries for catalog podcast episodes */
  podcastEpisodes: CatalogPodcastEpisodeQuery;
  /** Queries for catalog stations (live station, stream) */
  stations: CatalogStationQuery;
  /** Queries for catalog tracks */
  tracks: CatalogTrackQuery;
  /** Searches across multiple data types */
  search: CatalogSearchQuery;
};

export type CatalogRecord =
  | CatalogAlbum
  | CatalogArtist
  | CatalogTrack
  | CatalogStation
  | CatalogPodcast
  | CatalogPlaylist
  | CatalogPodcastEpisode;

/** Interface to make building fragments on common fields of the different possible types of CatalogRecord */
export type CatalogRecordCommonFields = {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
};

export type CatalogRef = {
  id: Scalars["String"];
  type: Scalars["String"];
  name: Scalars["String"];
};

/** A reference to a catalog item. This will be overridden by the catalog service / WebAPI. */
export type CatalogReference = {
  id: Scalars["ID"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  record?: Maybe<CatalogRecord>;
};

/** The input for a catalog reference. */
export type CatalogReferenceInput = {
  /** The ID of the catalog item. */
  id: Scalars["ID"];
  /** The kind of catalog item. */
  kind: CatalogKind;
  /** The country of the catalog item. */
  country: Scalars["String"];
};

export type CatalogSearchAllArgs = {
  /** The search query. */
  keywords: Scalars["String"];
  /** Optionally boost results for this market. */
  boostMarketId?: Maybe<Scalars["Int"]>;
  /** Whether to query artists. */
  artist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query tracks. */
  track?: Maybe<Scalars["Boolean"]>;
  /** Whether to query albums. */
  album?: Maybe<Scalars["Boolean"]>;
  /** Whether to query stations. */
  station?: Maybe<Scalars["Boolean"]>;
  /** Whether to query playlists. */
  playlist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query podcasts. */
  podcast?: Maybe<Scalars["Boolean"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
  /** Fetch no more than this many results. */
  limit?: Maybe<Scalars["Int"]>;
  /** Start response at this position. */
  offset?: Maybe<Scalars["Int"]>;
  /** Whether generated web links should include the autoplay parameter */
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogSearchAllResult = {
  albums: Array<CatalogAlbum>;
  artists: Array<CatalogArtist>;
  playlists: Array<CatalogPlaylist>;
  podcasts: Array<CatalogPodcast>;
  stations: Array<CatalogStation>;
  tracks: Array<CatalogTrack>;
};

export type CatalogSearchCombinedArgs = {
  /** The search query. */
  keywords: Scalars["String"];
  /** Optionally boost results for this market. */
  boostMarketId?: Maybe<Scalars["Int"]>;
  /** Encoded pagination key for next page */
  pageKey?: Maybe<Scalars["String"]>;
  /** Whether to query artists. */
  artist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query tracks. */
  track?: Maybe<Scalars["Boolean"]>;
  /** Whether to query albums. */
  album?: Maybe<Scalars["Boolean"]>;
  /** Whether to query stations. */
  station?: Maybe<Scalars["Boolean"]>;
  /** Whether to query playlists. */
  playlist?: Maybe<Scalars["Boolean"]>;
  /** Whether to query podcasts. */
  podcast?: Maybe<Scalars["Boolean"]>;
  /** The country for which to run the query. If not defined, falls back to 'US'. */
  country?: Maybe<Scalars["String"]>;
  /** Whether generated web links should include the autoplay parameter */
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogSearchCombinedResult = {
  nextPage?: Maybe<Scalars["String"]>;
  results: Array<CatalogRecord>;
};

export type CatalogSearchQuery = {
  /** Returns catalog search results by content type. */
  searchAll: CatalogSearchAllResult;
  /** Returns a single list of search results with mixed content types. */
  searchCombined: CatalogSearchCombinedResult;
  /** Retrieve a CatalogRecord by kind, id, and country */
  byRef?: Maybe<CatalogRecord>;
};

export type CatalogSearchQuerySearchAllArgs = {
  query: CatalogSearchAllArgs;
};

export type CatalogSearchQuerySearchCombinedArgs = {
  query: CatalogSearchCombinedArgs;
};

export type CatalogSearchQueryByRefArgs = {
  ref: CatalogByRefArgs;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogServiceSummary = {
  name: Scalars["String"];
  img: Scalars["String"];
};

/** A catalog station record. */
export type CatalogStation = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  amp?: Maybe<CatalogAmpStation>;
};

/** Queries available for catalog station (live station, stream) records. */
export type CatalogStationQuery = {
  /** Retrieve a station by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogStation>;
};

/** Queries available for catalog station (live station, stream) records. */
export type CatalogStationQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** A catalog track record. */
export type CatalogTrack = CatalogRecordCommonFields & {
  id: Scalars["String"];
  kind: CatalogKind;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  img: Scalars["String"];
  web_link?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  artistId: Scalars["Int"];
  artistName: Scalars["String"];
  amp?: Maybe<CatalogAmpTrack>;
};

/** Queries available for catalog track records. */
export type CatalogTrackQuery = {
  /** Retrieve a track by id and country. If a country is not supplied, will default to 'US'. */
  byId?: Maybe<CatalogTrack>;
  /** Retrieve a series of tracks by id and country. If a country is not supplied, will default to 'US'. */
  byIds: Array<Maybe<CatalogTrack>>;
  /** Retrieve multiple tracks by id and country. */
  byIdsWithCountry: Array<Maybe<CatalogTrack>>;
};

/** Queries available for catalog track records. */
export type CatalogTrackQueryByIdArgs = {
  id: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for catalog track records. */
export type CatalogTrackQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

/** Queries available for catalog track records. */
export type CatalogTrackQueryByIdsWithCountryArgs = {
  refs: Array<CatalogTrackWithCountryInput>;
  autoplay?: Maybe<Scalars["Boolean"]>;
};

export type CatalogTrackWithCountryInput = {
  id: Scalars["Int"];
  country: Scalars["String"];
};

export type ChartFunctions = {
  charts: ChartsCharts;
  lists: ChartsLists;
  chartDates: ChartsChartDates;
};

export type ChartsChart = {
  brand: SiteRef;
  created: DateRef;
  createdBy: Scalars["String"];
  published: DateRef;
  slug: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  subtitle: Scalars["String"];
  canonicalUrl?: Maybe<Scalars["String"]>;
  lists: ChartsListResults;
  dates?: Maybe<ChartsChartDatesList>;
};

export type ChartsChartListsArgs = {
  filters?: Maybe<ChartsListsFilters>;
};

export type ChartsChartDates = {
  byId?: Maybe<ChartsChartDatesList>;
  bySlug?: Maybe<ChartsChartDatesList>;
};

export type ChartsChartDatesByIdArgs = {
  id: Scalars["String"];
};

export type ChartsChartDatesBySlugArgs = {
  slug: Scalars["String"];
};

export type ChartsChartDatesList = {
  years: Array<Scalars["String"]>;
  months: Array<ChartsChartYearMonth>;
};

export type ChartsChartListSong = {
  artist?: Maybe<Scalars["String"]>;
  catalogRef?: Maybe<CatalogPointerRef>;
  artistRef?: Maybe<CatalogPointerRef>;
  delta: Scalars["Int"];
  image?: Maybe<Scalars["String"]>;
  peak: Scalars["Int"];
  pos: Scalars["Int"];
  timeOnChart: Scalars["Int"];
  title: Scalars["String"];
  type: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
};

export type ChartsChartYearMonth = {
  year: Scalars["Int"];
  months: Array<Scalars["Int"]>;
};

export type ChartsCharts = {
  byId?: Maybe<ChartsChart>;
  bySlug?: Maybe<ChartsChart>;
  filter: ChartsChartsResults;
};

export type ChartsChartsByIdArgs = {
  id: Scalars["String"];
};

export type ChartsChartsBySlugArgs = {
  slug: Scalars["String"];
};

export type ChartsChartsFilterArgs = {
  filters?: Maybe<ChartsChartsFilters>;
};

export type ChartsChartsFilters = {
  searchTerm?: Maybe<Scalars["String"]>;
  editor?: Maybe<Scalars["String"]>;
  cursor?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
};

export type ChartsChartsResults = ChartsPagedResult & {
  items: Array<ChartsChart>;
  count: Scalars["Int"];
  pageInfo: ChartsPageInfo;
};

export type ChartsList = {
  id: Scalars["String"];
  chart?: Maybe<ChartsChart>;
  title: Scalars["String"];
  chartDate?: Maybe<DateRef>;
  created: DateRef;
  createdBy: Scalars["String"];
  published: DateRef;
  publishedEnd?: Maybe<DateRef>;
  slug: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  songs: Array<ChartsChartListSong>;
  previousList?: Maybe<ChartsList>;
  nextList?: Maybe<ChartsList>;
  topItems?: Maybe<Scalars["String"]>;
};

export type ChartsListResults = ChartsPagedResult & {
  items: Array<ChartsList>;
  count: Scalars["Int"];
  pageInfo: ChartsPageInfo;
};

export type ChartsLists = {
  byId?: Maybe<ChartsList>;
  bySlug?: Maybe<ChartsList>;
  filter: ChartsListResults;
};

export type ChartsListsByIdArgs = {
  id: Scalars["String"];
};

export type ChartsListsBySlugArgs = {
  slug: Scalars["String"];
};

export type ChartsListsFilterArgs = {
  chartId?: Maybe<Scalars["String"]>;
  chartSlug?: Maybe<Scalars["String"]>;
  filters?: Maybe<ChartsListsFilters>;
};

export type ChartsListsFilters = {
  chartDate?: Maybe<Scalars["Float"]>;
  startDate?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["Float"]>;
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type ChartsPageInfo = {
  nextCursor?: Maybe<Scalars["String"]>;
  prevCursor?: Maybe<Scalars["String"]>;
};

export type ChartsPagedResult = {
  count: Scalars["Int"];
  pageInfo: ChartsPageInfo;
};

export type ChartsPagedResults = ChartsPagedResult & {
  count: Scalars["Int"];
  pageInfo: ChartsPageInfo;
};

export type Collection = {
  id: Scalars["String"];
  userId: Scalars["String"];
  name: Scalars["String"];
  duration: Scalars["Float"];
  dateCreated: Scalars["Float"];
  lastUpdated: Scalars["Float"];
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  reportingKey: Scalars["String"];
  slug: Scalars["String"];
  urls: Array<CollectionUrls>;
  writeable: Scalars["Boolean"];
  deletable: Scalars["Boolean"];
  renameable: Scalars["Boolean"];
  followable: Scalars["Boolean"];
  followed: Scalars["Boolean"];
  curated: Scalars["Boolean"];
  shareable: Scalars["Boolean"];
  allowed?: Maybe<Scalars["Int"]>;
  prepopulated?: Maybe<Scalars["Int"]>;
  tracks: Array<CollectionItem>;
  premium?: Maybe<Scalars["Boolean"]>;
  backfillTracks: Array<Scalars["Int"]>;
};

export type CollectionItem = {
  id: Scalars["String"];
  trackId: Scalars["Int"];
  track?: Maybe<PublicTrack>;
};

export type CollectionUrls = {
  web: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  play: Scalars["String"];
  goto?: Maybe<Scalars["String"]>;
};

export type CombinedPnpTrackHistory = TrackHistory & {
  artistId: Scalars["Int"];
  albumId: Scalars["Int"];
  trackId: Scalars["Int"];
  title: Scalars["String"];
  trackDuration?: Maybe<Scalars["Int"]>;
  imagePath?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  startTime: Scalars["Int"];
  endTime: Scalars["Int"];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  trackDurationInSec?: Maybe<Scalars["Int"]>;
  dataSource?: Maybe<Scalars["String"]>;
  isCurrentlyPlayingTrack: Scalars["Boolean"];
  artist?: Maybe<Artist>;
  artistName: Scalars["String"];
  albumName: Scalars["String"];
};

export type ConfigData = {
  /**
   * _id: String
   * id: String
   */
  index: BaseIndex;
  config: BaseConfig;
};

export type ContentFunctions = {
  item?: Maybe<ContentRecord>;
  /**
   * Lookup the full contents of a piece of content by its id
   * DEPRECATED
   */
  id?: Maybe<ContentRecord>;
  /**
   * Lookup the full contents of a piece of content by its slug
   * DEPRECATED
   */
  slug?: Maybe<ContentRecord>;
  /**
   * Accepts a complex data structure of type `PublishingQueryInput`. This allows you to
   * do advanced searches against the content publishing database.
   */
  query: Array<ContentRecord>;
};

export type ContentFunctionsItemArgs = {
  select: ContentQuery;
};

export type ContentFunctionsIdArgs = {
  id: Scalars["String"];
};

export type ContentFunctionsSlugArgs = {
  slug: Scalars["String"];
};

export type ContentFunctionsQueryArgs = {
  query: QueryInput;
};

export type ContentPayload = {
  include_recommendations: Scalars["Boolean"];
  fb_allow_comments: Scalars["Boolean"];
  amp_enabled?: Maybe<Scalars["Boolean"]>;
  seo_title?: Maybe<Scalars["String"]>;
  social_title?: Maybe<Scalars["String"]>;
  publish_origin: Scalars["String"];
  keywords: Array<Scalars["String"]>;
  blocks: Array<Scalars["JSON"]>;
  canonical_url: Scalars["String"];
  cuser: Scalars["String"];
  external_url?: Maybe<Scalars["String"]>;
  is_sponsored: Scalars["Boolean"];
  simplereach_enabled?: Maybe<Scalars["Boolean"]>;
  permalink?: Maybe<Scalars["String"]>;
  feed_permalink?: Maybe<Scalars["String"]>;
  feed_vendor?: Maybe<Scalars["String"]>;
  show_updated_timestamp?: Maybe<Scalars["Boolean"]>;
  feed_content_id?: Maybe<Scalars["String"]>;
  enable_featured_widget?: Maybe<Scalars["Boolean"]>;
  featured_widget?: Maybe<Scalars["JSON"]>;
};

export type ContentQuery = {
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type ContentRecord = {
  ref_id: Scalars["ID"];
  slug: Scalars["String"];
  summary: PublishSummary;
  payload: ContentPayload;
  subscription: Array<Subscription>;
  ad_params: AdParams;
  pub_start: Scalars["Float"];
  pub_until?: Maybe<Scalars["Float"]>;
  pub_changed?: Maybe<Scalars["Float"]>;
  eyebrow?: Maybe<Eyebrow>;
};

export type CurrentKeyword = {
  previous: KeywordDetails;
  current: KeywordDetails;
  next: KeywordDetails;
};

export type CustomLink = {
  title: Scalars["String"];
  link: Scalars["String"];
  navigationIcon: Scalars["String"];
};

export type DatasourceFeed = {
  entries?: Maybe<Array<DatasourceFeedEntry>>;
  id: Scalars["String"];
  repeat_start_idx?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

export type DatasourceFeedEntry = {
  limit: Scalars["Float"];
  offset: Scalars["Float"];
  ref_id: Scalars["String"];
  type: Scalars["String"];
};

export type DatasourceFeedItem = {
  ref_id?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  data?: Maybe<Scalars["JSON"]>;
};

export type DatasourcePubsubQuery = {
  type?: Maybe<Scalars["String"]>;
  targets?: Maybe<Array<DatasourcePubsubTarget>>;
};

export type DatasourcePubsubTarget = {
  what?: Maybe<Array<Maybe<Scalars["String"]>>>;
  where?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DatasourceQuery = {
  id: Scalars["String"];
  title: Scalars["String"];
  pubsub: DatasourcePubsubQuery;
};

export type DateRef = {
  timestampMs: Scalars["Float"];
  timestamp: Scalars["Float"];
  iso8601: Scalars["String"];
};

/** Standard ObjectDB resource metadata. */
export type DocumentMetadata = {
  _id: Scalars["String"];
  annotations: QueryAnnotations;
  base_id: Scalars["String"];
  bucket: Scalars["String"];
  ctime: Scalars["Float"];
  cuser: Scalars["String"];
  deleted: Scalars["Boolean"];
  member_of: Array<Scalars["String"]>;
  mimetype?: Maybe<Scalars["String"]>;
  mtime: Scalars["Float"];
  muser: Scalars["String"];
  parent_id?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  ref_map?: Maybe<Scalars["JSON"]>;
  refs: Array<Scalars["String"]>;
  schema: Scalars["String"];
  uri?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Eyebrow = {
  topic?: Maybe<Scalars["String"]>;
  topic_name?: Maybe<Scalars["String"]>;
};

export type FeedRef = {
  widget?: Maybe<Scalars["Boolean"]>;
  sponsored?: Maybe<Scalars["Boolean"]>;
  childOriented?: Maybe<Scalars["Boolean"]>;
  regGate?: Maybe<Scalars["Boolean"]>;
  feed?: Maybe<Scalars["String"]>;
  enableTritonTracking?: Maybe<Scalars["Boolean"]>;
  site_id?: Maybe<Scalars["Float"]>;
};

export type Genre = {
  id: Scalars["Int"];
  brand?: Maybe<Scalars["String"]>;
  countries?: Maybe<Array<Maybe<Scalars["String"]>>>;
  genreGroup: Scalars["String"];
  genreName: Scalars["String"];
  metadata: GenreMeta;
  sortOrder?: Maybe<Scalars["Int"]>;
  brandRef?: Maybe<SiteRef>;
  /** @deprecated No longer supported. Use the `brandRef` resolver to select into site data. */
  site?: Maybe<SiteFunctions>;
};

export type GenreFunctions = {
  /** Lookup Leads cards targeted to the specified genre. */
  leads: Array<Card>;
  /** Lookup articles that related to the genre specified. */
  content: Array<ContentRecord>;
  /** Lookup genre object from streams based on genre id */
  genre?: Maybe<Genre>;
};

export type GenreFunctionsLeadsArgs = {
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  locale?: Maybe<Scalars["String"]>;
};

export type GenreFunctionsContentArgs = {
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type GenreMeta = {
  icons?: Maybe<Icons>;
};

export type GenreRef = {
  id: Scalars["Float"];
  name: Scalars["String"];
  country: Scalars["String"];
  sortIndex?: Maybe<Scalars["Float"]>;
  isPrimary?: Maybe<Scalars["Boolean"]>;
};

export type HostAmpClient = {
  id: Scalars["Int"];
  hostName: Scalars["String"];
  allowedCountries?: Maybe<Array<Scalars["String"]>>;
  authScheme?: Maybe<Scalars["String"]>;
  channelId: Scalars["Int"];
  countryCode?: Maybe<Scalars["String"]>;
  deeplink?: Maybe<Scalars["String"]>;
  fbDisplay?: Maybe<Scalars["String"]>;
  providers?: Maybe<Array<Scalars["String"]>>;
  noGoodAfter?: Maybe<Scalars["Int"]>;
  rangeAuthTTL?: Maybe<Scalars["Int"]>;
  streamBaseUrl?: Maybe<Scalars["String"]>;
  streamType?: Maybe<Array<Scalars["String"]>>;
  talkBaseUrl?: Maybe<Scalars["String"]>;
  terminalId?: Maybe<Scalars["Int"]>;
};

export type HostThirdPartyType = {
  id: Scalars["Int"];
  device?: Maybe<Scalars["String"]>;
  salt?: Maybe<Scalars["String"]>;
};

export type IhrUser = {
  profileId: Scalars["ID"];
  iHeartId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  hometown?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type IhrUserRef = {
  id: Scalars["ID"];
  amp?: Maybe<IhrUser>;
};

export type Icon = {
  url: Scalars["String"];
  genreName: Scalars["String"];
  display: Scalars["Boolean"];
  locales: Array<Maybe<Scalars["String"]>>;
};

export type Icons = {
  primary?: Maybe<Array<Maybe<Icon>>>;
  secondary?: Maybe<Array<Maybe<Icon>>>;
};

export type KeywordDetails = {
  sort?: Maybe<Scalars["String"]>;
  timeStamp?: Maybe<Scalars["Float"]>;
  endTime?: Maybe<Scalars["Float"]>;
  isComplete?: Maybe<Scalars["Boolean"]>;
  isLive?: Maybe<Scalars["Boolean"]>;
  isStarted?: Maybe<Scalars["Boolean"]>;
  isSubmittable?: Maybe<Scalars["Boolean"]>;
  active?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  answer?: Maybe<Scalars["String"]>;
  countdownKeywordId?: Maybe<Scalars["String"]>;
  prize?: Maybe<Scalars["JSON"]>;
};

export type LiveMetaCurrentlyPlaying = {
  count: Scalars["Int"];
  pageInfo: AmpPageInfo;
  tracks: Array<CombinedPnpTrackHistory>;
};

export type LiveMetaRecentlyPlayed = {
  count: Scalars["Int"];
  pageInfo: AmpPageInfo;
  tracks: Array<PnpTrackHistory>;
};

export type LiveMetaTopArtists = {
  count: Scalars["Int"];
  pageInfo: AmpPageInfo;
  artists: Array<TopArtist>;
};

export type LiveMetaTopTracks = {
  count: Scalars["Int"];
  pageInfo: AmpPageInfo;
  tracks: Array<TopTrack>;
};

export type MarketRef = {
  id: Scalars["Float"];
  name: Scalars["String"];
  city: Scalars["String"];
  state: Scalars["String"];
  stateId: Scalars["Float"];
  country: Scalars["String"];
  countryId: Scalars["Float"];
  sortIndex: Scalars["Float"];
  cityId: Scalars["Float"];
  isOrigin: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
};

export type MediaAsset = {
  bucket: Scalars["String"];
  resourceId: Scalars["String"];
  metadata?: Maybe<MediaAssetMetadata>;
  href: Scalars["String"];
};

export type MediaAssetHrefArgs = {
  cdnUrl?: Maybe<Scalars["String"]>;
};

export type MediaAssetMetadata = {
  mimetype?: Maybe<Scalars["String"]>;
  format: Scalars["String"];
  width: Scalars["Int"];
  height: Scalars["Int"];
  length: Scalars["Int"];
  keys: Array<Scalars["String"]>;
  signature: Scalars["String"];
  properties: Scalars["JSON"];
  generated: Scalars["Int"];
  source: MediaAssetSource;
};

export type MediaAssetRef = {
  id: Scalars["ID"];
  asset?: Maybe<MediaAsset>;
};

export type MediaAssetSource = {
  _id: Scalars["String"];
  bucket: Scalars["String"];
  object: Scalars["JSON"];
  uri: Scalars["String"];
  is_blacklisted: Scalars["Boolean"];
};

export type MicroConfig = BaseConfig & {
  legacy_id: Scalars["Int"];
  general: MicroGeneral;
  navigation: MicroNavigation;
  contact: MicroContact;
  social: MicroSocial;
  /** pages: [Page]! */
  pages: Scalars["JSON"];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
};

export type MicroContact = {
  full_bio?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  txt_number?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
};

export type MicroData = ConfigData & {
  /**
   * _id: String
   * id: String
   */
  index: MicroIndex;
  config: MicroConfig;
};

export type MicroGeneral = {
  name: Scalars["String"];
  description: Scalars["String"];
  default_content: Array<Maybe<Scalars["String"]>>;
  keywords: Array<Scalars["String"]>;
  linked_shows: Array<Scalars["String"]>;
  thumbnail_image?: Maybe<Scalars["ImageRef"]>;
  sixteen_by_nine?: Maybe<Scalars["ImageRef"]>;
  ihr_channel_switch: Scalars["Boolean"];
  ihr_channel_type: Scalars["String"];
  ihr_podcast?: Maybe<CatalogRef>;
  ihr_live_stream?: Maybe<CatalogRef>;
  ihr_hero_image?: Maybe<Scalars["ImageRef"]>;
  ihr_hero_color?: Maybe<Scalars["String"]>;
  status: MicroGeneralStatus;
  active_start: Scalars["Int"];
  active_end: Scalars["Int"];
  password_switch: Scalars["Boolean"];
  password: Scalars["String"];
  hero_image?: Maybe<Scalars["ImageRef"]>;
  hero_fallback_color?: Maybe<Scalars["String"]>;
  simplereach_enabled?: Maybe<Scalars["Boolean"]>;
  recommendations: Scalars["Boolean"];
  default_feed?: Maybe<Scalars["String"]>;
};

export enum MicroGeneralStatus {
  Active = "active",
  Inactive = "inactive",
}

export type MicroIndex = BaseIndex & {
  id: Scalars["String"];
  brand_id: Scalars["String"];
  legacy_id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  configs: Array<BaseIndexConfigPointer>;
  default: Scalars["String"];
  description: Scalars["String"];
  image?: Maybe<Scalars["ImageRef"]>;
  slug: Scalars["String"];
  facets: Array<Scalars["String"]>;
  tags: Array<Maybe<Scalars["String"]>>;
  own_brand: Scalars["String"];
  /** Microsite specific fields */
  primary_site: Scalars["String"];
  linked_shows: Array<Scalars["String"]>;
  config: MicroConfig;
};

export type MicroIndexConfigArgs = {
  variant?: Maybe<Scalars["String"]>;
};

export type MicroNavigation = {
  enable_nav_switch: Scalars["Boolean"];
  menu: Array<Maybe<SiteMenu>>;
};

export type MicroSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  facebook_name?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  twitter_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  youtube_name?: Maybe<Scalars["String"]>;
  googleplus_switch?: Maybe<Scalars["Boolean"]>;
  googleplus_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  instagram_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  pinterest_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  snapchat_name?: Maybe<Scalars["String"]>;
};

export type MicrositeRef = {
  brand_id: Scalars["String"];
  slug: Scalars["String"];
};

export type Mutation = {
  premiereMember: PwsPremiereMemberMutation;
  talkback: TalkBackMutation;
  postup: PostupMutation;
  /** @deprecated Perform a rolling restart of the running webapi build to reload schemas. This mutation does nothing. */
  cacheClear?: Maybe<CacheResponse>;
  reloadSchema?: Maybe<Scalars["Boolean"]>;
};

export type MutationCacheClearArgs = {
  keys?: Maybe<Array<Scalars["String"]>>;
};

export type PwsAmpPodcastEpisodeInput = {
  /** It can be `US`, `AU`, `CA`, `WW`, `MX`, `NZ`. Default is `US`. */
  country?: Maybe<Scalars["String"]>;
  /** The number of items to return. Default is 20. */
  limit?: Maybe<Scalars["Int"]>;
  /** `startDate-desc`, `startDate-asc`, Default is `startDate-desc` */
  sortBy?: Maybe<Scalars["String"]>;
  /** If true, pagination will include episode specified in the pageKey, otherwise it will exclude it. Defaults to false unless fromEpisodeId is specified. */
  pageKeyInclusive?: Maybe<Scalars["Boolean"]>;
  /** Start pagination from this episode ID */
  fromEpisodeId?: Maybe<Scalars["Int"]>;
  /** Include episode progress for this user. If absent, validates the profile ID and session in the headers and uses that profile ID if possible. */
  profileId?: Maybe<Scalars["Int"]>;
  /** The pageKey can be `next` or `prev` from the `pageInfo` object. */
  pageKey?: Maybe<Scalars["String"]>;
};

export type PwsCoastAppContentModule = {
  /** The featured module is used to load the featured section of a particular content type (Such as shows or articles) */
  featured: PwsCoastFeaturedResult;
  /**
   * The search module is used to search Coast content by a search term.
   * You can either search by a specific content type, or use the `doMerge` option to search across all content types.
   * Either `doMerge` **or** one of the include options must be supplied
   */
  search: PwsCoastSearchResponse;
  show: PwsCoastShowModule;
  article: PwsCoastArticleModule;
  guest: PwsCoastGuestModule;
  host: PwsCoastHostModule;
  topics: PwsCoastTopicsResponse;
};

export type PwsCoastAppContentModuleFeaturedArgs = {
  shows?: Maybe<PwsCoastFeaturedItemOptions>;
  articles?: Maybe<PwsCoastFeaturedItemOptions>;
  photos?: Maybe<PwsCoastFeaturedItemOptions>;
  sections?: Maybe<Scalars["Int"]>;
  platform?: Maybe<PwsCoastFeaturedPlatform>;
};

export type PwsCoastAppContentModuleSearchArgs = {
  term: Scalars["String"];
  doMerge?: Maybe<Scalars["Boolean"]>;
  includeArticles?: Maybe<Scalars["Boolean"]>;
  includeGuests?: Maybe<Scalars["Boolean"]>;
  includeShows?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  platform?: Maybe<PwsCoastSearchPlatform>;
  cursor?: Maybe<Scalars["String"]>;
};

export type PwsCoastAppContentModuleTopicsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type PwsCoastAppQueryInput = {
  /** Pubsub categories to filter by */
  categories?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["PWSTimestamp"]>;
  pub_end?: Maybe<Scalars["PWSTimestamp"]>;
  cursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type PwsCoastArticleListResponse = PwsPagedResult & {
  items: Array<Maybe<PwsCoastArticleResponse>>;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

/**
 * This modules methods are used to get an article by it's unique slug, or to get an arbitrary list of articles.
 * Articles are written content and have no edges to other types of nodes.
 */
export type PwsCoastArticleModule = {
  bySlug?: Maybe<PwsCoastArticleResponse>;
  list?: Maybe<PwsCoastArticleListResponse>;
  /** Get a list of Articles by their unique IDs or slugs. Only one of `ids` or `slugs` can be supplied. If both are supplied, `ids` will be used. */
  byIds: Array<PwsCoastArticleResponse>;
};

/**
 * This modules methods are used to get an article by it's unique slug, or to get an arbitrary list of articles.
 * Articles are written content and have no edges to other types of nodes.
 */
export type PwsCoastArticleModuleBySlugArgs = {
  slug: Scalars["String"];
  articleType?: Maybe<PwsCoastArticleType>;
};

/**
 * This modules methods are used to get an article by it's unique slug, or to get an arbitrary list of articles.
 * Articles are written content and have no edges to other types of nodes.
 */
export type PwsCoastArticleModuleListArgs = {
  articleType: PwsCoastArticleType;
  query?: Maybe<PwsCoastAppQueryInput>;
};

/**
 * This modules methods are used to get an article by it's unique slug, or to get an arbitrary list of articles.
 * Articles are written content and have no edges to other types of nodes.
 */
export type PwsCoastArticleModuleByIdsArgs = {
  ids?: Maybe<Array<Scalars["String"]>>;
  slugs?: Maybe<Array<Scalars["String"]>>;
};

export type PwsCoastArticleResponse = PwsCoastStandardArticleResponse | PwsCoastInTheNewsArticleResponse;

export enum PwsCoastArticleType {
  Default = "DEFAULT",
  InTheNews = "IN_THE_NEWS",
}

export type PwsCoastFeaturedDefaultPlatform = {
  value?: Maybe<PwsCoastFeaturedDefaultPlatformValue>;
};

export type PwsCoastFeaturedDefaultPlatformValue = {
  shows: Array<PwsCoastFeaturedItem>;
  articles: Array<PwsCoastFeaturedItem>;
  photos: Array<PwsCoastFeaturedItem>;
};

export type PwsCoastFeaturedDesktopPlatform = {
  value: Array<Array<PwsCoastFeaturedItem>>;
};

export type PwsCoastFeaturedItem = {
  slug?: Maybe<Scalars["String"]>;
  ref_id: Scalars["String"];
  pub_start: DateRef;
  content_type: PwsCoastFeaturedItemContentType;
  summary: PwsCoastFeaturedItemSummary;
};

export enum PwsCoastFeaturedItemContentType {
  Show = "SHOW",
  Article = "ARTICLE",
  Photo = "PHOTO",
}

export type PwsCoastFeaturedItemOptions = {
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type PwsCoastFeaturedItemSummary = {
  image?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export enum PwsCoastFeaturedPlatform {
  Desktop = "DESKTOP",
}

export type PwsCoastFeaturedResult = PwsCoastFeaturedDefaultPlatform | PwsCoastFeaturedDesktopPlatform;

export type PwsCoastGuestListResponse = PwsPagedResult & {
  items: Array<Maybe<PwsCoastGuestResponse>>;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

/**
 * This modules methods are used to get a guest by it's unique slug, or to get an arbitrary list of guests.
 * Guests are linked by shows and link to the shows they appear on.
 */
export type PwsCoastGuestModule = {
  bySlug?: Maybe<PwsCoastGuestResponse>;
  list: PwsCoastGuestListResponse;
};

/**
 * This modules methods are used to get a guest by it's unique slug, or to get an arbitrary list of guests.
 * Guests are linked by shows and link to the shows they appear on.
 */
export type PwsCoastGuestModuleBySlugArgs = {
  slug: Scalars["String"];
};

/**
 * This modules methods are used to get a guest by it's unique slug, or to get an arbitrary list of guests.
 * Guests are linked by shows and link to the shows they appear on.
 */
export type PwsCoastGuestModuleListArgs = {
  query?: Maybe<PwsCoastAppQueryInput>;
};

export type PwsCoastGuestResponse = {
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  publishTimestamp: Scalars["PWSTimestamp"];
  name: Scalars["String"];
  author: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  categories: Array<Maybe<PwsCoastMediaCategory>>;
  shortDescription?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
  websites?: Maybe<Scalars["String"]>;
  books?: Maybe<Scalars["String"]>;
  video?: Maybe<Scalars["String"]>;
  shows: Array<Maybe<PwsCoastShowResponse>>;
  media?: Maybe<PwsCoastMediaItem>;
};

export type PwsCoastHostListResponse = PwsPagedResult & {
  items: Array<Maybe<PwsCoastHostResponse>>;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

/**
 * This modules methods are used to get a host by it's unique slug, or to get an arbitrary list of hosts.
 * Hosts are techincally of the `article` content type in Pubsub, and they are associated with shows.
 */
export type PwsCoastHostModule = {
  bySlug?: Maybe<PwsCoastHostResponse>;
  list: PwsCoastHostListResponse;
};

/**
 * This modules methods are used to get a host by it's unique slug, or to get an arbitrary list of hosts.
 * Hosts are techincally of the `article` content type in Pubsub, and they are associated with shows.
 */
export type PwsCoastHostModuleBySlugArgs = {
  slug: Scalars["String"];
};

/**
 * This modules methods are used to get a host by it's unique slug, or to get an arbitrary list of hosts.
 * Hosts are techincally of the `article` content type in Pubsub, and they are associated with shows.
 */
export type PwsCoastHostModuleListArgs = {
  query?: Maybe<PwsCoastAppQueryInput>;
};

export type PwsCoastHostPlainValue = {
  value: Scalars["String"];
};

export type PwsCoastHostResponse = {
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  publishTimestamp: Scalars["PWSTimestamp"];
  name: Scalars["String"];
  author: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  media?: Maybe<PwsCoastMediaItem>;
  categories: Array<Maybe<PwsCoastMediaCategory>>;
  shortDescription?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
};

export type PwsCoastHostValue = PwsCoastHostResponse | PwsCoastHostPlainValue;

export type PwsCoastInTheNewsArticleResponse = {
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  ref_id: Scalars["String"];
  publishTimestamp: Scalars["PWSTimestamp"];
  title: Scalars["String"];
  author: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  media?: Maybe<PwsCoastMediaItem>;
  categories: Array<Maybe<PwsCoastMediaCategory>>;
  shortDescription?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
  newsOutlet: Scalars["String"];
  sourceUrl: Scalars["String"];
};

export type PwsCoastMediaCategory = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PwsCoastMediaEpisode = {
  podcast?: Maybe<Array<Maybe<PwsMediaDeliveryPodcast>>>;
  subscriptionRequired: Scalars["Boolean"];
  streaming?: Maybe<Array<Maybe<PwsMediaDeliveryZypeVideo>>>;
};

/** Media location/embed info */
export type PwsCoastMediaItem = {
  /** Image URL */
  img?: Maybe<Scalars["String"]>;
  video?: Maybe<PwsCoastMediaVideo>;
};

/** Video embed info */
export type PwsCoastMediaVideo = {
  /** Video source URL */
  url: Scalars["String"];
  /** Embed HTML */
  html: Scalars["String"];
};

export type PwsCoastSearchItem = {
  ref_id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  pub_start: DateRef;
  summary: PwsCoastSearchItemSummary;
  category: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  source_url?: Maybe<Scalars["String"]>;
};

export type PwsCoastSearchItemSummary = {
  title: Scalars["String"];
  description: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
};

export enum PwsCoastSearchPlatform {
  Desktop = "DESKTOP",
  App = "APP",
}

export type PwsCoastSearchResponse = PwsPagedResult & {
  value: PwsCoastSearchResponseValue;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

export type PwsCoastSearchResponseByCategoryValue = {
  show?: Maybe<PwsCoastSearchResponseCategory>;
  article?: Maybe<PwsCoastSearchResponseCategory>;
  guest?: Maybe<PwsCoastSearchResponseCategory>;
};

export type PwsCoastSearchResponseCategory = {
  items: Array<PwsCoastSearchItem>;
};

export type PwsCoastSearchResponseMergedValue = {
  items: Array<PwsCoastSearchItem>;
};

export type PwsCoastSearchResponseValue = PwsCoastSearchResponseByCategoryValue | PwsCoastSearchResponseMergedValue;

export type PwsCoastShowListResponse = PwsPagedResult & {
  items: Array<Maybe<PwsCoastShowResponse>>;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

/**
 * This modules methods are used to get a show by it's unique slug, or to get an arbitrary list of shows.
 * A show includes data about who hosted the show, who was a guest on the show, and any Zype episodes associated with it.
 */
export type PwsCoastShowModule = {
  bySlug?: Maybe<PwsCoastShowResponse>;
  list: PwsCoastShowListResponse;
  /** Get a list of shows by their unique IDs or slugs. Only one of `ids` or `slugs` can be supplied. If both are supplied, `ids` will be used. */
  byIds: Array<PwsCoastShowResponse>;
};

/**
 * This modules methods are used to get a show by it's unique slug, or to get an arbitrary list of shows.
 * A show includes data about who hosted the show, who was a guest on the show, and any Zype episodes associated with it.
 */
export type PwsCoastShowModuleBySlugArgs = {
  slug: Scalars["String"];
  showType?: Maybe<PwsCoastShowType>;
};

/**
 * This modules methods are used to get a show by it's unique slug, or to get an arbitrary list of shows.
 * A show includes data about who hosted the show, who was a guest on the show, and any Zype episodes associated with it.
 */
export type PwsCoastShowModuleListArgs = {
  showType: PwsCoastShowType;
  query?: Maybe<PwsCoastAppQueryInput>;
};

/**
 * This modules methods are used to get a show by it's unique slug, or to get an arbitrary list of shows.
 * A show includes data about who hosted the show, who was a guest on the show, and any Zype episodes associated with it.
 */
export type PwsCoastShowModuleByIdsArgs = {
  ids?: Maybe<Array<Scalars["String"]>>;
  slugs?: Maybe<Array<Scalars["String"]>>;
};

export type PwsCoastShowResponse = {
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  ref_id: Scalars["String"];
  subscriptionRequired: Scalars["Boolean"];
  onAir: Scalars["Boolean"];
  showTimestamp?: Maybe<Scalars["PWSTimestamp"]>;
  publishTimestamp: Scalars["PWSTimestamp"];
  title: Scalars["String"];
  author: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  media?: Maybe<PwsCoastMediaItem>;
  categories: Array<Maybe<PwsCoastMediaCategory>>;
  host?: Maybe<PwsCoastHostValue>;
  guests: Array<Maybe<PwsCoastGuestResponse>>;
  shortDescription?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
  videos?: Maybe<Scalars["String"]>;
  websites?: Maybe<Scalars["String"]>;
  music?: Maybe<Scalars["String"]>;
  books?: Maybe<Scalars["String"]>;
  articles?: Maybe<Scalars["String"]>;
  episodes: PwsCoastMediaEpisode;
};

export enum PwsCoastShowType {
  Default = "DEFAULT",
  ArtBell = "ART_BELL",
  Classic = "CLASSIC",
  Upcoming = "UPCOMING",
}

export type PwsCoastStandardArticleResponse = {
  id: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  ref_id: Scalars["String"];
  publishTimestamp: Scalars["PWSTimestamp"];
  title: Scalars["String"];
  author: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  media?: Maybe<PwsCoastMediaItem>;
  categories: Array<Maybe<PwsCoastMediaCategory>>;
  shortDescription?: Maybe<Scalars["String"]>;
  longDescription?: Maybe<Scalars["String"]>;
};

export type PwsCoastTopic = {
  id: Scalars["String"];
  name: Scalars["String"];
  /** For future use */
  img?: Maybe<Scalars["String"]>;
  isCategory: Scalars["Boolean"];
};

export type PwsCoastTopicsResponse = PwsPagedResult & {
  results: Array<Maybe<PwsCoastTopic>>;
  /** The number of total records from pubsub, NOT the total items returned by this resolver. */
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

export type PwsCreateGiftSubscription = {
  planId: Scalars["String"];
  recurlyTokenId: Scalars["String"];
  recipientId: Scalars["String"];
};

export type PwsCreateZypeUser = {
  name?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  birthday?: Maybe<Scalars["String"]>;
  updates?: Maybe<Scalars["Boolean"]>;
  terms?: Maybe<Scalars["Boolean"]>;
  custom?: Maybe<PwsCustomZypeUserProps>;
};

/** Common custom value among all current PWS sites */
export type PwsCustomZypeUserProps = {
  gifted_coupon_code?: Maybe<Scalars["String"]>;
  password_hint?: Maybe<Scalars["String"]>;
  privacy_optout?: Maybe<Scalars["Boolean"]>;
  user_preferences?: Maybe<Scalars["JSON"]>;
};

export type PwsMediaDeliveryConfigResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  tabs: Array<PwsMediaDeliveryConfigTab>;
};

export type PwsMediaDeliveryConfigTab = {
  slug: Scalars["String"];
  title: Scalars["String"];
  subscriptionRequired: Scalars["Boolean"];
  hasLive: Scalars["Boolean"];
  autoplay: Scalars["Boolean"];
  videos?: Maybe<Array<PwsMediaDeliveryZypeVideo>>;
  categories?: Maybe<Scalars["JSON"]>;
  pagination?: Maybe<PwsZypePagination>;
  resume?: Maybe<Scalars["String"]>;
};

export type PwsMediaDeliveryIsAudioResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  isAudio: Scalars["Boolean"];
};

export type PwsMediaDeliveryModule = {
  config: PwsMediaDeliveryConfigResponse;
  isOnAir: PwsMediaDeliveryOnAirStatusResponse;
  video: PwsMediaDeliveryVideoQuery;
  videos: PwsMediaDeliveryVideosQuery;
};

export type PwsMediaDeliveryModuleConfigArgs = {
  show: PwsShow;
  includeVideos?: Maybe<Scalars["Boolean"]>;
  perPage?: Maybe<Scalars["Int"]>;
};

export type PwsMediaDeliveryModuleIsOnAirArgs = {
  show: PwsShow;
};

/** Object for querying the next video in an show */
export type PwsMediaDeliveryNextQuery = {
  /** The tab slug to query data from. Options include 'art-bell-vault' and 'tv-clips' */
  groupSlug?: Maybe<Scalars["String"]>;
  /** JSON string of categories to query. Shape is something like {groupSlug: [categoryName, categoryName]} */
  categories?: Maybe<Scalars["String"]>;
  /** The 'category group' to include from Zype. Options include 'Clay and Buck' for Clay and Buck or 'Groups' for Coast to Coast */
  categoryName?: Maybe<Scalars["String"]>;
  /** The selection of specific categories from the groupSlug. Options include '24/7 Full Show' for 'Clay and Buck' or 'Art Bell Vault' for Groups */
  categoryValue?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["PWSTimestamp"]>;
};

export type PwsMediaDeliveryOnAirStatusResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  isOnAir: Scalars["Boolean"];
};

export type PwsMediaDeliveryPodcast = {
  episodeId?: Maybe<Scalars["String"]>;
  show?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  guid?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** Basic shape of a response from MediaDelivery */
export type PwsMediaDeliveryResponse = {
  authenticated: Scalars["Boolean"];
  /** Used by the Zype video player on the client */
  playerApiKey?: Maybe<Scalars["String"]>;
};

export type PwsMediaDeliveryVideoQuery = {
  byId: PwsMediaDeliveryVideoResponse;
  bySlug: PwsMediaDeliveryVideoResponse;
  bySourceId: PwsMediaDeliveryVideoResponse;
  next: PwsMediaDeliveryVideoResponse;
  onAir: PwsMediaDeliveryVideoResponse;
  isAudio: PwsMediaDeliveryIsAudioResponse;
};

export type PwsMediaDeliveryVideoQueryByIdArgs = {
  show: PwsShow;
  id: Scalars["String"];
};

export type PwsMediaDeliveryVideoQueryBySlugArgs = {
  show: PwsShow;
  slug: Scalars["String"];
};

export type PwsMediaDeliveryVideoQueryBySourceIdArgs = {
  show: PwsShow;
  sourceId: Scalars["String"];
};

export type PwsMediaDeliveryVideoQueryNextArgs = {
  show: PwsShow;
  query: PwsMediaDeliveryNextQuery;
};

export type PwsMediaDeliveryVideoQueryOnAirArgs = {
  show: PwsShow;
};

export type PwsMediaDeliveryVideoQueryIsAudioArgs = {
  show: PwsShow;
  id: Scalars["String"];
  isOnAir?: Maybe<Scalars["Boolean"]>;
};

export type PwsMediaDeliveryVideoResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  video?: Maybe<PwsMediaDeliveryZypeVideo>;
};

export type PwsMediaDeliveryVideosBySlugsResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  videos: Array<PwsMediaDeliveryZypeVideo>;
};

export type PwsMediaDeliveryVideosQuery = {
  list: PwsMediaDeliveryVideosResponse;
  bySlugs: PwsMediaDeliveryVideosBySlugsResponse;
};

export type PwsMediaDeliveryVideosQueryListArgs = {
  show: PwsShow;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
  resume?: Maybe<Scalars["String"]>;
  query?: Maybe<PwsMediaDeliveryVideosSearchQuery>;
};

export type PwsMediaDeliveryVideosQueryBySlugsArgs = {
  show: PwsShow;
  slugs: Array<Scalars["String"]>;
};

export type PwsMediaDeliveryVideosResponse = PwsMediaDeliveryResponse & {
  authenticated: Scalars["Boolean"];
  playerApiKey?: Maybe<Scalars["String"]>;
  videos: Array<PwsMediaDeliveryZypeVideo>;
  pagination: PwsZypePagination;
  resume?: Maybe<Scalars["String"]>;
};

export type PwsMediaDeliveryVideosSearchQuery = {
  /** The tab slug to query data from. Options include 'art-bell-vault' and 'tv-clips' */
  groupSlug?: Maybe<Scalars["String"]>;
  /** JSON string of categories to query. Shape is something like {groupSlug: [categoryName, categoryName]} */
  categories?: Maybe<Scalars["String"]>;
  /** The 'category group' to include from Zype. Options include 'Clay and Buck' for Clay and Buck or 'Groups' for Coast to Coast */
  categoryName?: Maybe<Scalars["String"]>;
  /** The selection of specific categories from the groupSlug. Options include '24/7 Full Show' for 'Clay and Buck' or 'Art Bell Vault' for Groups */
  categoryValue?: Maybe<Scalars["String"]>;
  /** Query term to search for */
  query?: Maybe<Scalars["String"]>;
  /** Search only videos within a date range */
  startDate?: Maybe<Scalars["PWSTimestamp"]>;
  /** Search only videos within a date range */
  endDate?: Maybe<Scalars["PWSTimestamp"]>;
};

export type PwsMediaDeliveryZypeCategory = {
  _id: Scalars["String"];
  category_id: Scalars["String"];
  title: Scalars["String"];
  value: Array<Maybe<Scalars["String"]>>;
};

export type PwsMediaDeliveryZypeThumbnail = {
  aspect_ratio: Scalars["Float"];
  height: Scalars["Int"];
  width: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type PwsMediaDeliveryZypeVideo = {
  id?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  slug: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<Array<Maybe<PwsMediaDeliveryZypeThumbnail>>>;
  categories?: Maybe<Array<Maybe<PwsMediaDeliveryZypeCategory>>>;
  publishedAt?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  onAir?: Maybe<Scalars["Boolean"]>;
  isAudio?: Maybe<Scalars["Boolean"]>;
  source_id?: Maybe<Scalars["String"]>;
  autoplay: Scalars["Boolean"];
  subscriptionRequired: Scalars["Boolean"];
  manifestsLocation?: Maybe<Scalars["String"]>;
  manifest?: Maybe<Scalars["String"]>;
};

export type PwsModules = {
  coastAppContent: PwsCoastAppContentModule;
  mediaDelivery: PwsMediaDeliveryModule;
  podcasts: PwsPodcastsModule;
  premiereMemberQuery: PwsPremiereMemberQuery;
};

export type PwsPageInfo = {
  nextCursor?: Maybe<Scalars["String"]>;
  prevCursor?: Maybe<Scalars["String"]>;
};

export type PwsPagedResult = {
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

export type PwsPagedResults = PwsPagedResult & {
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

export type PwsPodcastEpisode = {
  id?: Maybe<Scalars["String"]>;
  podcastId?: Maybe<Scalars["String"]>;
  podcastSlug?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  isExplicit?: Maybe<Scalars["Boolean"]>;
  isInteractive?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  mediaUrl?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  ingestionDate?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
};

export type PwsPodcastEpisodeQuery = {
  /**
   * Get the paginated list of podcast episodes.
   * Default limit is 20.
   * Default sort is by descending.
   */
  list: PwsPodcastEpisodeResponse;
  /** Get episodes by ids. */
  byIds: Array<Maybe<PwsPodcastEpisode>>;
};

export type PwsPodcastEpisodeQueryListArgs = {
  show: PwsPodcastShows;
  config?: Maybe<PwsAmpPodcastEpisodeInput>;
};

export type PwsPodcastEpisodeQueryByIdsArgs = {
  ids: Array<Scalars["Int"]>;
};

export type PwsPodcastEpisodeResponse = {
  items: Array<Maybe<PwsPodcastEpisode>>;
  count: Scalars["Int"];
  pageInfo: PwsPageInfo;
};

export enum PwsPodcastShows {
  BestOfCoastToCoastAm = "BEST_OF_COAST_TO_COAST_AM",
  ShadesOfTheAfterlife = "SHADES_OF_THE_AFTERLIFE",
  StrangeThings = "STRANGE_THINGS",
  DarkBecomesLight = "DARK_BECOMES_LIGHT",
  ClayAndBuck = "CLAY_AND_BUCK",
  VaudevilleForTheFrightened = "VAUDEVILLE_FOR_THE_FRIGHTENED",
}

export type PwsPodcastsModule = {
  /** Query for podcast episodes. */
  episodes: PwsPodcastEpisodeQuery;
};

/**
 * These are the operations you can perform on keys of user_preferences.
 * PLEASE READ THE DESCRIPTIONS OF THE OPERATIONS.
 */
export enum PwsPreferenceOperation {
  /** This adds data to an ARRAY value. This confused me at first, but if you want to set a new key/value, use REPLACE. */
  Add = "ADD",
  /** Replaces a keys value with the provided value. */
  Replace = "REPLACE",
  /** Removes a value from an ARRAY value. Similar to the ADD operation. */
  Remove = "REMOVE",
}

export type PwsPremiereMemberMutation = {
  createUser?: Maybe<PwsZypeUser>;
  /** Requires Access Token */
  createGiftUser?: Maybe<PwsZypeUser>;
  updateUser?: Maybe<PwsZypeUser>;
  /**
   * This resolver is used to update a custom field in Zype. That field holds arbitrary JSON that is decided by the site and currently has no set schema.
   * Requires Access Token
   */
  updateUserPreferences?: Maybe<Scalars["JSON"]>;
  /** Requires Access Token */
  deleteUser: Scalars["Boolean"];
  verifyEmail: Scalars["Boolean"];
  initUpdateEmail: Scalars["Boolean"];
  completeUpdateEmail: PwsZypeUpdatedResponse;
  updatePassword: PwsZypeUpdatedResponse;
  forgotPassword: Scalars["Boolean"];
  resetPassword: Scalars["Boolean"];
  login?: Maybe<PwsZypeLoginResponse>;
  logout: Scalars["Boolean"];
  /** Requires Access Token */
  refreshToken?: Maybe<PwsZypeLoginResponse>;
  /** Requires Access Token */
  createSubscription: PwsZypeSubscription;
  /** Requires Access Token */
  createGiftSubscription: PwsZypeGiftSubscription;
  /** Requires Access Token */
  cancelSubscription: Scalars["Boolean"];
  /** Requires Access Token */
  reactivateSubscription: PwsZypeSubscription;
  /** Requires Access Token */
  changeSubscriptionPlan: PwsZypeSubscription;
  /** Requires Access Token */
  cancelSubscriptionPlanChange: Scalars["Boolean"];
  /** Requires Access Token */
  updateBillingInfo: PwsRecurlyBillingInfo;
};

export type PwsPremiereMemberMutationCreateUserArgs = {
  site: PwsShow;
  emailToken: Scalars["String"];
  user: PwsCreateZypeUser;
  doLogin?: Maybe<Scalars["Boolean"]>;
};

export type PwsPremiereMemberMutationCreateGiftUserArgs = {
  site: PwsShow;
  recaptcha: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type PwsPremiereMemberMutationUpdateUserArgs = {
  site: PwsShow;
  user: PwsUpdateZypeUser;
};

export type PwsPremiereMemberMutationUpdateUserPreferencesArgs = {
  site: PwsShow;
  preferences: Array<PwsUpdatePreference>;
};

export type PwsPremiereMemberMutationDeleteUserArgs = {
  site: PwsShow;
  recaptcha: Scalars["String"];
};

export type PwsPremiereMemberMutationVerifyEmailArgs = {
  site: PwsShow;
  email: Scalars["String"];
  recaptcha: Scalars["String"];
};

export type PwsPremiereMemberMutationInitUpdateEmailArgs = {
  site: PwsShow;
  emailInfo: PwsUpdateZypeUserEmail;
};

export type PwsPremiereMemberMutationCompleteUpdateEmailArgs = {
  site: PwsShow;
  emailToken: Scalars["String"];
};

export type PwsPremiereMemberMutationUpdatePasswordArgs = {
  site: PwsShow;
  passwordInfo: PwsUpdateZypeUserPassword;
};

export type PwsPremiereMemberMutationForgotPasswordArgs = {
  site: PwsShow;
  email: Scalars["String"];
  recaptcha: Scalars["String"];
};

export type PwsPremiereMemberMutationResetPasswordArgs = {
  site: PwsShow;
  passwordInfo: PwsResetZypeUserPassword;
};

export type PwsPremiereMemberMutationLoginArgs = {
  site: PwsShow;
  username: Scalars["String"];
  password: Scalars["String"];
};

export type PwsPremiereMemberMutationLogoutArgs = {
  site: PwsShow;
};

export type PwsPremiereMemberMutationRefreshTokenArgs = {
  site: PwsShow;
  refreshToken: Scalars["String"];
};

export type PwsPremiereMemberMutationCreateSubscriptionArgs = {
  site: PwsShow;
  planId: Scalars["String"];
  recurlyTokenId: Scalars["String"];
};

export type PwsPremiereMemberMutationCreateGiftSubscriptionArgs = {
  site: PwsShow;
  planId: Scalars["String"];
  recurlyTokenId: Scalars["String"];
  recipientId: Scalars["String"];
};

export type PwsPremiereMemberMutationCancelSubscriptionArgs = {
  site: PwsShow;
  subscriptionId: Scalars["String"];
};

export type PwsPremiereMemberMutationReactivateSubscriptionArgs = {
  site: PwsShow;
  subscriptionId: Scalars["String"];
};

export type PwsPremiereMemberMutationChangeSubscriptionPlanArgs = {
  site: PwsShow;
  subscriptionId: Scalars["String"];
  planId: Scalars["String"];
};

export type PwsPremiereMemberMutationCancelSubscriptionPlanChangeArgs = {
  site: PwsShow;
  subscriptionId: Scalars["String"];
};

export type PwsPremiereMemberMutationUpdateBillingInfoArgs = {
  site: PwsShow;
  billingInfo: PwsRecurlyBillingInfoInput;
};

export type PwsPremiereMemberQuery = {
  /** Requires Access Token */
  user: PwsZypeUser;
  /** Requires Access Token */
  token: PwsZypeTokenResponse;
  plans: Array<PwsZypeSubscriptionPlan>;
  /** Use this resolver if you need a password hint without an access token */
  passwordHint?: Maybe<Scalars["String"]>;
};

export type PwsPremiereMemberQueryUserArgs = {
  site: PwsShow;
};

export type PwsPremiereMemberQueryTokenArgs = {
  site: PwsShow;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type PwsPremiereMemberQueryPlansArgs = {
  site: PwsShow;
};

export type PwsPremiereMemberQueryPasswordHintArgs = {
  site: PwsShow;
  email: Scalars["String"];
  recaptcha: Scalars["String"];
};

export type PwsRecurlyBillingInfo = {
  id?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  accountId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  address?: Maybe<PwsRecurlyStreetAddress>;
  vatNumber?: Maybe<Scalars["String"]>;
  valid?: Maybe<Scalars["Boolean"]>;
  paymentMethod?: Maybe<PwsRecurlyPaymentMethod>;
  fraud?: Maybe<PwsRecurlyFraudInfo>;
  primaryPaymentMethod?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["PWSTimestamp"]>;
  updatedAt?: Maybe<Scalars["PWSTimestamp"]>;
  updatedBy?: Maybe<PwsRecurlyBillingInfoUpdatedBy>;
};

export type PwsRecurlyBillingInfoInput = {
  tokenId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  address?: Maybe<PwsRecurlyStreetAddressInput>;
  number?: Maybe<Scalars["String"]>;
  month?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
  cvv?: Maybe<Scalars["String"]>;
  vatNumber?: Maybe<Scalars["String"]>;
  gatewayToken?: Maybe<Scalars["String"]>;
  gatewayCode?: Maybe<Scalars["String"]>;
  amazonBillingAgreementId?: Maybe<Scalars["String"]>;
  paypalBillingAgreementId?: Maybe<Scalars["String"]>;
  fraudSessionId?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
  threeDSecureActionResultTokenId?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  nameOnAccount?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  routingNumber?: Maybe<Scalars["String"]>;
  sortCode?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  accountType?: Maybe<Scalars["String"]>;
  primaryPaymentMethod?: Maybe<Scalars["Boolean"]>;
};

export type PwsRecurlyBillingInfoUpdatedBy = {
  ip?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type PwsRecurlyFraudInfo = {
  score?: Maybe<Scalars["Float"]>;
  decision?: Maybe<Scalars["String"]>;
  riskRulesTriggered?: Maybe<Scalars["PWSAny"]>;
};

export type PwsRecurlyPaymentMethod = {
  object?: Maybe<Scalars["String"]>;
  cardType?: Maybe<Scalars["String"]>;
  firstSix?: Maybe<Scalars["String"]>;
  lastFour?: Maybe<Scalars["String"]>;
  lastTwo?: Maybe<Scalars["String"]>;
  expMonth?: Maybe<Scalars["Int"]>;
  expYear?: Maybe<Scalars["Int"]>;
  gatewayToken?: Maybe<Scalars["String"]>;
  gatewayCode?: Maybe<Scalars["String"]>;
  billingAgreementId?: Maybe<Scalars["String"]>;
  nameOnAccount?: Maybe<Scalars["String"]>;
  accountType?: Maybe<Scalars["String"]>;
  routingNumber?: Maybe<Scalars["String"]>;
  routingNumberBank?: Maybe<Scalars["String"]>;
};

export type PwsRecurlyStreetAddress = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type PwsRecurlyStreetAddressInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type PwsResetZypeUserPassword = {
  passwordToken: Scalars["String"];
  password: Scalars["String"];
  passwordHint: Scalars["String"];
};

/** The show you want to fetch for. */
export enum PwsShow {
  Coast = "COAST",
  ClayAndBuck = "CLAY_AND_BUCK",
  Sandbox = "SANDBOX",
  ClayAndBuckSandbox = "CLAY_AND_BUCK_SANDBOX",
}

/**
 * Describes operations that need to happen to user preferences
 * PLEASE READ THE DESCRIPTIONS OF THE OPERATIONS BEFORE USING.
 */
export type PwsUpdatePreference = {
  /**
   * Operation to be completed
   * PLEASE READ THE DESCRIPTIONS OF THE OPERATIONS BEFORE USING.
   */
  operation: PwsPreferenceOperation;
  /** The field you want to operate on. */
  field: Scalars["String"];
  /**
   * The value you want to use for the operation.
   * This is a nebulous type.
   */
  value?: Maybe<Scalars["PWSAny"]>;
};

export type PwsUpdateZypeUser = {
  name?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  updates?: Maybe<Scalars["Boolean"]>;
  terms?: Maybe<Scalars["Boolean"]>;
};

export type PwsUpdateZypeUserEmail = {
  currentEmail: Scalars["String"];
  newEmail: Scalars["String"];
  password: Scalars["String"];
};

export type PwsUpdateZypeUserPassword = {
  email: Scalars["String"];
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
  passwordHint: Scalars["String"];
};

export type PwsZypeGiftSubscription = {
  success: Scalars["Boolean"];
  donorData: PwsZypeUser;
};

export type PwsZypeLoginData = {
  consumer_id: Scalars["String"];
  access_token: Scalars["String"];
  token_type: Scalars["String"];
  expires_in: Scalars["PWSTimestamp"];
  refresh_token: Scalars["String"];
  scope: Scalars["String"];
  created_at: Scalars["PWSTimestamp"];
};

export type PwsZypeLoginResponse = {
  user: PwsZypeUser;
  login: PwsZypeLoginData;
};

export type PwsZypeNotRefreshedTokenResponse = {
  token: PwsZypeTokenData;
  user: PwsZypeUser;
  refreshed: Scalars["Boolean"];
};

export type PwsZypePagination = {
  current: Scalars["Int"];
  previous?: Maybe<Scalars["Int"]>;
  next?: Maybe<Scalars["Int"]>;
  per_page: Scalars["Int"];
  pages: Scalars["Int"];
};

export enum PwsZypePlanInterval {
  Monthly = "MONTHLY",
  Annual = "ANNUAL",
}

export type PwsZypePlanMarketplaceIds = {
  itunes?: Maybe<Scalars["String"]>;
  googleplay?: Maybe<Scalars["String"]>;
};

export type PwsZypeRefreshedTokenResponse = {
  access_token: Scalars["String"];
  refresh_token: Scalars["String"];
  token: PwsZypeTokenData;
  user: PwsZypeUser;
  refreshed: Scalars["Boolean"];
};

export type PwsZypeSubscription = {
  _id: Scalars["String"];
  _keywords: Array<Scalars["String"]>;
  amount: Scalars["String"];
  cancel_at_period_end: Scalars["Boolean"];
  cancelled_at?: Maybe<Scalars["String"]>;
  consumer_id: Scalars["String"];
  coupon_code?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  currency: Scalars["String"];
  current_period_end_at?: Maybe<Scalars["String"]>;
  current_period_start_at?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["String"]>;
  discount_amount?: Maybe<Scalars["String"]>;
  discount_duration?: Maybe<Scalars["String"]>;
  discount_duration_months?: Maybe<Scalars["Int"]>;
  discount_percent?: Maybe<Scalars["Float"]>;
  interval: PwsZypePlanInterval;
  interval_count: Scalars["Int"];
  last_gateway_sync?: Maybe<Scalars["String"]>;
  last_gateway_sync_error?: Maybe<Scalars["String"]>;
  mrr?: Maybe<Scalars["Float"]>;
  plan_id: Scalars["String"];
  recurly_token_id?: Maybe<Scalars["String"]>;
  recurly_uuid?: Maybe<Scalars["String"]>;
  site_id: Scalars["String"];
  start_at?: Maybe<Scalars["String"]>;
  status: PwsZypeSubscriptionStatus;
  tax_percent?: Maybe<Scalars["Float"]>;
  tracking_code?: Maybe<Scalars["String"]>;
  tracking_partner?: Maybe<Scalars["String"]>;
  trial_allowed?: Maybe<Scalars["Boolean"]>;
  trial_period_days?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["String"];
  plan_name: Scalars["String"];
  entitlement_type: Scalars["String"];
  days_remaining: Scalars["Int"];
};

export type PwsZypeSubscriptionPlan = {
  _id: Scalars["String"];
  active: Scalars["Boolean"];
  amazon_id?: Maybe<Scalars["String"]>;
  amount: Scalars["String"];
  braintree_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  currency: Scalars["String"];
  deleted_at?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  discovery_url?: Maybe<Scalars["String"]>;
  entitlement_type: Scalars["String"];
  interval: PwsZypePlanInterval;
  interval_count: Scalars["Int"];
  name: Scalars["String"];
  position: Scalars["Int"];
  recurly_code: Scalars["String"];
  site_id: Scalars["String"];
  third_party_id?: Maybe<Scalars["String"]>;
  trial_period_days: Scalars["Int"];
  updated_at: Scalars["String"];
  marketplace_ids?: Maybe<PwsZypePlanMarketplaceIds>;
};

export enum PwsZypeSubscriptionStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Paused = "PAUSED",
}

export type PwsZypeTokenApplication = {
  uid: Scalars["String"];
};

export type PwsZypeTokenData = {
  resource_owner_id: Scalars["String"];
  scopes: Array<Scalars["String"]>;
  expires_in_seconds: Scalars["PWSTimestamp"];
  application: PwsZypeTokenApplication;
  created_at: Scalars["PWSTimestamp"];
};

export type PwsZypeTokenResponse = PwsZypeRefreshedTokenResponse | PwsZypeNotRefreshedTokenResponse;

export type PwsZypeUpdatedResponse = {
  user: PwsZypeUser;
  updated: Scalars["Boolean"];
};

export type PwsZypeUser = {
  _id: Scalars["String"];
  _keywords?: Maybe<Array<Scalars["String"]>>;
  amazon_user_id?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  braintree_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  current_sign_in_at?: Maybe<Scalars["String"]>;
  current_sign_in_ip?: Maybe<Scalars["String"]>;
  days_trialed: Scalars["Int"];
  email: Scalars["String"];
  has_trialed: Scalars["Boolean"];
  last_sign_in_at?: Maybe<Scalars["String"]>;
  last_sign_in_ip?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  pass_count: Scalars["Int"];
  password_hint?: Maybe<Scalars["String"]>;
  password_token?: Maybe<Scalars["String"]>;
  playlist_count: Scalars["Int"];
  remember_token?: Maybe<Scalars["String"]>;
  rss_token: Scalars["String"];
  sex?: Maybe<Scalars["String"]>;
  sign_in_count: Scalars["Int"];
  site_id: Scalars["String"];
  stripe_id?: Maybe<Scalars["String"]>;
  subscription_count: Scalars["Int"];
  terms: Scalars["Boolean"];
  transaction_count: Scalars["Int"];
  updated_at: Scalars["String"];
  updates: Scalars["Boolean"];
  video_count: Scalars["Int"];
  linked_devices?: Maybe<Scalars["JSON"]>;
  subscription_plans: Array<PwsZypeSubscription>;
  subscription_ids: Array<Maybe<Scalars["String"]>>;
  gifted_coupon_code?: Maybe<Array<Maybe<Scalars["String"]>>>;
  recurly_code?: Maybe<Scalars["String"]>;
  has_active_subscription: Scalars["Boolean"];
  privacy_optout?: Maybe<Scalars["Boolean"]>;
  user_preferences: Scalars["JSON"];
  billing_info?: Maybe<PwsRecurlyBillingInfo>;
  /** Only available with createUser resolver if the "doLogin" flag is present, contains ZypeLoginData metadata */
  login?: Maybe<PwsZypeLoginData>;
};

export type Page = {
  name: Scalars["String"];
  path: Scalars["String"];
  pathVariables?: Maybe<Array<Scalars["String"]>>;
  view_name: Scalars["String"];
  type: Scalars["String"];
  designation: Scalars["String"];
  primary?: Maybe<Scalars["String"]>;
  blocks: Array<PageBlock>;
  block: PageBlock;
};

export type PageBlockArgs = {
  id: Scalars["String"];
};

export type PageBlock = {
  id: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  type: Scalars["String"];
  value?: Maybe<Scalars["JSON"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
};

export type PageInfo = {
  prevToken?: Maybe<Scalars["ID"]>;
  nextToken?: Maybe<Scalars["ID"]>;
  itemTokens?: Maybe<Array<Scalars["ID"]>>;
};

export type PageTokenInput = {
  take?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  prevToken?: Maybe<Scalars["String"]>;
};

export type PlaybackRights = {
  onDemand?: Maybe<Scalars["Boolean"]>;
};

export type PlaybackRightsProjection = {
  onDemand?: Maybe<Scalars["Boolean"]>;
};

export type PlayedTrack = {
  track: PnpTrack;
  count: Scalars["Int"];
};

export type PlaylistRef = {
  id: Scalars["ID"];
  /** @deprecated Field no longer supported */
  collection?: Maybe<Collection>;
};

export type PlaylistsCatalogRef = {
  id: Scalars["ID"];
  country: Scalars["String"];
  kind: Scalars["String"];
};

export type PlaylistsCatalogRefInput = {
  id: Scalars["ID"];
  country: Scalars["String"];
  kind: Scalars["String"];
};

/** The type of the database field. */
export enum PlaylistsFieldType {
  /** Textual data. */
  Text = "TEXT",
  /** Numerical data. */
  Number = "NUMBER",
  /** A date, represented as a number. */
  Date = "DATE",
  /** Boolean data. */
  Boolean = "BOOLEAN",
}

/** A singular filter to apply to a playlist search. */
export type PlaylistsFilter = {
  /** The name of the database field to match against. */
  fieldName: Scalars["String"];
  /** The type of the match to make. */
  matchType: PlaylistsMatchType;
  /** The value to match. */
  matchValue: Scalars["String"];
};

/** The type of match to make. */
export enum PlaylistsMatchType {
  /** An exact value match. */
  Match = "MATCH",
  /** Match the beginning of the text. */
  Prefix = "PREFIX",
  /** Match the end of the text. */
  Suffix = "SUFFIX",
  /** Match if anywhere within the text (can be slow). */
  Contain = "CONTAIN",
  /** A fuzzy match (may contain mispellings, etc). */
  Fuzzy = "FUZZY",
  /** Is not an exact match. */
  Not = "NOT",
  /** Value is less than the search (only works for numeric values). */
  LessThan = "LESS_THAN",
  /** Value is greater than the search (only works for numeric values). */
  GreaterThan = "GREATER_THAN",
  /** Match if the value is contained in the list. */
  OneOf = "ONE_OF",
}

/** The input for creating or updating a playlist. */
export type PlaylistsPlaylistInput = {
  /** The name of the brand, station, personality, or artist that created the playlist. */
  author?: Maybe<Scalars["String"]>;
  /** Backfill tracks are not shown to users, but augment primary tracks when played by a free user for DMCA compliance. */
  backfillTracks?: Maybe<Array<PlaylistsTrackInput>>;
  /** For organization of playlists. */
  categories?: Maybe<Array<Scalars["String"]>>;
  /** User responsible for this playlist. */
  curator?: Maybe<Scalars["String"]>;
  /** Specifies of which countries a playlist should be available in. */
  countries: Array<Scalars["String"]>;
  /** Url of image uploaded by the user */
  customImagePath?: Maybe<Scalars["String"]>;
  /** A longer narrative summary of what the playlist represents. */
  description: Scalars["String"];
  /** Defines permissions, editors with access to this tag can edit the playlist. */
  editors: Array<Scalars["String"]>;
  /** Controls when this playlist will no longer be available in clients. */
  endDate?: Maybe<Scalars["PlaylistsDate"]>;
  /** Specifies of which countries a playlist should not be available in. */
  exclusions?: Maybe<Array<Scalars["String"]>>;
  /** Designates whether a playlist needs to be COPPA-compliant for ad targeting purposes. */
  isChildOriented?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether a playlist Metadata is to be updated by MLE or not. */
  stickyMetadata?: Maybe<Scalars["Boolean"]>;
  /** Flag to indicate if the playlist is deleted or not. */
  isDeleted?: Maybe<Scalars["Boolean"]>;
  /** Controls whether playlists display an explicit warning to users. */
  isExplicit?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether a playlist is pushed to AMP or not. */
  isInAMP?: Maybe<Scalars["Boolean"]>;
  /** Determines whether a playlist in AMP is searchable on or not. */
  isInAMPSearch?: Maybe<Scalars["Boolean"]>;
  /** Controls whether a playlist is only available to paid users. */
  isPremium?: Maybe<Scalars["Boolean"]>;
  /** Type of playlist. */
  playlistType: PlaylistsPlaylistType;
  /** Controls when this playlist will first be available in clients. */
  startDate: Scalars["PlaylistsDate"];
  /** Sets the image of the playlist automatically based on the station selected. */
  streamId?: Maybe<Scalars["String"]>;
  /** The primary name of the playlist. */
  title: Scalars["String"];
  /** Main tracks show to users in the playlist. */
  tracks?: Maybe<Array<PlaylistsTrackInput>>;
  /** Primary Artist/Track for the playlist. */
  primaryCatalog?: Maybe<PlaylistsCatalogRefInput>;
};

export type PlaylistsPlaylistMutation = {
  /** Insert a new playlist. */
  insert?: Maybe<PlaylistsPlaylistRecord>;
  /** Update an existing playlist. Any properties that are not provided are left unchanged. */
  update?: Maybe<PlaylistsPlaylistRecord>;
  /** Publish or Unpublish a playlist. */
  publish?: Maybe<PlaylistsPlaylistRecord>;
  /** Delete an existing playlist. */
  delete?: Maybe<Scalars["Boolean"]>;
  /** Restore a deleted playlist. */
  restore?: Maybe<Scalars["Boolean"]>;
  /** Update only exclusoins */
  manageExclusions?: Maybe<PlaylistsPlaylistRecord>;
};

export type PlaylistsPlaylistMutationInsertArgs = {
  attrs: PlaylistsPlaylistInput;
};

export type PlaylistsPlaylistMutationUpdateArgs = {
  id: Scalars["ID"];
  attrs: PlaylistsPlaylistInput;
  mtime: Scalars["Float"];
};

export type PlaylistsPlaylistMutationPublishArgs = {
  id: Scalars["ID"];
  publish: Scalars["Boolean"];
};

export type PlaylistsPlaylistMutationDeleteArgs = {
  id: Scalars["ID"];
};

export type PlaylistsPlaylistMutationRestoreArgs = {
  id: Scalars["ID"];
};

export type PlaylistsPlaylistMutationManageExclusionsArgs = {
  id: Scalars["ID"];
  exclusions: Array<Scalars["String"]>;
};

export type PlaylistsPlaylistQuery = {
  /** Runs a search for playlists. */
  search: PlaylistsPlaylistSearchResponse;
  /** Get Playlist BY ID */
  byId: PlaylistsPlaylistRecord;
  /** Returns the available search fields and their types. */
  searchFields: Array<PlaylistsPlaylistSearchField>;
};

export type PlaylistsPlaylistQuerySearchArgs = {
  query: PlaylistsPlaylistSearchArgs;
};

export type PlaylistsPlaylistQueryByIdArgs = {
  id: Scalars["ID"];
};

/** A single playlist record in the system. */
export type PlaylistsPlaylistRecord = {
  _id: Scalars["ID"];
  /** Metadata for the record. */
  _meta: DocumentMetadata;
  /** The name of the brand, station, personality, or artist that created the playlist. */
  author?: Maybe<Scalars["String"]>;
  /** Backfill tracks are not shown to users, but augment primary tracks when played by a free user for DMCA compliance. */
  backfillTracks: Array<PlaylistsTrackRef>;
  /** For organization of playlists. */
  categories: Array<TagRef>;
  /** User responsible for this playlist. */
  curator?: Maybe<TagRef>;
  /** Specifies of which countries a playlist should be available in. */
  countries: Array<TagRef>;
  /** Url of image uploaded by the user */
  customImagePath?: Maybe<Scalars["String"]>;
  /** A longer narrative summary of what the playlist represents. */
  description?: Maybe<Scalars["String"]>;
  /** Defines permissions, editors with access to this tag can edit the playlist. */
  editors: Array<TagRef>;
  /** Controls when this playlist will no longer be available in clients. */
  endDate?: Maybe<DateRef>;
  /** Specifies of which countries a playlist should not be available in. */
  exclusions?: Maybe<Array<TagRef>>;
  /** Indicates wheather the record contains changes which are not yet published. */
  hasChanges: Scalars["Boolean"];
  /** Designates whether a playlist needs to be COPPA-compliant for ad targeting purposes. */
  isChildOriented: Scalars["Boolean"];
  /** Flag to indicate if the playlist is deleted or not. */
  isDeleted?: Maybe<Scalars["Boolean"]>;
  /** Indicates whether a playlist Metadata is to be updated by MLE or not. */
  stickyMetadata?: Maybe<Scalars["Boolean"]>;
  /** Controls whether playlists display an explicit warning to users. */
  isExplicit: Scalars["Boolean"];
  /** Indicates whether a playlist is pushed to AMP or not. */
  isInAMP: Scalars["Boolean"];
  /** Determines whether a playlist in AMP is searchable on or not. */
  isInAMPSearch: Scalars["Boolean"];
  /** Controls whether a playlist is only available to paid users. */
  isPremium: Scalars["Boolean"];
  /** Type of playlist. */
  playlistType: PlaylistsPlaylistType;
  /** Date and time of most recent publish to AMP */
  recentPublishedDate?: Maybe<DateRef>;
  /** User that most recently published to AMP */
  recentPublishedUser?: Maybe<Scalars["String"]>;
  /** Id of playlist record in AMP database. */
  publishedPlaylistId?: Maybe<Scalars["String"]>;
  /** AMP profile id using which the playlist is published. */
  publishedUserId?: Maybe<Scalars["String"]>;
  /** Controls when this playlist will first be available in clients. [Required] */
  startDate?: Maybe<DateRef>;
  /** Controls when this playlist will first be available in clients after publishing to AMP */
  scheduledStartDate?: Maybe<DateRef>;
  /** Controls when this playlist will be unpublished from AMP. */
  scheduledEndDate?: Maybe<DateRef>;
  /** Sets the image of the playlist automatically based on the station selected. */
  streamId?: Maybe<PlaylistsStreamRef>;
  /** The primary name of the playlist. */
  title: Scalars["String"];
  /** Mapping of tracks to their AMP id's. */
  trackAmpIdMap?: Maybe<Scalars["JSON"]>;
  /** Main tracks show to users in the playlist. */
  tracks: Array<PlaylistsTrackRef>;
  /** A thumbnail image that visually represents the playlist. */
  urlImage?: Maybe<Scalars["String"]>;
  /** Shows the URL used by IHeartRadio Flagship mobile apps (ie. iOS/Android) to see/play the playlist. */
  urlMobile?: Maybe<Scalars["String"]>;
  /** Once a playlist is published, shows the address users may visit to see/play the playlist. */
  urlWeb?: Maybe<Scalars["String"]>;
  /** Primary Artist/Track for the playlist. */
  primaryCatalog?: Maybe<PlaylistsCatalogRef>;
};

export type PlaylistsPlaylistRootMutaion = {
  playlists: PlaylistsPlaylistMutation;
  swaps: PlaylistsSwapMutation;
};

/** Search arguments for a search query. */
export type PlaylistsPlaylistSearchArgs = {
  /**
   * An array of Filters used to build a query for playlists. These filters will be
   * joined by AND.
   */
  filters?: Maybe<Array<PlaylistsFilter>>;
  /** An array of filters used for sorting playlists. */
  sortFilters?: Maybe<Array<PlaylistsSortFilter>>;
  /** The page of results requested for the specified query. */
  page?: Maybe<Scalars["Int"]>;
  /** The number of results to return per-page for the specified query. */
  take?: Maybe<Scalars["Int"]>;
};

/** A representation of a field available for searching in the system. */
export type PlaylistsPlaylistSearchField = {
  /** The internal name of the field, used for referencing the field. */
  fieldName: Scalars["String"];
  /** A human-readable display name for the field. */
  label: Scalars["String"];
  /**
   * The database name of the field. Distinct from fieldName in that it may
   * reference a field multiple levels deep. ("object.attr").
   */
  dbField: Scalars["String"];
  /** The data type of the field. */
  type: PlaylistsFieldType;
};

/** The response from a playlist search query. */
export type PlaylistsPlaylistSearchResponse = {
  /** The total count of playlists matching the search query. */
  count: Scalars["Int"];
  /** A single page of results matching the search query. */
  playlists: Array<PlaylistsPlaylistRecord>;
};

export enum PlaylistsPlaylistType {
  /** An automatically generated playlist that is mapped to a live station. */
  Dynamic = "DYNAMIC",
  /** A hand-curated playlist. Created and managed exclusively by a user of the Playlists tool. */
  Manual = "MANUAL",
}

export type PlaylistsRootQuery = {
  playlists: PlaylistsPlaylistQuery;
  swaps: PlaylistsSwapQuery;
};

/** A sort to apply to a playlist search. */
export type PlaylistsSortFilter = {
  /** The name of the database field to sort against. */
  fieldName: Scalars["String"];
  /** The order of sort. */
  sortType: PlaylistsSortType;
};

/** The order of sort. */
export enum PlaylistsSortType {
  /** Sort results in ascending order. */
  Asc = "ASC",
  /** Sort results in descending order. */
  Desc = "DESC",
}

export type PlaylistsStreamRef = {
  id: Scalars["ID"];
};

/** A Swap, representing a replacement track to be swapped in for another in a Playlist. */
export type PlaylistsSwap = {
  /** The ID of the Swap. Generated from the original track id to prevent duplicates. */
  _id: Scalars["ID"];
  /** Metadata for the record. */
  _meta: DocumentMetadata;
  /** The ID of the track to replace. */
  fromId: Scalars["ID"];
  /** The ID of the replacement track. */
  toId: Scalars["ID"];
  /** The country for the initial and replacement tracks. Should be a 2 letter country code. */
  country: Scalars["String"];
  /** The title of the original track, looked up via AMP. */
  fromTitle?: Maybe<Scalars["String"]>;
  /** The artist name of the original track, looked up via AMP. */
  fromArtist?: Maybe<Scalars["String"]>;
  /** The album name of the original track, looked up via AMP. */
  fromAlbum?: Maybe<Scalars["String"]>;
  /** The title of the replacement track, looked up via AMP. */
  toTitle?: Maybe<Scalars["String"]>;
  /** The artist name of the replacement track, looked up via AMP. */
  toArtist?: Maybe<Scalars["String"]>;
  /** The album name of the replacement track, looked up via AMP. */
  toAlbum?: Maybe<Scalars["String"]>;
};

export type PlaylistsSwapInput = {
  /** The ID of the track to replace. */
  fromId: Scalars["String"];
  /** The ID of the replacement track. */
  toId: Scalars["String"];
  /** The country for the initial and replacement tracks. Should be a 2 letter country code. */
  country: Scalars["String"];
};

export type PlaylistsSwapListArgs = {
  /** The country to return results for. Should be a 2 letter country code. */
  country?: Maybe<Scalars["String"]>;
  /** The ID of the track to replace. */
  fromId?: Maybe<Scalars["String"]>;
  /** The page of results requested for the specified query. */
  page?: Maybe<Scalars["Int"]>;
  /** The number of results to return for the specified query. */
  take?: Maybe<Scalars["Int"]>;
};

export type PlaylistsSwapListResponse = {
  count: Scalars["Int"];
  items: Array<PlaylistsSwap>;
};

export type PlaylistsSwapMutation = {
  /** Create a new Swap. */
  create: PlaylistsSwap;
  /** Delete an existing Swap. */
  delete: Scalars["Boolean"];
};

export type PlaylistsSwapMutationCreateArgs = {
  fromId: Scalars["ID"];
  toId: Scalars["ID"];
  country: Scalars["String"];
};

export type PlaylistsSwapMutationDeleteArgs = {
  id: Scalars["ID"];
};

export type PlaylistsSwapQuery = {
  /** Get a list of all Swaps. */
  list: PlaylistsSwapListResponse;
  /** Returns a Swap by the original track ID */
  byId?: Maybe<PlaylistsSwap>;
};

export type PlaylistsSwapQueryListArgs = {
  query?: Maybe<PlaylistsSwapListArgs>;
};

export type PlaylistsSwapQueryByIdArgs = {
  id: Scalars["ID"];
};

/** The input for creating a track. */
export type PlaylistsTrackInput = {
  /** country for which the track is added. */
  country: Scalars["String"];
  /** ID of the track on radioedit side. */
  id: Scalars["Float"];
};

export type PlaylistsTrackRef = {
  country: Scalars["String"];
  id: Scalars["Float"];
};

export type PnpTrack = {
  artistId: Scalars["Int"];
  albumId: Scalars["Int"];
  trackId: Scalars["Int"];
  title: Scalars["String"];
  trackDuration?: Maybe<Scalars["Int"]>;
  imagePath?: Maybe<Scalars["String"]>;
  explicitLyrics: Scalars["Boolean"];
  version?: Maybe<Scalars["String"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  playbackRights?: Maybe<PlaybackRights>;
  artist?: Maybe<Artist>;
  artistName: Scalars["String"];
  albumName: Scalars["String"];
};

export type PnpTrackHistory = TrackHistory & {
  artistId: Scalars["Int"];
  albumId: Scalars["Int"];
  trackId: Scalars["Int"];
  title: Scalars["String"];
  trackDuration?: Maybe<Scalars["Int"]>;
  imagePath?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  startTime: Scalars["Int"];
  endTime: Scalars["Int"];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  trackDurationInSec?: Maybe<Scalars["Int"]>;
  dataSource?: Maybe<Scalars["String"]>;
  artist?: Maybe<Artist>;
  artistName: Scalars["String"];
  albumName: Scalars["String"];
};

export type Podcast = {
  id: Scalars["Int"];
  title: Scalars["String"];
  subtitle?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  lastUpdated: Scalars["Float"];
  slug: Scalars["String"];
  isExternal: Scalars["Boolean"];
  imageUrl: Scalars["String"];
  customLinks: Array<CustomLink>;
  socialMediaLinks: Array<SocialMediaLink>;
  heroImage?: Maybe<Scalars["String"]>;
  heroImageColor?: Maybe<Scalars["String"]>;
  hostIds: Array<Scalars["String"]>;
  showType?: Maybe<Scalars["String"]>;
  episodes: PodcastEpisodes;
};

export type PodcastEpisodesArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type PodcastEpisode = {
  id: Scalars["Int"];
  podcastId: Scalars["Int"];
  podcastSlug: Scalars["String"];
  title: Scalars["String"];
  duration: Scalars["Int"];
  isExplicit: Scalars["Boolean"];
  isInteractive?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  mediaUrl?: Maybe<Scalars["String"]>;
  startDate: Scalars["Float"];
  endDate?: Maybe<Scalars["String"]>;
  ingestionDate?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  podcast?: Maybe<Podcast>;
  webLink: Scalars["String"];
};

export type PodcastEpisodeRef = {
  id: Scalars["ID"];
  amp?: Maybe<PodcastEpisode>;
};

export type PodcastEpisodes = {
  pageInfo: AmpPageInfo;
  items: Array<PodcastEpisode>;
};

export type PodcastFollow = {
  profileId: Scalars["Int"];
  podcastId: Scalars["Int"];
  dateCreated: Scalars["Float"];
  lastPlayed: Scalars["Float"];
  settings: PodcastFollowSettings;
};

export type PodcastFollowSettings = {
  notifications: Scalars["Boolean"];
};

export type PodcastRef = {
  id: Scalars["ID"];
  amp?: Maybe<Podcast>;
};

export type PodcastTranscriptionFormatterQuery = {
  /** The current version of the podcast-transcription-formatter service */
  _version?: Maybe<Scalars["String"]>;
  /** Get the transcription for a podcast episode in the requested format */
  format?: Maybe<Scalars["String"]>;
};

export type PodcastTranscriptionFormatterQueryFormatArgs = {
  episodeId: Scalars["Int"];
  options?: Maybe<PodcastTranscriptionFormattingOptions>;
};

/**
 * Options for formatting a podcast transcription
 * NOTE: Options other than outputFormat are ignored if the outputFormat is JSON
 */
export type PodcastTranscriptionFormattingOptions = {
  /** The requested output format. Defaults to TEXT. */
  outputFormat?: Maybe<PodcastTranscriptionOutputFormat>;
  /** Should newlines in the individual transcription segments be stripped? Defaults to false. */
  stripNewlines?: Maybe<Scalars["Boolean"]>;
  /** Should the speaker names be collapsed to prevent repeat while the speaker remains the same? Defaults to true. */
  collapseSpeakers?: Maybe<Scalars["Boolean"]>;
  /** Should the times be included in the output? Defaults to true. */
  includeTimes?: Maybe<Scalars["Boolean"]>;
  /** Should the times be collapsed to avoid outputting times for every segment? Defaults to true. */
  collapseTimes?: Maybe<Scalars["Boolean"]>;
  /** The threshold for collapsing times. If the time difference between two segments is less than this threshold, the time will not be output. Defaults to 10. */
  timeCollapseThreshold?: Maybe<Scalars["Int"]>;
  /** A class to apply to the span around speaker name. Defaults to 'podcast-transcription-speaker'. Only useful when the outputFormat is HTML. */
  speakerClass?: Maybe<Scalars["String"]>;
  /** A class to apply to the span around time. Defaults to 'podcast-transcription-time'. Only useful when the outputFormat is HTML. */
  timeClass?: Maybe<Scalars["String"]>;
  /** A class to apply to the span around text. Defaults to 'podcast-transcription-text'. Only useful when the outputFormat is HTML. */
  textClass?: Maybe<Scalars["String"]>;
};

/** The output format for a podcast transcription */
export enum PodcastTranscriptionOutputFormat {
  /** Output in JSON format. This is the raw output from the `srt-parser-2` library. */
  Json = "JSON",
  /** HTML output. Speaker names, times, and text are wrapped in spans with classes applied. Newlines are converted to line breaks. */
  Html = "HTML",
  /** Plain text output with newlines. */
  Text = "TEXT",
}

export type PodcastWithFollow = {
  podcast: Podcast;
  follow?: Maybe<PodcastFollow>;
};

/** Email=E, SMS=S, Push=P */
export enum PostupChannel {
  E = "E",
  S = "S",
  P = "P",
}

/**
 * "ALL" tracks all clicks, "NONE" disables click tracking, "HTML" tracks clicks in HTML parts only,
 * "USER" tracks selected URLs, or "USER_HTML" tracks selected URLs in HTML parts only.
 */
export enum PostupClickTrackType {
  All = "ALL",
  None = "NONE",
  Html = "HTML",
  User = "USER",
  UserHtml = "USER_HTML",
}

/** Single subscription record for a newsletter and recipient */
export type PostupListSubscription = {
  mailingId?: Maybe<Scalars["String"]>;
  listId: Scalars["Int"];
  recipientId: Scalars["Int"];
  /** Superset of ListSubscriptionStatus (not all possible values are documented). */
  status: Scalars["String"];
  confirmed?: Maybe<Scalars["Boolean"]>;
  sourceId?: Maybe<Scalars["String"]>;
  dateUnsub?: Maybe<Scalars["PostupDateTime"]>;
  dateJoined: Scalars["PostupDateTime"];
  address: Scalars["PostupEmailAddress"];
  /** Superset of ListSubscriptionStatus (not all possible values are documented). */
  listStatus?: Maybe<Scalars["String"]>;
  /** Superset of ListSubscriptionStatus (not all possible values are documented). */
  globalStatus?: Maybe<Scalars["String"]>;
};

export type PostupListSubscriptionCreateInput = {
  listId: Scalars["Int"];
  recipientId: Scalars["Int"];
  status: PostupListSubscriptionStatus;
  sourceId?: Maybe<Scalars["String"]>;
};

export enum PostupListSubscriptionStatus {
  Normal = "NORMAL",
  Unsub = "UNSUB",
}

/** Record of mail sent to subscribers for a newsletter */
export type PostupMailing = {
  mailingId: Scalars["Int"];
  status: PostupMailingStatus;
  channel: PostupChannel;
  title?: Maybe<Scalars["String"]>;
  brandId?: Maybe<Scalars["Int"]>;
  campaignId?: Maybe<Scalars["Int"]>;
  categoryId?: Maybe<Scalars["Int"]>;
  creator?: Maybe<Scalars["String"]>;
  clickTrackType?: Maybe<PostupClickTrackType>;
  openTrackType?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  maxRecips?: Maybe<Scalars["Int"]>;
  realtime?: Maybe<Scalars["Boolean"]>;
};

/** "NEW" creates a mailing draft. "PENDING" schedules the mailing. "DONE" is a completed mailing. */
export enum PostupMailingStatus {
  New = "NEW",
  Pending = "PENDING",
  Done = "DONE",
}

/**
 * Support for a selection of mutation endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupMutation = {
  /** Create a new recipient */
  createRecipient?: Maybe<PostupRecipient>;
  /** Update an existing recipient */
  updateRecipient?: Maybe<PostupRecipient>;
  /** Update subscriptions for a recipient. Input can be a partial list of subscriptions. */
  updateSubscriptions: Array<PostupListSubscription>;
  /** Send correspondence to a station */
  sendMail?: Maybe<PostupMailing>;
};

/**
 * Support for a selection of mutation endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupMutationCreateRecipientArgs = {
  input: PostupRecipientCreateInput;
};

/**
 * Support for a selection of mutation endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupMutationUpdateRecipientArgs = {
  input: PostupRecipientUpdateInput;
};

/**
 * Support for a selection of mutation endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupMutationUpdateSubscriptionsArgs = {
  input: Array<PostupListSubscriptionCreateInput>;
};

/**
 * Support for a selection of mutation endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupMutationSendMailArgs = {
  slug: Scalars["String"];
  input: PostupSendMailInput;
};

/** "NONE" will disable open tracking. "HTML" will track opens in HTML parts. */
export enum PostupOpenTrackType {
  None = "NONE",
  Html = "HTML",
}

/**
 * Support for a selection of GET endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupQuery = {
  /** Get newsletters for a station */
  station?: Maybe<PostupStation>;
  /** Get a recipient by email address */
  recipient?: Maybe<Array<PostupRecipient>>;
};

/**
 * Support for a selection of GET endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupQueryStationArgs = {
  slug: Scalars["String"];
};

/**
 * Support for a selection of GET endpoints/fields from the Postup API.
 * See: https://apidocs.postup.com/docs/
 */
export type PostupQueryRecipientArgs = {
  emailAddress: Scalars["String"];
};

/** Subscriber record */
export type PostupRecipient = {
  recipientId: Scalars["Int"];
  address: Scalars["PostupEmailAddress"];
  externalId?: Maybe<Scalars["String"]>;
  channel?: Maybe<PostupChannel>;
  /** Superset of RecipientStatus (not all possible values are documented). */
  status: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  sourceDescription?: Maybe<Scalars["String"]>;
  sourceSignupDate?: Maybe<Scalars["String"]>;
  signupIP?: Maybe<Scalars["String"]>;
  signupMethod?: Maybe<Scalars["String"]>;
  thirdPartySource?: Maybe<Scalars["String"]>;
  thirdPartySignupDate?: Maybe<Scalars["PostupDateTime"]>;
  dateJoined: Scalars["PostupDateTime"];
  dateUnsub?: Maybe<Scalars["PostupDateTime"]>;
  demographics?: Maybe<Array<Scalars["String"]>>;
  subscriptions?: Maybe<Array<PostupListSubscription>>;
};

/** Subscriber record */
export type PostupRecipientSubscriptionsArgs = {
  slug: Scalars["String"];
};

export type PostupRecipientCreateInput = {
  address: Scalars["PostupEmailAddress"];
  externalId?: Maybe<Scalars["String"]>;
  channel: PostupChannel;
  status?: Maybe<PostupRecipientStatus>;
  sourceDescription?: Maybe<Scalars["String"]>;
  signupIP?: Maybe<Scalars["POSTUP_IP"]>;
  thirdPartySource?: Maybe<Scalars["String"]>;
  thirdPartySignupDate?: Maybe<Scalars["PostupDateTime"]>;
  demographics?: Maybe<Array<Scalars["String"]>>;
  comment?: Maybe<Scalars["String"]>;
};

/** N=Normal, U=Unsubscribe, H=Held */
export enum PostupRecipientStatus {
  N = "N",
  U = "U",
  H = "H",
}

export type PostupRecipientUpdateInput = {
  recipientId: Scalars["Int"];
  address?: Maybe<Scalars["PostupEmailAddress"]>;
  externalId?: Maybe<Scalars["String"]>;
  channel?: Maybe<PostupChannel>;
  status?: Maybe<PostupRecipientStatus>;
  comment?: Maybe<Scalars["String"]>;
  demographics?: Maybe<Array<Scalars["String"]>>;
  resubscribe?: Maybe<Scalars["Boolean"]>;
};

export type PostupSendMailInput = {
  senderIP: Scalars["POSTUP_IP"];
  senderName: Scalars["String"];
  senderEmail: Scalars["PostupEmailAddress"];
  recipientType: Scalars["String"];
  subject: Scalars["String"];
  message: Scalars["String"];
  replyDesired?: Maybe<Scalars["Boolean"]>;
};

/** Collection of newsletters for a station */
export type PostupStation = {
  slug: Scalars["String"];
  lists?: Maybe<Array<PostupStationList>>;
};

/** Newsletter record for a station */
export type PostupStationList = {
  listId: Scalars["Int"];
  title: Scalars["String"];
  friendlyTitle?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  populated: Scalars["Boolean"];
  publicSignup: Scalars["Boolean"];
  externalId?: Maybe<Scalars["String"]>;
  channel: PostupChannel;
};

export type PronouncementRef = {
  is_primary: Scalars["Boolean"];
  utterance: Scalars["String"];
};

export type PublicTrack = {
  id: Scalars["Int"];
  title: Scalars["String"];
  artistId: Scalars["Int"];
  artistName: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  albumId: Scalars["Int"];
  albumName: Scalars["String"];
  duration?: Maybe<Scalars["Float"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  publisher?: Maybe<Scalars["String"]>;
  trackNumber: Scalars["Int"];
  playbackRights: PlaybackRightsProjection;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  volume?: Maybe<Scalars["Int"]>;
};

export type PublishSummary = {
  author: Scalars["String"];
  description: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type PubsubAdditionalEntityFields = {
  path?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type PubsubContentAssetField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentAssetValue>;
};

export type PubsubContentAssetValue = {
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  alt_text?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  attributes?: Maybe<PubsubContentAssetValueAttributes>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  public_uri?: Maybe<Scalars["String"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PubsubContentAssetValueAttributes = {
  mimetype?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type PubsubContentAuthorField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentAuthorLink>>>;
};

export type PubsubContentAuthorLink = {
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PubsubContentAuthorPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentAuthorPayloadFields>;
};

export type PubsubContentAuthorPayloadFields = {
  name?: Maybe<PubsubContentTitleField>;
  bio?: Maybe<PubsubContentHtmlField>;
  photo?: Maybe<PubsubContentAssetField>;
  link?: Maybe<PubsubContentPlainField>;
  author_facebook?: Maybe<PubsubContentPlainField>;
  author_twitter?: Maybe<PubsubContentPlainField>;
  author_instagram?: Maybe<PubsubContentPlainField>;
  author_tiktok?: Maybe<PubsubContentPlainField>;
  youtube?: Maybe<PubsubContentPlainField>;
  slug?: Maybe<PubsubContentSlugField>;
};

export type PubsubContentAuthorPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:author. */
  data?: Maybe<PubsubContentAuthorPayload>;
};

export type PubsubContentBlockSchedule = {
  enabled?: Maybe<Scalars["Boolean"]>;
  begin?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export enum PubsubContentBlockType {
  Title = "title",
  Plain = "plain",
  Slug = "slug",
  Author = "author",
  Text = "text",
  Tag = "tag",
  Asset = "asset",
  Date = "date",
  Checkbox = "checkbox",
  Link = "link",
  Flex = "flex",
  Catalog = "catalog",
  Contest = "contest",
  Content = "content",
  Gallery = "gallery",
  Html = "html",
  Embed = "embed",
  Pnpodcast = "pnpodcast",
  Bumpermusic = "bumpermusic",
  Multidate = "multidate",
}

export type PubsubContentBumperMusicField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentBumperMusicValue>>>;
};

export type PubsubContentBumperMusicValue = {
  artist?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type PubsubContentCalendarPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCalendarPayloadFields>;
};

export type PubsubContentCalendarPayloadFields = {
  seo_title?: Maybe<PubsubContentTitleField>;
  social_title?: Maybe<PubsubContentTitleField>;
  title?: Maybe<PubsubContentTitleField>;
  primary_image?: Maybe<PubsubContentAssetField>;
  event_date?: Maybe<PubsubContentMultiDateField>;
  hide_event_start_time?: Maybe<PubsubContentCheckboxField>;
  venue_name?: Maybe<PubsubContentPlainField>;
  venue_address?: Maybe<PubsubContentPlainField>;
  slug?: Maybe<PubsubContentSlugField>;
  summary?: Maybe<PubsubContentPlainField>;
  keywords?: Maybe<PubsubContentTagField>;
  blocks?: Maybe<PubsubContentFlexField>;
};

export type PubsubContentCalendarPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:calendar. */
  data?: Maybe<PubsubContentCalendarPayload>;
};

export type PubsubContentCardTestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCardTestPayloadFields>;
};

export type PubsubContentCardTestPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  image?: Maybe<PubsubContentAssetField>;
  decription?: Maybe<PubsubContentPlainField>;
  link?: Maybe<PubsubContentPlainField>;
  advanced_field?: Maybe<PubsubContentPlainField>;
};

export type PubsubContentCardTestPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:card-test. */
  data?: Maybe<PubsubContentCardTestPayload>;
};

export type PubsubContentCarplayIosHomeSurfacingPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCarplayIosHomeSurfacingPayloadFields>;
};

export type PubsubContentCarplayIosHomeSurfacingPayloadFields = {
  test_input?: Maybe<PubsubContentPlainField>;
  Made_for_you?: Maybe<PubsubContentTitleField>;
  tag_associated_content?: Maybe<PubsubContentTagField>;
  surface_item_1?: Maybe<PubsubContentCatalogField>;
  surfaced_item_2?: Maybe<PubsubContentCatalogField>;
  surfaced_item_3?: Maybe<PubsubContentCatalogField>;
};

export type PubsubContentCarplayIosHomeSurfacingPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:carplay-ios-home-surfacing. */
  data?: Maybe<PubsubContentCarplayIosHomeSurfacingPayload>;
};

export type PubsubContentCastawayDestinationPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCastawayDestinationPayloadFields>;
};

export type PubsubContentCastawayDestinationPayloadFields = {
  City_or_Theme_Name?: Maybe<PubsubContentTitleField>;
  Author?: Maybe<PubsubContentAuthorField>;
  List_Header?: Maybe<PubsubContentPlainField>;
  List_Overview?: Maybe<PubsubContentPlainField>;
  Order_Number?: Maybe<PubsubContentPlainField>;
  Taxonomy?: Maybe<PubsubContentTagField>;
  Latitude?: Maybe<PubsubContentPlainField>;
  Longitude?: Maybe<PubsubContentPlainField>;
  background_img_url?: Maybe<PubsubContentPlainField>;
  Asset?: Maybe<PubsubContentAssetField>;
};

export type PubsubContentCastawayDestinationPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:castaway-destination. */
  data?: Maybe<PubsubContentCastawayDestinationPayload>;
};

export type PubsubContentCastawayPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCastawayPayloadFields>;
};

export type PubsubContentCastawayPayloadFields = {
  TitleOfContent?: Maybe<PubsubContentTitleField>;
  Theme_or_Destination?: Maybe<PubsubContentPlainField>;
  PodcastName?: Maybe<PubsubContentPlainField>;
  ShortDescription?: Maybe<PubsubContentPlainField>;
  PodcastShowId?: Maybe<PubsubContentPlainField>;
  EpisodeTitle?: Maybe<PubsubContentPlainField>;
  PodcastEpisodeId?: Maybe<PubsubContentPlainField>;
  TaxonomyField?: Maybe<PubsubContentTagField>;
};

export type PubsubContentCastawayPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:castaway. */
  data?: Maybe<PubsubContentCastawayPayload>;
};

export type PubsubContentCatalogField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentCatalogValue>;
};

export type PubsubContentCatalogListsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCatalogListsPayloadFields>;
};

export type PubsubContentCatalogListsPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  catalogItems?: Maybe<PubsubContentFlexField>;
};

export type PubsubContentCatalogListsPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:catalog-lists. */
  data?: Maybe<PubsubContentCatalogListsPayload>;
};

export type PubsubContentCatalogValue = {
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  relatedId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  web_link?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
};

export type PubsubContentCheckboxField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["Boolean"]>;
};

export type PubsubContentCoastToCoastArticlePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCoastToCoastArticlePayloadFields>;
};

export type PubsubContentCoastToCoastArticlePayloadFields = {
  seo_title?: Maybe<PubsubContentTitleField>;
  social_title?: Maybe<PubsubContentTitleField>;
  title?: Maybe<PubsubContentTitleField>;
  primary_image?: Maybe<PubsubContentAssetField>;
  primary_video?: Maybe<PubsubContentEmbedField>;
  author?: Maybe<PubsubContentAuthorField>;
  slug?: Maybe<PubsubContentSlugField>;
  summary?: Maybe<PubsubContentHtmlField>;
  keywords?: Maybe<PubsubContentTagField>;
  blocks?: Maybe<PubsubContentFlexField>;
  include_recommendations?: Maybe<PubsubContentCheckboxField>;
  fb_allow_comments?: Maybe<PubsubContentCheckboxField>;
  is_sponsored?: Maybe<PubsubContentCheckboxField>;
  simplereach_enabled?: Maybe<PubsubContentCheckboxField>;
  Updated?: Maybe<PubsubContentCheckboxField>;
  source?: Maybe<PubsubContentPlainField>;
  source_url?: Maybe<PubsubContentPlainField>;
};

export type PubsubContentCoastToCoastArticlePayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-article. */
  data?: Maybe<PubsubContentCoastToCoastArticlePayload>;
};

export type PubsubContentCoastToCoastGuestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCoastToCoastGuestPayloadFields>;
};

export type PubsubContentCoastToCoastGuestPayloadFields = {
  seo_title?: Maybe<PubsubContentTitleField>;
  social_title?: Maybe<PubsubContentTitleField>;
  title?: Maybe<PubsubContentTitleField>;
  primary_image?: Maybe<PubsubContentAssetField>;
  author?: Maybe<PubsubContentAuthorField>;
  slug?: Maybe<PubsubContentSlugField>;
  summary?: Maybe<PubsubContentHtmlField>;
  blocks?: Maybe<PubsubContentFlexField>;
  include_recommendations?: Maybe<PubsubContentCheckboxField>;
  fb_allow_comments?: Maybe<PubsubContentCheckboxField>;
  is_sponsored?: Maybe<PubsubContentCheckboxField>;
  simplereach_enabled?: Maybe<PubsubContentCheckboxField>;
  Updated?: Maybe<PubsubContentCheckboxField>;
  websites?: Maybe<PubsubContentHtmlField>;
  books?: Maybe<PubsubContentHtmlField>;
  videos?: Maybe<PubsubContentHtmlField>;
  cds?: Maybe<PubsubContentHtmlField>;
};

export type PubsubContentCoastToCoastGuestPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-guest. */
  data?: Maybe<PubsubContentCoastToCoastGuestPayload>;
};

export type PubsubContentCoastToCoastShowPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentCoastToCoastShowPayloadFields>;
};

export type PubsubContentCoastToCoastShowPayloadFields = {
  seo_title?: Maybe<PubsubContentTitleField>;
  social_title?: Maybe<PubsubContentTitleField>;
  title?: Maybe<PubsubContentTitleField>;
  primary_image?: Maybe<PubsubContentAssetField>;
  author?: Maybe<PubsubContentAuthorField>;
  summary?: Maybe<PubsubContentHtmlField>;
  blocks?: Maybe<PubsubContentFlexField>;
  show_date?: Maybe<PubsubContentDateField>;
  guest?: Maybe<PubsubContentLinkField>;
  host?: Maybe<PubsubContentLinkField>;
  host_name?: Maybe<PubsubContentPlainField>;
  slug?: Maybe<PubsubContentSlugField>;
  keywords?: Maybe<PubsubContentTagField>;
  display_audiobox?: Maybe<PubsubContentCheckboxField>;
  include_recommendations?: Maybe<PubsubContentCheckboxField>;
  fb_allow_comments?: Maybe<PubsubContentCheckboxField>;
  is_sponsored?: Maybe<PubsubContentCheckboxField>;
  simplereach_enabled?: Maybe<PubsubContentCheckboxField>;
  Updated?: Maybe<PubsubContentCheckboxField>;
  highlights?: Maybe<PubsubContentPlainField>;
  zype_highlights?: Maybe<PubsubContentPlainField>;
  bumper_music?: Maybe<PubsubContentBumperMusicField>;
  books?: Maybe<PubsubContentHtmlField>;
  articles?: Maybe<PubsubContentHtmlField>;
  videos?: Maybe<PubsubContentHtmlField>;
  websites?: Maybe<PubsubContentHtmlField>;
  episodes?: Maybe<PubsubContentPlainField>;
  zype_episodes?: Maybe<PubsubContentPlainField>;
  pn_podcast?: Maybe<PubsubContentPnPodcastField>;
};

export type PubsubContentCoastToCoastShowPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-show. */
  data?: Maybe<PubsubContentCoastToCoastShowPayload>;
};

export type PubsubContentContestValue = {
  contest_id?: Maybe<Scalars["Int"]>;
  call_letters?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type PubsubContentDateField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["Float"]>;
};

export type PubsubContentEmbedField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentEmbedValue>;
};

export type PubsubContentEmbedValue = {
  url?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  embed_type?: Maybe<Scalars["String"]>;
  rendering_hint?: Maybe<Scalars["String"]>;
};

export type PubsubContentExclusion = {
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentFlexField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentScheduledField>>>;
};

export type PubsubContentHtmlField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentHolidayHatPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentHolidayHatPayloadFields>;
};

export type PubsubContentHolidayHatPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  web_color?: Maybe<PubsubContentAssetField>;
  web_inverted?: Maybe<PubsubContentAssetField>;
  ios_light?: Maybe<PubsubContentAssetField>;
  ios_dark?: Maybe<PubsubContentAssetField>;
  android_light?: Maybe<PubsubContentAssetField>;
  android_dark?: Maybe<PubsubContentAssetField>;
};

export type PubsubContentHolidayHatPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:holiday-hat. */
  data?: Maybe<PubsubContentHolidayHatPayload>;
};

export type PubsubContentIngestion = {
  external_url?: Maybe<Scalars["String"]>;
  feed_content_id?: Maybe<Scalars["String"]>;
  feed_permalink?: Maybe<Scalars["String"]>;
  feed_vendor?: Maybe<Scalars["String"]>;
  feed_type?: Maybe<Scalars["String"]>;
  feed_partner_content?: Maybe<Scalars["Boolean"]>;
};

export type PubsubContentLegacyAppleNewsRef = {
  ref_id?: Maybe<Scalars["String"]>;
  revision_id?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyAssetValue = {
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  alt_text?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export enum PubsubContentLegacyBlockType {
  Asset = "asset",
  Html = "html",
  Embed = "embed",
  Catalog = "catalog",
  Contest = "contest",
  Iheartcontent = "iheartcontent",
  Gallery = "gallery",
}

export type PubsubContentLegacyCatalogRef = {
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  related_id?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyEmbeddedContentSummary = {
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyPublishTarget = {
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentLegacyResourceRef = {
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyScheduledAssetField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  attributes?: Maybe<PubsubContentAssetValueAttributes>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyScheduledCatalogField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  relatedId?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyScheduledContentField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  id?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  pub_changed?: Maybe<Scalars["Float"]>;
  summary?: Maybe<PubsubContentLegacyEmbeddedContentSummary>;
};

export type PubsubContentLegacyScheduledContestField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  contest_id?: Maybe<Scalars["Int"]>;
  call_letters?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyScheduledEmbedField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  url?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  embed_type?: Maybe<Scalars["String"]>;
  rendering_hint?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyScheduledField =
  | PubsubContentLegacyScheduledAssetField
  | PubsubContentLegacyScheduledCatalogField
  | PubsubContentLegacyScheduledContentField
  | PubsubContentLegacyScheduledContestField
  | PubsubContentLegacyScheduledEmbedField
  | PubsubContentLegacyScheduledGalleryField
  | PubsubContentLegacyScheduledHtmlField;

export type PubsubContentLegacyScheduledGalleryField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  assets?: Maybe<Array<Maybe<PubsubContentLegacyAssetValue>>>;
};

export type PubsubContentLegacyScheduledHtmlField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentLegacyBlockType>;
  html?: Maybe<Scalars["String"]>;
};

export type PubsubContentLegacyTopicSet = {
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  distribution?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentLinkField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentMultiAuthorTestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentMultiAuthorTestPayloadFields>;
};

export type PubsubContentMultiAuthorTestPayloadFields = {
  Title?: Maybe<PubsubContentTitleField>;
  Author1?: Maybe<PubsubContentAuthorField>;
  Author2?: Maybe<PubsubContentAuthorField>;
  Author3?: Maybe<PubsubContentAuthorField>;
};

export type PubsubContentMultiAuthorTestPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:multi-author-test. */
  data?: Maybe<PubsubContentMultiAuthorTestPayload>;
};

export type PubsubContentMultiDateField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentBlockSchedule>>>;
};

export type PubsubContentPnPodcastEpisode = {
  show?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  episodeId?: Maybe<Scalars["String"]>;
};

export type PubsubContentPnPodcastField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentPnPodcastEpisode>>>;
};

export type PubsubContentPayload = {
  title?: Maybe<Scalars["String"]>;
  seo_title?: Maybe<Scalars["String"]>;
  social_title?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  feed_content_id?: Maybe<Scalars["String"]>;
  feed_permalink?: Maybe<Scalars["String"]>;
  feed_vendor?: Maybe<Scalars["String"]>;
  external_url?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  include_recommendations?: Maybe<Scalars["Boolean"]>;
  fb_allow_comments?: Maybe<Scalars["Boolean"]>;
  is_sponsored?: Maybe<Scalars["Boolean"]>;
  amp_enabled?: Maybe<Scalars["Boolean"]>;
  publish_date?: Maybe<Scalars["Float"]>;
  publish_end_date?: Maybe<Scalars["Float"]>;
  publish_origin?: Maybe<Scalars["String"]>;
  primary_target?: Maybe<PubsubContentLegacyTopicSet>;
  targets?: Maybe<Array<Maybe<PubsubContentLegacyTopicSet>>>;
  exclusion?: Maybe<PubsubContentLegacyPublishTarget>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_image?: Maybe<PubsubContentLegacyResourceRef>;
  primary_image_uri?: Maybe<Scalars["String"]>;
  blocks?: Maybe<Array<Maybe<PubsubContentLegacyScheduledField>>>;
  apple_news?: Maybe<PubsubContentLegacyAppleNewsRef>;
  simplereach_enabled?: Maybe<Scalars["Boolean"]>;
  show_updated_timestamp?: Maybe<Scalars["Boolean"]>;
  cuser?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  enable_featured_widget?: Maybe<Scalars["Boolean"]>;
  featured_widget?: Maybe<PubsubContentLegacyCatalogRef>;
};

export type PubsubContentPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content. */
  data?: Maybe<PubsubContentPayload>;
};

export type PubsubContentPersonalityPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentPersonalityPayloadFields>;
};

export type PubsubContentPersonalityPayloadFields = {
  name?: Maybe<PubsubContentTitleField>;
  bio?: Maybe<PubsubContentHtmlField>;
};

export type PubsubContentPersonalityPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:personality. */
  data?: Maybe<PubsubContentPersonalityPayload>;
};

export type PubsubContentPlainField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentPodcastCollectionsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentPodcastCollectionsPayloadFields>;
};

export type PubsubContentPodcastCollectionsPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  description?: Maybe<PubsubContentPlainField>;
  image?: Maybe<PubsubContentAssetField>;
  slug?: Maybe<PubsubContentSlugField>;
  episodes?: Maybe<PubsubContentFlexField>;
};

export type PubsubContentPodcastCollectionsPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:podcast-collections. */
  data?: Maybe<PubsubContentPodcastCollectionsPayload>;
};

export type PubsubContentPodguidesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentPodguidesPayloadFields>;
};

export type PubsubContentPodguidesPayloadFields = {
  name?: Maybe<PubsubContentTitleField>;
  slug?: Maybe<PubsubContentSlugField>;
  author?: Maybe<PubsubContentAuthorField>;
  description?: Maybe<PubsubContentHtmlField>;
  color?: Maybe<PubsubContentPlainField>;
  position?: Maybe<PubsubContentPlainField>;
  city?: Maybe<PubsubContentTagField>;
  background_image?: Maybe<PubsubContentAssetField>;
  test1?: Maybe<PubsubContentCatalogField>;
  thumbnail_image?: Maybe<PubsubContentAssetField>;
  podcast_episodes?: Maybe<PubsubContentFlexField>;
};

export type PubsubContentPodguidesPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:podguides. */
  data?: Maybe<PubsubContentPodguidesPayload>;
};

export type PubsubContentScheduledAssetField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentAssetValue>;
};

export type PubsubContentScheduledCatalogField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentCatalogValue>;
};

export type PubsubContentScheduledContentField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentValue>;
};

export type PubsubContentScheduledContestField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentContestValue>;
};

export type PubsubContentScheduledEmbedField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<PubsubContentEmbedValue>;
};

export type PubsubContentScheduledField =
  | PubsubContentScheduledAssetField
  | PubsubContentScheduledCatalogField
  | PubsubContentScheduledContentField
  | PubsubContentScheduledContestField
  | PubsubContentScheduledEmbedField
  | PubsubContentScheduledGalleryField
  | PubsubContentScheduledHtmlField
  | PubsubContentScheduledPlainField;

export type PubsubContentScheduledGalleryField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<PubsubContentAssetValue>>>;
};

export type PubsubContentScheduledHtmlField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentScheduledPlainField = {
  schedule?: Maybe<PubsubContentBlockSchedule>;
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentSlugField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentSoundscapesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentSoundscapesPayloadFields>;
};

export type PubsubContentSoundscapesPayloadFields = {
  name?: Maybe<PubsubContentTitleField>;
  slug?: Maybe<PubsubContentSlugField>;
  background_images?: Maybe<PubsubContentFlexField>;
};

export type PubsubContentSoundscapesPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:soundscapes. */
  data?: Maybe<PubsubContentSoundscapesPayload>;
};

export type PubsubContentSummary = {
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<PubsubContentAssetValue>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_slug?: Maybe<Scalars["String"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
};

export type PubsubContentSyndication = {
  publish_origin?: Maybe<Scalars["String"]>;
  refs?: Maybe<PubsubContentSyndicationRefs>;
};

export type PubsubContentSyndicationRef = {
  id?: Maybe<Scalars["String"]>;
  statusId?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentSyndicationRefs = {
  apple_news?: Maybe<PubsubContentSyndicationRef>;
  google_news_sitemap?: Maybe<PubsubContentSyndicationRef>;
  partner_feeds?: Maybe<PubsubContentSyndicationRef>;
};

export type PubsubContentTagField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PubsubContentTalkbackResponsesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentTalkbackResponsesPayloadFields>;
};

export type PubsubContentTalkbackResponsesPayloadFields = {
  Headline?: Maybe<PubsubContentTitleField>;
  Description?: Maybe<PubsubContentPlainField>;
};

export type PubsubContentTalkbackResponsesPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:talkback-responses. */
  data?: Maybe<PubsubContentTalkbackResponsesPayload>;
};

export type PubsubContentTargeting = {
  exclusion?: Maybe<PubsubContentExclusion>;
  publish_date?: Maybe<Scalars["Float"]>;
  publish_end_date?: Maybe<Scalars["Float"]>;
  publish_origin?: Maybe<Scalars["String"]>;
  syndication?: Maybe<PubsubContentSyndication>;
  targets?: Maybe<Array<Maybe<PubsubContentTopicSet>>>;
};

export type PubsubContentTitleField = {
  type?: Maybe<PubsubContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type PubsubContentToolNotificationsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentToolNotificationsPayloadFields>;
};

export type PubsubContentToolNotificationsPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  body?: Maybe<PubsubContentHtmlField>;
  link?: Maybe<PubsubContentPlainField>;
};

export type PubsubContentToolNotificationsPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:tool-notifications. */
  data?: Maybe<PubsubContentToolNotificationsPayload>;
};

export type PubsubContentTopicSet = {
  id?: Maybe<Scalars["String"]>;
  what?: Maybe<Array<Maybe<Scalars["String"]>>>;
  where?: Maybe<Array<Maybe<Scalars["String"]>>>;
  operator?: Maybe<Scalars["String"]>;
};

export type PubsubContentValue = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_slug?: Maybe<Scalars["String"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
};

export type PubsubContentWazeFeaturedPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentWazeFeaturedPayloadFields>;
};

export type PubsubContentWazeFeaturedPayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  playable_1?: Maybe<PubsubContentCatalogField>;
  playable_2?: Maybe<PubsubContentCatalogField>;
  playable_3?: Maybe<PubsubContentCatalogField>;
  playable_4?: Maybe<PubsubContentCatalogField>;
  playable_5?: Maybe<PubsubContentCatalogField>;
  playable_6?: Maybe<PubsubContentCatalogField>;
};

export type PubsubContentWazeFeaturedPayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-featured. */
  data?: Maybe<PubsubContentWazeFeaturedPayload>;
};

export type PubsubContentWazeItemizedTemplatePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentWazeItemizedTemplatePayloadFields>;
};

export type PubsubContentWazeItemizedTemplatePayloadFields = {
  title?: Maybe<PubsubContentTitleField>;
  playable_1?: Maybe<PubsubContentCatalogField>;
  playable_2?: Maybe<PubsubContentCatalogField>;
  playable_3?: Maybe<PubsubContentCatalogField>;
  playable_4?: Maybe<PubsubContentCatalogField>;
  playable_5?: Maybe<PubsubContentCatalogField>;
  playable_6?: Maybe<PubsubContentCatalogField>;
};

export type PubsubContentWazeItemizedTemplatePayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-itemized-template. */
  data?: Maybe<PubsubContentWazeItemizedTemplatePayload>;
};

export type PubsubContentWazeMenuTemplatePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<PubsubContentIngestion>;
  summary?: Maybe<PubsubContentSummary>;
  targeting?: Maybe<PubsubContentTargeting>;
  fields?: Maybe<PubsubContentWazeMenuTemplatePayloadFields>;
};

export type PubsubContentWazeMenuTemplatePayloadFields = {
  waze_title?: Maybe<PubsubContentTitleField>;
  multiple_items?: Maybe<PubsubContentFlexField>;
  layout_tag?: Maybe<PubsubContentTagField>;
};

export type PubsubContentWazeMenuTemplatePayloadSelection = PubsubPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-menu-template. */
  data?: Maybe<PubsubContentWazeMenuTemplatePayload>;
};

/** Subscriptions are the entity used to materialize and query content. */
export type PubsubDsSubscription = {
  _id: Scalars["ID"];
  datasourceId: Scalars["ID"];
  /** Primary tag and identifier for a scope in the datasource. */
  scopeId: Scalars["ID"];
  /** Content type */
  type: Scalars["String"];
  /** List of combinations of tags from the datasource for content to be matched against */
  where: Array<Scalars["String"]>;
  /** @deprecated this field is no longer used and will be removed in the next major release */
  what: Array<Scalars["String"]>;
  /** List of tags to match against exclusions in content; any match is not included in the materialized query */
  subject: Array<Scalars["String"]>;
  /** Status of content materialization on this subscription */
  backfill?: Maybe<PubsubDsSubscriptionBackfillStatus>;
  /** Fetch a page of content from this subscription, filtered to the given 'what' list (or default if unspecified) */
  query?: Maybe<PubsubDsSubscriptionQueryResult>;
  mtime: Scalars["Float"];
  targetId: Scalars["String"];
};

/** Subscriptions are the entity used to materialize and query content. */
export type PubsubDsSubscriptionQueryArgs = {
  pageToken?: Maybe<Scalars["ID"]>;
  size?: Maybe<Scalars["Int"]>;
  what?: Maybe<Array<Scalars["String"]>>;
};

export type PubsubDsSubscriptionQueryResult = {
  items: Array<PubsubPublishRecord>;
  page: PubsubPageInfo;
};

/** A Datasource is a configuration for one or more materialized queries (subscriptions) and an entrypoint for querying */
export type PubsubDatasource = {
  _id: Scalars["ID"];
  /** Datasource name (may not be unique) */
  name: Scalars["String"];
  /** Content type this datasource applies to */
  type: Scalars["String"];
  /** List of arbitrary strings that can be used to find a datasource record */
  references: Array<Scalars["String"]>;
  /** The primary tag is used to match content in a standalone fashion */
  primary: Scalars["String"];
  /** Secondary tags work the same as primary; content targeted to any one of them will match the datasource */
  secondaries?: Maybe<Array<Scalars["String"]>>;
  /** Segments work in pairings with primary/secondary tags; content targeted to a primary AND segment will match */
  segments: Array<Scalars["String"]>;
  /** Scopes are for creating narrow queries (i.e. with more tags) to give publishers ways to target only this datasource */
  scopes: Array<PubsubScope>;
  /** This flag (if true) splits the scopes each into their own subscription which can be queried separately */
  subscriptionPerScope: Scalars["Boolean"];
  /** Default set of categories used to filter content queried under this datasource */
  what: Array<Scalars["String"]>;
  /** Find a subscription by id OR by segment with optional scope id */
  subscription?: Maybe<PubsubDsSubscription>;
};

/** A Datasource is a configuration for one or more materialized queries (subscriptions) and an entrypoint for querying */
export type PubsubDatasourceSubscriptionArgs = {
  _id?: Maybe<Scalars["ID"]>;
  segment?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
};

export type PubsubDefaultPayloadSelection = PubsubPayloadSelection & {
  /** Content Type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Raw content. */
  data?: Maybe<Scalars["JSON"]>;
};

/** Original targeting format for content */
export type PubsubDistribution = {
  /** List of tags indicating categorization and/or destination for content */
  tags: Array<Scalars["String"]>;
};

export type PubsubDistributionInput = {
  tags: Array<Scalars["String"]>;
  operator?: Maybe<PubsubDistributionOperator>;
};

/** How to combine tags in distribution (i.e. "match all tags" vs "match any combination of tags" */
export enum PubsubDistributionOperator {
  And = "AND",
}

export enum PubsubDsSubscriptionBackfillStates {
  /** Materialization hasn't occurred, or the datasource was updated */
  Unknown = "UNKNOWN",
  /** Materialization is active (check processed/total counts for progress) */
  InProgress = "IN_PROGRESS",
  /** Materialization is finished */
  Complete = "COMPLETE",
}

export type PubsubDsSubscriptionBackfillStatus = {
  /** Current state of backfill */
  status: PubsubDsSubscriptionBackfillStates;
  /** Timestamp (milliseconds) when backfill began */
  startTime: Scalars["Float"];
  /** Count of records backfilled */
  processed: Scalars["Int"];
  /** Count of matching records to backfill */
  total: Scalars["Int"];
  /** Subscription targetIds that act as a fallback for content querying while the this subscription is backfilling */
  alternativeTargetIds?: Maybe<Array<Scalars["String"]>>;
};

export type PubsubExclusionInput = {
  tags: Array<Scalars["String"]>;
};

export type PubsubFederationQuery = {
  /** Get a published record by id or slug */
  get?: Maybe<PubsubPublishRecord>;
  /** List records with the given filter applied */
  query?: Maybe<PubsubQueryOutput>;
  /** Perform a fulltext search on records */
  search: PubsubSearchOutput;
  /** Get a registered datasource by id */
  datasource?: Maybe<PubsubDatasource>;
  /** Get a datasource subscription by id */
  subscription?: Maybe<PubsubDsSubscription>;
};

export type PubsubFederationQueryGetArgs = {
  type: Scalars["String"];
  select: PubsubSelectInput;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
};

export type PubsubFederationQueryQueryArgs = {
  type: Scalars["String"];
  query: PubsubQueryInput;
};

export type PubsubFederationQuerySearchArgs = {
  type?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Scalars["String"]>>;
  search: PubsubSearchInput;
};

export type PubsubFederationQueryDatasourceArgs = {
  _id: Scalars["ID"];
};

export type PubsubFederationQuerySubscriptionArgs = {
  _id?: Maybe<Scalars["ID"]>;
};

export type PubsubLinksInInput = {
  /** Field name to fetch links for. */
  name: Scalars["String"];
  /** Query a particular pubsub type for results. */
  type: Scalars["String"];
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  /** Page size for results. Default: 25 */
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubLinksOutInput = {
  /** Field name to fetch links for. */
  name: Scalars["String"];
  /** Narrow results to a particular pubsub type. */
  type?: Maybe<Scalars["String"]>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubPageInfo = {
  prevToken?: Maybe<Scalars["ID"]>;
  nextToken?: Maybe<Scalars["ID"]>;
  itemTokens?: Maybe<Array<Scalars["ID"]>>;
};

/**
 * Base type for all content payloads.
 * If a content type has a payload schema, it will define a typed `data` property.
 * (The default implementation will just have the raw JSON data under `data`.)
 */
export type PubsubPayloadSelection = {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
};

export type PubsubPublishLinkInput = {
  type: Scalars["String"];
  name: Scalars["String"];
  ref_id: Scalars["String"];
};

export type PubsubPublishRecord = {
  /** Primary key id in storage */
  id: Scalars["ID"];
  /** Reference to item id in the publishing application */
  ref_id: Scalars["ID"];
  /** Primary slug for item */
  slug?: Maybe<Scalars["String"]>;
  /** Content type (created manually or by the publishing application) */
  type: Scalars["String"];
  summary: PubsubPublishSummary;
  /** The raw item content. Using the `content` field to have a typed selection is preferred */
  payload?: Maybe<Scalars["JSON"]>;
  /** Typed item payload (format differs between content types), for content types that have a schema. */
  content?: Maybe<PubsubPayloadSelection>;
  /** Item targeting (e.g. which sites receive the content) */
  subscription: Array<PubsubDistribution>;
  /** List of entities to exclude when returning this item (e.g. when running a broad query) */
  exclusion: Array<PubsubDistribution>;
  /** Beginning millisecond timestamp for item availability */
  pub_start: Scalars["Float"];
  /** Ending millisecond timestamp for item availability */
  pub_until: Scalars["Float"];
  /** Millisecond timestamp for the latest update of the item (according to the publishing application) */
  pub_changed?: Maybe<Scalars["Float"]>;
  /** Millisecond timestamp for the latest update of the item (always updated during publish) */
  pub_touched: Scalars["Float"];
  /** Fetch content which links to this record. */
  linksIn: PubsubQueryOutput;
  /** Fetch content this record links to. */
  linksOut: Array<PubsubPublishRecord>;
  /** List of unique identifiers for the item */
  keys: Array<Scalars["String"]>;
  /** List of additional datapoints that can be used to find this item (not unique). */
  lookup?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** List of complete what/where targets used in materializing datasource content */
  datasourceTargets?: Maybe<Array<PubsubTarget>>;
  /** List of references to linked content */
  links?: Maybe<Array<PubsubRawPublishLink>>;
};

export type PubsubPublishRecordPayloadArgs = {
  fields?: Array<Scalars["String"]>;
};

export type PubsubPublishRecordLinksInArgs = {
  query: PubsubLinksInInput;
};

export type PubsubPublishRecordLinksOutArgs = {
  query: PubsubLinksOutInput;
};

/** Common information for publish records */
export type PubsubPublishSummary = {
  /** By-line for content */
  author: Scalars["String"];
  /** Subtitle or other descriptive info */
  description: Scalars["String"];
  /** Thumbnail (or other) image URI to associate with content */
  image?: Maybe<Scalars["String"]>;
  /** Title or equivalent display name for the item */
  title: Scalars["String"];
};

export type PubsubPublishSummaryInput = {
  author: Scalars["String"];
  description: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type PubsubQueryInput = {
  lookup?: Maybe<Array<Scalars["String"]>>;
  subscription?: Maybe<Array<PubsubDistributionInput>>;
  /** This field is unused. Use `subject` instead. */
  exclusion?: Maybe<Array<PubsubExclusionInput>>;
  subject?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_after?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Int"]>;
  /** Use `nextToken` instead. This option will be removed in a future release. */
  offset?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubQueryOutput = {
  items: Array<PubsubPublishRecord>;
  query: Scalars["JSON"];
  /** @deprecated Use `page.nextToken`, if present this query has more results. */
  has_more: Scalars["Boolean"];
  /** @deprecated Use `page.nextToken` instead. */
  next?: Maybe<Scalars["String"]>;
  page: PubsubPageInfo;
};

export type PubsubRawPublishLink = {
  type: Scalars["String"];
  ref_id: Scalars["String"];
  name: Scalars["String"];
};

export type PubsubScope = {
  /** Primary tag for the scope, which is paired with each of the primary/secondary datasource tags for the base query */
  id: Scalars["String"];
  /** These tags plus the datasource segments are combined the base scope query to form more specific queries */
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type PubsubScopeFilterInput = {
  /** At least one scope's primary tag must match the given tag */
  id?: Maybe<Scalars["String"]>;
  /** At least one scope's non-primary tags must include all listed tags */
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type PubsubSearchInput = {
  query: Scalars["JSON"];
  lookup?: Maybe<Array<Scalars["String"]>>;
  subscription?: Maybe<Array<PubsubDistributionInput>>;
  /** This field is unused. Use `subject` instead. */
  exclusion?: Maybe<Array<PubsubExclusionInput>>;
  subject?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_after?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Int"]>;
  /** Use `nextToken` instead. This option will be removed in a future release. */
  offset?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortField?: Maybe<PubsubSortField>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type PubsubSearchOutput = {
  items: Array<PubsubPublishRecord>;
  search: Scalars["JSON"];
  /** @deprecated Use `page.nextToken`, if present this search has more results. */
  has_more: Scalars["Boolean"];
  /** @deprecated Use `page.nextToken` instead. */
  next?: Maybe<Scalars["String"]>;
  page: PubsubPageInfo;
};

export type PubsubSelectInput = {
  /** Reference id (e.g. the item id from content or leads) */
  id?: Maybe<Scalars["String"]>;
  /** Item slug (tool dependent) */
  slug?: Maybe<Scalars["String"]>;
};

export enum PubsubSortField {
  PubStart = "PUB_START",
  PubTouched = "PUB_TOUCHED",
  None = "NONE",
}

/** What/where targeting information for content */
export type PubsubTarget = {
  /** Tags representing categorization of content, used as a secondary filter during query */
  what: Array<Scalars["String"]>;
  /** Tags representing the destination of content (e.g. a site or grouping of sites) */
  where: Array<Scalars["String"]>;
  operator?: Maybe<PubsubDistributionOperator>;
};

export type PubsubTargetsInput = {
  what: Array<Scalars["String"]>;
  where: Array<Scalars["String"]>;
  operator?: Maybe<PubsubDistributionOperator>;
};

export type Query = {
  sites: SitesSites;
  adPositions: SitesAdPositions;
  onAir: SitesOnAir;
  /** Pubsub queries for public consumption */
  pubsub: PubsubFederationQuery;
  playlists: PlaylistsRootQuery;
  charts: ChartFunctions;
  pws: PwsModules;
  talkback: TalkBackQuery;
  catalog: CatalogFunctions;
  postup: PostupQuery;
  /** Queries available for carousels. */
  carousels: CarouselQuery;
  podcastTranscriptionFormatter: PodcastTranscriptionFormatterQuery;
  content?: Maybe<ContentFunctions>;
  /** @deprecated This uses site-manager 4 instead of sites */
  site?: Maybe<SiteFunctions>;
  artist?: Maybe<ArtistFunctions>;
  genre?: Maybe<GenreFunctions>;
  leads: Array<Card>;
  streams?: Maybe<StreamsFunctions>;
  calendar?: Maybe<CalendarFunctions>;
  aptivada?: Maybe<AptivadaFunctions>;
  amp: AmpFunctions;
  taxonomy?: Maybe<TaxonomyFunctions>;
};

export type QuerySiteArgs = {
  select: SiteQuery;
};

export type QueryArtistArgs = {
  artistId: Scalars["Int"];
};

export type QueryGenreArgs = {
  genreId: Scalars["Int"];
};

export type QueryLeadsArgs = {
  query: QueryInput;
  locale?: Maybe<Scalars["String"]>;
};

export type QueryAptivadaArgs = {
  accountId: Scalars["String"];
};

/** ObjectDB resource annotations. */
export type QueryAnnotations = {
  cuser?: Maybe<UserAnnotation>;
  muser?: Maybe<UserAnnotation>;
};

export type QueryInput = {
  lookup?: Maybe<Array<Scalars["String"]>>;
  subscription?: Maybe<Array<SubscriptionInput>>;
  exclusion?: Maybe<Array<SubscriptionInput>>;
  subject?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_after?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type RawSchedule = {
  associated_microsite?: Maybe<Scalars["String"]>;
  batch_id: Scalars["Int"];
  core_show_id: Scalars["Int"];
  core_station_id: Scalars["Int"];
  link?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  personality?: Maybe<Scalars["String"]>;
  show: Scalars["String"];
  site: Scalars["String"];
  site_id: Scalars["String"];
  station: Scalars["String"];
  tz_id: Scalars["String"];
  schedule: RawScheduleWeek;
};

export type RawScheduleDay = {
  start: Scalars["Float"];
  start_local: Scalars["String"];
  stop: Scalars["Float"];
  stop_local: Scalars["String"];
};

export type RawScheduleWeek = {
  su?: Maybe<RawScheduleDay>;
  mo?: Maybe<RawScheduleDay>;
  tu?: Maybe<RawScheduleDay>;
  we?: Maybe<RawScheduleDay>;
  th?: Maybe<RawScheduleDay>;
  fr?: Maybe<RawScheduleDay>;
  sa?: Maybe<RawScheduleDay>;
};

export type Redirect = {
  sourceType?: Maybe<Scalars["String"]>;
  source: RedirectSource;
  destinationType?: Maybe<Scalars["String"]>;
  destination: RedirectDestination;
  startDate?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["Float"]>;
  permanent: Scalars["Boolean"];
};

export type RedirectDestination = {
  url?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
};

export type RedirectQuery = {
  path: Scalars["String"];
};

export type RedirectSource = {
  path?: Maybe<Scalars["String"]>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  regex?: Maybe<Scalars["Boolean"]>;
};

export enum Sites_Lookup_By {
  Id = "ID",
  Slug = "SLUG",
  Brand = "BRAND",
  Hostname = "HOSTNAME",
  Show = "SHOW",
  PrimaryParentOf = "PRIMARY_PARENT_OF",
  BroadcastFacility = "BROADCAST_FACILITY",
  BroadcastFacilityCallsign = "BROADCAST_FACILITY_CALLSIGN",
  Stream = "STREAM",
  Genre = "GENRE",
  Podcast = "PODCAST",
}

export enum Sites_Onair_Day {
  Su = "SU",
  Mo = "MO",
  Tu = "TU",
  We = "WE",
  Th = "TH",
  Fr = "FR",
  Sa = "SA",
}

export enum Sites_Onair_Source {
  Core = "CORE",
  User = "USER",
}

export enum Sites_Referenced_By {
  EmbeddedIn = "EMBEDDED_IN",
}

export type Schedule = {
  timezone?: Maybe<Scalars["String"]>;
  /**
   * This represents the current show that is on the air for this brand.
   * It's quite possible this could be null as *most* brands have gaps in their
   * schedule where shows do not exist.
   */
  current?: Maybe<ScheduleEntry>;
  /**
   * This represents the current show that is on the air for this brand.
   * It's quite possible this could be null as *most* brands have gaps in their
   * schedule where shows do not exist.
   */
  upcoming: Array<ScheduleEntry>;
};

export type ScheduleUpcomingArgs = {
  next?: Maybe<Scalars["Int"]>;
};

/**
 * This contains an array of possible start and stop values to make
 * representing the appropriate time or deserializing the date info
 * easy on the client.
 *
 * For links, image and content about the show's site checkout the
 * `destination` attribute.
 */
export type ScheduleEntry = {
  core_show_id: Scalars["Int"];
  core_station_id: Scalars["Int"];
  destination?: Maybe<ScheduleEntryDestination>;
  name: Scalars["String"];
  on_now: Scalars["Boolean"];
  show: Scalars["String"];
  site_id: Scalars["String"];
  site: Scalars["String"];
  start_date: Scalars["String"];
  start_eng: Scalars["String"];
  start_ms: Scalars["Float"];
  start_time_12: Scalars["String"];
  station: Scalars["String"];
  stop_date: Scalars["String"];
  stop_eng: Scalars["String"];
  stop_ms: Scalars["Float"];
  stop_time_12: Scalars["String"];
};

export type ScheduleEntryDestination = {
  host: Scalars["String"];
  href: Scalars["String"];
  image_ref?: Maybe<Scalars["ImageRef"]>;
  link?: Maybe<Scalars["String"]>;
  linked_to_microsite: Scalars["Boolean"];
  name: Scalars["String"];
  slug: Scalars["String"];
  thumbnail?: Maybe<Scalars["String"]>;
};

export type SiteAds = {
  dfp_switch?: Maybe<Scalars["Boolean"]>;
  interstitial_switch?: Maybe<Scalars["Boolean"]>;
  simplifi_switch?: Maybe<Scalars["Boolean"]>;
  simplifi_cid?: Maybe<Scalars["String"]>;
  simplifi_segment?: Maybe<Scalars["String"]>;
  simplifi_sifi_tuid?: Maybe<Scalars["String"]>;
  header_bidding: Scalars["Boolean"];
  facebook_ad_track_pixel?: Maybe<Scalars["String"]>;
  facebook_ad_track_pixel_switch?: Maybe<Scalars["Boolean"]>;
  facebook_ad_track_pixel_id?: Maybe<Scalars["String"]>;
  yieldmo_switch?: Maybe<Scalars["Boolean"]>;
};

export type SiteAlert = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["Float"]>;
  end_date?: Maybe<Scalars["Float"]>;
};

export type SiteAnalytics = {
  adobe_library?: Maybe<Scalars["String"]>;
  omniture_switch?: Maybe<Scalars["Boolean"]>;
  omniture_id_poc_control?: Maybe<Scalars["String"]>;
  omniture_id_market?: Maybe<Scalars["String"]>;
  google_analytics_switch?: Maybe<Scalars["Boolean"]>;
  poc_primary_analytics_id?: Maybe<Scalars["String"]>;
  poc_secondary_analytics_id?: Maybe<Scalars["String"]>;
  quantcast_switch?: Maybe<Scalars["Boolean"]>;
  quantcast_account_id?: Maybe<Scalars["String"]>;
  charbeat_switch?: Maybe<Scalars["Boolean"]>;
  chartbeat_account_id?: Maybe<Scalars["String"]>;
  parsely_switch?: Maybe<Scalars["Boolean"]>;
  parsely_account_id?: Maybe<Scalars["String"]>;
};

export type SiteConfig = BaseConfig & {
  page: Page;
  pages: Scalars["JSON"];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
  /** // ownership: String! */
  menu: Array<SiteMenu>;
  menu_footer: Array<SiteMenu>;
  general: SiteGeneral;
  alert: SiteAlert;
  redirects: Array<Redirect>;
  social: SiteSocial;
  contact: SiteContact;
  partners: SitePartners;
  design: SiteDesign;
  hide: SiteHide;
  ads: SiteAds;
  analytics: SiteAnalytics;
  navigation: SiteNavigation;
};

export type SiteConfigPageArgs = {
  viewName?: Maybe<Scalars["String"]>;
};

export type SiteContact = {
  page_intro?: Maybe<Scalars["String"]>;
  show_social_switch?: Maybe<Scalars["Boolean"]>;
  phone_text_numbers_switch?: Maybe<Scalars["Boolean"]>;
  request_phone_number?: Maybe<Scalars["String"]>;
  business_phone_number?: Maybe<Scalars["String"]>;
  traffic_tip_phone?: Maybe<Scalars["String"]>;
  newsroom_phone?: Maybe<Scalars["String"]>;
  sms_number?: Maybe<Scalars["String"]>;
  prize_pickup_directions_switch?: Maybe<Scalars["Boolean"]>;
  prize_pickup_info?: Maybe<Scalars["String"]>;
  location_address1?: Maybe<Scalars["String"]>;
  location_address2?: Maybe<Scalars["String"]>;
  location_city?: Maybe<Scalars["String"]>;
  location_state?: Maybe<Scalars["String"]>;
  location_zip?: Maybe<Scalars["String"]>;
  office_hours?: Maybe<Scalars["String"]>;
  email_form_switch?: Maybe<Scalars["Boolean"]>;
  contacts?: Maybe<Array<SiteContactEntry>>;
  employment_info_switch?: Maybe<Scalars["String"]>;
  eeo_report_asset?: Maybe<Scalars["ImageRef"]>;
  eeo_report?: Maybe<Scalars["String"]>;
  employment_contact_name?: Maybe<Scalars["String"]>;
  employment_link_override?: Maybe<Scalars["String"]>;
  tech_contact?: Maybe<Scalars["String"]>;
  programming_contact?: Maybe<Scalars["String"]>;
};

export type SiteContactEntry = {
  type?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type SiteData = ConfigData & {
  /**
   * _id: String
   * id: String
   */
  index: SiteIndex;
  config: SiteConfig;
  surrogate_keys: Array<Scalars["String"]>;
};

export type SiteDesign = {
  logo_best_res?: Maybe<Scalars["ImageRef"]>;
  station_logo?: Maybe<Scalars["ImageRef"]>;
  logo_favicon?: Maybe<Scalars["ImageRef"]>;
  link_color?: Maybe<Scalars["String"]>;
  read_next_stripe?: Maybe<Scalars["String"]>;
  nav_bg_color?: Maybe<Scalars["String"]>;
  nav_text_color?: Maybe<Scalars["String"]>;
  nav_ihr_link_color?: Maybe<Scalars["String"]>;
  player_bar_switch?: Maybe<Scalars["Boolean"]>;
  ihr_hero_image?: Maybe<Scalars["ImageRef"]>;
  ihr_hero_color?: Maybe<Scalars["String"]>;
};

export type SiteFunctions = {
  summary: SiteSummary;
  /** @deprecated This provides the entire config structure, it assumes that all output is site content not microsite content so there could be errors when trying to address site-only fields. */
  config?: Maybe<ConfigData>;
  adConfig?: Maybe<AdConfig>;
  redirect?: Maybe<Scalars["String"]>;
  timeline: Array<ContentRecord>;
  onairRaw?: Maybe<Array<RawSchedule>>;
  /** This function processes the raw scheduling data from `onairRaw` into useful output. */
  schedule?: Maybe<Schedule>;
  leads: Array<Card>;
  /**
   * Get calendar event info for the site. Default start date is "now".
   * @deprecated Always empty, because the original calendar service no longer exists.
   */
  events: Array<CalendarEvent>;
  /** @deprecated Always empty, because the original calendar service no longer exists. */
  eventCategories: Array<Scalars["String"]>;
  stream?: Maybe<Stream>;
  microsites?: Maybe<Array<MicrositeRef>>;
  sitemap: SiteMapIndex;
  feed?: Maybe<Array<Maybe<DatasourceFeedItem>>>;
};

export type SiteFunctionsRedirectArgs = {
  path: Scalars["String"];
};

export type SiteFunctionsTimelineArgs = {
  topic?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
};

export type SiteFunctionsLeadsArgs = {
  locale?: Maybe<Scalars["String"]>;
};

export type SiteFunctionsEventsArgs = {
  category?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["Float"]>;
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type SiteFunctionsEventCategoriesArgs = {
  start_time?: Maybe<Scalars["Float"]>;
  end_time?: Maybe<Scalars["Float"]>;
};

export type SiteFunctionsFeedArgs = {
  id?: Maybe<Scalars["String"]>;
  context?: Maybe<Scalars["JSON"]>;
  limit?: Maybe<Scalars["Float"]>;
  offset?: Maybe<Scalars["Float"]>;
  locale?: Maybe<Scalars["String"]>;
};

export type SiteGeneral = {
  name: Scalars["String"];
  positioner?: Maybe<Scalars["String"]>;
  default_content?: Maybe<Array<Scalars["String"]>>;
  description: Scalars["String"];
  keywords?: Maybe<Array<Scalars["String"]>>;
  market: Scalars["String"];
  weather_market?: Maybe<Scalars["String"]>;
  geo_latitude?: Maybe<Scalars["String"]>;
  geo_longitude?: Maybe<Scalars["String"]>;
  tz_id?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  ownership?: Maybe<Scalars["String"]>;
  language: Scalars["String"];
  broadcast_frequency: Scalars["String"];
  broadcast_band: Scalars["String"];
  facility_id?: Maybe<Scalars["String"]>;
  broadcast_call_letters: Scalars["String"];
  broadcast_facilities: Array<BroadcastFacility>;
  default_podcasts?: Maybe<Array<Scalars["String"]>>;
  default_feed?: Maybe<Scalars["String"]>;
  google_amp_switch?: Maybe<Scalars["Boolean"]>;
};

export type SiteHide = {
  hide_switch?: Maybe<Scalars["Boolean"]>;
  hide_start_date?: Maybe<Scalars["Float"]>;
  hide_end_date?: Maybe<Scalars["Float"]>;
  url_redirect?: Maybe<Scalars["String"]>;
};

/** TODO Should probably split these into interfaces with Site/Micro/Index etc... */
export type SiteIndex = BaseIndex & {
  id: Scalars["String"];
  brand_id: Scalars["String"];
  default: Scalars["String"];
  description: Scalars["String"];
  legacy_id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  own_brand: Scalars["String"];
  slug: Scalars["String"];
  configs: Array<BaseIndexConfigPointer>;
  facets: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  image?: Maybe<Scalars["ImageRef"]>;
  /** Site specific fields */
  hosts?: Maybe<Array<SiteIndexHost>>;
  linked?: Maybe<Array<MicroData>>;
  market?: Maybe<Scalars["String"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
};

export type SiteIndexHost = {
  design?: Maybe<Scalars["String"]>;
  hostname: Scalars["String"];
  primary?: Maybe<Scalars["Boolean"]>;
};

export type SiteMap = {
  primary_origin?: Maybe<Scalars["String"]>;
  records?: Maybe<Scalars["Float"]>;
  items: Array<Maybe<SiteMapItem>>;
};

export type SiteMapIndex = {
  pages: SiteMap;
  timeline: SiteMap;
};

export type SiteMapItem = {
  loc: Scalars["String"];
  lastmod?: Maybe<Scalars["String"]>;
  changefreq: Scalars["String"];
};

export type SiteMenu = {
  type?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  children?: Maybe<Array<Maybe<SiteMenu>>>;
  target?: Maybe<Scalars["String"]>;
  ref?: Maybe<Scalars["String"]>;
};

export type SiteNavigation = {
  listen_live_switch?: Maybe<Scalars["Boolean"]>;
  contest_nav_switch?: Maybe<Scalars["Boolean"]>;
  search_text?: Maybe<Scalars["Boolean"]>;
  user_menu_switch?: Maybe<Scalars["Boolean"]>;
  connect_menu_switch?: Maybe<Scalars["Boolean"]>;
  show_public_file_switch?: Maybe<Scalars["Boolean"]>;
  search_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitePartners = {
  iheartradio_switch?: Maybe<Scalars["Boolean"]>;
  stream_id?: Maybe<Scalars["String"]>;
  ihr_stream?: Maybe<CatalogRef>;
  ihr_on_demand?: Maybe<Scalars["String"]>;
  ihr_playlist_max?: Maybe<Scalars["String"]>;
  ihr_player_playlist?: Maybe<Scalars["Boolean"]>;
  national_programming_switch?: Maybe<Scalars["Boolean"]>;
  format?: Maybe<Scalars["String"]>;
  exacttarget_switch?: Maybe<Scalars["Boolean"]>;
  exacttarget_business_unit_id?: Maybe<Scalars["String"]>;
  outbrain_switch?: Maybe<Scalars["Boolean"]>;
  total_traffic_switch?: Maybe<Scalars["Boolean"]>;
  traffic_market?: Maybe<Scalars["String"]>;
  traffic_zoom?: Maybe<Scalars["String"]>;
  ldr_key_id?: Maybe<Scalars["String"]>;
  tynt_switch?: Maybe<Scalars["Boolean"]>;
  aptivada_switch?: Maybe<Scalars["Boolean"]>;
  perfect_pixel_switch?: Maybe<Scalars["Boolean"]>;
  alexa_pronouncer?: Maybe<Scalars["String"]>;
  google_pronouncer?: Maybe<Scalars["String"]>;
  ihr_show_in_iheart_switch?: Maybe<Scalars["Boolean"]>;
  simple_reach_switch?: Maybe<Scalars["Boolean"]>;
  simple_reach_id?: Maybe<Scalars["String"]>;
};

export type SiteQuery = {
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  hostname?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  stream?: Maybe<Scalars["Int"]>;
  genre?: Maybe<Scalars["Int"]>;
  podcast?: Maybe<Scalars["Int"]>;
  parent_id?: Maybe<Scalars["String"]>;
};

export type SiteRef = {
  id: Scalars["ID"];
  sites?: Maybe<SitesIndexRecord>;
  site?: Maybe<TalkBackSitesLookup>;
  taxo?: Maybe<Tag>;
  streams?: Maybe<Stream>;
};

export type SiteSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  facebook_name?: Maybe<Scalars["String"]>;
  facebook_app_id?: Maybe<Scalars["String"]>;
  facebook_fan_page_id?: Maybe<Scalars["String"]>;
  facebook_api_key?: Maybe<Scalars["String"]>;
  facebook_app_secret?: Maybe<Scalars["String"]>;
  googleplus_switch?: Maybe<Scalars["Boolean"]>;
  googleplus_name?: Maybe<Scalars["String"]>;
  googleplus_page_id?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  twitter_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  instagram_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  snapchat_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  youtube_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  pinterest_name?: Maybe<Scalars["String"]>;
};

export type SiteSummary = {
  hero_color?: Maybe<Scalars["String"]>;
  hero_image?: Maybe<Scalars["ImageRef"]>;
  request_phone_number?: Maybe<Scalars["String"]>;
  sms_number?: Maybe<Scalars["String"]>;
  facebook_name?: Maybe<Scalars["String"]>;
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  googleplus_name?: Maybe<Scalars["String"]>;
  googleplus_switch?: Maybe<Scalars["Boolean"]>;
  twitter_name?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  instagram_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  youtube_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  snapchat_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  pinterest_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitesAdBreakpoint = {
  breakpoint: Scalars["Int"];
  sizes: Array<SitesAdSize>;
};

export type SitesAdPositionFilterInput = {
  templates?: Maybe<Array<Scalars["String"]>>;
  positionIds?: Maybe<Array<Scalars["String"]>>;
};

export type SitesAdPositionInput = {
  positionId: Scalars["String"];
  adSplit: Scalars["Int"];
  forceLoad: Scalars["Boolean"];
  templates: Array<Scalars["String"]>;
};

export type SitesAdPositionMutation = {
  update: SitesAdPositionRecord;
  delete?: Maybe<Scalars["Boolean"]>;
  addSize: SitesAdPositionRecord;
  remSize: SitesAdPositionRecord;
};

export type SitesAdPositionMutationUpdateArgs = {
  payload: SitesAdPositionInput;
};

export type SitesAdPositionMutationAddSizeArgs = {
  breakpoint: Scalars["Int"];
  width: Scalars["Int"];
  height: Scalars["Int"];
};

export type SitesAdPositionMutationRemSizeArgs = {
  breakpoint: Scalars["Int"];
  width: Scalars["Int"];
  height: Scalars["Int"];
};

export type SitesAdPositionMutations = {
  create: SitesAdPositionRecord;
  find: SitesAdPositionMutation;
};

export type SitesAdPositionMutationsCreateArgs = {
  payload: SitesAdPositionInput;
};

export type SitesAdPositionMutationsFindArgs = {
  id: Scalars["ID"];
};

export type SitesAdPositionRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  positionId: Scalars["String"];
  /** @deprecated AdPositionRecord now supports multiple templates, use the `AdPositionRecord.templates` resolver instead. */
  template: Scalars["String"];
  templates: Array<Scalars["String"]>;
  adSplit: Scalars["Int"];
  forceLoad: Scalars["Boolean"];
  breakpoints: Array<SitesAdBreakpoint>;
};

export type SitesAdPositionResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesAdPositionRecord>;
};

export type SitesAdPositions = {
  /** @deprecated Non-paginated, use filter2 */
  filter: Array<SitesAdPositionRecord>;
  filter2: SitesAdPositionResults;
  find?: Maybe<SitesAdPositionRecord>;
  findMany: Array<SitesAdPositionRecord>;
};

export type SitesAdPositionsFilterArgs = {
  filter: SitesAdPositionFilterInput;
};

export type SitesAdPositionsFilter2Args = {
  filter: SitesAdPositionFilterInput;
  paging?: Maybe<SitesPageInput>;
};

export type SitesAdPositionsFindArgs = {
  id: Scalars["ID"];
};

export type SitesAdPositionsFindManyArgs = {
  ids: Array<Scalars["ID"]>;
};

export type SitesAdSize = {
  w: Scalars["Int"];
  h: Scalars["Int"];
};

export type SitesAddPageInput = {
  name: Scalars["String"];
  configId: Scalars["ID"];
  layoutId: Scalars["String"];
  displayName: Scalars["String"];
  path: Scalars["String"];
  pathVariables: Scalars["JSON"];
  tags: Array<Scalars["String"]>;
  blocks: Array<SitesBlockInput>;
};

export type SitesAdditionalEntityFields = {
  path?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SitesAppleNewsIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
  plugin: SitesAppleNewsIntegrationData;
};

export type SitesAppleNewsIntegrationData = {
  api_key: Scalars["String"];
  api_secret: Scalars["String"];
  channel_id?: Maybe<Scalars["String"]>;
  published?: Maybe<Scalars["Boolean"]>;
};

export type SitesAudienceApp = {
  account: Scalars["String"];
  app_id: Scalars["String"];
  display_image: Scalars["String"];
  end_time: Scalars["String"];
  grid_image: Scalars["String"];
  grid_redirect: Scalars["String"];
  grid_status: Scalars["String"];
  mobile_image: Scalars["String"];
  page_url: Scalars["String"];
  parent_app_id: Scalars["String"];
  primary_image: Scalars["String"];
  prize: Scalars["String"];
  schedule_status: Scalars["String"];
  share_title: Scalars["String"];
  sort: Scalars["String"];
  start_time: Scalars["String"];
  subtitle: Scalars["String"];
  tags: Scalars["String"];
  terms: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type SitesBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type SitesBlockInput = {
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesScheduleInput>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type SitesBlockOverride = {
  id: Scalars["String"];
  type: Scalars["String"];
  value: Scalars["JSON"];
};

export type SitesBlockOverrideInput = {
  id: Scalars["String"];
  type: Scalars["String"];
  value: Scalars["JSON"];
};

export type SitesBrandingCommon = {
  name?: Maybe<Scalars["String"]>;
  logo?: Maybe<MediaAssetRef>;
  description?: Maybe<Scalars["String"]>;
};

export type SitesBroadcastFacilitiesIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
  plugin: SitesBroadcastFacilitiesIntegrationData;
};

export type SitesBroadcastFacilitiesIntegrationData = {
  broadcastFacilities: Array<SitesBroadcastFacility>;
};

export type SitesBroadcastFacility = {
  facilityId?: Maybe<Scalars["String"]>;
  broadcastFrequency?: Maybe<Scalars["String"]>;
  broadcastBand?: Maybe<Scalars["String"]>;
  broadcastCallLetters?: Maybe<Scalars["String"]>;
  applicationNotices: Array<SitesBroadcastFacilityApplicationNotice>;
  politicalLinkOverride?: Maybe<Scalars["String"]>;
};

export type SitesBroadcastFacilityApplicationNotice = {
  text?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
};

export type SitesCatalogItem = {
  name?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  kind: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
};

export type SitesCatalogRef = {
  id: Scalars["ID"];
  kind: Scalars["String"];
  country: Scalars["String"];
  record?: Maybe<CatalogRecord>;
};

export type SitesCatalogsBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  catalogType: Scalars["String"];
  country: Scalars["String"];
  catalogRefs: Array<SitesCatalogRef>;
};

export type SitesCommonConfigRecord = {
  count: Scalars["Int"];
  config: SitesConfigInternalRecord;
};

export type SitesCommonConfigsInput = {
  tags?: Maybe<Array<Scalars["String"]>>;
  orTags?: Maybe<Scalars["Boolean"]>;
  template: Scalars["String"];
};

export type SitesCommonsConfig = {
  branding?: Maybe<SitesBrandingCommon>;
  location?: Maybe<SitesLocationCommon>;
  content?: Maybe<SitesContentCommon>;
  design?: Maybe<SitesDesignCommon>;
  contact?: Maybe<SitesContactCommon>;
  social?: Maybe<SitesSocialCommon>;
  partners?: Maybe<SitesPartnersCommon>;
};

export type SitesConfigCreateInput = {
  indexId: Scalars["ID"];
  name: Scalars["String"];
  template: Scalars["String"];
  parentLink?: Maybe<Scalars["String"]>;
  memberships?: Maybe<Array<Scalars["String"]>>;
};

export type SitesConfigFilterInput = {
  indexIds?: Maybe<Array<Scalars["String"]>>;
  memberships?: Maybe<Array<Scalars["String"]>>;
};

export type SitesConfigInternalRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  name: Scalars["String"];
  indexId: Scalars["String"];
  parentLink: Scalars["String"];
  template: Scalars["String"];
  redirects: Array<SitesRedirect>;
  searchOverrides: Array<SitesSearchOverride>;
  queries: Array<SitesQueryDefinition>;
  feeds: Array<SitesFeed>;
  userConfig: Scalars["JSON"];
  isDeleted: Scalars["Boolean"];
  stack: Array<SitesConfigInternalRecord>;
  merged?: Maybe<SitesConfigRecord>;
  datasources: Array<SitesDatasourceRecord>;
};

export type SitesConfigInternalResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesConfigInternalRecord>;
};

export type SitesConfigMutation = {
  rename: SitesConfigInternalRecord;
  update: SitesConfigInternalRecord;
  delete?: Maybe<Scalars["Boolean"]>;
  materialize?: Maybe<Scalars["Boolean"]>;
  materializeDatasource?: Maybe<Scalars["Boolean"]>;
  backfillDatasource?: Maybe<Scalars["Boolean"]>;
  softDelete: SitesConfigInternalRecord;
  fork: SitesConfigInternalRecord;
  copy: SitesConfigInternalRecord;
  redirectAdd: SitesConfigInternalRecord;
  redirectSet: SitesConfigInternalRecord;
  redirectRem: SitesConfigInternalRecord;
  searchOverrideAdd: SitesConfigInternalRecord;
  searchOverrideSet: SitesConfigInternalRecord;
  searchOverrideRem: SitesConfigInternalRecord;
  feedAdd: SitesConfigInternalRecord;
  feedSet: SitesConfigInternalRecord;
  feedRem: SitesConfigInternalRecord;
  queryAdd: SitesConfigInternalRecord;
  querySet: SitesConfigInternalRecord;
  queryRem: SitesConfigInternalRecord;
  pagePromote: SitesPageInternalRecord;
  setParentLink: SitesConfigInternalRecord;
  setLookups: SitesConfigInternalRecord;
};

export type SitesConfigMutationRenameArgs = {
  newName: Scalars["String"];
};

export type SitesConfigMutationUpdateArgs = {
  payload: Scalars["JSON"];
};

export type SitesConfigMutationMaterializeDatasourceArgs = {
  queryId: Scalars["ID"];
};

export type SitesConfigMutationBackfillDatasourceArgs = {
  queryId: Scalars["ID"];
  settings: SitesDatasourceBackfillSettings;
};

export type SitesConfigMutationForkArgs = {
  newName: Scalars["String"];
};

export type SitesConfigMutationCopyArgs = {
  newName: Scalars["String"];
};

export type SitesConfigMutationRedirectAddArgs = {
  redirect: SitesRedirectInput;
};

export type SitesConfigMutationRedirectSetArgs = {
  redirectId: Scalars["ID"];
  redirect: SitesRedirectInput;
};

export type SitesConfigMutationRedirectRemArgs = {
  redirectId: Scalars["ID"];
};

export type SitesConfigMutationSearchOverrideAddArgs = {
  searchOverride: SitesSearchOverrideInput;
};

export type SitesConfigMutationSearchOverrideSetArgs = {
  searchOverrideId: Scalars["ID"];
  searchOverride: SitesSearchOverrideInput;
};

export type SitesConfigMutationSearchOverrideRemArgs = {
  searchOverrideId: Scalars["ID"];
};

export type SitesConfigMutationFeedAddArgs = {
  feed: SitesFeedInput;
};

export type SitesConfigMutationFeedSetArgs = {
  feedId: Scalars["ID"];
  feed: SitesFeedInput;
};

export type SitesConfigMutationFeedRemArgs = {
  feedId: Scalars["ID"];
};

export type SitesConfigMutationQueryAddArgs = {
  query: SitesQueryDefinitionInput;
};

export type SitesConfigMutationQuerySetArgs = {
  queryId: Scalars["ID"];
  query: SitesQueryDefinitionInput;
};

export type SitesConfigMutationQueryRemArgs = {
  queryId: Scalars["ID"];
};

export type SitesConfigMutationPagePromoteArgs = {
  pageName: Scalars["String"];
};

export type SitesConfigMutationSetParentLinkArgs = {
  parentLink: Scalars["String"];
};

export type SitesConfigMutationSetLookupsArgs = {
  lookups: Array<Scalars["String"]>;
};

export type SitesConfigMutations = {
  create: SitesConfigInternalRecord;
  byId: SitesConfigMutation;
};

export type SitesConfigMutationsCreateArgs = {
  payload: SitesConfigCreateInput;
};

export type SitesConfigMutationsByIdArgs = {
  configId: Scalars["ID"];
};

export type SitesConfigOverrideInput = {
  parentId: Scalars["String"];
  name: Scalars["String"];
  facets: Array<Scalars["String"]>;
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  disabled: Scalars["Boolean"];
  reason: Scalars["String"];
};

export type SitesConfigOverrideMutation = {
  update: SitesConfigOverrideRecord;
  copy: SitesConfigOverrideRecord;
  setConfig: SitesConfigOverrideRecord;
  redirectAdd: SitesConfigOverrideRecord;
  redirectSet: SitesConfigOverrideRecord;
  redirectRem: SitesConfigOverrideRecord;
  searchOverrideAdd: SitesConfigOverrideRecord;
  searchOverrideSet: SitesConfigOverrideRecord;
  searchOverrideRem: SitesConfigOverrideRecord;
  delete?: Maybe<Scalars["Boolean"]>;
};

export type SitesConfigOverrideMutationUpdateArgs = {
  payload: SitesConfigOverrideInput;
};

export type SitesConfigOverrideMutationCopyArgs = {
  newName: Scalars["String"];
};

export type SitesConfigOverrideMutationSetConfigArgs = {
  payload: Scalars["JSON"];
};

export type SitesConfigOverrideMutationRedirectAddArgs = {
  redirect: SitesRedirectInput;
};

export type SitesConfigOverrideMutationRedirectSetArgs = {
  redirectId: Scalars["ID"];
  redirect: SitesRedirectInput;
};

export type SitesConfigOverrideMutationRedirectRemArgs = {
  redirectId: Scalars["ID"];
};

export type SitesConfigOverrideMutationSearchOverrideAddArgs = {
  searchOverride: SitesSearchOverrideInput;
};

export type SitesConfigOverrideMutationSearchOverrideSetArgs = {
  searchOverrideId: Scalars["ID"];
  searchOverride: SitesSearchOverrideInput;
};

export type SitesConfigOverrideMutationSearchOverrideRemArgs = {
  searchOverrideId: Scalars["ID"];
};

export type SitesConfigOverrideMutations = {
  create: SitesConfigOverrideRecord;
  find: SitesConfigOverrideMutation;
};

export type SitesConfigOverrideMutationsCreateArgs = {
  payload: SitesConfigOverrideInput;
};

export type SitesConfigOverrideMutationsFindArgs = {
  id: Scalars["ID"];
};

export type SitesConfigOverrideRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  parentId: Scalars["String"];
  name: Scalars["String"];
  facets: Array<Scalars["String"]>;
  userConfig: Scalars["JSON"];
  redirects: Array<SitesRedirect>;
  searchOverrides: Array<SitesSearchOverride>;
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  disabled: Scalars["Boolean"];
  reason: Scalars["String"];
  parent?: Maybe<SitesConfigInternalRecord>;
};

export type SitesConfigOverrideResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesConfigOverrideRecord>;
};

export type SitesConfigOverrides = {
  filter: SitesConfigOverrideResults;
  find?: Maybe<SitesConfigOverrideRecord>;
  findMany: Array<SitesConfigOverrideRecord>;
};

export type SitesConfigOverridesFilterArgs = {
  filter: SitesOverrideFilterInput;
  paging?: Maybe<SitesPageInput>;
};

export type SitesConfigOverridesFindArgs = {
  id: Scalars["ID"];
};

export type SitesConfigOverridesFindManyArgs = {
  ids: Array<Scalars["ID"]>;
};

export type SitesConfigRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  name: Scalars["String"];
  indexId: Scalars["String"];
  /** This is a bucket:id e.g. ref://sites.indexes/203010002 */
  parentLink: Scalars["String"];
  template: Scalars["String"];
  /** @deprecated Fetching all redirects is inefficient for both client and server. Use redirect(path) instead. */
  redirects: Array<SitesRedirect>;
  /**
   * Given a path with optional query string, determine if a redirect should happen, with optional parent site
   * for embedded site hostname selection.
   */
  redirect?: Maybe<SitesRedirectResponse>;
  searchOverrides: Array<SitesSearchOverride>;
  queries: Array<SitesQueryDefinition>;
  feeds: Array<SitesFeed>;
  userConfig: Scalars["JSON"];
  configChunk?: Maybe<Scalars["JSON"]>;
  adPositions: Array<SitesAdPositionRecord>;
  feed?: Maybe<SitesFeedResponse>;
  routes: Array<SitesPageRoute>;
  page?: Maybe<SitesPageRecord>;
  pages: Array<SitesPageRecord>;
  isDeleted: Scalars["Boolean"];
  /** Current status of materialization. */
  taskStatus?: Maybe<SitesTaskStatus>;
  common?: Maybe<SitesCommonsConfig>;
  sections?: Maybe<SitesConfigTemplateUnion>;
};

export type SitesConfigRecordRedirectArgs = {
  path: Scalars["String"];
  parent?: Maybe<SitesFindManyLookup>;
};

export type SitesConfigRecordUserConfigArgs = {
  glob?: Maybe<Scalars["String"]>;
};

export type SitesConfigRecordConfigChunkArgs = {
  chunkId: Scalars["String"];
};

export type SitesConfigRecordFeedArgs = {
  params: SitesFeedResumeInput;
};

export type SitesConfigRecordPageArgs = {
  name: Scalars["String"];
};

export type SitesConfigTemplateUnion =
  | SitesTemplateCoastConfig
  | SitesTemplateInfernoMicrosite
  | SitesTemplateInfernoSite
  | SitesTemplateOnairEnrichment
  | SitesTemplatePremiereSite;

export type SitesConfigs = {
  filter: SitesConfigInternalResults;
  byId?: Maybe<SitesConfigInternalRecord>;
  byIds: Array<SitesConfigInternalRecord>;
  byName?: Maybe<SitesConfigInternalRecord>;
};

export type SitesConfigsFilterArgs = {
  filter: SitesConfigFilterInput;
  paging?: Maybe<SitesPageInput>;
};

export type SitesConfigsByIdArgs = {
  configId: Scalars["ID"];
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesConfigsByIdsArgs = {
  configIds: Array<Scalars["ID"]>;
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesConfigsByNameArgs = {
  indexId: Scalars["ID"];
  name: Scalars["String"];
};

export type SitesContactCommon = {
  requestPhone?: Maybe<Scalars["String"]>;
  sms?: Maybe<Scalars["String"]>;
  businessPhone?: Maybe<Scalars["String"]>;
  techEmail?: Maybe<Scalars["String"]>;
};

export type SitesContactSection = {
  name: Scalars["Boolean"];
  businessPhoneNumber?: Maybe<Scalars["String"]>;
  locationAddress1?: Maybe<Scalars["String"]>;
  locationAddress2?: Maybe<Scalars["String"]>;
  locationCity?: Maybe<Scalars["String"]>;
  locationState?: Maybe<Scalars["String"]>;
  locationZip?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  requestPhoneNumber?: Maybe<Scalars["String"]>;
  smsNumber?: Maybe<Scalars["String"]>;
  techContact?: Maybe<Scalars["String"]>;
  txtNumber?: Maybe<Scalars["String"]>;
};

export type SitesContentAssetField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentAssetValue>;
};

export type SitesContentAssetValue = {
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  alt_text?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  attributes?: Maybe<SitesContentAssetValueAttributes>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  public_uri?: Maybe<Scalars["String"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type SitesContentAssetValueAttributes = {
  mimetype?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SitesContentAuthorField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentAuthorLink>>>;
};

export type SitesContentAuthorLink = {
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SitesContentAuthorPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentAuthorPayloadFields>;
};

export type SitesContentAuthorPayloadFields = {
  name?: Maybe<SitesContentTitleField>;
  bio?: Maybe<SitesContentHtmlField>;
  photo?: Maybe<SitesContentAssetField>;
  link?: Maybe<SitesContentPlainField>;
  author_facebook?: Maybe<SitesContentPlainField>;
  author_twitter?: Maybe<SitesContentPlainField>;
  author_instagram?: Maybe<SitesContentPlainField>;
  author_tiktok?: Maybe<SitesContentPlainField>;
  youtube?: Maybe<SitesContentPlainField>;
  slug?: Maybe<SitesContentSlugField>;
};

export type SitesContentAuthorPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:author. */
  data?: Maybe<SitesContentAuthorPayload>;
};

export type SitesContentBlockSchedule = {
  enabled?: Maybe<Scalars["Boolean"]>;
  begin?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["Float"]>;
};

export enum SitesContentBlockType {
  Title = "title",
  Plain = "plain",
  Slug = "slug",
  Author = "author",
  Text = "text",
  Tag = "tag",
  Asset = "asset",
  Date = "date",
  Checkbox = "checkbox",
  Link = "link",
  Flex = "flex",
  Catalog = "catalog",
  Contest = "contest",
  Content = "content",
  Gallery = "gallery",
  Html = "html",
  Embed = "embed",
  Pnpodcast = "pnpodcast",
  Bumpermusic = "bumpermusic",
  Multidate = "multidate",
}

export type SitesContentBumperMusicField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentBumperMusicValue>>>;
};

export type SitesContentBumperMusicValue = {
  artist?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SitesContentCalendarPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCalendarPayloadFields>;
};

export type SitesContentCalendarPayloadFields = {
  seo_title?: Maybe<SitesContentTitleField>;
  social_title?: Maybe<SitesContentTitleField>;
  title?: Maybe<SitesContentTitleField>;
  primary_image?: Maybe<SitesContentAssetField>;
  event_date?: Maybe<SitesContentMultiDateField>;
  hide_event_start_time?: Maybe<SitesContentCheckboxField>;
  venue_name?: Maybe<SitesContentPlainField>;
  venue_address?: Maybe<SitesContentPlainField>;
  slug?: Maybe<SitesContentSlugField>;
  summary?: Maybe<SitesContentPlainField>;
  keywords?: Maybe<SitesContentTagField>;
  blocks?: Maybe<SitesContentFlexField>;
};

export type SitesContentCalendarPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:calendar. */
  data?: Maybe<SitesContentCalendarPayload>;
};

export type SitesContentCardTestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCardTestPayloadFields>;
};

export type SitesContentCardTestPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  image?: Maybe<SitesContentAssetField>;
  decription?: Maybe<SitesContentPlainField>;
  link?: Maybe<SitesContentPlainField>;
  advanced_field?: Maybe<SitesContentPlainField>;
};

export type SitesContentCardTestPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:card-test. */
  data?: Maybe<SitesContentCardTestPayload>;
};

export type SitesContentCarplayIosHomeSurfacingPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCarplayIosHomeSurfacingPayloadFields>;
};

export type SitesContentCarplayIosHomeSurfacingPayloadFields = {
  test_input?: Maybe<SitesContentPlainField>;
  Made_for_you?: Maybe<SitesContentTitleField>;
  tag_associated_content?: Maybe<SitesContentTagField>;
  surface_item_1?: Maybe<SitesContentCatalogField>;
  surfaced_item_2?: Maybe<SitesContentCatalogField>;
  surfaced_item_3?: Maybe<SitesContentCatalogField>;
};

export type SitesContentCarplayIosHomeSurfacingPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:carplay-ios-home-surfacing. */
  data?: Maybe<SitesContentCarplayIosHomeSurfacingPayload>;
};

export type SitesContentCastawayDestinationPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCastawayDestinationPayloadFields>;
};

export type SitesContentCastawayDestinationPayloadFields = {
  City_or_Theme_Name?: Maybe<SitesContentTitleField>;
  Author?: Maybe<SitesContentAuthorField>;
  List_Header?: Maybe<SitesContentPlainField>;
  List_Overview?: Maybe<SitesContentPlainField>;
  Order_Number?: Maybe<SitesContentPlainField>;
  Taxonomy?: Maybe<SitesContentTagField>;
  Latitude?: Maybe<SitesContentPlainField>;
  Longitude?: Maybe<SitesContentPlainField>;
  background_img_url?: Maybe<SitesContentPlainField>;
  Asset?: Maybe<SitesContentAssetField>;
};

export type SitesContentCastawayDestinationPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:castaway-destination. */
  data?: Maybe<SitesContentCastawayDestinationPayload>;
};

export type SitesContentCastawayPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCastawayPayloadFields>;
};

export type SitesContentCastawayPayloadFields = {
  TitleOfContent?: Maybe<SitesContentTitleField>;
  Theme_or_Destination?: Maybe<SitesContentPlainField>;
  PodcastName?: Maybe<SitesContentPlainField>;
  ShortDescription?: Maybe<SitesContentPlainField>;
  PodcastShowId?: Maybe<SitesContentPlainField>;
  EpisodeTitle?: Maybe<SitesContentPlainField>;
  PodcastEpisodeId?: Maybe<SitesContentPlainField>;
  TaxonomyField?: Maybe<SitesContentTagField>;
};

export type SitesContentCastawayPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:castaway. */
  data?: Maybe<SitesContentCastawayPayload>;
};

export type SitesContentCatalogField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentCatalogValue>;
};

export type SitesContentCatalogListsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCatalogListsPayloadFields>;
};

export type SitesContentCatalogListsPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  catalogItems?: Maybe<SitesContentFlexField>;
};

export type SitesContentCatalogListsPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:catalog-lists. */
  data?: Maybe<SitesContentCatalogListsPayload>;
};

export type SitesContentCatalogValue = {
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  relatedId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  device_link?: Maybe<Scalars["String"]>;
  web_link?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
};

export type SitesContentCheckboxField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["Boolean"]>;
};

export type SitesContentCoastToCoastArticlePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCoastToCoastArticlePayloadFields>;
};

export type SitesContentCoastToCoastArticlePayloadFields = {
  seo_title?: Maybe<SitesContentTitleField>;
  social_title?: Maybe<SitesContentTitleField>;
  title?: Maybe<SitesContentTitleField>;
  primary_image?: Maybe<SitesContentAssetField>;
  primary_video?: Maybe<SitesContentEmbedField>;
  author?: Maybe<SitesContentAuthorField>;
  slug?: Maybe<SitesContentSlugField>;
  summary?: Maybe<SitesContentHtmlField>;
  keywords?: Maybe<SitesContentTagField>;
  blocks?: Maybe<SitesContentFlexField>;
  include_recommendations?: Maybe<SitesContentCheckboxField>;
  fb_allow_comments?: Maybe<SitesContentCheckboxField>;
  is_sponsored?: Maybe<SitesContentCheckboxField>;
  simplereach_enabled?: Maybe<SitesContentCheckboxField>;
  Updated?: Maybe<SitesContentCheckboxField>;
  source?: Maybe<SitesContentPlainField>;
  source_url?: Maybe<SitesContentPlainField>;
};

export type SitesContentCoastToCoastArticlePayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-article. */
  data?: Maybe<SitesContentCoastToCoastArticlePayload>;
};

export type SitesContentCoastToCoastGuestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCoastToCoastGuestPayloadFields>;
};

export type SitesContentCoastToCoastGuestPayloadFields = {
  seo_title?: Maybe<SitesContentTitleField>;
  social_title?: Maybe<SitesContentTitleField>;
  title?: Maybe<SitesContentTitleField>;
  primary_image?: Maybe<SitesContentAssetField>;
  author?: Maybe<SitesContentAuthorField>;
  slug?: Maybe<SitesContentSlugField>;
  summary?: Maybe<SitesContentHtmlField>;
  blocks?: Maybe<SitesContentFlexField>;
  include_recommendations?: Maybe<SitesContentCheckboxField>;
  fb_allow_comments?: Maybe<SitesContentCheckboxField>;
  is_sponsored?: Maybe<SitesContentCheckboxField>;
  simplereach_enabled?: Maybe<SitesContentCheckboxField>;
  Updated?: Maybe<SitesContentCheckboxField>;
  websites?: Maybe<SitesContentHtmlField>;
  books?: Maybe<SitesContentHtmlField>;
  videos?: Maybe<SitesContentHtmlField>;
  cds?: Maybe<SitesContentHtmlField>;
};

export type SitesContentCoastToCoastGuestPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-guest. */
  data?: Maybe<SitesContentCoastToCoastGuestPayload>;
};

export type SitesContentCoastToCoastShowPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentCoastToCoastShowPayloadFields>;
};

export type SitesContentCoastToCoastShowPayloadFields = {
  seo_title?: Maybe<SitesContentTitleField>;
  social_title?: Maybe<SitesContentTitleField>;
  title?: Maybe<SitesContentTitleField>;
  primary_image?: Maybe<SitesContentAssetField>;
  author?: Maybe<SitesContentAuthorField>;
  summary?: Maybe<SitesContentHtmlField>;
  blocks?: Maybe<SitesContentFlexField>;
  show_date?: Maybe<SitesContentDateField>;
  guest?: Maybe<SitesContentLinkField>;
  host?: Maybe<SitesContentLinkField>;
  host_name?: Maybe<SitesContentPlainField>;
  slug?: Maybe<SitesContentSlugField>;
  keywords?: Maybe<SitesContentTagField>;
  display_audiobox?: Maybe<SitesContentCheckboxField>;
  include_recommendations?: Maybe<SitesContentCheckboxField>;
  fb_allow_comments?: Maybe<SitesContentCheckboxField>;
  is_sponsored?: Maybe<SitesContentCheckboxField>;
  simplereach_enabled?: Maybe<SitesContentCheckboxField>;
  Updated?: Maybe<SitesContentCheckboxField>;
  highlights?: Maybe<SitesContentPlainField>;
  zype_highlights?: Maybe<SitesContentPlainField>;
  bumper_music?: Maybe<SitesContentBumperMusicField>;
  books?: Maybe<SitesContentHtmlField>;
  articles?: Maybe<SitesContentHtmlField>;
  videos?: Maybe<SitesContentHtmlField>;
  websites?: Maybe<SitesContentHtmlField>;
  episodes?: Maybe<SitesContentPlainField>;
  zype_episodes?: Maybe<SitesContentPlainField>;
  pn_podcast?: Maybe<SitesContentPnPodcastField>;
};

export type SitesContentCoastToCoastShowPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:coast-to-coast-show. */
  data?: Maybe<SitesContentCoastToCoastShowPayload>;
};

export type SitesContentCommon = {
  topics?: Maybe<Array<TagRef>>;
  distribution?: Maybe<Array<TagRef>>;
  podcasts?: Maybe<Array<PodcastRef>>;
  playlists?: Maybe<Array<PlaylistRef>>;
  shows?: Maybe<Array<SiteRef>>;
};

export type SitesContentContestValue = {
  contest_id?: Maybe<Scalars["Int"]>;
  call_letters?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SitesContentDateField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["Float"]>;
};

export type SitesContentEmbedField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentEmbedValue>;
};

export type SitesContentEmbedValue = {
  url?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  embed_type?: Maybe<Scalars["String"]>;
  rendering_hint?: Maybe<Scalars["String"]>;
};

export type SitesContentExclusion = {
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentFlexField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentScheduledField>>>;
};

export type SitesContentHtmlField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentHolidayHatPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentHolidayHatPayloadFields>;
};

export type SitesContentHolidayHatPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  web_color?: Maybe<SitesContentAssetField>;
  web_inverted?: Maybe<SitesContentAssetField>;
  ios_light?: Maybe<SitesContentAssetField>;
  ios_dark?: Maybe<SitesContentAssetField>;
  android_light?: Maybe<SitesContentAssetField>;
  android_dark?: Maybe<SitesContentAssetField>;
};

export type SitesContentHolidayHatPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:holiday-hat. */
  data?: Maybe<SitesContentHolidayHatPayload>;
};

export type SitesContentIngestion = {
  external_url?: Maybe<Scalars["String"]>;
  feed_content_id?: Maybe<Scalars["String"]>;
  feed_permalink?: Maybe<Scalars["String"]>;
  feed_vendor?: Maybe<Scalars["String"]>;
  feed_type?: Maybe<Scalars["String"]>;
  feed_partner_content?: Maybe<Scalars["Boolean"]>;
};

export type SitesContentLegacyAppleNewsRef = {
  ref_id?: Maybe<Scalars["String"]>;
  revision_id?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyAssetValue = {
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  alt_text?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export enum SitesContentLegacyBlockType {
  Asset = "asset",
  Html = "html",
  Embed = "embed",
  Catalog = "catalog",
  Contest = "contest",
  Iheartcontent = "iheartcontent",
  Gallery = "gallery",
}

export type SitesContentLegacyCatalogRef = {
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  related_id?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyEmbeddedContentSummary = {
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyPublishTarget = {
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentLegacyResourceRef = {
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyScheduledAssetField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  url?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  use_caption?: Maybe<Scalars["Boolean"]>;
  source_credit?: Maybe<Scalars["String"]>;
  attributes?: Maybe<SitesContentAssetValueAttributes>;
  new_tab?: Maybe<Scalars["Boolean"]>;
  bucket?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyScheduledCatalogField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  kind?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  relatedId?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyScheduledContentField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  id?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  pub_changed?: Maybe<Scalars["Float"]>;
  summary?: Maybe<SitesContentLegacyEmbeddedContentSummary>;
};

export type SitesContentLegacyScheduledContestField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  contest_id?: Maybe<Scalars["Int"]>;
  call_letters?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyScheduledEmbedField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  url?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  embed_type?: Maybe<Scalars["String"]>;
  rendering_hint?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyScheduledField =
  | SitesContentLegacyScheduledAssetField
  | SitesContentLegacyScheduledCatalogField
  | SitesContentLegacyScheduledContentField
  | SitesContentLegacyScheduledContestField
  | SitesContentLegacyScheduledEmbedField
  | SitesContentLegacyScheduledGalleryField
  | SitesContentLegacyScheduledHtmlField;

export type SitesContentLegacyScheduledGalleryField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  assets?: Maybe<Array<Maybe<SitesContentLegacyAssetValue>>>;
};

export type SitesContentLegacyScheduledHtmlField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentLegacyBlockType>;
  html?: Maybe<Scalars["String"]>;
};

export type SitesContentLegacyTopicSet = {
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  distribution?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentLinkField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentMultiAuthorTestPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentMultiAuthorTestPayloadFields>;
};

export type SitesContentMultiAuthorTestPayloadFields = {
  Title?: Maybe<SitesContentTitleField>;
  Author1?: Maybe<SitesContentAuthorField>;
  Author2?: Maybe<SitesContentAuthorField>;
  Author3?: Maybe<SitesContentAuthorField>;
};

export type SitesContentMultiAuthorTestPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:multi-author-test. */
  data?: Maybe<SitesContentMultiAuthorTestPayload>;
};

export type SitesContentMultiDateField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentBlockSchedule>>>;
};

export type SitesContentPnPodcastEpisode = {
  show?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  episodeId?: Maybe<Scalars["String"]>;
};

export type SitesContentPnPodcastField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentPnPodcastEpisode>>>;
};

export type SitesContentPayload = {
  title?: Maybe<Scalars["String"]>;
  seo_title?: Maybe<Scalars["String"]>;
  social_title?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  feed_content_id?: Maybe<Scalars["String"]>;
  feed_permalink?: Maybe<Scalars["String"]>;
  feed_vendor?: Maybe<Scalars["String"]>;
  external_url?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  include_recommendations?: Maybe<Scalars["Boolean"]>;
  fb_allow_comments?: Maybe<Scalars["Boolean"]>;
  is_sponsored?: Maybe<Scalars["Boolean"]>;
  amp_enabled?: Maybe<Scalars["Boolean"]>;
  publish_date?: Maybe<Scalars["Float"]>;
  publish_end_date?: Maybe<Scalars["Float"]>;
  publish_origin?: Maybe<Scalars["String"]>;
  primary_target?: Maybe<SitesContentLegacyTopicSet>;
  targets?: Maybe<Array<Maybe<SitesContentLegacyTopicSet>>>;
  exclusion?: Maybe<SitesContentLegacyPublishTarget>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_image?: Maybe<SitesContentLegacyResourceRef>;
  primary_image_uri?: Maybe<Scalars["String"]>;
  blocks?: Maybe<Array<Maybe<SitesContentLegacyScheduledField>>>;
  apple_news?: Maybe<SitesContentLegacyAppleNewsRef>;
  simplereach_enabled?: Maybe<Scalars["Boolean"]>;
  show_updated_timestamp?: Maybe<Scalars["Boolean"]>;
  cuser?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  enable_featured_widget?: Maybe<Scalars["Boolean"]>;
  featured_widget?: Maybe<SitesContentLegacyCatalogRef>;
};

export type SitesContentPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content. */
  data?: Maybe<SitesContentPayload>;
};

export type SitesContentPersonalityPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentPersonalityPayloadFields>;
};

export type SitesContentPersonalityPayloadFields = {
  name?: Maybe<SitesContentTitleField>;
  bio?: Maybe<SitesContentHtmlField>;
};

export type SitesContentPersonalityPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:personality. */
  data?: Maybe<SitesContentPersonalityPayload>;
};

export type SitesContentPlainField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentPodcastCollectionsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentPodcastCollectionsPayloadFields>;
};

export type SitesContentPodcastCollectionsPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  description?: Maybe<SitesContentPlainField>;
  image?: Maybe<SitesContentAssetField>;
  slug?: Maybe<SitesContentSlugField>;
  episodes?: Maybe<SitesContentFlexField>;
};

export type SitesContentPodcastCollectionsPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:podcast-collections. */
  data?: Maybe<SitesContentPodcastCollectionsPayload>;
};

export type SitesContentPodguidesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentPodguidesPayloadFields>;
};

export type SitesContentPodguidesPayloadFields = {
  name?: Maybe<SitesContentTitleField>;
  slug?: Maybe<SitesContentSlugField>;
  author?: Maybe<SitesContentAuthorField>;
  description?: Maybe<SitesContentHtmlField>;
  color?: Maybe<SitesContentPlainField>;
  position?: Maybe<SitesContentPlainField>;
  city?: Maybe<SitesContentTagField>;
  background_image?: Maybe<SitesContentAssetField>;
  test1?: Maybe<SitesContentCatalogField>;
  thumbnail_image?: Maybe<SitesContentAssetField>;
  podcast_episodes?: Maybe<SitesContentFlexField>;
};

export type SitesContentPodguidesPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:podguides. */
  data?: Maybe<SitesContentPodguidesPayload>;
};

export type SitesContentScheduledAssetField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentAssetValue>;
};

export type SitesContentScheduledCatalogField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentCatalogValue>;
};

export type SitesContentScheduledContentField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentValue>;
};

export type SitesContentScheduledContestField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentContestValue>;
};

export type SitesContentScheduledEmbedField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<SitesContentEmbedValue>;
};

export type SitesContentScheduledField =
  | SitesContentScheduledAssetField
  | SitesContentScheduledCatalogField
  | SitesContentScheduledContentField
  | SitesContentScheduledContestField
  | SitesContentScheduledEmbedField
  | SitesContentScheduledGalleryField
  | SitesContentScheduledHtmlField
  | SitesContentScheduledPlainField;

export type SitesContentScheduledGalleryField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<SitesContentAssetValue>>>;
};

export type SitesContentScheduledHtmlField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentScheduledPlainField = {
  schedule?: Maybe<SitesContentBlockSchedule>;
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentSlugField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentSoundscapesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentSoundscapesPayloadFields>;
};

export type SitesContentSoundscapesPayloadFields = {
  name?: Maybe<SitesContentTitleField>;
  slug?: Maybe<SitesContentSlugField>;
  background_images?: Maybe<SitesContentFlexField>;
};

export type SitesContentSoundscapesPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:soundscapes. */
  data?: Maybe<SitesContentSoundscapesPayload>;
};

export type SitesContentSummary = {
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<SitesContentAssetValue>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_slug?: Maybe<Scalars["String"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
};

export type SitesContentSyndication = {
  publish_origin?: Maybe<Scalars["String"]>;
  refs?: Maybe<SitesContentSyndicationRefs>;
};

export type SitesContentSyndicationRef = {
  id?: Maybe<Scalars["String"]>;
  statusId?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentSyndicationRefs = {
  apple_news?: Maybe<SitesContentSyndicationRef>;
  google_news_sitemap?: Maybe<SitesContentSyndicationRef>;
  partner_feeds?: Maybe<SitesContentSyndicationRef>;
};

export type SitesContentTagField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SitesContentTalkbackResponsesPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentTalkbackResponsesPayloadFields>;
};

export type SitesContentTalkbackResponsesPayloadFields = {
  Headline?: Maybe<SitesContentTitleField>;
  Description?: Maybe<SitesContentPlainField>;
};

export type SitesContentTalkbackResponsesPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:talkback-responses. */
  data?: Maybe<SitesContentTalkbackResponsesPayload>;
};

export type SitesContentTargeting = {
  exclusion?: Maybe<SitesContentExclusion>;
  publish_date?: Maybe<Scalars["Float"]>;
  publish_end_date?: Maybe<Scalars["Float"]>;
  publish_origin?: Maybe<Scalars["String"]>;
  syndication?: Maybe<SitesContentSyndication>;
  targets?: Maybe<Array<Maybe<SitesContentTopicSet>>>;
};

export type SitesContentTitleField = {
  type?: Maybe<SitesContentBlockType>;
  value?: Maybe<Scalars["String"]>;
};

export type SitesContentToolNotificationsPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentToolNotificationsPayloadFields>;
};

export type SitesContentToolNotificationsPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  body?: Maybe<SitesContentHtmlField>;
  link?: Maybe<SitesContentPlainField>;
};

export type SitesContentToolNotificationsPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:tool-notifications. */
  data?: Maybe<SitesContentToolNotificationsPayload>;
};

export type SitesContentTopicSet = {
  id?: Maybe<Scalars["String"]>;
  what?: Maybe<Array<Maybe<Scalars["String"]>>>;
  where?: Maybe<Array<Maybe<Scalars["String"]>>>;
  operator?: Maybe<Scalars["String"]>;
};

export type SitesContentType = {
  type: Scalars["String"];
  source: Scalars["String"];
  name: Scalars["String"];
  search: SitesContentTypeSearchSettings;
  cache: SitesContentTypeCacheSettings;
  payload: SitesContentTypePayloadSettings;
};

export type SitesContentTypeCacheSettings = {
  surrogate: SitesSurrogateCacheSettings;
};

export type SitesContentTypeCacheSettingsInput = {
  surrogate?: Maybe<SitesSurrogateCacheSettingsInput>;
};

export type SitesContentTypeInput = {
  name?: Maybe<Scalars["String"]>;
  search?: Maybe<SitesContentTypeSearchSettingsInput>;
  cache?: Maybe<SitesContentTypeCacheSettingsInput>;
  payload?: Maybe<SitesContentTypePayloadSettingsInput>;
};

export type SitesContentTypePayloadSettings = {
  schema: Scalars["String"];
};

export type SitesContentTypePayloadSettingsInput = {
  /** GraphQL schema for payloads under this content type. Typedefs in the schema must be compatible with existing types */
  schema: Scalars["String"];
};

export type SitesContentTypeSearchSettings = {
  enabled: Scalars["Boolean"];
};

export type SitesContentTypeSearchSettingsInput = {
  enabled?: Maybe<Scalars["Boolean"]>;
};

export type SitesContentValue = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  canonical_url?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_slug?: Maybe<Scalars["String"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
};

export type SitesContentWazeFeaturedPayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentWazeFeaturedPayloadFields>;
};

export type SitesContentWazeFeaturedPayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  playable_1?: Maybe<SitesContentCatalogField>;
  playable_2?: Maybe<SitesContentCatalogField>;
  playable_3?: Maybe<SitesContentCatalogField>;
  playable_4?: Maybe<SitesContentCatalogField>;
  playable_5?: Maybe<SitesContentCatalogField>;
  playable_6?: Maybe<SitesContentCatalogField>;
};

export type SitesContentWazeFeaturedPayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-featured. */
  data?: Maybe<SitesContentWazeFeaturedPayload>;
};

export type SitesContentWazeItemizedTemplatePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentWazeItemizedTemplatePayloadFields>;
};

export type SitesContentWazeItemizedTemplatePayloadFields = {
  title?: Maybe<SitesContentTitleField>;
  playable_1?: Maybe<SitesContentCatalogField>;
  playable_2?: Maybe<SitesContentCatalogField>;
  playable_3?: Maybe<SitesContentCatalogField>;
  playable_4?: Maybe<SitesContentCatalogField>;
  playable_5?: Maybe<SitesContentCatalogField>;
  playable_6?: Maybe<SitesContentCatalogField>;
};

export type SitesContentWazeItemizedTemplatePayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-itemized-template. */
  data?: Maybe<SitesContentWazeItemizedTemplatePayload>;
};

export type SitesContentWazeMenuTemplatePayload = {
  canonical_url?: Maybe<Scalars["String"]>;
  cuser?: Maybe<Scalars["String"]>;
  ingestion?: Maybe<SitesContentIngestion>;
  summary?: Maybe<SitesContentSummary>;
  targeting?: Maybe<SitesContentTargeting>;
  fields?: Maybe<SitesContentWazeMenuTemplatePayloadFields>;
};

export type SitesContentWazeMenuTemplatePayloadFields = {
  waze_title?: Maybe<SitesContentTitleField>;
  multiple_items?: Maybe<SitesContentFlexField>;
  layout_tag?: Maybe<SitesContentTagField>;
};

export type SitesContentWazeMenuTemplatePayloadSelection = SitesPayloadSelection & {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Typed data for content type content:waze-menu-template. */
  data?: Maybe<SitesContentWazeMenuTemplatePayload>;
};

/** Subscriptions are the entity used to materialize and query content. */
export type SitesDsSubscription = {
  _id: Scalars["ID"];
  datasourceId: Scalars["ID"];
  /** Primary tag and identifier for a scope in the datasource. */
  scopeId: Scalars["ID"];
  /** Content type */
  type: Scalars["String"];
  /** List of combinations of tags from the datasource for content to be matched against */
  where: Array<Scalars["String"]>;
  /** @deprecated this field is no longer used and will be removed in the next major release */
  what: Array<Scalars["String"]>;
  /** List of tags to match against exclusions in content; any match is not included in the materialized query */
  subject: Array<Scalars["String"]>;
  /** Status of content materialization on this subscription */
  backfill?: Maybe<SitesDsSubscriptionBackfillStatus>;
  /** Fetch a page of content from this subscription, filtered to the given 'what' list (or default if unspecified) */
  query?: Maybe<SitesDsSubscriptionQueryResult>;
  mtime: Scalars["Float"];
  targetId: Scalars["String"];
};

/** Subscriptions are the entity used to materialize and query content. */
export type SitesDsSubscriptionQueryArgs = {
  pageToken?: Maybe<Scalars["ID"]>;
  size?: Maybe<Scalars["Int"]>;
  what?: Maybe<Array<Scalars["String"]>>;
};

export type SitesDsSubscriptionQueryResult = {
  items: Array<SitesPublishRecord>;
  page: SitesPageInfo;
};

/** A Datasource is a configuration for one or more materialized queries (subscriptions) and an entrypoint for querying */
export type SitesDatasource = {
  _id: Scalars["ID"];
  /** Datasource name (may not be unique) */
  name: Scalars["String"];
  /** Content type this datasource applies to */
  type: Scalars["String"];
  /** List of arbitrary strings that can be used to find a datasource record */
  references: Array<Scalars["String"]>;
  /** The primary tag is used to match content in a standalone fashion */
  primary: Scalars["String"];
  /** Secondary tags work the same as primary; content targeted to any one of them will match the datasource */
  secondaries?: Maybe<Array<Scalars["String"]>>;
  /** Segments work in pairings with primary/secondary tags; content targeted to a primary AND segment will match */
  segments: Array<Scalars["String"]>;
  /** Scopes are for creating narrow queries (i.e. with more tags) to give publishers ways to target only this datasource */
  scopes: Array<SitesScope>;
  /** This flag (if true) splits the scopes each into their own subscription which can be queried separately */
  subscriptionPerScope: Scalars["Boolean"];
  /** Default set of categories used to filter content queried under this datasource */
  what: Array<Scalars["String"]>;
  /** Find a subscription by id OR by segment with optional scope id */
  subscription?: Maybe<SitesDsSubscription>;
  /** List all subscriptions under this datasource. There is only one if subscriptionPerScope=false */
  subscriptions?: Maybe<Array<SitesDsSubscription>>;
};

/** A Datasource is a configuration for one or more materialized queries (subscriptions) and an entrypoint for querying */
export type SitesDatasourceSubscriptionArgs = {
  _id?: Maybe<Scalars["ID"]>;
  segment?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
};

export type SitesDatasourceBackfillSettings = {
  all: Scalars["Boolean"];
  size?: Maybe<Scalars["Int"]>;
};

export type SitesDatasourceInput = {
  /** Friendly name for the data source. */
  name: Scalars["String"];
  /** Type of content to subscribe to. */
  type: Scalars["String"];
  /** Client specific identification of how the data source is used. At least one is required. */
  references: Array<Scalars["String"]>;
  /** Primary identifier for this datasource; used in combinations with every segment and scope. */
  primary: Scalars["String"];
  /** Secondary identifiers for this datasource; treated the same as `primary`. */
  secondaries?: Maybe<Array<Scalars["String"]>>;
  /** List of identifiers for the site, to be used for exclusions and querying. */
  segments: Array<Scalars["String"]>;
  scopes: Array<SitesScopeInput>;
  subscriptionPerScope: Scalars["Boolean"];
  what: Array<Scalars["String"]>;
};

export type SitesDatasourceListFilter = {
  /** All datasources matching a name */
  name?: Maybe<Scalars["String"]>;
  /** All datasources matching a content type */
  type?: Maybe<Scalars["String"]>;
  /** Find records that include all of the listed references */
  references?: Maybe<Array<Scalars["String"]>>;
  /** Find records whose primary or secondary tags include all items in this list */
  primaries?: Maybe<Array<Scalars["String"]>>;
  /** Find records matching all listed non-primary tags */
  segments?: Maybe<Array<Scalars["String"]>>;
  /** Find records matching particular scope configurations */
  scopes?: Maybe<Array<SitesScopeFilterInput>>;
  /** Find records matching one or more 'what' tags (categories or the like) */
  what?: Maybe<Array<Scalars["String"]>>;
};

export type SitesDatasourceRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  datasourceId: Scalars["ID"];
  configId: Scalars["ID"];
  queryId: Scalars["String"];
  materialized?: Maybe<Scalars["Boolean"]>;
};

export type SitesDefaultPayloadSelection = SitesPayloadSelection & {
  /** Content Type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
  /** Raw content. */
  data?: Maybe<Scalars["JSON"]>;
};

export type SitesDesignCommon = {
  theme?: Maybe<Scalars["String"]>;
  heroColor?: Maybe<Scalars["String"]>;
  heroImage?: Maybe<MediaAssetRef>;
  accentColor?: Maybe<Scalars["String"]>;
};

export type SitesDesignSection = {
  accentColor?: Maybe<Scalars["String"]>;
  ihrHeroColor?: Maybe<Scalars["String"]>;
  ihrHeroImage?: Maybe<Scalars["String"]>;
  linkColor?: Maybe<Scalars["String"]>;
  navBgColor?: Maybe<Scalars["String"]>;
  navIhrLinkColor?: Maybe<Scalars["String"]>;
  navTextColor?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  heroFallbackColor?: Maybe<Scalars["String"]>;
  heroImage?: Maybe<Scalars["String"]>;
};

export type SitesDestinationInput = {
  type: SitesDestinationType;
  value: Scalars["String"];
};

export enum SitesDestinationType {
  Url = "URL",
  Brand = "BRAND",
}

/** Original targeting format for content */
export type SitesDistribution = {
  /** List of tags indicating categorization and/or destination for content */
  tags: Array<Scalars["String"]>;
  tagsData: Array<TagRef>;
};

export type SitesDistributionInput = {
  tags: Array<Scalars["String"]>;
  operator?: Maybe<SitesDistributionOperator>;
};

/** How to combine tags in distribution (i.e. "match all tags" vs "match any combination of tags" */
export enum SitesDistributionOperator {
  And = "AND",
}

export type SitesDocumentMetadata = {
  _id: Scalars["String"];
  annotations: Scalars["JSON"];
  base_id: Scalars["String"];
  bucket: Scalars["String"];
  ctime: Scalars["Float"];
  cuser: Scalars["String"];
  deleted: Scalars["Boolean"];
  mimetype?: Maybe<Scalars["String"]>;
  mtime: Scalars["Float"];
  muser: Scalars["String"];
  parent_id?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  ref_map?: Maybe<Scalars["JSON"]>;
  refs: Array<Scalars["String"]>;
  schema: Scalars["String"];
  uri?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  member_of: Array<Scalars["String"]>;
};

export enum SitesDsSubscriptionBackfillStates {
  /** Materialization hasn't occurred, or the datasource was updated */
  Unknown = "UNKNOWN",
  /** Materialization is active (check processed/total counts for progress) */
  InProgress = "IN_PROGRESS",
  /** Materialization is finished */
  Complete = "COMPLETE",
}

export type SitesDsSubscriptionBackfillStatus = {
  /** Current state of backfill */
  status: SitesDsSubscriptionBackfillStates;
  /** Timestamp (milliseconds) when backfill began */
  startTime: Scalars["Float"];
  /** Count of records backfilled */
  processed: Scalars["Int"];
  /** Count of matching records to backfill */
  total: Scalars["Int"];
  /** Subscription targetIds that act as a fallback for content querying while the this subscription is backfilling */
  alternativeTargetIds?: Maybe<Array<Scalars["String"]>>;
};

export type SitesExclusionInput = {
  tags: Array<Scalars["String"]>;
};

export type SitesFeed = {
  id: Scalars["String"];
  disabled: Scalars["Boolean"];
  name: Scalars["String"];
  usage?: Maybe<Scalars["String"]>;
  repeatedIndex?: Maybe<Scalars["Int"]>;
  entries: Array<SitesFeedEntry>;
};

export type SitesFeedAudienceResult = SitesFeedResult & {
  id: Scalars["ID"];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars["JSON"];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesAudienceApp;
};

export type SitesFeedBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  feed?: Maybe<SitesFeedResponse>;
};

export type SitesFeedBlockFeedArgs = {
  scopes?: Maybe<Array<Scalars["String"]>>;
  context?: Maybe<Scalars["JSON"]>;
  from?: Maybe<Scalars["String"]>;
};

export type SitesFeedEntry = {
  queryId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type SitesFeedEntryInput = {
  queryId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type SitesFeedInput = {
  disabled: Scalars["Boolean"];
  name: Scalars["String"];
  usage?: Maybe<Scalars["String"]>;
  repeatedIndex?: Maybe<Scalars["Int"]>;
  entries: Array<SitesFeedEntryInput>;
};

export type SitesFeedInspection = {
  queryId: Scalars["String"];
  size: Scalars["Int"];
  skip: Scalars["Int"];
  params: Scalars["JSON"];
};

export type SitesFeedLeadsResult = SitesFeedResult & {
  id: Scalars["ID"];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars["JSON"];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesLeadsCard;
};

export type SitesFeedPwsResult = SitesFeedResult & {
  id: Scalars["ID"];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars["JSON"];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesPwsRecord;
};

export type SitesFeedPubsubResult = SitesFeedResult & {
  id: Scalars["ID"];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars["JSON"];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesPublishRecord;
};

export type SitesFeedResponse = {
  results: Array<SitesFeedResult>;
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  resolvedQueries: Array<SitesQueryDefinition>;
  inspect: Array<SitesFeedInspection>;
  index: SitesIndexRecord;
  config: SitesConfigRecord;
};

export type SitesFeedResult = {
  id: Scalars["ID"];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars["JSON"];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
};

export type SitesFeedResume = {
  id: Scalars["String"];
  scopes?: Maybe<Array<Scalars["String"]>>;
  context?: Maybe<Scalars["JSON"]>;
  size?: Maybe<Scalars["Int"]>;
  from?: Maybe<Scalars["String"]>;
};

export type SitesFeedResumeInput = {
  id: Scalars["String"];
  scopes?: Maybe<Array<Scalars["String"]>>;
  context?: Maybe<Scalars["JSON"]>;
  size?: Maybe<Scalars["Int"]>;
  from?: Maybe<Scalars["String"]>;
};

export type SitesFindManyLookup = {
  type: Sites_Lookup_By;
  value: Scalars["String"];
};

export type SitesIndexBulkMutation = {
  addTags: Array<SitesIndexInternalRecord>;
  remTags: Array<SitesIndexInternalRecord>;
};

export type SitesIndexBulkMutationAddTagsArgs = {
  tags: Array<Scalars["String"]>;
};

export type SitesIndexBulkMutationRemTagsArgs = {
  tags: Array<Scalars["String"]>;
};

export type SitesIndexCreateInput = {
  name: Scalars["String"];
  slug: Scalars["String"];
  parentIndex?: Maybe<Scalars["String"]>;
};

export type SitesIndexInternalRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  configLookups: Scalars["JSON"];
  keys: Array<Scalars["String"]>;
  references: Array<Scalars["String"]>;
  summary: SitesIndexSummary;
  parentIndex: Scalars["ID"];
  ownBrand: Scalars["String"];
  timeZone?: Maybe<Scalars["String"]>;
  ownership?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  market: Scalars["String"];
  slug: Scalars["String"];
  hostnames: Array<Scalars["String"]>;
  canonicalHostname: Scalars["String"];
  facets: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  status: SitesSiteStatus;
  isDistributionSecured?: Maybe<Scalars["Boolean"]>;
  integrations: SitesIntegrations;
  /** Get config by lookup using the lookup map on the IndexInternalRecord, which is either a template id or tag. */
  configByLookup?: Maybe<SitesConfigInternalRecord>;
  stack: Array<SitesIndexInternalRecord>;
  merged?: Maybe<SitesIndexRecord>;
};

export type SitesIndexInternalRecordConfigByLookupArgs = {
  lookup: Scalars["String"];
};

export type SitesIndexInternalResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesIndexInternalRecord>;
};

export type SitesIndexMutation = {
  setSummary: SitesIndexInternalRecord;
  setSlug: SitesIndexInternalRecord;
  remSlug: SitesIndexInternalRecord;
  setBrand: SitesIndexInternalRecord;
  remBrand: SitesIndexInternalRecord;
  setMarket: SitesIndexInternalRecord;
  setTimeZone: SitesIndexInternalRecord;
  setOwnership: SitesIndexInternalRecord;
  setCountry: SitesIndexInternalRecord;
  setDistributionSecured: SitesIndexInternalRecord;
  addTags: SitesIndexInternalRecord;
  remTags: SitesIndexInternalRecord;
  setActive: SitesIndexInternalRecord;
  setInactive: SitesIndexInternalRecord;
  hide: SitesIndexInternalRecord;
  unhide: SitesIndexInternalRecord;
  setOnairStatus: SitesIndexInternalRecord;
  setParent: SitesIndexInternalRecord;
  addHostname: SitesIndexInternalRecord;
  remHostname: SitesIndexInternalRecord;
  setCanonical: SitesIndexInternalRecord;
  setIntegration: SitesIndexInternalRecord;
  remIntegration: SitesIndexInternalRecord;
  setAsParentOf: SitesIndexInternalRecord;
  remAsParentOf: SitesIndexInternalRecord;
  setAsChildOf: SitesIndexInternalRecord;
  remAsChildOf: SitesIndexInternalRecord;
  setAsPrimaryOf: SitesIndexInternalRecord;
  remAsPrimaryOf: SitesIndexInternalRecord;
  setConfigLookups: SitesIndexInternalRecord;
  delete?: Maybe<Scalars["Boolean"]>;
  purgeOnAir?: Maybe<Scalars["Boolean"]>;
  materialize?: Maybe<Scalars["Boolean"]>;
};

export type SitesIndexMutationSetSummaryArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
  image: Scalars["String"];
};

export type SitesIndexMutationSetSlugArgs = {
  newSlug: Scalars["String"];
};

export type SitesIndexMutationRemSlugArgs = {
  slug: Scalars["String"];
};

export type SitesIndexMutationSetBrandArgs = {
  newBrand: Scalars["String"];
};

export type SitesIndexMutationRemBrandArgs = {
  brand: Scalars["String"];
};

export type SitesIndexMutationSetMarketArgs = {
  newMarket: Scalars["String"];
};

export type SitesIndexMutationSetTimeZoneArgs = {
  newTimeZone: Scalars["String"];
};

export type SitesIndexMutationSetOwnershipArgs = {
  newOwnership?: Maybe<Scalars["String"]>;
};

export type SitesIndexMutationSetCountryArgs = {
  newCountry?: Maybe<Scalars["String"]>;
};

export type SitesIndexMutationSetDistributionSecuredArgs = {
  isSecured: Scalars["Boolean"];
};

export type SitesIndexMutationAddTagsArgs = {
  tags: Array<Scalars["String"]>;
};

export type SitesIndexMutationRemTagsArgs = {
  tags: Array<Scalars["String"]>;
};

export type SitesIndexMutationHideArgs = {
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  redirectTo: Scalars["String"];
};

export type SitesIndexMutationSetOnairStatusArgs = {
  enableForward: Scalars["Boolean"];
  forwardToSiteId: Scalars["String"];
};

export type SitesIndexMutationSetParentArgs = {
  parentId?: Maybe<Scalars["ID"]>;
};

export type SitesIndexMutationAddHostnameArgs = {
  hostname: Scalars["String"];
  canonical?: Maybe<Scalars["Boolean"]>;
};

export type SitesIndexMutationRemHostnameArgs = {
  hostname: Scalars["String"];
};

export type SitesIndexMutationSetCanonicalArgs = {
  hostname: Scalars["String"];
};

export type SitesIndexMutationSetIntegrationArgs = {
  plugin: SitesIntegrationPluginInput;
};

export type SitesIndexMutationRemIntegrationArgs = {
  type: Scalars["String"];
};

export type SitesIndexMutationSetAsParentOfArgs = {
  childIndexId: Scalars["ID"];
};

export type SitesIndexMutationRemAsParentOfArgs = {
  childIndexId: Scalars["ID"];
};

export type SitesIndexMutationSetAsChildOfArgs = {
  parentIndexId: Scalars["ID"];
};

export type SitesIndexMutationRemAsChildOfArgs = {
  parentIndexId: Scalars["ID"];
};

export type SitesIndexMutationSetAsPrimaryOfArgs = {
  childIndexId: Scalars["ID"];
};

export type SitesIndexMutationRemAsPrimaryOfArgs = {
  childIndexId: Scalars["ID"];
};

export type SitesIndexMutationSetConfigLookupsArgs = {
  lookups: Array<Scalars["String"]>;
  configId: Scalars["ID"];
};

export type SitesIndexRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  configLookups: Scalars["JSON"];
  /**
   * This is a general purpose String! array that contains semantic data. It's
   * the same as the `references` field except that there is a unique database
   * index on it. So this is good for keeping some integrity in the data for
   * unique keys applied to different sites.
   *
   * This field stores things like slugs, hostnames and other uniquely linked
   * data points.
   */
  keys: Array<Scalars["String"]>;
  /**
   * This is a general purpose String! array that contains semantic data. It's
   * a lot like `keys` except that there is an index on this field that is
   * __not__ unique. This is great for tagging relationships between sites
   * that aren't necesarily unique.
   * Right now this replaces the old array of linked microsites thing.
   */
  references: Array<Scalars["String"]>;
  summary: SitesIndexSummary;
  parentIndex: Scalars["ID"];
  ownBrand: Scalars["String"];
  timeZone?: Maybe<Scalars["String"]>;
  ownership?: Maybe<TagRef>;
  country?: Maybe<TagRef>;
  market: Scalars["String"];
  slug: Scalars["String"];
  hostnames: Array<Scalars["String"]>;
  canonicalHostname: Scalars["String"];
  facets: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  status: SitesSiteStatus;
  isDistributionSecured?: Maybe<Scalars["Boolean"]>;
  integration?: Maybe<SitesIntegrationPlugin>;
  integrations: SitesIntegrations;
  /** Get config by it's name. */
  configByName?: Maybe<SitesConfigRecord>;
  /** Get config by lookup using the lookup map on the IndexRecord, which is either a template id or tag. */
  configByLookup?: Maybe<SitesConfigRecord>;
  /** Shorthand for the `configByLookup` resolver using the 'site-config-lookups/live' lookup. */
  liveConfig?: Maybe<SitesConfigRecord>;
  /** OnAir entries that are scheduled on this site. */
  onAir: Array<SitesOnAirRecord>;
  /** OnAir entries that this site is used to enrich. This is the opposite of the `onAir` resolver. */
  onAirShows: Array<SitesOnAirRecord>;
  onAirSchedule: SitesOnAirScheduleResponse;
  onAirScheduleForDay: SitesOnAirDayScheduleResponse;
  /**
   * Check all indexes in site manager to see if this Site or a parent site
   * is referenced by the type indicated in the `by` argument. Includes references
   * from parent sites.
   *
   * If the referencing sites have a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   * @deprecated Non-paginated, use `inboundRefs2`
   */
  inboundRefs: Array<SitesIndexRecord>;
  /**
   * Check all indexes in site manager to see if this Site or a parent site
   * is referenced by the type indicated in the `by` argument. Includes references
   * from parent sites.
   *
   * If the referencing sites have a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundRefs2: SitesIndexResults;
  /**
   * Look up a site using the specified `type`/`value` lookup and return it if
   * this Site is referenced by the type indicated in the `by` argument.
   *
   * If the referencing site has an site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundRef?: Maybe<SitesIndexRecord>;
  /**
   * Check my own references and create a dataset based on a particular
   * constraint.
   *
   * If the referenced site has an site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  outboundRefs: Array<SitesIndexRecord>;
  /**
   * Look in the entire database for my id constrained with `by` argument.
   *
   * If the inbound site has a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundKeys?: Maybe<SitesIndexRecord>;
  /** Mount point for routes to this site inside a parent site. */
  embedBasePath?: Maybe<Scalars["String"]>;
  /** Current status of materialization. */
  taskStatus?: Maybe<SitesTaskStatus>;
  /** @deprecated Always empty, because the original calendar API no longer exists. */
  events: Array<CalendarEvent>;
  /** @deprecated Always empty, because the original calendar API no longer exists. */
  eventCategories: Array<Scalars["String"]>;
  stream?: Maybe<Stream>;
};

export type SitesIndexRecordIntegrationArgs = {
  type: Scalars["String"];
};

export type SitesIndexRecordConfigByNameArgs = {
  name: Scalars["String"];
};

export type SitesIndexRecordConfigByLookupArgs = {
  lookup: Scalars["String"];
};

export type SitesIndexRecordOnAirScheduleArgs = {
  currentDate?: Maybe<Scalars["Float"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type SitesIndexRecordOnAirScheduleForDayArgs = {
  day: Sites_Onair_Day;
  timeZone?: Maybe<Scalars["String"]>;
};

export type SitesIndexRecordInboundRefsArgs = {
  by: Sites_Referenced_By;
};

export type SitesIndexRecordInboundRefs2Args = {
  by: Sites_Referenced_By;
  paging?: Maybe<SitesPageInput>;
};

export type SitesIndexRecordInboundRefArgs = {
  type: Sites_Lookup_By;
  value: Scalars["String"];
  by: Sites_Referenced_By;
};

export type SitesIndexRecordOutboundRefsArgs = {
  by: Sites_Referenced_By;
};

export type SitesIndexRecordInboundKeysArgs = {
  by: Sites_Lookup_By;
};

export type SitesIndexRecordEventsArgs = {
  category?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["Float"]>;
  num?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type SitesIndexRecordEventCategoriesArgs = {
  start_time?: Maybe<Scalars["Float"]>;
  end_time?: Maybe<Scalars["Float"]>;
};

export type SitesIndexResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesIndexRecord>;
};

export type SitesIndexSummary = {
  name: Scalars["String"];
  description: Scalars["String"];
  image: Scalars["String"];
};

export type SitesIntegrationPlugin = {
  type: Scalars["String"];
  data: Scalars["JSON"];
};

export type SitesIntegrationPluginInput = {
  type: Scalars["String"];
  data: Scalars["JSON"];
};

export type SitesIntegrations = {
  plugins: Array<SitesIntegrationPlugin>;
};

export type SitesInternalBlock = {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type SitesItemBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  item?: Maybe<SitesItemResponse>;
};

export type SitesItemBlockItemArgs = {
  scopes?: Maybe<Array<Scalars["String"]>>;
  context?: Maybe<Scalars["JSON"]>;
};

export type SitesItemResponse = {
  result?: Maybe<SitesFeedResult>;
  resolvedQueries: Array<SitesQueryDefinition>;
  inspect: Array<SitesFeedInspection>;
  index: SitesIndexRecord;
  config: SitesConfigRecord;
};

export type SitesKeywordContestBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  campaignId: Scalars["String"];
};

export type SitesLeadsCard = {
  id: Scalars["String"];
  img_uri?: Maybe<Scalars["String"]>;
  position: Scalars["Int"];
  priority: Scalars["Int"];
  important: Scalars["Boolean"];
  range: SitesLeadsCardRange;
  title: Scalars["String"];
  subtitle: Scalars["String"];
  background_color: Scalars["String"];
  catalog?: Maybe<SitesCatalogItem>;
  use_catalog_image?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<SitesLeadsCardLink>;
  img_meta?: Maybe<SitesLeadsCardImgMeta>;
  targets: Array<SitesDistribution>;
  exclusion: Array<SitesDistribution>;
};

export type SitesLeadsCardImgMeta = {
  bucket?: Maybe<Scalars["String"]>;
  base_id?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  ops: Array<SitesMediaOperation>;
};

export type SitesLeadsCardLink = {
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  urls?: Maybe<Scalars["JSON"]>;
};

export type SitesLeadsCardRange = {
  begin: Scalars["Float"];
  end?: Maybe<Scalars["Float"]>;
};

export type SitesLinksData = {
  name: Scalars["String"];
  linksType: SitesLinksType;
  result: SitesQueryOutput;
};

export type SitesLinksInInput = {
  /** Field name to fetch links for. */
  name: Scalars["String"];
  /** Query a particular pubsub type for results. */
  type: Scalars["String"];
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  /** Page size for results. Default: 25 */
  limit?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<SitesSortField>;
};

export type SitesLinksOutInput = {
  /** Field name to fetch links for. */
  name: Scalars["String"];
  /** Narrow results to a particular pubsub type. */
  type?: Maybe<Scalars["String"]>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<SitesSortField>;
};

export enum SitesLinksType {
  LinksIn = "LINKS_IN",
  LinksOut = "LINKS_OUT",
}

export type SitesLocationCommon = {
  locale?: Maybe<TagRef>;
  language?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["String"]>;
  lon?: Maybe<Scalars["String"]>;
  addr1?: Maybe<Scalars["String"]>;
  addr2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
  /** @deprecated This data has been moved to the `IndexRecord.country` resolver. */
  country?: Maybe<Scalars["String"]>;
};

export type SitesMaxAges = {
  /** max-age for query and search endpoints */
  query: Scalars["Int"];
  /** max-age for get and getId endpoints */
  get: Scalars["Int"];
  /** max-age for subscription content */
  subscription: Scalars["Int"];
  /** max-age when an error is encountered during resolution */
  error: Scalars["Int"];
  /** max-age when a resolved value is null */
  nullValue: Scalars["Int"];
};

export type SitesMaxAgesInput = {
  /** max-age for query and search endpoints (Default: 5 minutes) */
  query?: Maybe<Scalars["Int"]>;
  /** max-age for get and getId endpoints (Default: 14 days) */
  get?: Maybe<Scalars["Int"]>;
  /** max-age for subscription content (Default: 14 days) */
  subscription?: Maybe<Scalars["Int"]>;
  /** max-age when an error is encountered during resolution (Default: 5 minutes) */
  error?: Maybe<Scalars["Int"]>;
  /** max-age when a resolved value is null (Default: 5 minutes) */
  nullValue?: Maybe<Scalars["Int"]>;
};

export type SitesMediaOperation = {
  op?: Maybe<Scalars["String"]>;
  args: Array<Scalars["String"]>;
};

export type SitesMenu = {
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  ref?: Maybe<Scalars["String"]>;
  children?: Maybe<Array<SitesMenu>>;
};

export type SitesOnAir = {
  byId?: Maybe<SitesOnAirRecord>;
  bySiteIds: Array<SitesOnAirRecord>;
  generateSchedule: SitesOnAirScheduleResponse;
  generateScheduleForDay: SitesOnAirDayScheduleResponse;
};

export type SitesOnAirByIdArgs = {
  id: Scalars["ID"];
};

export type SitesOnAirBySiteIdsArgs = {
  siteIds: Array<Scalars["ID"]>;
};

export type SitesOnAirGenerateScheduleArgs = {
  siteId: Scalars["ID"];
  currentDate?: Maybe<Scalars["Float"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type SitesOnAirGenerateScheduleForDayArgs = {
  siteId: Scalars["ID"];
  day: Sites_Onair_Day;
  timeZone?: Maybe<Scalars["String"]>;
};

export type SitesOnAirDayScheduleResponse = {
  schedule: Array<SitesOnAirExtended>;
  timeZone: Scalars["String"];
};

export type SitesOnAirDayScheduleResponseScheduleArgs = {
  take?: Maybe<Scalars["Int"]>;
};

export type SitesOnAirExtended = {
  siteId: Scalars["String"];
  /** @deprecated Use the `siteRef.id` resolver instead, which also allows resolving the site. */
  site: Scalars["String"];
  coreShowId?: Maybe<Scalars["Int"]>;
  coreStationId?: Maybe<Scalars["Int"]>;
  show: Scalars["String"];
  station: Scalars["String"];
  /**
   * This name is copied from CORE data and, if this entry is associated with a
   * site, replaced with that site's display name.
   */
  name: Scalars["String"];
  /**
   * If this entry is associated with a site, this image is populated with that
   * sites' display thumbnail.
   */
  image?: Maybe<MediaAssetRef>;
  /**
   * The site linked to this onair entry via its `shows` tag from CORE. Allows
   * selecting any datapoints pertaining to associated site.
   */
  showSite?: Maybe<SitesIndexRecord>;
  siteRef: SiteRef;
  /**
   * Provides some display properties for rendering the destination of an onair
   * entry, if a destination exists. These values are stored on the onair entry
   * itself.
   */
  destination?: Maybe<SitesOnAirExtendedDestination>;
  onNow: Scalars["Boolean"];
  startTime12: Scalars["String"];
  startDate: Scalars["String"];
  startMs: Scalars["Float"];
  startEng: Scalars["String"];
  stopTime12: Scalars["String"];
  stopDate: Scalars["String"];
  stopMs: Scalars["Float"];
  stopEng: Scalars["String"];
  link: Scalars["String"];
};

export type SitesOnAirExtendedDestination = {
  imageRef?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  host: Scalars["String"];
  slug: Scalars["String"];
  name: Scalars["String"];
  /** @deprecated Always `true`. */
  linkedToMicrosite: Scalars["Boolean"];
  href: Scalars["String"];
  link: Scalars["String"];
};

export type SitesOnAirFilterInput = {
  indexIds?: Maybe<Array<Scalars["ID"]>>;
};

export type SitesOnAirInput = {
  siteId: Scalars["String"];
  site: Scalars["String"];
  coreShowId?: Maybe<Scalars["Int"]>;
  coreStationId?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  personality: Scalars["String"];
  show: Scalars["String"];
  station: Scalars["String"];
  link: Scalars["String"];
  source: Sites_Onair_Source;
  schedule: SitesOnAirScheduleInput;
  micrositeId?: Maybe<Scalars["String"]>;
};

export type SitesOnAirMutation = {
  update: SitesOnAirRecord;
  delete?: Maybe<Scalars["Boolean"]>;
};

export type SitesOnAirMutationUpdateArgs = {
  payload: SitesOnAirInput;
};

export type SitesOnAirMutations = {
  create: SitesOnAirRecord;
  byId: SitesOnAirMutation;
};

export type SitesOnAirMutationsCreateArgs = {
  payload: SitesOnAirInput;
};

export type SitesOnAirMutationsByIdArgs = {
  id: Scalars["ID"];
};

export type SitesOnAirRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  siteId: Scalars["String"];
  /** @deprecated Use the `siteRef.id` resolver instead, which also allows resolving the site. */
  site: Scalars["String"];
  coreShowId?: Maybe<Scalars["Int"]>;
  coreStationId?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  image?: Maybe<MediaAssetRef>;
  personality: Scalars["String"];
  show: Scalars["String"];
  station: Scalars["String"];
  link: Scalars["String"];
  schedule: SitesOnAirSchedule;
  source: Sites_Onair_Source;
  /**
   * The site linked to this onair entry via its `shows` tag from CORE. Allows
   * selecting any datapoints pertaining to associated site.
   */
  showSite?: Maybe<SitesIndexRecord>;
  siteRef: SiteRef;
  micrositeId?: Maybe<Scalars["String"]>;
};

export type SitesOnAirResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesOnAirRecord>;
};

export type SitesOnAirSchedule = {
  su?: Maybe<SitesOnAirTimeBox>;
  mo?: Maybe<SitesOnAirTimeBox>;
  tu?: Maybe<SitesOnAirTimeBox>;
  we?: Maybe<SitesOnAirTimeBox>;
  th?: Maybe<SitesOnAirTimeBox>;
  fr?: Maybe<SitesOnAirTimeBox>;
  sa?: Maybe<SitesOnAirTimeBox>;
};

export type SitesOnAirScheduleInput = {
  su?: Maybe<SitesOnAirTimeBoxInput>;
  mo?: Maybe<SitesOnAirTimeBoxInput>;
  tu?: Maybe<SitesOnAirTimeBoxInput>;
  we?: Maybe<SitesOnAirTimeBoxInput>;
  th?: Maybe<SitesOnAirTimeBoxInput>;
  fr?: Maybe<SitesOnAirTimeBoxInput>;
  sa?: Maybe<SitesOnAirTimeBoxInput>;
};

export type SitesOnAirScheduleResponse = {
  current?: Maybe<SitesOnAirExtended>;
  upcoming: Array<SitesOnAirExtended>;
  timeZone: Scalars["String"];
};

export type SitesOnAirScheduleResponseUpcomingArgs = {
  take?: Maybe<Scalars["Int"]>;
  includeCurrent?: Maybe<Scalars["Boolean"]>;
};

export type SitesOnAirTimeBox = {
  start: Scalars["String"];
  stop: Scalars["String"];
};

export type SitesOnAirTimeBoxInput = {
  start: Scalars["String"];
  stop: Scalars["String"];
};

export type SitesOverrideFilterInput = {
  startDate?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["Float"]>;
  active?: Maybe<Scalars["Boolean"]>;
  search?: Maybe<Scalars["String"]>;
  facets?: Maybe<Array<Scalars["String"]>>;
};

export type SitesPwsGuest = {
  id: Scalars["String"];
  pubStart: DateRef;
  slug?: Maybe<Scalars["String"]>;
  summary: SitesPublishSummary;
};

export type SitesPwsRecord = SitesPwsGuest;

export type SitesPageBlock = {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type SitesPageInfo = {
  /** @deprecated Use `nextToken` instead. */
  nextCursor?: Maybe<Scalars["String"]>;
  /** @deprecated Use `prevToken` instead. */
  prevCursor?: Maybe<Scalars["String"]>;
  prevToken?: Maybe<Scalars["ID"]>;
  nextToken?: Maybe<Scalars["ID"]>;
  itemTokens?: Maybe<Array<Scalars["ID"]>>;
};

export type SitesPageInput = {
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  nextCursor?: Maybe<Scalars["String"]>;
  prevCursor?: Maybe<Scalars["String"]>;
};

export type SitesPageInternalRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  configId: Scalars["String"];
  name: Scalars["String"];
  layoutId: Scalars["String"];
  displayName: Scalars["String"];
  path: Scalars["String"];
  pathVariables: Scalars["JSON"];
  pageConfig: Scalars["JSON"];
  tags: Array<TagRef>;
  blocks: Array<SitesInternalBlock>;
  isDeleted: Scalars["Boolean"];
  stack: Array<SitesPageInternalRecord>;
};

export type SitesPageMutation = {
  setConfig: SitesPageInternalRecord;
  setDisplayName: SitesPageInternalRecord;
  setPath: SitesPageInternalRecord;
  setTags: SitesPageInternalRecord;
  setVariables: SitesPageInternalRecord;
  blockAdd: SitesPageInternalRecord;
  blockSet: SitesPageInternalRecord;
  blockRem: SitesPageInternalRecord;
  blockSetOrdinal: SitesPageInternalRecord;
  restore: SitesPageInternalRecord;
  delete?: Maybe<Scalars["Boolean"]>;
};

export type SitesPageMutationSetConfigArgs = {
  data: Scalars["JSON"];
};

export type SitesPageMutationSetDisplayNameArgs = {
  newName: Scalars["String"];
};

export type SitesPageMutationSetPathArgs = {
  newPath: Scalars["String"];
};

export type SitesPageMutationSetTagsArgs = {
  tags: Array<Scalars["String"]>;
};

export type SitesPageMutationSetVariablesArgs = {
  variables: Scalars["JSON"];
};

export type SitesPageMutationBlockAddArgs = {
  block: SitesBlockInput;
};

export type SitesPageMutationBlockSetArgs = {
  blockId: Scalars["String"];
  block: SitesBlockInput;
};

export type SitesPageMutationBlockRemArgs = {
  blockId: Scalars["String"];
};

export type SitesPageMutationBlockSetOrdinalArgs = {
  blockId: Scalars["String"];
  ordinal: Scalars["Int"];
};

export type SitesPageMutations = {
  create: SitesPageInternalRecord;
  byId: SitesPageMutation;
};

export type SitesPageMutationsCreateArgs = {
  page: SitesAddPageInput;
};

export type SitesPageMutationsByIdArgs = {
  pageId: Scalars["ID"];
};

export type SitesPageOverrideInput = {
  parentId: Scalars["String"];
  name: Scalars["String"];
  facets: Array<Scalars["String"]>;
  pages: Array<Scalars["String"]>;
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  disabled: Scalars["Boolean"];
  reason: Scalars["String"];
  blocks: Array<SitesBlockOverrideInput>;
};

export type SitesPageOverrideMutation = {
  update: SitesPageOverrideRecord;
  copy: SitesPageOverrideRecord;
  delete?: Maybe<Scalars["Boolean"]>;
};

export type SitesPageOverrideMutationUpdateArgs = {
  payload: SitesPageOverrideInput;
};

export type SitesPageOverrideMutationCopyArgs = {
  newName: Scalars["String"];
};

export type SitesPageOverrideMutations = {
  create: SitesPageOverrideRecord;
  find: SitesPageOverrideMutation;
};

export type SitesPageOverrideMutationsCreateArgs = {
  payload: SitesPageOverrideInput;
};

export type SitesPageOverrideMutationsFindArgs = {
  id: Scalars["ID"];
};

export type SitesPageOverrideRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  parentId: Scalars["String"];
  name: Scalars["String"];
  facets: Array<Scalars["String"]>;
  pages: Array<Scalars["String"]>;
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  disabled: Scalars["Boolean"];
  reason: Scalars["String"];
  blocks: Array<SitesBlockOverride>;
  parent?: Maybe<SitesConfigInternalRecord>;
};

export type SitesPageOverrideResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
  results: Array<SitesPageOverrideRecord>;
};

export type SitesPageOverrides = {
  filter: SitesPageOverrideResults;
  find?: Maybe<SitesPageOverrideRecord>;
  findMany: Array<SitesPageOverrideRecord>;
};

export type SitesPageOverridesFilterArgs = {
  filter: SitesOverrideFilterInput;
  paging?: Maybe<SitesPageInput>;
};

export type SitesPageOverridesFindArgs = {
  id: Scalars["ID"];
};

export type SitesPageOverridesFindManyArgs = {
  ids: Array<Scalars["ID"]>;
};

export type SitesPageRecord = {
  _id: Scalars["ID"];
  _meta: SitesDocumentMetadata;
  configId: Scalars["String"];
  name: Scalars["String"];
  layoutId: Scalars["String"];
  displayName: Scalars["String"];
  path: Scalars["String"];
  pathVariables: Scalars["JSON"];
  pageConfig: Scalars["JSON"];
  tags: Array<TagRef>;
  block?: Maybe<SitesPageBlock>;
  blocks: Array<SitesPageBlock>;
  resolvedPath?: Maybe<Scalars["String"]>;
  /** @deprecated Field no longer supported */
  parentId?: Maybe<Scalars["String"]>;
  /** @deprecated Parse blocks for block tagged with 'display-hints/primary'. */
  primaryBlockId: Scalars["String"];
};

export type SitesPageRecordBlockArgs = {
  blockId: Scalars["String"];
};

export type SitesPageRecordResolvedPathArgs = {
  params?: Maybe<Scalars["JSON"]>;
};

export type SitesPageRoute = {
  name: Scalars["String"];
  path: Scalars["String"];
};

export type SitesPagedResult = {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
};

export type SitesPagedResults = SitesPagedResult & {
  count: Scalars["Int"];
  pageInfo: SitesPageInfo;
};

export type SitesPages = {
  byId?: Maybe<SitesPageInternalRecord>;
  byConfig: Array<SitesPageInternalRecord>;
  byName?: Maybe<SitesPageInternalRecord>;
};

export type SitesPagesByIdArgs = {
  pageId: Scalars["ID"];
};

export type SitesPagesByConfigArgs = {
  configId: Scalars["ID"];
  unrestricted?: Maybe<Scalars["Boolean"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
};

export type SitesPagesByNameArgs = {
  configId: Scalars["ID"];
  name: Scalars["String"];
  isDeleted?: Maybe<Scalars["Boolean"]>;
};

export type SitesPartnersCommon = {
  showIniHeartSwitch?: Maybe<Scalars["Boolean"]>;
  postupSwitch?: Maybe<Scalars["Boolean"]>;
  emailFormat?: Maybe<TagRef>;
};

export type SitesPartnersSection = {
  emailFormat?: Maybe<Scalars["String"]>;
  ihrShowInIheartSwitch?: Maybe<Scalars["Boolean"]>;
  postupSwitch?: Maybe<Scalars["Boolean"]>;
};

/**
 * Base type for all content payloads.
 * If a content type has a payload schema, it will define a typed `data` property.
 * (The default implementation will just have the raw JSON data under `data`.)
 */
export type SitesPayloadSelection = {
  /** Content type */
  type: Scalars["String"];
  /** Content type source (e.g. an app id) */
  source: Scalars["String"];
};

export type SitesPodcastsBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  podcasts: Array<Scalars["String"]>;
  podcastRefs: Array<PodcastRef>;
};

export type SitesPopulateInput = {
  /** Link all matching records to the datasource, instead of stopping at a set amount. */
  all?: Maybe<Scalars["Boolean"]>;
  /** Link up to this number of matching records to the datasource, and stop. Ignored if all=true */
  size?: Maybe<Scalars["Int"]>;
};

export type SitesPublishInput = {
  ref_id: Scalars["ID"];
  slug?: Maybe<Scalars["String"]>;
  summary: SitesPublishSummaryInput;
  payload?: Maybe<Scalars["JSON"]>;
  lookup?: Maybe<Array<Maybe<Scalars["String"]>>>;
  subscription: Array<SitesDistributionInput>;
  exclusion?: Maybe<Array<SitesExclusionInput>>;
  pub_start: Scalars["Float"];
  pub_until?: Maybe<Scalars["Float"]>;
  links?: Maybe<Array<SitesPublishLinkInput>>;
};

export type SitesPublishLinkInput = {
  type: Scalars["String"];
  name: Scalars["String"];
  ref_id: Scalars["String"];
};

export type SitesPublishRecord = {
  /** Content type (created manually or by the publishing application) */
  type: Scalars["String"];
  /** Primary key id in storage */
  id: Scalars["ID"];
  /** Reference to item id in the publishing application */
  ref_id: Scalars["ID"];
  /** Primary slug for item */
  slug?: Maybe<Scalars["String"]>;
  summary: SitesPublishSummary;
  /** The raw item content. Using the `content` field to have a typed selection is preferred */
  payload?: Maybe<Scalars["JSON"]>;
  /** Typed item payload (format differs between content types), for content types that have a schema. */
  content?: Maybe<SitesPayloadSelection>;
  /** Item targeting (e.g. which sites receive the content) */
  subscription: Array<SitesDistribution>;
  /** List of entities to exclude when returning this item (e.g. when running a broad query) */
  exclusion: Array<SitesDistribution>;
  /** Beginning millisecond timestamp for item availability */
  pub_start: Scalars["Float"];
  /** Ending millisecond timestamp for item availability */
  pub_until: Scalars["Float"];
  /** Millisecond timestamp for the latest update of the item (according to the publishing application) */
  pub_changed?: Maybe<Scalars["Float"]>;
  /** Millisecond timestamp for the latest update of the item (always updated during publish) */
  pub_touched: Scalars["Float"];
  /** Fetch content which links to this record. */
  linksIn: SitesQueryOutput;
  /** Fetch content this record links to. */
  linksOut: Array<SitesPublishRecord>;
  /** List of unique identifiers for the item */
  keys: Array<Scalars["String"]>;
  /** List of additional datapoints that can be used to find this item (not unique). */
  lookup?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** List of complete what/where targets used in materializing datasource content */
  datasourceTargets?: Maybe<Array<SitesTarget>>;
  /** List of references to linked content */
  links?: Maybe<Array<SitesRawPublishLink>>;
  linksData: Array<SitesLinksData>;
  canonicalUrl?: Maybe<Scalars["String"]>;
  permalink?: Maybe<Scalars["String"]>;
};

export type SitesPublishRecordPayloadArgs = {
  fields?: Array<Scalars["String"]>;
};

export type SitesPublishRecordLinksInArgs = {
  query: SitesLinksInInput;
};

export type SitesPublishRecordLinksOutArgs = {
  query: SitesLinksOutInput;
};

/** Common information for publish records */
export type SitesPublishSummary = {
  /** By-line for content */
  author: Scalars["String"];
  /** Subtitle or other descriptive info */
  description: Scalars["String"];
  /** Thumbnail (or other) image URI to associate with content */
  image?: Maybe<Scalars["String"]>;
  /** Title or equivalent display name for the item */
  title: Scalars["String"];
};

export type SitesPublishSummaryInput = {
  author: Scalars["String"];
  description: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SitesPublishToDatasourcesInput = {
  ref_id: Scalars["ID"];
  targets: Array<SitesTargetsInput>;
};

export type SitesPubsubRootMutation = {
  defineContentType?: Maybe<SitesContentType>;
  removeContentType?: Maybe<Scalars["Boolean"]>;
  publish?: Maybe<Scalars["String"]>;
  unpublish?: Maybe<Scalars["String"]>;
  retarget?: Maybe<Scalars["Boolean"]>;
  createDatasource?: Maybe<SitesDatasource>;
  updateDatasource?: Maybe<SitesDatasource>;
  deleteDatasource?: Maybe<Scalars["Boolean"]>;
  publishToDatasources?: Maybe<Scalars["Boolean"]>;
};

export type SitesPubsubRootMutationDefineContentTypeArgs = {
  type: Scalars["String"];
  config?: Maybe<SitesContentTypeInput>;
};

export type SitesPubsubRootMutationRemoveContentTypeArgs = {
  type: Scalars["String"];
};

export type SitesPubsubRootMutationPublishArgs = {
  type: Scalars["String"];
  record: SitesPublishInput;
};

export type SitesPubsubRootMutationUnpublishArgs = {
  type: Scalars["String"];
  ref: Scalars["String"];
};

export type SitesPubsubRootMutationRetargetArgs = {
  type: Scalars["String"];
  fromRefId: Scalars["String"];
  toRefId: Scalars["String"];
};

export type SitesPubsubRootMutationCreateDatasourceArgs = {
  input: SitesDatasourceInput;
  populate?: Maybe<SitesPopulateInput>;
};

export type SitesPubsubRootMutationUpdateDatasourceArgs = {
  _id: Scalars["ID"];
  ops: Array<SitesUpdateDatasourceOperationInput>;
};

export type SitesPubsubRootMutationDeleteDatasourceArgs = {
  _id: Scalars["ID"];
};

export type SitesPubsubRootMutationPublishToDatasourcesArgs = {
  type: Scalars["String"];
  record: SitesPublishToDatasourcesInput;
};

export type SitesPubsubRootQuery = {
  contentType?: Maybe<SitesContentType>;
  /** Get a published record by id or slug */
  get?: Maybe<SitesPublishRecord>;
  /** List records with the given filter applied */
  query?: Maybe<SitesQueryOutput>;
  /** Perform a fulltext search on records */
  search: SitesSearchOutput;
  /** Get a registered datasource by id */
  datasource?: Maybe<SitesDatasource>;
  /** Get list of registered datasources matching the given filter */
  datasources?: Maybe<Array<SitesDatasource>>;
  /** Get a datasource subscription by id */
  subscription?: Maybe<SitesDsSubscription>;
};

export type SitesPubsubRootQueryContentTypeArgs = {
  type: Scalars["String"];
};

export type SitesPubsubRootQueryGetArgs = {
  type: Scalars["String"];
  select: SitesPubsubSelectInput;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
};

export type SitesPubsubRootQueryQueryArgs = {
  type: Scalars["String"];
  query: SitesQueryInput;
};

export type SitesPubsubRootQuerySearchArgs = {
  type?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Scalars["String"]>>;
  search: SitesSearchInput;
};

export type SitesPubsubRootQueryDatasourceArgs = {
  _id: Scalars["ID"];
};

export type SitesPubsubRootQueryDatasourcesArgs = {
  filter?: Maybe<SitesDatasourceListFilter>;
};

export type SitesPubsubRootQuerySubscriptionArgs = {
  _id?: Maybe<Scalars["ID"]>;
};

export type SitesPubsubSelectInput = {
  /** Reference id (e.g. the item id from content or leads) */
  id?: Maybe<Scalars["String"]>;
  /** Item slug (tool dependent) */
  slug?: Maybe<Scalars["String"]>;
};

export enum SitesQueryDatasource {
  Publishing = "PUBLISHING",
  Leads = "LEADS",
  Pws = "PWS",
  Audience = "AUDIENCE",
}

export type SitesQueryDefinition = {
  id: Scalars["String"];
  disabled: Scalars["Boolean"];
  source: SitesQueryDatasource;
  name: Scalars["String"];
  spec: Scalars["JSON"];
  defaultVariables: Scalars["JSON"];
};

export type SitesQueryDefinitionInput = {
  disabled: Scalars["Boolean"];
  source: SitesQueryDatasource;
  name: Scalars["String"];
  spec: Scalars["JSON"];
  defaultVariables: Scalars["JSON"];
};

export type SitesQueryInput = {
  lookup?: Maybe<Array<Scalars["String"]>>;
  subscription?: Maybe<Array<SitesDistributionInput>>;
  /** This field is unused. Use `subject` instead. */
  exclusion?: Maybe<Array<SitesExclusionInput>>;
  subject?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_after?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Int"]>;
  /** Use `nextToken` instead. This option will be removed in a future release. */
  offset?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortField?: Maybe<SitesSortField>;
};

export type SitesQueryOutput = {
  items: Array<SitesPublishRecord>;
  query: Scalars["JSON"];
  /** @deprecated Use `page.nextToken`, if present this query has more results. */
  has_more: Scalars["Boolean"];
  /** @deprecated Use `page.nextToken` instead. */
  next?: Maybe<Scalars["String"]>;
  page: SitesPageInfo;
};

export type SitesRawPublishLink = {
  type: Scalars["String"];
  ref_id: Scalars["String"];
  name: Scalars["String"];
};

export type SitesRedirect = {
  id: Scalars["String"];
  disabled: Scalars["Boolean"];
  source: Scalars["String"];
  destination: SitesRedirectDest;
  schedule?: Maybe<SitesSchedule>;
  permanent: Scalars["Boolean"];
  regex: Scalars["Boolean"];
  priority: Scalars["Int"];
  matchQueryString: Scalars["Boolean"];
  queryParamsHandling: SitesRedirectQueryParamsHandling;
};

export type SitesRedirectDest = {
  type: SitesDestinationType;
  value: Scalars["String"];
};

export type SitesRedirectInput = {
  disabled: Scalars["Boolean"];
  source: Scalars["String"];
  destination: SitesDestinationInput;
  schedule?: Maybe<SitesScheduleInput>;
  permanent: Scalars["Boolean"];
  regex: Scalars["Boolean"];
  priority: Scalars["Int"];
  matchQueryString: Scalars["Boolean"];
  queryParamsHandling: SitesRedirectQueryParamsHandling;
};

export enum SitesRedirectQueryParamsHandling {
  Original = "ORIGINAL",
  Merge = "MERGE",
  Discard = "DISCARD",
}

export type SitesRedirectResponse = {
  destination: Scalars["String"];
  permanent: Scalars["Boolean"];
};

export type SitesSchedule = {
  begin: Scalars["Float"];
  end: Scalars["Float"];
};

export type SitesScheduleInput = {
  begin: Scalars["Float"];
  end: Scalars["Float"];
};

export type SitesScope = {
  /** Primary tag for the scope, which is paired with each of the primary/secondary datasource tags for the base query */
  id: Scalars["String"];
  /** These tags plus the datasource segments are combined the base scope query to form more specific queries */
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type SitesScopeFilterInput = {
  /** At least one scope's primary tag must match the given tag */
  id?: Maybe<Scalars["String"]>;
  /** At least one scope's non-primary tags must include all listed tags */
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type SitesScopeInput = {
  id: Scalars["String"];
  segments?: Maybe<Array<Scalars["String"]>>;
};

export type SitesSearchInput = {
  query: Scalars["JSON"];
  lookup?: Maybe<Array<Scalars["String"]>>;
  subscription?: Maybe<Array<SitesDistributionInput>>;
  /** This field is unused. Use `subject` instead. */
  exclusion?: Maybe<Array<SitesExclusionInput>>;
  subject?: Maybe<Array<Scalars["String"]>>;
  pub_start?: Maybe<Scalars["Float"]>;
  pub_after?: Maybe<Scalars["Float"]>;
  pub_until?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Int"]>;
  /** Use `nextToken` instead. This option will be removed in a future release. */
  offset?: Maybe<Scalars["Int"]>;
  nextToken?: Maybe<Scalars["String"]>;
  sortField?: Maybe<SitesSortField>;
  sort?: Maybe<Scalars["JSON"]>;
};

export type SitesSearchOutput = {
  items: Array<SitesPublishRecord>;
  search: Scalars["JSON"];
  /** @deprecated Use `page.nextToken`, if present this search has more results. */
  has_more: Scalars["Boolean"];
  /** @deprecated Use `page.nextToken` instead. */
  next?: Maybe<Scalars["String"]>;
  page: SitesPageInfo;
};

export type SitesSearchOverride = {
  id: Scalars["String"];
  disabled: Scalars["Boolean"];
  keywords: Array<Scalars["String"]>;
  destination: SitesRedirectDest;
  schedule?: Maybe<SitesSchedule>;
};

export type SitesSearchOverrideInput = {
  disabled: Scalars["Boolean"];
  keywords: Array<Scalars["String"]>;
  destination: SitesDestinationInput;
  schedule?: Maybe<SitesScheduleInput>;
};

export type SitesShowBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  shows: Array<SitesIndexRecord>;
};

export type SitesShowsKeysIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
  plugin: SitesShowsKeysIntegrationData;
};

export type SitesShowsKeysIntegrationData = {
  linked_shows: Array<Scalars["String"]>;
};

export type SitesSiteMutations = {
  create: SitesIndexInternalRecord;
  find: SitesIndexMutation;
  findMany: SitesIndexBulkMutation;
};

export type SitesSiteMutationsCreateArgs = {
  payload: SitesIndexCreateInput;
};

export type SitesSiteMutationsFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars["String"];
};

export type SitesSiteMutationsFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
};

export type SitesSiteStatus = {
  isActive: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  hide?: Maybe<SitesSiteStatusHide>;
  onair?: Maybe<SitesSiteStatusOnAir>;
};

export type SitesSiteStatusHide = {
  startDate: Scalars["Float"];
  endDate: Scalars["Float"];
  redirectTo: Scalars["String"];
};

export type SitesSiteStatusOnAir = {
  enableForward: Scalars["Boolean"];
  forwardToSiteId: Scalars["String"];
  forwardToSite?: Maybe<SitesIndexRecord>;
};

export type SitesSites = {
  /** @deprecated Non-paginated, use `filter` */
  search: SitesSitesSearchOutput;
  filter: SitesIndexResults;
  find?: Maybe<SitesIndexRecord>;
  findMany: Array<SitesIndexRecord>;
  /** @deprecated Non-paginated, use `findReferenced2` */
  findReferenced: Array<SitesIndexRecord>;
  findReferenced2: SitesIndexResults;
};

export type SitesSitesSearchArgs = {
  filter: SitesSitesFilterInput;
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesSitesFilterArgs = {
  filter: SitesSitesFilterInput;
  unrestricted?: Maybe<Scalars["Boolean"]>;
  paging?: Maybe<SitesPageInput>;
};

export type SitesSitesFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars["String"];
};

export type SitesSitesFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesSitesFindReferencedArgs = {
  type: Sites_Referenced_By;
  value: Scalars["String"];
};

export type SitesSitesFindReferenced2Args = {
  type: Sites_Referenced_By;
  value: Scalars["String"];
  paging?: Maybe<SitesPageInput>;
};

export type SitesSitesFilterInput = {
  search?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  lookups?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<Scalars["String"]>>;
  orTags?: Maybe<Scalars["Boolean"]>;
  ownership?: Maybe<Array<Scalars["String"]>>;
  active?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  template?: Maybe<Scalars["String"]>;
  referencers?: Maybe<Array<SitesFindManyLookup>>;
};

export type SitesSitesInternal = {
  filter: SitesIndexInternalResults;
  find?: Maybe<SitesIndexInternalRecord>;
  findMany: Array<SitesIndexInternalRecord>;
  findReferenced: SitesIndexInternalResults;
  commonConfigs: Array<SitesCommonConfigRecord>;
};

export type SitesSitesInternalFilterArgs = {
  filter: SitesSitesFilterInput;
  paging?: Maybe<SitesPageInput>;
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesSitesInternalFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars["String"];
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesSitesInternalFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
  unrestricted?: Maybe<Scalars["Boolean"]>;
};

export type SitesSitesInternalFindReferencedArgs = {
  type: Sites_Referenced_By;
  value: Scalars["String"];
  paging?: Maybe<SitesPageInput>;
};

export type SitesSitesInternalCommonConfigsArgs = {
  filter: SitesCommonConfigsInput;
};

export type SitesSitesSearchOutput = {
  count: Scalars["Int"];
  records: Array<SitesIndexRecord>;
};

export type SitesSlackIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
  plugin: SitesSlackIntegrationData;
};

export type SitesSlackIntegrationData = {
  subscriptions: Array<SitesSlackSubscriptionIntegrationData>;
};

export type SitesSlackSubscriptionIntegrationData = {
  channel: Scalars["String"];
  events: Array<Scalars["String"]>;
};

export type SitesSocialCommon = {
  facebookName?: Maybe<Scalars["String"]>;
  facebookSwitch?: Maybe<Scalars["Boolean"]>;
  twitterName?: Maybe<Scalars["String"]>;
  twitterSwitch?: Maybe<Scalars["String"]>;
  instagramName?: Maybe<Scalars["String"]>;
  instagramSwitch?: Maybe<Scalars["String"]>;
  youtubeName?: Maybe<Scalars["String"]>;
  youtubeSwitch?: Maybe<Scalars["String"]>;
  snapchatName?: Maybe<Scalars["String"]>;
  snapchatSwitch?: Maybe<Scalars["String"]>;
  pinterestName?: Maybe<Scalars["String"]>;
  pinterestSwitch?: Maybe<Scalars["String"]>;
  tiktokName?: Maybe<Scalars["String"]>;
  tiktokSwitch?: Maybe<Scalars["String"]>;
  threadsName?: Maybe<Scalars["String"]>;
  threadsSwitch?: Maybe<Scalars["Boolean"]>;
};

export type SitesSocialSection = {
  facebookName?: Maybe<Scalars["String"]>;
  facebookSwitch?: Maybe<Scalars["Boolean"]>;
  instagramName?: Maybe<Scalars["String"]>;
  instagramSwitch?: Maybe<Scalars["Boolean"]>;
  pinterestName?: Maybe<Scalars["String"]>;
  pinterestSwitch?: Maybe<Scalars["Boolean"]>;
  snapchatName?: Maybe<Scalars["String"]>;
  snapchatSwitch?: Maybe<Scalars["Boolean"]>;
  tiktokName?: Maybe<Scalars["String"]>;
  tiktokSwitch?: Maybe<Scalars["Boolean"]>;
  twitterName?: Maybe<Scalars["String"]>;
  twitterSwitch?: Maybe<Scalars["Boolean"]>;
  youtubeName?: Maybe<Scalars["String"]>;
  youtubeSwitch?: Maybe<Scalars["Boolean"]>;
  threadsName?: Maybe<Scalars["String"]>;
  threadsSwitch?: Maybe<Scalars["Boolean"]>;
};

export enum SitesSortField {
  PubStart = "PUB_START",
  PubTouched = "PUB_TOUCHED",
  None = "NONE",
}

export type SitesStreamKeysIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
};

export type SitesSurrogateCacheSettings = {
  maxAge: SitesMaxAges;
  /** Surrogates serve stale content for up to this long while pubsub processes a fresh query */
  staleWhileRevalidate: Scalars["Int"];
  /** Surrogates serve stale content for up to this long if pubsub is in a failure state */
  staleIfError: Scalars["Int"];
};

export type SitesSurrogateCacheSettingsInput = {
  maxAge?: Maybe<SitesMaxAgesInput>;
  /** Surrogates serve stale content for up to this long while pubsub processes a fresh query (Default: 60 seconds) */
  staleWhileRevalidate?: Maybe<Scalars["Int"]>;
  /** Surrogates serve stale content for up to this long if pubsub is in a failure state (Default: 1 day) */
  staleIfError?: Maybe<Scalars["Int"]>;
};

/** What/where targeting information for content */
export type SitesTarget = {
  /** Tags representing categorization of content, used as a secondary filter during query */
  what: Array<Scalars["String"]>;
  /** Tags representing the destination of content (e.g. a site or grouping of sites) */
  where: Array<Scalars["String"]>;
  operator?: Maybe<SitesDistributionOperator>;
};

export type SitesTargetsInput = {
  what: Array<Scalars["String"]>;
  where: Array<Scalars["String"]>;
  operator?: Maybe<SitesDistributionOperator>;
};

export enum SitesTaskStatus {
  Idle = "IDLE",
  Pending = "PENDING",
  Error = "ERROR",
}

export type SitesTemplateCoastConfig = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplateCoastConfigGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplateCoastConfigAlert>;
  navigation?: Maybe<SitesTemplateCoastConfigNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplateCoastConfigDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplateCoastConfigSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplateCoastConfigContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplateCoastConfigPartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplateCoastConfigAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplateCoastConfigAnalytics>;
};

export type SitesTemplateCoastConfigAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars["Boolean"]>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars["Boolean"]>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars["String"]>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars["String"]>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars["String"]>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplateCoastConfigAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars["String"]>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars["Boolean"]>;
  poc_primary_analytics_id?: Maybe<Scalars["String"]>;
  poc_secondary_analytics_id?: Maybe<Scalars["String"]>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars["Boolean"]>;
  parsely_account_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars["String"]>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars["Boolean"]>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars["String"]>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars["String"]>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars["String"]>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars["String"]>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars["String"]>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars["Boolean"]>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars["String"]>;
  /** Physical street address */
  location_address1?: Maybe<Scalars["String"]>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars["String"]>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars["String"]>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars["String"]>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars["String"]>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars["String"]>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars["Boolean"]>;
  contacts?: Maybe<Array<SitesTemplateCoastConfigContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars["Boolean"]>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars["String"]>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars["String"]>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars["String"]>;
  /** Enabling this option will send email notifications to the programming contact when your station receives new Talkbacks. */
  talkback_notification_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitesTemplateCoastConfigContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars["String"]>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars["String"]>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum size 316x200px */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  link_color?: Maybe<Scalars["String"]>;
  /** Background color for the header at the top of the site */
  nav_bg_color?: Maybe<Scalars["String"]>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_text_color?: Maybe<Scalars["String"]>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_ihr_link_color?: Maybe<Scalars["String"]>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars["Boolean"]>;
  /**
   * Image used at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /**
   * Face color at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_color?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars["String"]>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars["String"]>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars["String"]>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<Array<TagRef>>;
  /** Specify content topics for distributing content to this site's homepage */
  default_distribution?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /** Specify this site's primary personalities by selecting their microsite brands.  Used to populate the personality-focused sections of the homepage */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Categories used to specify what types of events should display on the site's events page */
  default_event_categories?: Maybe<Array<TagRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars["String"]>;
  /**
   * The country this site is licensed to or originates from. This information is sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars["String"]>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars["String"]>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplateCoastConfigGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines whether text that cannot otherwise be customized on this site shows in English or Spanish */
  language?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars["String"]>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars["String"]>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars["String"]>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of social and contact links in the site header and footer */
  connect_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables links in the site footer for Public Inspection File and Political file in the footer */
  show_public_file_switch?: Maybe<Scalars["Boolean"]>;
  menu?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateCoastConfigPartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars["Boolean"]>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<SitesCatalogRef>;
  /** Specify the maximum number of tracks that should be shown on the Top Songs page */
  ihr_playlist_max?: Maybe<Scalars["String"]>;
  /**
   * Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_player_playlist?: Maybe<Scalars["Boolean"]>;
  /** Specify whether content shown on this site's iHeart.com profile page opens on iHeart.com (checked) or on the station site (unchecked). */
  ihr_show_in_iheart_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars["Boolean"]>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars["Boolean"]>;
  /** Enable DataSkrive Analytics Token and Script */
  dataskrive_switch?: Maybe<Scalars["Boolean"]>;
  /** The video provider used to render related videos and video advertisements */
  video_monetization_provider?: Maybe<Scalars["String"]>;
  /** Enables the Total Traffic SigAlert widget on this site */
  total_traffic_switch?: Maybe<Scalars["Boolean"]>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars["String"]>;
  /** Zoom level on map, 0 = most zoomed in; 3 = most zoomed out */
  traffic_zoom?: Maybe<Scalars["String"]>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars["Boolean"]>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  alexa_pronouncer?: Maybe<Scalars["String"]>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  google_pronouncer?: Maybe<Scalars["String"]>;
};

export type SitesTemplateCoastConfigSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_api_key?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_app_secret?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's X (Formerly Twitter) handle - no @ symbol */
  twitter_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars["String"]>;
  tiktok_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoMicrosite = {
  /** Supply basic information about this microsite's name/description, CORE show, default content, locale, imagery, and podcast */
  general?: Maybe<SitesTemplateInfernoMicrositeGeneral>;
  /** Manage secondary navigation that appearse at the top of every page within this microsite */
  navigation?: Maybe<SitesTemplateInfernoMicrositeNavigation>;
  /** Specify a long-form biography and contact information for this microsite */
  contact?: Maybe<SitesTemplateInfernoMicrositeContact>;
  /** Connect this microsite with various social networks */
  social?: Maybe<SitesTemplateInfernoMicrositeSocial>;
};

export type SitesTemplateInfernoMicrositeContact = {
  full_bio?: Maybe<Scalars["String"]>;
  /** Dedicated phone number for this show, general station request lines not recommended */
  phone_number?: Maybe<Scalars["String"]>;
  /** Shortcode listeners may use to text the show - legally required disclaimer added automatically */
  txt_number?: Maybe<Scalars["String"]>;
  /** Public e-mail address, visible to end users - for multi-market shows, use an @iheartmedia.com or @iheartradio.com address */
  email_address?: Maybe<Scalars["String"]>;
  /** Enabling this option will send email notifications when your show receives new Talkbacks. */
  talkback_notification_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitesTemplateInfernoMicrositeGeneral = {
  /** The name of the brand, as shown at the top of the page */
  name?: Maybe<Scalars["String"]>;
  /** Used for Google and other search engines and appears at the top of microsite pages if enabled. Recommended length 275-300 characters */
  description?: Maybe<Scalars["String"]>;
  /** Used for SEO purposes.  Also can be used for ad targeting */
  keywords?: Maybe<Array<TagRef>>;
  /**
   * Associate this microsite with one or more CORE shows
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'show-keys')` resolver.
   */
  linked_shows?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics to populate this microsite's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<Array<TagRef>>;
  /** Reserved for future use */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  default_playlists?: Maybe<Array<PlaylistRef>>;
  /** Square image at least 1200x1200px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Upload a 16x9 image, at least 300px tall */
  sixteen_by_nine?: Maybe<MediaAssetRef>;
  /** Shows the SEO description in the header of the microsite */
  header_description?: Maybe<Scalars["Boolean"]>;
  /** Title shown at the top of the editorial feed on the homepage */
  post_block_title?: Maybe<Scalars["String"]>;
  /** Title shown at the top of the Leads-powered promotional/sponsorship block on the homepage */
  static_content_block_title?: Maybe<Scalars["String"]>;
  /** Enables 3rd party recommendation widgets to appear on this site, if also enabled on parent site */
  recommendations?: Maybe<Scalars["Boolean"]>;
  /** Determines whether microsite is visible to end users */
  status?: Maybe<Scalars["String"]>;
  /** Outside of these dates, this microsite will not be accessible */
  active_start?: Maybe<DateRef>;
  active_end?: Maybe<DateRef>;
  password_switch?: Maybe<Scalars["Boolean"]>;
  /** Contact Digital Support to add/remove password protection */
  password?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoMicrositeNavigation = {
  enable_nav_switch?: Maybe<Scalars["Boolean"]>;
  menu?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateInfernoMicrositeSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  /** Used to generate links to facebook - just the part of your facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  /** This microsite's X (Formerly Twitter) handle - no @ symbol */
  twitter_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  /** If at youtube.com/JohnDoe, put "JohnDoe" here - if at youtube.com/channel/JaneSmith, put "channel/JaneSmith" here */
  youtube_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  /** This microsite's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  /** This microsite's Pinterest username */
  pinterest_name?: Maybe<Scalars["String"]>;
  tiktok_switch?: Maybe<Scalars["Boolean"]>;
  /** This microsite's TikTok username */
  tiktok_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  /** This microsite's Snapchat username */
  snapchat_name?: Maybe<Scalars["String"]>;
  threads_switch?: Maybe<Scalars["Boolean"]>;
  /** This brand's Threads handle - no @ symbol */
  threads_name?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSite = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplateInfernoSiteGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplateInfernoSiteAlert>;
  navigation?: Maybe<SitesTemplateInfernoSiteNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplateInfernoSiteDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplateInfernoSiteSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplateInfernoSiteContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplateInfernoSitePartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplateInfernoSiteAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplateInfernoSiteAnalytics>;
};

export type SitesTemplateInfernoSiteAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars["Boolean"]>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars["Boolean"]>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars["String"]>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars["String"]>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars["String"]>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplateInfernoSiteAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars["String"]>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars["Boolean"]>;
  poc_primary_analytics_id?: Maybe<Scalars["String"]>;
  poc_secondary_analytics_id?: Maybe<Scalars["String"]>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars["Boolean"]>;
  parsely_account_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars["String"]>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a block on the contact page with a link to iHeartRadio Help site */
  enable_contest_promotions?: Maybe<Scalars["Boolean"]>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars["Boolean"]>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars["String"]>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars["String"]>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars["String"]>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars["String"]>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars["String"]>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars["Boolean"]>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars["String"]>;
  /** Physical street address */
  location_address1?: Maybe<Scalars["String"]>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars["String"]>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars["String"]>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars["String"]>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars["String"]>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars["String"]>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars["Boolean"]>;
  contacts?: Maybe<Array<SitesTemplateInfernoSiteContactContacts>>;
  /** IP addresses that are blocked from using the contact form.  Contact Digital Support to request an addition. Each address on its own line. */
  email_ip_blacklist?: Maybe<Scalars["String"]>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars["Boolean"]>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars["String"]>;
  /** Location of public file assistance page if not using the iHeartMedia standard text */
  public_file_assistance_link_override?: Maybe<Scalars["String"]>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars["String"]>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars["String"]>;
  /** Enabling this option will send email notifications to the programming contact when your station receives new Talkbacks. */
  talkback_notification_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitesTemplateInfernoSiteContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars["String"]>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars["String"]>;
  /** Informs default color palette used on the site */
  color_scheme?: Maybe<TagRef>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum 600px on the smallest side */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Used on Premiere Network’s Websites: Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum 600px on the smallest side */
  logo_footer?: Maybe<MediaAssetRef>;
  /** Used on Premiere Network’s Websites: Square image at least 1200x1200px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Choose a font style to use for all text across the site */
  font?: Maybe<TagRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  accent_color?: Maybe<Scalars["String"]>;
  /** Used on Premiere Networks Websites */
  header_color?: Maybe<Scalars["String"]>;
  /** Used on Premiere Networks Websites */
  navigation_color?: Maybe<Scalars["String"]>;
  /** Used on Premiere Networks Websites */
  navigation_links_color?: Maybe<Scalars["String"]>;
  /**
   * Displays the stripe at bottom of site with links to listen live
   * @deprecated This has been deprecated in favor of the `ihr_embed_player_bar_switch` resolver.
   */
  player_bar_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  ihr_embed_player_bar_switch?: Maybe<Scalars["Boolean"]>;
  /** Color palette to use on the player bar, if not set will match the site's color scheme */
  ihr_embed_player_bar_stream_theme?: Maybe<Scalars["String"]>;
  ihr_embed_player_bar_stream_override?: Maybe<SitesCatalogRef>;
  /**
   * iHeart Stream ID
   * @deprecated This data has been moved to the `ihr_embed_player_bar_stream_override.id` resolver in this type.
   */
  ihr_embed_player_bar_stream_id?: Maybe<Scalars["String"]>;
  /** @deprecated This data has been moved to the `ihr_embed_player_bar_stream_override.kind` resolver in this type. */
  ihr_embed_player_bar_stream_type?: Maybe<Scalars["String"]>;
  /**
   * E.g. 'Listen Live'. Can be left blank.
   * @deprecated This data has been moved to the `ihr_embed_player_bar_stream_override.kind` resolver in this type.
   */
  ihr_embed_player_bar_cta?: Maybe<Scalars["String"]>;
  /**
   * Image used at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /**
   * Face color at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_color?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars["String"]>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars["String"]>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars["String"]>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<Array<TagRef>>;
  /** Facets used to distribute Content/Leads items to select feeds other than the normal brand/market/genre/format tags already on a site (ex: regional news hub facets) */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /** Specify which iHeartRadio playlists are this sites' defaults */
  default_playlists?: Maybe<Array<PlaylistRef>>;
  /** Specify this site's primary personalities by selecting their microsite brands.  Used to populate the personality-focused sections of the homepage */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Categories used to specify what types of events should display on the site's events page */
  default_event_categories?: Maybe<Array<TagRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars["String"]>;
  /**
   * The country this site is licensed to or originates from. This information is sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars["String"]>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars["String"]>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplateInfernoSiteGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines what language hard-coded template text is displayed in */
  locale?: Maybe<TagRef>;
  /** @deprecated Replaced by `locale`. */
  language?: Maybe<Scalars["String"]>;
  additional_copyright?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars["String"]>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars["String"]>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars["String"]>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars["String"]>;
  /** Text of the FCC Application Notice as provided by the Public File team */
  application_text?: Maybe<Scalars["String"]>;
  /** Link to the FCC application, as provided by the Public File team */
  application_link?: Maybe<Scalars["String"]>;
  /** Location of political file if not hosted on the iHeartMedia system */
  political_link_override?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of contact links in the site header and footer */
  contact_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of the advertise with us links in the site header and footer */
  advertise_switch?: Maybe<Scalars["Boolean"]>;
  /**
   * Enables links in the site footer for Public Inspection File and Political file in the footer
   * @deprecated No longer supported.
   */
  show_public_file_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of a find your station button in the site header of Premiere Networks websites */
  find_your_station_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of downloading the iHeartRadio app and finding podcasts in the site footer for Premiere Networks websites */
  enable_podcasts_links_switch?: Maybe<Scalars["Boolean"]>;
  menu?: Maybe<Array<SitesMenu>>;
  national_promotions?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateInfernoSitePartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars["Boolean"]>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<SitesCatalogRef>;
  /**
   * Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_player_playlist?: Maybe<Scalars["Boolean"]>;
  /** Specify whether content shown on this site's iHeart.com profile page opens on iHeart.com (checked) or on the station site (unchecked). */
  ihr_show_in_iheart_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars["Boolean"]>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars["Boolean"]>;
  /** Enable DataSkrive Analytics Token and Script */
  dataskrive_switch?: Maybe<Scalars["Boolean"]>;
  /** The video provider used to render related videos and video advertisements */
  video_monetization_provider?: Maybe<Scalars["String"]>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars["String"]>;
  /** Authentication provider. SDK-bridge is likely what you want here */
  auth_type?: Maybe<TagRef>;
  /** Enables integration with OneTrust privacy management */
  onetrust_switch?: Maybe<Scalars["Boolean"]>;
  /** Domain-specific identifier provided by OneTrust */
  onetrust_domain_script?: Maybe<Scalars["String"]>;
  /** Cookie category IDs provided by OneTrust, comma separated */
  onetrust_optout_ids?: Maybe<Scalars["String"]>;
  /** State codes, comma separated, or “all” */
  onetrust_optout_geo?: Maybe<Scalars["String"]>;
  /** Limited Service Provider Agreement value from OneTrust, checked = true */
  onetrust_optout_lspa?: Maybe<Scalars["Boolean"]>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars["Boolean"]>;
  /** Campaign ID from Aptivada PromoBuilder for the active keyword contest on this site */
  aptivada_id?: Maybe<Scalars["String"]>;
  /** Allows you to verify that you own this domain */
  google_webmaster_verification?: Maybe<Scalars["String"]>;
};

export type SitesTemplateInfernoSiteSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's X (Formerly Twitter) handle - no @ symbol */
  twitter_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars["String"]>;
  tiktok_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars["String"]>;
  threads_switch?: Maybe<Scalars["Boolean"]>;
  /** This brand's Threads handle - no @ symbol */
  threads_name?: Maybe<Scalars["String"]>;
};

export type SitesTemplateOnairEnrichment = {
  /** Supply basic information about this onair entry's branding */
  general?: Maybe<SitesTemplateOnairEnrichmentGeneral>;
};

export type SitesTemplateOnairEnrichmentGeneral = {
  /** The name of the brand, as shown in the onair entry */
  name?: Maybe<Scalars["String"]>;
  /** A link to an off-network site that this onair entry should direct to */
  link?: Maybe<Scalars["String"]>;
  /** Square image at least 1200x1200px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  default_playlists?: Maybe<Array<PlaylistRef>>;
};

export type SitesTemplatePremiereSite = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplatePremiereSiteGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplatePremiereSiteAlert>;
  navigation?: Maybe<SitesTemplatePremiereSiteNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplatePremiereSiteDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplatePremiereSiteSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplatePremiereSiteContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplatePremiereSitePartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplatePremiereSiteAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplatePremiereSiteAnalytics>;
};

export type SitesTemplatePremiereSiteAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars["Boolean"]>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars["Boolean"]>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars["String"]>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars["String"]>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars["String"]>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplatePremiereSiteAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars["String"]>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars["Boolean"]>;
  poc_primary_analytics_id?: Maybe<Scalars["String"]>;
  poc_secondary_analytics_id?: Maybe<Scalars["String"]>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars["Boolean"]>;
  parsely_account_id?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars["String"]>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars["Boolean"]>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars["String"]>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars["String"]>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars["String"]>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars["String"]>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars["String"]>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars["Boolean"]>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars["String"]>;
  /** Physical street address */
  location_address1?: Maybe<Scalars["String"]>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars["String"]>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars["String"]>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars["String"]>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars["String"]>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars["String"]>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars["Boolean"]>;
  contacts?: Maybe<Array<SitesTemplatePremiereSiteContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars["Boolean"]>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars["String"]>;
  /** Location of public file assistance page if not using the iHeartMedia standard text */
  public_file_assistance_link_override?: Maybe<Scalars["String"]>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars["String"]>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars["String"]>;
  /** Enabling this option will send email notifications to the programming contact when your station receives new Talkbacks. */
  talkback_notification_switch?: Maybe<Scalars["Boolean"]>;
};

export type SitesTemplatePremiereSiteContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars["String"]>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars["String"]>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum size 316x200px */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Used on Premiere Network’s Websites: Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum 600px on the smallest side */
  logo_footer?: Maybe<MediaAssetRef>;
  /** Used on Premiere Network’s Websites: Square image at least 1200x1200px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  link_color?: Maybe<Scalars["String"]>;
  /** Background color for the header at the top of the site */
  nav_bg_color?: Maybe<Scalars["String"]>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_text_color?: Maybe<Scalars["String"]>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_ihr_link_color?: Maybe<Scalars["String"]>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars["Boolean"]>;
  /** Displays the stripe at bottom with the embedded iHeart mini player */
  ihr_embed_player_bar_switch?: Maybe<Scalars["Boolean"]>;
  /** E.g. 'Listen Live'. Can be left blank. */
  ihr_embed_player_bar_cta?: Maybe<Scalars["String"]>;
  /** iHeart Stream ID */
  ihr_embed_player_bar_stream_id?: Maybe<Scalars["String"]>;
  ihr_embed_player_bar_stream_type?: Maybe<Scalars["String"]>;
  ihr_embed_player_bar_stream_theme?: Maybe<Scalars["String"]>;
  /**
   * Image used at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /**
   * Face color at top of iHeart.com station profile. Contact Digital Support for assistance
   * @deprecated Deprecated
   */
  ihr_hero_color?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars["String"]>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars["String"]>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars["String"]>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<Array<TagRef>>;
  /** Facets used to distribute Content/Leads items to select feeds other than the normal brand/market/genre/format tags already on a site (ex: regional news hub facets) */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /** Specify this site's primary personalities by selecting their microsite brands.  Used to populate the personality-focused sections of the homepage */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Categories used to specify what types of events should display on the site's events page */
  default_event_categories?: Maybe<Array<TagRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars["String"]>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars["String"]>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars["String"]>;
  /** Specify which time zone this station is in */
  tz_id?: Maybe<Scalars["String"]>;
  /**
   * The country this site is licensed to or originates from. This information is sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplatePremiereSiteGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines whether text that cannot otherwise be customized on this site shows in English or Spanish */
  language?: Maybe<Scalars["String"]>;
  additional_copyright?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars["String"]>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars["String"]>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars["String"]>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars["String"]>;
  /** Location of political file if not hosted on the iHeartMedia system */
  political_link_override?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of social and contact links in the site header and footer */
  connect_menu_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables links in the site footer for Public Inspection File and Political file in the footer */
  show_public_file_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of a find your station button in the site header of Premiere Networks websites */
  find_your_station_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables the addition of downloading the iHeartRadio app and finding podcasts in the site footer for Premiere Networks websites */
  enable_podcasts_links_switch?: Maybe<Scalars["Boolean"]>;
  menu?: Maybe<Array<SitesMenu>>;
  national_promotions?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplatePremiereSitePartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars["Boolean"]>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<SitesCatalogRef>;
  /** Specify the maximum number of tracks that should be shown on the Top Songs page */
  ihr_playlist_max?: Maybe<Scalars["String"]>;
  /**
   * Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_player_playlist?: Maybe<Scalars["Boolean"]>;
  /** Specify whether content shown on this site's iHeart.com profile page opens on iHeart.com (checked) or on the station site (unchecked). */
  ihr_show_in_iheart_switch?: Maybe<Scalars["Boolean"]>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars["Boolean"]>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars["Boolean"]>;
  /** Enable DataSkrive Analytics Token and Script */
  dataskrive_switch?: Maybe<Scalars["Boolean"]>;
  /** The video provider used to render related videos and video advertisements */
  video_monetization_provider?: Maybe<Scalars["String"]>;
  /** Enables the Total Traffic SigAlert widget on this site */
  total_traffic_switch?: Maybe<Scalars["Boolean"]>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars["String"]>;
  /** Zoom level on map, 0 = most zoomed in; 3 = most zoomed out */
  traffic_zoom?: Maybe<Scalars["String"]>;
  /** Enables integration with OneTrust privacy management */
  onetrust_switch?: Maybe<Scalars["Boolean"]>;
  /** Domain-specific identifier provided by OneTrust */
  onetrust_domain_script?: Maybe<Scalars["String"]>;
  /** Cookie category IDs provided by OneTrust, comma separated */
  onetrust_optout_ids?: Maybe<Scalars["String"]>;
  /** State codes, comma separated, or “all” */
  onetrust_optout_geo?: Maybe<Scalars["String"]>;
  /** Limited Service Provider Agreement value from OneTrust, checked = true */
  onetrust_optout_lspa?: Maybe<Scalars["Boolean"]>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars["Boolean"]>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  alexa_pronouncer?: Maybe<Scalars["String"]>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  google_pronouncer?: Maybe<Scalars["String"]>;
};

export type SitesTemplatePremiereSiteSocial = {
  facebook_switch?: Maybe<Scalars["Boolean"]>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_api_key?: Maybe<Scalars["String"]>;
  /** Required to enable commenting and other plugins */
  facebook_app_secret?: Maybe<Scalars["String"]>;
  twitter_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's X (Formerly Twitter) handle - no @ symbol */
  twitter_name?: Maybe<Scalars["String"]>;
  youtube_switch?: Maybe<Scalars["Boolean"]>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars["String"]>;
  instagram_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars["String"]>;
  snapchat_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars["String"]>;
  pinterest_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars["String"]>;
  tiktok_switch?: Maybe<Scalars["Boolean"]>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars["String"]>;
};

export type SitesUpdateDatasourceOperationInput = {
  setAttributes?: Maybe<SitesDatasourceInput>;
  populate?: Maybe<SitesPopulateInput>;
};

export enum SitesVariableSource {
  Config = "CONFIG",
  Page = "PAGE",
}

export type SitesYoutubeBlock = SitesPageBlock & {
  id: Scalars["String"];
  type: Scalars["String"];
  ordinal: Scalars["Int"];
  region: Scalars["String"];
  value: Scalars["JSON"];
  disabled: Scalars["Boolean"];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars["String"]>>;
  linkType?: Maybe<SitesYoutubeBlockLinkType>;
  handle?: Maybe<Scalars["String"]>;
  default_video_id?: Maybe<Scalars["String"]>;
  playlist?: Maybe<YoutubePlaylistRef>;
  channel?: Maybe<YoutubeChannelRef>;
};

export enum SitesYoutubeBlockLinkType {
  Channel = "CHANNEL",
  Playlist = "PLAYLIST",
}

export type SitesYoutubeIntegration = SitesIntegrationPlugin & {
  type: Scalars["String"];
  data: Scalars["JSON"];
  plugin: SitesYoutubeIntegrationData;
};

export type SitesYoutubeIntegrationData = {
  apiKey: Scalars["String"];
};

export type Sites_Service = {
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars["String"]>;
};

export type SocialMediaLink = {
  link: Scalars["String"];
  name: Scalars["String"];
};

export type SocialRef = {
  facebook?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  google?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  snapchat?: Maybe<Scalars["String"]>;
  tiktok?: Maybe<Scalars["String"]>;
  youtube?: Maybe<Scalars["String"]>;
};

export type Stream = {
  id: Scalars["Int"];
  raw_id: Scalars["String"];
  name: Scalars["String"];
  name_clean?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  esid?: Maybe<Scalars["String"]>;
  fcc_facility_id?: Maybe<Scalars["Float"]>;
  rds?: Maybe<Scalars["String"]>;
  format: Scalars["String"];
  freq?: Maybe<Scalars["String"]>;
  freq_clean?: Maybe<Scalars["String"]>;
  callLetterAlias: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  callLetterRoyalty?: Maybe<Scalars["String"]>;
  band: Scalars["String"];
  callLetters: Scalars["String"];
  streamingPlatform?: Maybe<TagRef>;
  gracenoteEnabled: Scalars["Boolean"];
  recentlyPlayedEnabled: Scalars["Boolean"];
  streams: StreamRef;
  email?: Maybe<Scalars["String"]>;
  cume?: Maybe<Scalars["Float"]>;
  logo: Scalars["String"];
  siriLogo?: Maybe<Scalars["String"]>;
  created: Scalars["Float"];
  modified: Scalars["Float"];
  isActive: Scalars["String"];
  genres: Array<Maybe<GenreRef>>;
  markets: Array<Maybe<MarketRef>>;
  provider: Scalars["String"];
  feeds: FeedRef;
  brand?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  sms?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  parentStation?: Maybe<Scalars["Float"]>;
  pronouncements?: Maybe<Array<Maybe<PronouncementRef>>>;
  sampaPronouncements?: Maybe<Array<PronouncementRef>>;
  social?: Maybe<SocialRef>;
  adswizz?: Maybe<AdswizzRef>;
  adswizz_zones?: Maybe<AdswizzZonesRef>;
  primaryPronouncement?: Maybe<Scalars["String"]>;
  ads: StreamAdsRef;
  streamingSettings?: Maybe<Array<Maybe<Scalars["String"]>>>;
  talkbackEnabled?: Maybe<Scalars["Boolean"]>;
  redirectStream?: Maybe<Scalars["String"]>;
  amp: AmpLiveMeta;
};

export type StreamAdsRef = {
  audioAdProvider?: Maybe<TagRef>;
  enableTritonToken?: Maybe<Scalars["Boolean"]>;
};

export type StreamRef = {
  hls_stream?: Maybe<Scalars["String"]>;
  secure_hls_stream?: Maybe<Scalars["String"]>;
  dash_stream?: Maybe<Scalars["String"]>;
  secure_dash_stream?: Maybe<Scalars["String"]>;
  pls_stream?: Maybe<Scalars["String"]>;
  secure_pls_stream?: Maybe<Scalars["String"]>;
  secure_mp3_pls_stream?: Maybe<Scalars["String"]>;
  flv_stream?: Maybe<Scalars["String"]>;
  abacast_stream?: Maybe<Scalars["String"]>;
  secure_rtmp_stream?: Maybe<Scalars["String"]>;
  rtmp_stream?: Maybe<Scalars["String"]>;
  shoutcast_stream?: Maybe<Scalars["String"]>;
  secure_shoutcast_streams?: Maybe<Scalars["String"]>;
  pivot_hls_stream?: Maybe<Scalars["String"]>;
  stw_stream?: Maybe<Scalars["String"]>;
};

export type StreamSiteQuery = {
  brand?: Maybe<Scalars["String"]>;
};

export type Streams = {
  byId?: Maybe<Stream>;
  byBrand?: Maybe<Stream>;
};

export type StreamsByIdArgs = {
  id: Scalars["Int"];
};

export type StreamsByBrandArgs = {
  brand: Scalars["String"];
};

export type StreamsFunctions = {
  streams: Streams;
  hosts: StreamsHostsFunctions;
};

export type StreamsHostsExportFunctions = {
  ampClients: Array<HostAmpClient>;
  thirdPartyType: Array<HostThirdPartyType>;
};

export type StreamsHostsFunctions = {
  export: StreamsHostsExportFunctions;
};

export type Subscription = {
  tags: Array<Scalars["String"]>;
};

export type SubscriptionInput = {
  tags: Array<Scalars["String"]>;
};

export type Tag = {
  /** @deprecated Field no longer supported */
  batch_id?: Maybe<Scalars["Float"]>;
  memberName: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  ns: Scalars["String"];
  source?: Maybe<TagSource>;
  isNamespace: Scalars["Boolean"];
  member_of?: Maybe<Array<Scalars["String"]>>;
  /** Shorthand for display_name property in tag source property. */
  displayName?: Maybe<Scalars["String"]>;
  /** @deprecated Field no longer supported */
  rawSource?: Maybe<Scalars["JSON"]>;
};

export type TagRef = {
  id: Scalars["ID"];
  taxo?: Maybe<Tag>;
};

export type TagRefTaxoArgs = {
  locale?: Maybe<Scalars["String"]>;
};

export type TagSource = {
  id?: Maybe<Scalars["String"]>;
  source_name?: Maybe<Scalars["String"]>;
  display_name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** A TalkBack record. */
export type TalkBack = {
  _id: Scalars["ID"];
  /** Metadata for the record. */
  _meta: DocumentMetadata;
  /** Station Brand that TalkBack was made on. Also referred to as "Primary Brand". */
  station: SiteRef;
  /** Microsite Brand of personality on the station when TalkBack was made. Also referred to as "Secondary Brand". */
  microsite?: Maybe<SiteRef>;
  /** IHR User Profile. */
  userProfileId: IhrUserRef;
  /** The podcast associated with the TalkBack. */
  podcast?: Maybe<PodcastRef>;
  /** The podcast episode associated with the TalkBack. */
  podcastEpisode?: Maybe<PodcastEpisodeRef>;
  /** Whether the TalkBack is archived or not. Equivalent to a soft delete. */
  isArchived: Scalars["Boolean"];
  /** Returns a url to load the binary of the TalkBack for playback and the transcoding status of the audio file. */
  binaryUrl: TalkBackBinaryUrlResponse;
  /** A list of searchable string annotations (can be "tags") */
  annotations: Array<Scalars["String"]>;
  /** The quality rating of the TalkBack. */
  qualityRating: Scalars["Float"];
  /**
   * Transcribed text of audio from the TalkBack.
   * The charLimit allows you to select a max number of characters from a transcription
   */
  transcription?: Maybe<Scalars["String"]>;
  /** Has a notification been sent to the user about this TalkBack? */
  hasNotificationBeenSent: Scalars["Boolean"];
  /** The data about a notification sent to the user about this TalkBack. */
  userNotification?: Maybe<TalkBackUserNotification>;
  /** Country of Origin of this TalkBack */
  country?: Maybe<Scalars["String"]>;
};

/** A TalkBack record. */
export type TalkBackTranscriptionArgs = {
  maxChars?: Maybe<Scalars["Int"]>;
};

/** Information about a podcast episode in AMP */
export type TalkBackAmpPodcastEpisodeInfo = {
  title?: Maybe<Scalars["String"]>;
};

/** Information about a podcast in AMP */
export type TalkBackAmpPodcastInfo = {
  title: Scalars["String"];
  imageUrl: Scalars["String"];
};

/** The response when looking up the binary url of a TalkBack for playback. */
export type TalkBackBinaryUrlResponse = {
  url?: Maybe<Scalars["String"]>;
  status: TalkBackTranscodeStatus;
};

export type TalkBackCountResponse = {
  count: Scalars["Int"];
};

/** The input for creating a new TalkBack. */
export type TalkBackCreateInput = {
  /** Station Brand the TalkBack was made on. Should be a tag membername string. Also referred to as "Primary Brand". */
  station: Scalars["String"];
  /** Microsite Brand of personality on the station when TalkBack was made. Should be a tag membername string. Also referred to as "Secondary Brand". */
  microsite?: Maybe<Scalars["String"]>;
  /** iHR Profile ID. */
  userProfileId: Scalars["ID"];
  /** An iHR User Session ID to be validated against the provided User Profile ID in AMP. */
  userSessionId?: Maybe<Scalars["ID"]>;
  /** The ID of the podcast associated with the created TalkBack. */
  podcastId?: Maybe<Scalars["ID"]>;
  /** The ID of the podcast episode associated with the created TalkBack. */
  podcastEpisodeId?: Maybe<Scalars["ID"]>;
  /** Transcription text of audio from Talkback */
  transcription?: Maybe<Scalars["String"]>;
  /** Whether to enable transcoding (conversion to MP3) of the TalkBack audio. The affects the uploadUrl returned in the response. Defaults to true. */
  enableTranscode?: Maybe<Scalars["Boolean"]>;
  /** Whether to enable transcription (speech to text) of the TalkBack audio. The affects the uploadUrl returned in the response. Defaults to true. */
  enableTranscribe?: Maybe<Scalars["Boolean"]>;
  /** A list of searchable string annotations (can be "tags") */
  annotations?: Maybe<Array<Scalars["String"]>>;
  /** Country of Origin of this TalkBack */
  country?: Maybe<Scalars["String"]>;
};

/** The result of a TalkBack creation request. */
export type TalkBackCreateOutput = {
  /** The created TalkBack record. */
  talkback: TalkBack;
  /** A url to use to upload the binary audio file of a TalkBack. This audio file will be transcoded into the proper format. */
  uploadUrl: Scalars["String"];
};

/** An IHR user (Not a RadioEdit user). */
export type TalkBackIhrUser = {
  profileId: Scalars["ID"];
  iHeartId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  hometown?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  talkbackScore?: Maybe<Scalars["Float"]>;
  talkbackDate?: Maybe<Scalars["Float"]>;
};

export type TalkBackListArgs = {
  /** A partial text query that will be run against all other fields. */
  query?: Maybe<Scalars["String"]>;
  /** Station Brand that TalkBack was made on. Should be a tag membername string. Also referred to as "Primary Brand". */
  station?: Maybe<Scalars["String"]>;
  /** Microsite Brand of personality on the station when TalkBack was made. Should be a tag membername string. Also referred to as "Secondary Brand". */
  microsite?: Maybe<Scalars["String"]>;
  /** iHR Profile ID. */
  userProfileId?: Maybe<Scalars["String"]>;
  /** The ID of the podcast associated with the TalkBack. */
  podcastId?: Maybe<Scalars["String"]>;
  /** The ID of the podcast episode associated with the TalkBack. */
  podcastEpisodeId?: Maybe<Scalars["String"]>;
  /** The number of results to skip for the specified query. */
  skip?: Maybe<Scalars["Int"]>;
  /** The number of results to return for the specified query. */
  take?: Maybe<Scalars["Int"]>;
  /** An array with 2 numbers, where the number at index 0 is the minimum duration (in seconds) and the number at index 1 is the maximum duration (in seconds), to filter results by their duration. */
  duration?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  /** The start date, inclusive, to filter results to based on their ctime value. */
  startDate?: Maybe<Scalars["Float"]>;
  /** The end date, inclusive, to filter results to based on their ctime value. */
  endDate?: Maybe<Scalars["Float"]>;
  /** The source of the TalkBack. */
  source?: Maybe<TalkBackSource>;
  /** Tags that will be used to make a lookup to the sites api and filter TalkBacks by the brands they are associated with. */
  brandFilterTags?: Maybe<Array<Scalars["String"]>>;
  /** Tags that will be used to make a lookup to the sites api and filter TalkBacks that are associated with these brands. */
  brandExcludeFilterTags?: Maybe<Array<Scalars["String"]>>;
  /** A list of annotation values to search for */
  annotations?: Maybe<Array<Scalars["String"]>>;
  /** Status of the TalkBack. */
  status?: Maybe<Scalars["String"]>;
  /** Whether the TalkBack is archived or not. Equivalent to a soft delete. */
  isArchived?: Maybe<Scalars["Boolean"]>;
  /** Whether to include low quality TalkBacks in the response. */
  includeLowQuality?: Maybe<Scalars["Boolean"]>;
  /** Country of Origin of the TalkBack */
  country?: Maybe<Scalars["String"]>;
};

export type TalkBackListResponse = {
  count: Scalars["Int"];
  items: Array<Maybe<TalkBack>>;
};

export type TalkBackMutation = {
  /** Create a new TalkBack. */
  create?: Maybe<TalkBackCreateOutput>;
  /** Modify an existing TalkBack. */
  update?: Maybe<TalkBack>;
  /** Archive (soft delete) an existing TalkBack. */
  archive?: Maybe<Scalars["Boolean"]>;
  /** Update the annotations on an existing TalkBack. */
  updateAnnotations?: Maybe<TalkBack>;
  /** Set the quality rating of an existing TalkBack. */
  setQualityRating?: Maybe<TalkBack>;
  /** Suspend the user that created a TalkBack indefinitely. New TalkBacks created by this user will be marked as low quality until an admin resets the user's rating. This also marks the TalkBack as Low Quality. */
  suspendUser?: Maybe<TalkBack>;
  /** Reset the user that created a TalkBack's score to neutral. This also marks the TalkBack as Neutral Quality. */
  resetUserScore?: Maybe<TalkBack>;
  /** Send a notification to the TalkBack creator. */
  sendUserNotification?: Maybe<TalkBack>;
};

export type TalkBackMutationCreateArgs = {
  input: TalkBackCreateInput;
};

export type TalkBackMutationUpdateArgs = {
  id: Scalars["ID"];
  input: TalkBackUpdateInput;
};

export type TalkBackMutationArchiveArgs = {
  id: Scalars["ID"];
};

export type TalkBackMutationUpdateAnnotationsArgs = {
  id: Scalars["ID"];
  annotations: Array<Scalars["String"]>;
};

export type TalkBackMutationSetQualityRatingArgs = {
  id: Scalars["ID"];
  rating: Scalars["Float"];
};

export type TalkBackMutationSuspendUserArgs = {
  id: Scalars["ID"];
};

export type TalkBackMutationResetUserScoreArgs = {
  id: Scalars["ID"];
};

export type TalkBackMutationSendUserNotificationArgs = {
  id: Scalars["ID"];
  header: Scalars["String"];
  body: Scalars["String"];
};

export type TalkBackQuery = {
  talkback: TalkBackQueryOps;
};

export type TalkBackQueryOps = {
  /** Get a list of all TalkBacks. */
  list: TalkBackListResponse;
  /** Get a count of all Talkbacks that match the specified query. */
  count: TalkBackCountResponse;
  /** Returns a TalkBack by id. */
  byId?: Maybe<TalkBack>;
  /** Return all annotations used in TalkBacks. */
  listAnnotations: Array<Scalars["String"]>;
};

export type TalkBackQueryOpsListArgs = {
  query?: Maybe<TalkBackListArgs>;
};

export type TalkBackQueryOpsCountArgs = {
  query?: Maybe<TalkBackListArgs>;
};

export type TalkBackQueryOpsByIdArgs = {
  id: Scalars["ID"];
};

export type TalkBackQueryOpsListAnnotationsArgs = {
  query?: Maybe<Scalars["String"]>;
};

/** A lookup for a brand in the RadioEdit sites system */
export type TalkBackSitesLookup = {
  slug: Scalars["String"];
  name: Scalars["String"];
  logo: Scalars["String"];
  stream_name?: Maybe<Scalars["String"]>;
  stream_logo?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
};

export enum TalkBackSource {
  Live = "LIVE",
  Podcast = "PODCAST",
}

/** The current status of an audio transcode process for a TalkBack. */
export enum TalkBackTranscodeStatus {
  InputInformation = "INPUT_INFORMATION",
  Progressing = "PROGRESSING",
  Complete = "COMPLETE",
  Error = "ERROR",
}

/** The input for updating a TalkBack. */
export type TalkBackUpdateInput = {
  /** Station Brand the TalkBack was made on. Should be a tag membername string. Also referred to as "Primary Brand". */
  station?: Maybe<Scalars["String"]>;
  /** Microsite Brand of personality on the station when TalkBack was made. Should be a tag membername string. Also referred to as "Secondary Brand". */
  microsite?: Maybe<Scalars["String"]>;
  /** The ID of the podcast associated with the TalkBack. */
  podcastId?: Maybe<Scalars["ID"]>;
  /** The ID of the podcast episode associated with the TalkBack. */
  podcastEpisodeId?: Maybe<Scalars["ID"]>;
  /** Transcription text of audio from Talkback */
  transcription?: Maybe<Scalars["String"]>;
};

/** A notification sent to a user about a TalkBack. */
export type TalkBackUserNotification = {
  header: Scalars["String"];
  body: Scalars["String"];
  link: Scalars["String"];
  dateSent: Scalars["Float"];
};

export type TaxonomyFunctions = {
  topic?: Maybe<Tag>;
  tag?: Maybe<Tag>;
};

export type TaxonomyFunctionsTopicArgs = {
  topic?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
};

export type TaxonomyFunctionsTagArgs = {
  tag: Scalars["String"];
  locale?: Maybe<Scalars["String"]>;
};

export type TopArtist = {
  artistId: Scalars["Int"];
  rank: Scalars["Int"];
  count: Scalars["Int"];
  tracks: Array<PlayedTrack>;
  artist?: Maybe<Artist>;
};

export type TopTrack = {
  trackId: Scalars["Int"];
  rank: Scalars["Int"];
  count: Scalars["Int"];
  track: PnpTrack;
};

export type TrackHistory = {
  artistId: Scalars["Int"];
  albumId: Scalars["Int"];
  trackId: Scalars["Int"];
  title: Scalars["String"];
  trackDuration?: Maybe<Scalars["Int"]>;
  imagePath?: Maybe<Scalars["String"]>;
  explicitLyrics?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  lyricsId?: Maybe<Scalars["Int"]>;
  startTime: Scalars["Int"];
  endTime: Scalars["Int"];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  trackDurationInSec?: Maybe<Scalars["Int"]>;
  dataSource?: Maybe<Scalars["String"]>;
  artist?: Maybe<Artist>;
  artistName: Scalars["String"];
  albumName: Scalars["String"];
};

/**
 * ObjectDB user annotation, providing additional information about a user attached
 * to a resource.
 */
export type UserAnnotation = {
  _id: Scalars["String"];
  name: Scalars["String"];
};

export type YoutubeChannel = {
  id: Scalars["ID"];
  snippet?: Maybe<YoutubeChannelSnippet>;
  playlists?: Maybe<YoutubePlaylistResults>;
  uploadsPlaylist?: Maybe<YoutubePlaylistRef>;
};

export type YoutubeChannelPlaylistsArgs = {
  paging?: Maybe<PageTokenInput>;
};

export type YoutubeChannelFunctions = {
  channel?: Maybe<YoutubeChannel>;
  playlists?: Maybe<YoutubePlaylistResults>;
  videos?: Maybe<Array<Maybe<YoutubeVideo>>>;
};

export type YoutubeChannelFunctionsPlaylistsArgs = {
  paging?: Maybe<PageTokenInput>;
};

export type YoutubeChannelFunctionsVideosArgs = {
  videoIds: Array<Scalars["String"]>;
};

export type YoutubeChannelRef = {
  id: Scalars["ID"];
  youtube: YoutubeChannelFunctions;
};

export type YoutubeChannelSnippet = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  customUrl?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<DateRef>;
  thumbnails?: Maybe<YoutubeThumbnailMap>;
};

export type YoutubePlaylist = {
  id: Scalars["ID"];
  snippet?: Maybe<YoutubePlaylistSnippet>;
  playlistItems?: Maybe<YoutubePlaylistItemResults>;
};

export type YoutubePlaylistPlaylistItemsArgs = {
  paging?: Maybe<PageTokenInput>;
};

export type YoutubePlaylistFunctions = {
  playlist?: Maybe<YoutubePlaylist>;
  playlistItems?: Maybe<YoutubePlaylistItemResults>;
};

export type YoutubePlaylistFunctionsPlaylistItemsArgs = {
  paging?: Maybe<PageTokenInput>;
};

export type YoutubePlaylistItem = {
  id: Scalars["ID"];
  snippet?: Maybe<YoutubePlaylistItemSnippet>;
};

export type YoutubePlaylistItemResults = {
  count: Scalars["Int"];
  pageInfo: PageInfo;
  items: Array<YoutubePlaylistItem>;
};

export type YoutubePlaylistItemSnippet = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  channelTitle?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<YoutubeThumbnailMap>;
  channelId?: Maybe<YoutubeChannelRef>;
  videoId?: Maybe<YoutubeVideoRef>;
  publishedAt?: Maybe<DateRef>;
  playlistId?: Maybe<YoutubePlaylistRef>;
};

export type YoutubePlaylistRef = {
  id: Scalars["ID"];
  youtube: YoutubePlaylistFunctions;
};

export type YoutubePlaylistResults = {
  count: Scalars["Int"];
  pageInfo: PageInfo;
  items: Array<YoutubePlaylist>;
};

export type YoutubePlaylistSnippet = {
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<YoutubeThumbnailMap>;
  channelId?: Maybe<YoutubeChannelRef>;
  publishedAt?: Maybe<DateRef>;
};

export type YoutubeThumbnail = {
  url: Scalars["String"];
  width: Scalars["Int"];
  height: Scalars["Int"];
};

export type YoutubeThumbnailMap = {
  default?: Maybe<YoutubeThumbnail>;
  medium?: Maybe<YoutubeThumbnail>;
  high?: Maybe<YoutubeThumbnail>;
  standard?: Maybe<YoutubeThumbnail>;
  maxres?: Maybe<YoutubeThumbnail>;
};

export type YoutubeVideo = {
  id: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<YoutubeThumbnailMap>;
};

export type YoutubeVideoRef = {
  id: Scalars["ID"];
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseConfig: ["MicroConfig", "SiteConfig"],
    BaseIndex: ["MicroIndex", "SiteIndex"],
    CatalogAmpTypedResult: [
      "CatalogAmpAlbumTypedResult",
      "CatalogAmpTrackTypedResult",
      "CatalogAmpArtistTypedResult",
      "CatalogAmpPodcastTypedResult",
      "CatalogAmpKeywordTypedResult",
      "CatalogAmpStationTypedResult",
      "CatalogAmpPlaylistTypedResult",
    ],
    CatalogRecord: [
      "CatalogAlbum",
      "CatalogArtist",
      "CatalogTrack",
      "CatalogStation",
      "CatalogPodcast",
      "CatalogPlaylist",
      "CatalogPodcastEpisode",
    ],
    CatalogRecordCommonFields: [
      "CatalogAlbum",
      "CatalogArtist",
      "CatalogPlaylist",
      "CatalogPodcast",
      "CatalogPodcastEpisode",
      "CatalogStation",
      "CatalogTrack",
    ],
    ChartsPagedResult: ["ChartsChartsResults", "ChartsListResults", "ChartsPagedResults"],
    ConfigData: ["MicroData", "SiteData"],
    PWSCoastArticleResponse: ["PWSCoastStandardArticleResponse", "PWSCoastInTheNewsArticleResponse"],
    PWSCoastFeaturedResult: ["PWSCoastFeaturedDefaultPlatform", "PWSCoastFeaturedDesktopPlatform"],
    PWSCoastHostValue: ["PWSCoastHostResponse", "PWSCoastHostPlainValue"],
    PWSCoastSearchResponseValue: ["PWSCoastSearchResponseByCategoryValue", "PWSCoastSearchResponseMergedValue"],
    PWSMediaDeliveryResponse: [
      "PWSMediaDeliveryConfigResponse",
      "PWSMediaDeliveryIsAudioResponse",
      "PWSMediaDeliveryOnAirStatusResponse",
      "PWSMediaDeliveryVideoResponse",
      "PWSMediaDeliveryVideosBySlugsResponse",
      "PWSMediaDeliveryVideosResponse",
    ],
    PWSPagedResult: [
      "PWSCoastArticleListResponse",
      "PWSCoastGuestListResponse",
      "PWSCoastHostListResponse",
      "PWSCoastSearchResponse",
      "PWSCoastShowListResponse",
      "PWSCoastTopicsResponse",
      "PWSPagedResults",
    ],
    PWSZypeTokenResponse: ["PWSZypeRefreshedTokenResponse", "PWSZypeNotRefreshedTokenResponse"],
    PubsubContentLegacyScheduledField: [
      "PubsubContentLegacyScheduledAssetField",
      "PubsubContentLegacyScheduledCatalogField",
      "PubsubContentLegacyScheduledContentField",
      "PubsubContentLegacyScheduledContestField",
      "PubsubContentLegacyScheduledEmbedField",
      "PubsubContentLegacyScheduledGalleryField",
      "PubsubContentLegacyScheduledHTMLField",
    ],
    PubsubContentScheduledField: [
      "PubsubContentScheduledAssetField",
      "PubsubContentScheduledCatalogField",
      "PubsubContentScheduledContentField",
      "PubsubContentScheduledContestField",
      "PubsubContentScheduledEmbedField",
      "PubsubContentScheduledGalleryField",
      "PubsubContentScheduledHTMLField",
      "PubsubContentScheduledPlainField",
    ],
    PubsubPayloadSelection: [
      "PubsubContentAuthorPayloadSelection",
      "PubsubContentCalendarPayloadSelection",
      "PubsubContentCardTestPayloadSelection",
      "PubsubContentCarplayIosHomeSurfacingPayloadSelection",
      "PubsubContentCastawayDestinationPayloadSelection",
      "PubsubContentCastawayPayloadSelection",
      "PubsubContentCatalogListsPayloadSelection",
      "PubsubContentCoastToCoastArticlePayloadSelection",
      "PubsubContentCoastToCoastGuestPayloadSelection",
      "PubsubContentCoastToCoastShowPayloadSelection",
      "PubsubContentHolidayHatPayloadSelection",
      "PubsubContentMultiAuthorTestPayloadSelection",
      "PubsubContentPayloadSelection",
      "PubsubContentPersonalityPayloadSelection",
      "PubsubContentPodcastCollectionsPayloadSelection",
      "PubsubContentPodguidesPayloadSelection",
      "PubsubContentSoundscapesPayloadSelection",
      "PubsubContentTalkbackResponsesPayloadSelection",
      "PubsubContentToolNotificationsPayloadSelection",
      "PubsubContentWazeFeaturedPayloadSelection",
      "PubsubContentWazeItemizedTemplatePayloadSelection",
      "PubsubContentWazeMenuTemplatePayloadSelection",
      "PubsubDefaultPayloadSelection",
    ],
    SitesConfigTemplateUnion: [
      "SitesTemplateCoastConfig",
      "SitesTemplateInfernoMicrosite",
      "SitesTemplateInfernoSite",
      "SitesTemplateOnairEnrichment",
      "SitesTemplatePremiereSite",
    ],
    SitesContentLegacyScheduledField: [
      "SitesContentLegacyScheduledAssetField",
      "SitesContentLegacyScheduledCatalogField",
      "SitesContentLegacyScheduledContentField",
      "SitesContentLegacyScheduledContestField",
      "SitesContentLegacyScheduledEmbedField",
      "SitesContentLegacyScheduledGalleryField",
      "SitesContentLegacyScheduledHTMLField",
    ],
    SitesContentScheduledField: [
      "SitesContentScheduledAssetField",
      "SitesContentScheduledCatalogField",
      "SitesContentScheduledContentField",
      "SitesContentScheduledContestField",
      "SitesContentScheduledEmbedField",
      "SitesContentScheduledGalleryField",
      "SitesContentScheduledHTMLField",
      "SitesContentScheduledPlainField",
    ],
    SitesFeedResult: ["SitesFeedAudienceResult", "SitesFeedLeadsResult", "SitesFeedPWSResult", "SitesFeedPubsubResult"],
    SitesIntegrationPlugin: [
      "SitesAppleNewsIntegration",
      "SitesBroadcastFacilitiesIntegration",
      "SitesShowsKeysIntegration",
      "SitesSlackIntegration",
      "SitesStreamKeysIntegration",
      "SitesYoutubeIntegration",
    ],
    SitesPWSRecord: ["SitesPWSGuest"],
    SitesPageBlock: [
      "SitesBlock",
      "SitesCatalogsBlock",
      "SitesFeedBlock",
      "SitesItemBlock",
      "SitesKeywordContestBlock",
      "SitesPodcastsBlock",
      "SitesShowBlock",
      "SitesYoutubeBlock",
    ],
    SitesPagedResult: [
      "SitesAdPositionResults",
      "SitesConfigInternalResults",
      "SitesConfigOverrideResults",
      "SitesIndexInternalResults",
      "SitesIndexResults",
      "SitesOnAirResults",
      "SitesPageOverrideResults",
      "SitesPagedResults",
    ],
    SitesPayloadSelection: [
      "SitesContentAuthorPayloadSelection",
      "SitesContentCalendarPayloadSelection",
      "SitesContentCardTestPayloadSelection",
      "SitesContentCarplayIosHomeSurfacingPayloadSelection",
      "SitesContentCastawayDestinationPayloadSelection",
      "SitesContentCastawayPayloadSelection",
      "SitesContentCatalogListsPayloadSelection",
      "SitesContentCoastToCoastArticlePayloadSelection",
      "SitesContentCoastToCoastGuestPayloadSelection",
      "SitesContentCoastToCoastShowPayloadSelection",
      "SitesContentHolidayHatPayloadSelection",
      "SitesContentMultiAuthorTestPayloadSelection",
      "SitesContentPayloadSelection",
      "SitesContentPersonalityPayloadSelection",
      "SitesContentPodcastCollectionsPayloadSelection",
      "SitesContentPodguidesPayloadSelection",
      "SitesContentSoundscapesPayloadSelection",
      "SitesContentTalkbackResponsesPayloadSelection",
      "SitesContentToolNotificationsPayloadSelection",
      "SitesContentWazeFeaturedPayloadSelection",
      "SitesContentWazeItemizedTemplatePayloadSelection",
      "SitesContentWazeMenuTemplatePayloadSelection",
      "SitesDefaultPayloadSelection",
    ],
    TrackHistory: ["CombinedPnpTrackHistory", "PnpTrackHistory"],
  },
};
export default result;

export type GetPodcastEpisodesQueryVariables = Exact<{
  id: Scalars["Int"];
  paging?: Maybe<AmpPageInput>;
}>;

export type GetPodcastEpisodesQuery = { amp: { podcast?: Maybe<PodcastFragment> } };

export type PodcastFragment = Pick<
  Podcast,
  "id" | "title" | "description" | "lastUpdated" | "slug" | "isExternal" | "imageUrl"
> & { episodes: PodcastEpisodesFragment };

export type PodcastEpisodesFragment = {
  pageInfo: Pick<AmpPageInfo, "nextCursor">;
  items: Array<PodcastEpisodeFragment>;
};

export type PodcastEpisodeFragment = Pick<
  PodcastEpisode,
  "id" | "podcastId" | "podcastSlug" | "title" | "duration" | "isExplicit" | "description" | "startDate" | "imageUrl"
>;

export type GetCurrentTrackQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetCurrentTrackQuery = {
  sites: { find?: Maybe<{ stream?: Maybe<{ amp: { currentTrack?: Maybe<TrackDetailFragment> } }> }> };
};

export type GetTopSongsQueryVariables = Exact<{
  slug: Scalars["String"];
  paging?: Maybe<AmpPageInput>;
}>;

export type GetTopSongsQuery = {
  sites: { find?: Maybe<{ stream?: Maybe<{ amp: { topTracks: TopTracksFragment } }> }> };
};

export type TopTracksFragment = Pick<LiveMetaTopTracks, "count"> & {
  pageInfo: Pick<AmpPageInfo, "nextCursor">;
  tracks: Array<TopTrackDetailFragment>;
};

export type TopTrackDetailFragment = Pick<TopTrack, "trackId" | "count" | "rank"> & { track: TrackDetailFragment };

export type TrackDetailFragment = Pick<
  PnpTrack,
  | "artistId"
  | "albumId"
  | "trackId"
  | "title"
  | "albumName"
  | "trackDuration"
  | "imagePath"
  | "explicitLyrics"
  | "lyricsId"
> & { artist?: Maybe<ArtistDetailFragment>; playbackRights?: Maybe<Pick<PlaybackRights, "onDemand">> };

export type ArtistDetailFragment = Pick<Artist, "artistName" | "genreId">;

export type GetCurrentlyPlayingSongsQueryVariables = Exact<{
  slug: Scalars["String"];
  paging?: Maybe<AmpPageInput>;
}>;

export type GetCurrentlyPlayingSongsQuery = {
  sites: { find?: Maybe<{ stream?: Maybe<{ amp: { currentlyPlaying: CurrentlyPlayingFragment } }> }> };
};

export type CurrentlyPlayingFragment = Pick<LiveMetaCurrentlyPlaying, "count"> & {
  pageInfo: Pick<AmpPageInfo, "nextCursor">;
  tracks: Array<CurrentlyPlayingTrackDetailFragment>;
};

export type CurrentlyPlayingTrackDetailFragment = Pick<
  CombinedPnpTrackHistory,
  | "artistId"
  | "albumId"
  | "trackId"
  | "title"
  | "albumName"
  | "trackDuration"
  | "imagePath"
  | "explicitLyrics"
  | "lyricsId"
  | "startTime"
  | "endTime"
  | "status"
  | "offset"
  | "trackDurationInSec"
  | "dataSource"
  | "isCurrentlyPlayingTrack"
> & { artist?: Maybe<ArtistDetailFragment>; playbackRights?: Maybe<Pick<PlaybackRights, "onDemand">> };

export type GetContestsQueryVariables = Exact<{
  accountId: Scalars["String"];
}>;

export type GetContestsQuery = { aptivada?: Maybe<{ apps?: Maybe<Array<AptivadaAppFragment>> }> };

export type GetContestQueryVariables = Exact<{
  accountId: Scalars["String"];
  appId: Scalars["String"];
}>;

export type GetContestQuery = { aptivada?: Maybe<{ app?: Maybe<AptivadaAppFragment> }> };

export type GetKeywordContestQueryVariables = Exact<{
  accountId: Scalars["String"];
  appId: Scalars["String"];
}>;

export type GetKeywordContestQuery = { aptivada?: Maybe<{ keyword?: Maybe<AptivadaKeywordAppFragment> }> };

export type AptivadaAppFragment = Pick<
  AptivadaApp,
  | "appType"
  | "appId"
  | "parentAppId"
  | "title"
  | "shareTitle"
  | "tags"
  | "gridStatus"
  | "gridRedirect"
  | "pageUrl"
  | "primaryImage"
  | "mobileImage"
  | "gridImage"
  | "prize"
  | "sort"
  | "startTime"
  | "endTime"
  | "scheduleStatus"
  | "startDate"
  | "endDate"
  | "description"
  | "active"
  | "subtitle"
  | "type"
  | "displayImage"
  | "accountId"
  | "facebookShareImage"
  | "width"
  | "shareLocation"
  | "timeZone"
>;

export type AptivadaKeywordAppFragment = Pick<AptivadaKeywordApp, "appType" | "appId" | "title"> & {
  currentKeyword: { previous: KeywordDetailsFragment; current: KeywordDetailsFragment; next: KeywordDetailsFragment };
  submittableKeyword?: Maybe<{
    previous: KeywordDetailsFragment;
    current: KeywordDetailsFragment;
    next: KeywordDetailsFragment;
  }>;
};

export type KeywordDetailsFragment = Pick<
  KeywordDetails,
  | "sort"
  | "timeStamp"
  | "endTime"
  | "isComplete"
  | "isLive"
  | "isStarted"
  | "isSubmittable"
  | "active"
  | "title"
  | "countdownKeywordId"
  | "prize"
>;

export type GetEventCategoriesQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetEventCategoriesQuery = { sites: { find?: Maybe<Pick<SitesIndexRecord, "eventCategories">> } };

export type GetEventsQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
  category?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
}>;

export type GetEventsQuery = { sites: { find?: Maybe<{ events: Array<CalendarEventFragment> }> } };

export type GetOccurrenceQueryVariables = Exact<{
  occurrenceId?: Maybe<Scalars["String"]>;
}>;

export type GetOccurrenceQuery = { calendar?: Maybe<{ occurrence?: Maybe<CalendarEventFragment> }> };

export type CalendarEventFragment = Pick<
  CalendarEvent,
  "id" | "eventId" | "origins" | "startDateTime" | "endDateTime" | "tags" | "venueName" | "venueAddress"
> & {
  content: Pick<
    CalendarContentRef,
    "id" | "img" | "title" | "slug" | "summary" | "author" | "publish_date" | "publish_origin"
  > & {
    record?: Maybe<{
      payload: Pick<ContentPayload, "blocks" | "canonical_url">;
      eyebrow?: Maybe<Pick<Eyebrow, "topic" | "topic_name">>;
      subscription: Array<Pick<Subscription, "tags">>;
    }>;
  };
};

export type GetChartsForSitemapQueryVariables = Exact<{
  brand: Scalars["String"];
  listsSize: Scalars["Int"];
}>;

export type GetChartsForSitemapQuery = {
  charts: {
    charts: {
      filter: {
        items: Array<
          Pick<ChartsChart, "slug"> & {
            lists: { items: Array<Pick<ChartsList, "slug"> & { published: Pick<DateRef, "timestampMs"> }> };
          }
        >;
      };
    };
  };
};

export type GetChartV2QueryVariables = Exact<{
  slug: Scalars["String"];
  listsSize?: Maybe<Scalars["Int"]>;
  listsStartDate?: Maybe<Scalars["Float"]>;
  listsEndDate?: Maybe<Scalars["Float"]>;
  listsCursor?: Maybe<Scalars["String"]>;
  includeDates?: Maybe<Scalars["Boolean"]>;
  includeLists?: Maybe<Scalars["Boolean"]>;
}>;

export type GetChartV2Query = { charts: { charts: { bySlug?: Maybe<ChartV2Fragment> } } };

export type GetChartListsPartialsQueryVariables = Exact<{
  chartSlug?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["Float"]>;
  cursor?: Maybe<Scalars["String"]>;
}>;

export type GetChartListsPartialsQuery = { charts: { lists: { filter: ChartListPartialResultsFragment } } };

export type GetChartListQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetChartListQuery = { charts: { lists: { bySlug?: Maybe<ChartsListFragment> } } };

export type ChartV2Fragment = Pick<ChartsChart, "slug" | "id" | "title" | "subtitle"> &
  ChartDatesFunctionFragment &
  ChartListsPartialsFunctionFragment;

export type ChartListsPartialsFunctionFragment = { lists: ChartListPartialResultsFragment };

export type ChartListPartialResultsFragment = {
  items: Array<ChartListPartialFragment>;
  pageInfo: Pick<ChartsPageInfo, "nextCursor">;
};

export type ChartListPartialFragment = Pick<ChartsList, "topItems" | "slug" | "title" | "image">;

export type ChartDatesFunctionFragment = { dates?: Maybe<ChartDatesListFragment> };

export type ChartDatesListFragment = { months: Array<ChartYearMonthFragment> };

export type ChartYearMonthFragment = Pick<ChartsChartYearMonth, "year" | "months">;

export type ChartsListFragment = Pick<ChartsList, "topItems" | "slug" | "image" | "title"> & {
  songs: Array<ChartListSongFragment>;
};

export type ChartListSongFragment = Pick<
  ChartsChartListSong,
  "artist" | "delta" | "image" | "peak" | "pos" | "timeOnChart" | "title" | "url"
>;

export type GetSiteByQueryQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
  lookup: Scalars["String"];
}>;

export type GetSiteByQueryQuery = { sites: { find?: Maybe<SiteConfigFragment> } };

export type GetContactEmailsQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
  lookup: Scalars["String"];
}>;

export type GetContactEmailsQuery = {
  sites: {
    find?: Maybe<{
      configByLookup?: Maybe<{
        sections?: Maybe<{
          contact?: Maybe<{ contacts?: Maybe<Array<Pick<SitesTemplateInfernoSiteContactContacts, "type" | "email">>> }>;
        }>;
      }>;
    }>;
  };
};

export type SiteConfigFragment = Pick<
  SitesIndexRecord,
  "_id" | "canonicalHostname" | "hostnames" | "facets" | "slug" | "market" | "ownBrand" | "timeZone" | "keys"
> & {
  stream?: Maybe<Pick<Stream, "id" | "primaryPronouncement" | "recentlyPlayedEnabled" | "isActive">>;
  ownership?: Maybe<Pick<TagRef, "id"> & { taxo?: Maybe<{ source?: Maybe<Pick<TagSource, "display_name">> }> }>;
  summary: Pick<SitesIndexSummary, "name" | "description">;
  status: Pick<SitesSiteStatus, "isHidden" | "isActive"> & {
    hide?: Maybe<Pick<SitesSiteStatusHide, "startDate" | "endDate" | "redirectTo">>;
  };
  configByLookup?: Maybe<
    Pick<SitesConfigRecord, "_id"> & {
      adPositions: Array<
        Pick<SitesAdPositionRecord, "_id" | "positionId" | "template" | "adSplit" | "forceLoad"> & {
          breakpoints: Array<Pick<SitesAdBreakpoint, "breakpoint"> & { sizes: Array<Pick<SitesAdSize, "w" | "h">> }>;
        }
      >;
      routes: Array<Pick<SitesPageRoute, "name" | "path">>;
      redirects: Array<
        Pick<
          SitesRedirect,
          "id" | "priority" | "source" | "regex" | "disabled" | "permanent" | "matchQueryString" | "queryParamsHandling"
        > & {
          schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">>;
          destination: Pick<SitesRedirectDest, "type" | "value">;
        }
      >;
      searchOverrides: Array<
        Pick<SitesSearchOverride, "id" | "keywords" | "disabled"> & {
          destination: Pick<SitesRedirectDest, "type" | "value">;
          schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">>;
        }
      >;
      sections?: Maybe<UserConfigFragment>;
    }
  >;
  integration?: Maybe<BroadcastFacilitiesIntegrationFragment>;
  liveConfig?: Maybe<{ sections?: Maybe<StreamOverrideConfigFragment> }>;
};

export type UserConfigFragment = {
  general?: Maybe<
    Pick<
      SitesTemplateInfernoSiteGeneral,
      | "name"
      | "positioner"
      | "description"
      | "weather_market"
      | "geo_latitude"
      | "geo_longitude"
      | "additional_copyright"
    > & {
      keywords?: Maybe<Array<Pick<TagRef, "id">>>;
      default_content?: Maybe<Array<Pick<TagRef, "id">>>;
      additional_distributions?: Maybe<Array<Pick<TagRef, "id">>>;
      default_podcasts?: Maybe<Array<Pick<PodcastRef, "id">>>;
      default_shows?: Maybe<
        Array<
          Pick<SiteRef, "id"> & {
            sites?: Maybe<
              Pick<SitesIndexRecord, "_id" | "slug" | "ownBrand"> & {
                configByLookup?: Maybe<{ common?: Maybe<{ branding?: Maybe<Pick<SitesBrandingCommon, "name">> }> }>;
              }
            >;
          }
        >
      >;
      default_playlists?: Maybe<Array<Pick<PlaylistRef, "id">>>;
      default_event_categories?: Maybe<Array<CategoryTagFragment>>;
      country?: Maybe<Pick<TagRef, "id">>;
      locale?: Maybe<{ taxo?: Maybe<Pick<Tag, "name">> }>;
    }
  >;
  alert?: Maybe<InfernoSiteAlertFragment>;
  navigation?: Maybe<
    Pick<
      SitesTemplateInfernoSiteNavigation,
      | "contact_menu_switch"
      | "listen_live_switch"
      | "contest_nav_switch"
      | "search_switch"
      | "user_menu_switch"
      | "show_public_file_switch"
      | "advertise_switch"
      | "find_your_station_switch"
      | "enable_podcasts_links_switch"
    > & {
      national_promotions?: Maybe<Array<Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref">>>;
      menu?: Maybe<
        Array<
          Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref"> & {
            children?: Maybe<Array<Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref">>>;
          }
        >
      >;
      menu_footer?: Maybe<
        Array<
          Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref"> & {
            children?: Maybe<Array<Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref">>>;
          }
        >
      >;
    }
  >;
  design?: Maybe<
    Pick<
      SitesTemplateInfernoSiteDesign,
      | "theme"
      | "accent_color"
      | "header_color"
      | "navigation_color"
      | "navigation_links_color"
      | "ihr_embed_player_bar_switch"
      | "ihr_embed_player_bar_stream_theme"
    > & {
      color_scheme?: Maybe<Pick<TagRef, "id">>;
      logo_best_res?: Maybe<
        Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
      >;
      station_logo?: Maybe<
        Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
      >;
      logo_favicon?: Maybe<
        Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
      >;
      font?: Maybe<Pick<TagRef, "id">>;
      ihr_embed_player_bar_stream_override?: Maybe<Pick<SitesCatalogRef, "id" | "kind">>;
      logo_footer?: Maybe<
        Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
      >;
      thumbnail_image?: Maybe<
        Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
      >;
    }
  >;
  social?: Maybe<SocialFragment>;
  contact?: Maybe<ContactFragment>;
  partners?: Maybe<
    Pick<
      SitesTemplateInfernoSitePartners,
      | "iheartradio_switch"
      | "ihr_show_in_iheart_switch"
      | "video_monetization_provider"
      | "postup_switch"
      | "dataskrive_switch"
      | "outbrain_switch"
      | "traffic_market"
      | "aptivada_switch"
      | "aptivada_id"
      | "google_webmaster_verification"
    > & { email_format?: Maybe<Pick<TagRef, "id">>; auth_type?: Maybe<{ taxo?: Maybe<Pick<Tag, "name">> }> }
  >;
  ads?: Maybe<
    Pick<
      SitesTemplateInfernoSiteAds,
      "dfp_switch" | "header_bidding" | "facebook_ad_track_pixel_switch" | "facebook_ad_track_pixel_id"
    >
  >;
  analytics?: Maybe<Pick<SitesTemplateInfernoSiteAnalytics, "adobe_library" | "parsely_switch" | "parsely_account_id">>;
};

export type StreamOverrideConfigFragment = {
  design?: Maybe<{ ihr_embed_player_bar_stream_override?: Maybe<{ record?: Maybe<StationFragment> }> }>;
};

export type StationFragment = { amp?: Maybe<Pick<CatalogAmpStation, "isActive">> };

export type CategoryTagFragment = Pick<TagRef, "id"> & { taxo?: Maybe<Pick<Tag, "memberName" | "displayName">> };

export type InfernoSiteAlertFragment = Pick<SitesTemplateInfernoSiteAlert, "title" | "link" | "target"> & {
  start_date?: Maybe<Pick<DateRef, "timestampMs">>;
  end_date?: Maybe<Pick<DateRef, "timestampMs">>;
};

export type ContactFragment = Pick<
  SitesTemplateInfernoSiteContact,
  | "page_intro"
  | "show_social_switch"
  | "phone_text_numbers_switch"
  | "request_phone_number"
  | "business_phone_number"
  | "traffic_tip_phone"
  | "newsroom_phone"
  | "sms_number"
  | "prize_pickup_directions_switch"
  | "prize_pickup_info"
  | "location_address1"
  | "location_address2"
  | "location_city"
  | "location_state"
  | "location_zip"
  | "office_hours"
  | "email_form_switch"
  | "employment_info_switch"
  | "employment_link_override"
  | "public_file_assistance_link_override"
  | "email_ip_blacklist"
  | "enable_contest_promotions"
> & {
  contacts?: Maybe<Array<Pick<SitesTemplateInfernoSiteContactContacts, "type">>>;
  eeo_report_asset?: Maybe<Pick<MediaAssetRef, "id">>;
};

export type SocialFragment = Pick<
  SitesTemplateInfernoSiteSocial,
  | "facebook_switch"
  | "facebook_name"
  | "facebook_app_id"
  | "facebook_fan_page_id"
  | "twitter_switch"
  | "twitter_name"
  | "youtube_switch"
  | "youtube_name"
  | "instagram_switch"
  | "instagram_name"
  | "snapchat_switch"
  | "snapchat_name"
  | "pinterest_switch"
  | "pinterest_name"
  | "tiktok_switch"
  | "tiktok_name"
>;

export type BroadcastFacilitiesIntegrationFragment = Pick<SitesBroadcastFacilitiesIntegration, "type"> & {
  plugin: { broadcastFacilities: Array<BroadcastFacilitiesFragment> };
};

export type BroadcastFacilitiesFragment = Pick<
  SitesBroadcastFacility,
  "facilityId" | "broadcastFrequency" | "broadcastBand" | "broadcastCallLetters" | "politicalLinkOverride"
> & { applicationNotices: Array<ApplicationNoticesFragment> };

export type ApplicationNoticesFragment = Pick<SitesBroadcastFacilityApplicationNotice, "text" | "link">;

export type GetSiteChildrenQueryVariables = Exact<{
  children: Array<SitesFindManyLookup> | SitesFindManyLookup;
}>;

export type GetSiteChildrenQuery = { sites: { findMany: Array<SiteChildRefFragment> } };

export type SiteChildRefFragment = Pick<SitesIndexRecord, "_id" | "slug" | "ownBrand"> & {
  summary: Pick<SitesIndexSummary, "name" | "image">;
  status: Pick<SitesSiteStatus, "isActive">;
};

export type GetMicrositeByQueryQueryVariables = Exact<{
  slug: Scalars["String"];
  micrositeSlug: Scalars["String"];
  lookup: Scalars["String"];
}>;

export type GetMicrositeByQueryQuery = {
  sites: {
    find?: Maybe<{
      inboundRef?: Maybe<
        Pick<SitesIndexRecord, "_id" | "slug" | "ownBrand" | "timeZone" | "keys"> & {
          summary: Pick<SitesIndexSummary, "name">;
          status: Pick<SitesSiteStatus, "isHidden" | "isActive"> & {
            hide?: Maybe<Pick<SitesSiteStatusHide, "startDate" | "endDate" | "redirectTo">>;
          };
          configByLookup?: Maybe<
            Pick<SitesConfigRecord, "_id"> & {
              routes: Array<Pick<SitesPageRoute, "name" | "path">>;
              sections?: Maybe<{
                general?: Maybe<MicrositeGeneralConfigFragment>;
                navigation?: Maybe<
                  Pick<SitesTemplateInfernoMicrositeNavigation, "enable_nav_switch"> & {
                    menu?: Maybe<
                      Array<
                        Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref"> & {
                          children?: Maybe<Array<Pick<SitesMenu, "id" | "type" | "label" | "target" | "ref">>>;
                        }
                      >
                    >;
                  }
                >;
                contact?: Maybe<
                  Pick<
                    SitesTemplateInfernoMicrositeContact,
                    "full_bio" | "phone_number" | "txt_number" | "email_address"
                  >
                >;
                social?: Maybe<MicrositeSocialConfigFragment>;
              }>;
            }
          >;
          inboundKeys?: Maybe<Pick<SitesIndexRecord, "slug" | "canonicalHostname">>;
        }
      >;
    }>;
  };
};

export type MicrositeSocialConfigFragment = Pick<
  SitesTemplateInfernoMicrositeSocial,
  | "facebook_switch"
  | "facebook_name"
  | "twitter_switch"
  | "twitter_name"
  | "youtube_switch"
  | "youtube_name"
  | "instagram_switch"
  | "instagram_name"
  | "snapchat_switch"
  | "snapchat_name"
  | "pinterest_switch"
  | "pinterest_name"
  | "tiktok_switch"
  | "tiktok_name"
>;

export type MicrositeGeneralConfigFragment = Pick<
  SitesTemplateInfernoMicrositeGeneral,
  "name" | "description" | "recommendations" | "status" | "password_switch" | "password" | "header_description"
> & {
  keywords?: Maybe<Array<Pick<TagRef, "id">>>;
  default_content?: Maybe<
    Array<
      Pick<TagRef, "id"> & {
        taxo?: Maybe<
          Pick<Tag, "memberName" | "name" | "ns" | "displayName"> & {
            source?: Maybe<Pick<TagSource, "display_name" | "description" | "type">>;
          }
        >;
      }
    >
  >;
  additional_distributions?: Maybe<Array<Pick<TagRef, "id">>>;
  default_podcasts?: Maybe<Array<Pick<PodcastRef, "id">>>;
  default_playlists?: Maybe<Array<Pick<PlaylistRef, "id">>>;
  thumbnail_image?: Maybe<
    Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
  >;
  sixteen_by_nine?: Maybe<
    Pick<MediaAssetRef, "id"> & { asset?: Maybe<Pick<MediaAsset, "bucket" | "resourceId" | "href">> }
  >;
  active_start?: Maybe<Pick<DateRef, "timestampMs" | "timestamp">>;
  active_end?: Maybe<Pick<DateRef, "timestampMs" | "timestamp">>;
};

export type GetSlugByBrandTagQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
}>;

export type GetSlugByBrandTagQuery = { sites: { find?: Maybe<Pick<SitesIndexRecord, "_id" | "slug">> } };

export type GetNavigationMicrositesQueryVariables = Exact<{
  lookups: Array<SitesFindManyLookup> | SitesFindManyLookup;
  lookup: Scalars["String"];
}>;

export type GetNavigationMicrositesQuery = { sites: { findMany: Array<NavigationMicrositeFragment> } };

export type NavigationMicrositeFragment = Pick<SitesIndexRecord, "_id" | "slug" | "ownBrand"> & {
  configByLookup?: Maybe<{ common?: Maybe<{ branding?: Maybe<Pick<SitesBrandingCommon, "name">> }> }>;
};

export type GetSlugFromHostnameQueryVariables = Exact<{
  hostname: Scalars["String"];
}>;

export type GetSlugFromHostnameQuery = { sites: { find?: Maybe<Pick<SitesIndexRecord, "slug">> } };

export type GetTimelineSitesQueryVariables = Exact<{
  active: Scalars["Boolean"];
  take: Scalars["Int"];
}>;

export type GetTimelineSitesQuery = {
  sites: { filter: { results: Array<Pick<SitesIndexRecord, "slug" | "ownBrand" | "canonicalHostname">> } };
};

export type GetHistoricalContentQueryVariables = Exact<{
  query: PubsubQueryInput;
}>;

export type GetHistoricalContentQuery = {
  pubsub: {
    query?: Maybe<{
      items: Array<Pick<PubsubPublishRecord, "slug" | "pub_start">>;
      page: Pick<PubsubPageInfo, "nextToken">;
    }>;
  };
};

export type GetOnAirNowQueryVariables = Exact<{
  slug: Scalars["String"];
  currentDate?: Maybe<Scalars["Float"]>;
}>;

export type GetOnAirNowQuery = { sites: { find?: Maybe<{ onAirSchedule: OnAirScheduleFragment }> } };

export type OnAirScheduleFragment = { current?: Maybe<OnAirShowFragment>; upcoming: Array<OnAirShowFragment> };

export type GetOnAirNowByDayQueryVariables = Exact<{
  day: Sites_Onair_Day;
  slug: Scalars["String"];
  currentDate?: Maybe<Scalars["Float"]>;
}>;

export type GetOnAirNowByDayQuery = {
  sites: {
    find?: Maybe<
      Pick<SitesIndexRecord, "timeZone"> & {
        onAirSchedule: OnAirScheduleFragment;
        onAirScheduleForDay: { schedule: Array<OnAirShowFragment> };
      }
    >;
  };
};

export type OnAirShowFragment = Pick<
  SitesOnAirExtended,
  | "siteId"
  | "site"
  | "coreShowId"
  | "coreStationId"
  | "name"
  | "show"
  | "station"
  | "link"
  | "onNow"
  | "startTime12"
  | "startDate"
  | "startMs"
  | "startEng"
  | "stopTime12"
  | "stopDate"
  | "stopMs"
  | "stopEng"
> & {
  showSite?: Maybe<
    Pick<SitesIndexRecord, "slug"> & {
      configByLookup?: Maybe<{
        sections?: Maybe<{
          general?: Maybe<
            Pick<SitesTemplateInfernoMicrositeGeneral, "name"> & {
              thumbnail_image?: Maybe<{ asset?: Maybe<Pick<MediaAsset, "href">> }>;
            }
          >;
        }>;
      }>;
    }
  >;
};

export type GetPageQueryVariables = Exact<{
  name: Scalars["String"];
  slug: Scalars["String"];
  lookup: Scalars["String"];
  context: Scalars["JSON"];
  scopes?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  from?: Maybe<Scalars["String"]>;
  paging?: Maybe<PageTokenInput>;
}>;

export type GetPageQuery = {
  sites: {
    find?: Maybe<
      Pick<SitesIndexRecord, "_id"> & {
        configByLookup?: Maybe<Pick<SitesConfigRecord, "_id"> & { page?: Maybe<PageFragment> }>;
      }
    >;
  };
};

export type PageFragment = Pick<
  SitesPageRecord,
  "_id" | "name" | "pageConfig" | "displayName" | "path" | "primaryBlockId" | "layoutId" | "parentId"
> & {
  tags: Array<Pick<TagRef, "id">>;
  blocks: Array<
    | Block_SitesBlock_Fragment
    | Block_SitesCatalogsBlock_Fragment
    | Block_SitesFeedBlock_Fragment
    | Block_SitesItemBlock_Fragment
    | Block_SitesKeywordContestBlock_Fragment
    | Block_SitesPodcastsBlock_Fragment
    | Block_SitesShowBlock_Fragment
    | Block_SitesYoutubeBlock_Fragment
  >;
};

export type Block_SitesBlock_Fragment = Pick<SitesBlock, "id" | "region" | "type" | "ordinal" | "value" | "tags"> & {
  schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">>;
};

export type Block_SitesCatalogsBlock_Fragment = Pick<
  SitesCatalogsBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & CatalogFragment;

export type Block_SitesFeedBlock_Fragment = Pick<
  SitesFeedBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & FeedFragment;

export type Block_SitesItemBlock_Fragment = Pick<
  SitesItemBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & ItemFragment;

export type Block_SitesKeywordContestBlock_Fragment = Pick<
  SitesKeywordContestBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & KeywordContestFragment;

export type Block_SitesPodcastsBlock_Fragment = Pick<
  SitesPodcastsBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & PodcastsFragment;

export type Block_SitesShowBlock_Fragment = Pick<
  SitesShowBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & ShowsFragment;

export type Block_SitesYoutubeBlock_Fragment = Pick<
  SitesYoutubeBlock,
  "id" | "region" | "type" | "ordinal" | "value" | "tags"
> & { schedule?: Maybe<Pick<SitesSchedule, "begin" | "end">> } & YoutubeFragment;

export type BlockFragment =
  | Block_SitesBlock_Fragment
  | Block_SitesCatalogsBlock_Fragment
  | Block_SitesFeedBlock_Fragment
  | Block_SitesItemBlock_Fragment
  | Block_SitesKeywordContestBlock_Fragment
  | Block_SitesPodcastsBlock_Fragment
  | Block_SitesShowBlock_Fragment
  | Block_SitesYoutubeBlock_Fragment;

export type YoutubeFragment = Pick<SitesYoutubeBlock, "default_video_id"> & {
  playlist?: Maybe<Pick<YoutubePlaylistRef, "id"> & { youtube: PlaylistFunctionFragment }>;
  channel?: Maybe<Pick<YoutubeChannelRef, "id"> & { youtube: ChannelFunctionFragment }>;
};

export type PlaylistFunctionFragment = {
  playlist?: Maybe<
    Pick<YoutubePlaylist, "id"> & {
      snippet?: Maybe<PlaylistSnippetFragment>;
      playlistItems?: Maybe<PlaylistItemResultsFragment>;
    }
  >;
};

export type ChannelFunctionFragment = {
  channel?: Maybe<
    Pick<YoutubeChannel, "id"> & {
      snippet?: Maybe<
        Pick<YoutubeChannelSnippet, "title" | "description" | "customUrl"> & {
          publishedAt?: Maybe<Pick<DateRef, "timestampMs" | "timestamp" | "iso8601">>;
          thumbnails?: Maybe<ThumbnailMapFragment>;
        }
      >;
      playlists?: Maybe<PlaylistResultsFragment>;
      uploadsPlaylist?: Maybe<Pick<YoutubePlaylistRef, "id"> & { youtube: PlaylistFunctionFragment }>;
    }
  >;
};

export type PlaylistItemResultsFragment = Pick<YoutubePlaylistItemResults, "count"> & {
  pageInfo: PageInfoFragment;
  items: Array<Pick<YoutubePlaylistItem, "id"> & { snippet?: Maybe<PlaylistItemSnippetFragment> }>;
};

export type PlaylistItemSnippetFragment = Pick<
  YoutubePlaylistItemSnippet,
  "title" | "description" | "position" | "channelTitle"
> & {
  thumbnails?: Maybe<ThumbnailMapFragment>;
  channelId?: Maybe<Pick<YoutubeChannelRef, "id">>;
  publishedAt?: Maybe<Pick<DateRef, "timestampMs" | "timestamp" | "iso8601">>;
  videoId?: Maybe<Pick<YoutubeVideoRef, "id">>;
  playlistId?: Maybe<Pick<YoutubePlaylistRef, "id">>;
};

export type PlaylistSnippetFragment = Pick<YoutubePlaylistSnippet, "title" | "description"> & {
  thumbnails?: Maybe<ThumbnailMapFragment>;
  channelId?: Maybe<Pick<YoutubeChannelRef, "id">>;
  publishedAt?: Maybe<Pick<DateRef, "timestampMs" | "timestamp" | "iso8601">>;
};

export type PlaylistResultsFragment = Pick<YoutubePlaylistResults, "count"> & {
  pageInfo: PageInfoFragment;
  items: Array<
    Pick<YoutubePlaylist, "id"> & {
      snippet?: Maybe<PlaylistSnippetFragment>;
      playlistItems?: Maybe<PlaylistItemResultsFragment>;
    }
  >;
};

export type PageInfoFragment = Pick<PageInfo, "prevToken" | "nextToken" | "itemTokens">;

export type ThumbnailMapFragment = {
  default?: Maybe<ThumbnailFragment>;
  medium?: Maybe<ThumbnailFragment>;
  high?: Maybe<ThumbnailFragment>;
  standard?: Maybe<ThumbnailFragment>;
  maxres?: Maybe<ThumbnailFragment>;
};

export type ThumbnailFragment = Pick<YoutubeThumbnail, "url" | "width" | "height">;

export type FeedFragment = { feed?: Maybe<FeedResponseFragment> };

export type FeedResponseFragment = {
  results: Array<
    | FeedResult_SitesFeedAudienceResult_Fragment
    | FeedResult_SitesFeedLeadsResult_Fragment
    | FeedResult_SitesFeedPwsResult_Fragment
    | FeedResult_SitesFeedPubsubResult_Fragment
  >;
  resume?: Maybe<Pick<SitesFeedResume, "id" | "context" | "size" | "from" | "scopes">>;
};

export type FeedResult_SitesFeedAudienceResult_Fragment = Pick<SitesFeedAudienceResult, "id" | "type"> & {
  record: AudienceRecordFragment;
};

export type FeedResult_SitesFeedLeadsResult_Fragment = Pick<SitesFeedLeadsResult, "id" | "type"> & {
  record: LeadsCardFragment;
};

export type FeedResult_SitesFeedPwsResult_Fragment = Pick<SitesFeedPwsResult, "id" | "type"> & {
  record: PwsGuestRecordFragment;
};

export type FeedResult_SitesFeedPubsubResult_Fragment = Pick<SitesFeedPubsubResult, "id" | "type"> & {
  record: PublishRecordFragment;
};

export type FeedResultFragment =
  | FeedResult_SitesFeedAudienceResult_Fragment
  | FeedResult_SitesFeedLeadsResult_Fragment
  | FeedResult_SitesFeedPwsResult_Fragment
  | FeedResult_SitesFeedPubsubResult_Fragment;

export type AudienceRecordFragment = Pick<
  SitesAudienceApp,
  | "account"
  | "app_id"
  | "display_image"
  | "end_time"
  | "grid_image"
  | "grid_redirect"
  | "grid_status"
  | "mobile_image"
  | "page_url"
  | "parent_app_id"
  | "primary_image"
  | "prize"
  | "schedule_status"
  | "share_title"
  | "sort"
  | "start_time"
  | "subtitle"
  | "tags"
  | "terms"
  | "title"
  | "type"
>;

export type PwsGuestRecordFragment = Pick<SitesPwsGuest, "id" | "slug"> & {
  summary: Pick<SitesPublishSummary, "title" | "description" | "image">;
};

export type PublishRecordFragment = Pick<
  SitesPublishRecord,
  "ref_id" | "type" | "payload" | "slug" | "pub_start" | "pub_until" | "pub_changed" | "lookup"
> & {
  summary: Pick<SitesPublishSummary, "image" | "title" | "description" | "author">;
  subscription: Array<Pick<SitesDistribution, "tags">>;
  exclusion: Array<Pick<SitesDistribution, "tags">>;
  linksData: Array<LinkedItemFragment>;
};

export type LinkedItemFragment = Pick<SitesLinksData, "name"> & { result: { items: Array<LinkedItemRecordFragment> } };

export type LinkedItemRecordFragment = Pick<
  SitesPublishRecord,
  "ref_id" | "slug" | "payload" | "pub_start" | "type"
> & { summary: Pick<SitesPublishSummary, "title" | "description" | "image"> };

export type LeadsCardFragment = Pick<SitesLeadsCard, "title" | "subtitle" | "use_catalog_image"> & {
  image: SitesLeadsCard["img_uri"];
} & {
  img_meta?: Maybe<Pick<SitesLeadsCardImgMeta, "base_id" | "bucket">>;
  link?: Maybe<Pick<SitesLeadsCardLink, "target" | "urls" | "name" | "description">>;
  catalog?: Maybe<Pick<SitesCatalogItem, "name" | "id" | "kind" | "country">>;
};

export type ItemFragment = {
  item?: Maybe<{
    result?: Maybe<
      | FeedResult_SitesFeedAudienceResult_Fragment
      | FeedResult_SitesFeedLeadsResult_Fragment
      | FeedResult_SitesFeedPwsResult_Fragment
      | FeedResult_SitesFeedPubsubResult_Fragment
    >;
  }>;
};

export type PodcastsFragment = Pick<SitesPodcastsBlock, "podcasts">;

export type CatalogFragment = Pick<SitesCatalogsBlock, "catalogType"> & {
  catalogRefs: Array<
    Pick<SitesCatalogRef, "id" | "kind" | "country"> & {
      record?: Maybe<
        | CatalogAlbumFragment
        | CatalogArtistFragment
        | CatalogTrackFragment
        | CatalogStationFragment
        | CatalogPodcastFragment
        | CatalogPlaylistFragment
      >;
    }
  >;
};

export type CatalogCommonFields_CatalogAlbum_Fragment = Pick<
  CatalogAlbum,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogArtist_Fragment = Pick<
  CatalogArtist,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogPlaylist_Fragment = Pick<
  CatalogPlaylist,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogPodcast_Fragment = Pick<
  CatalogPodcast,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogPodcastEpisode_Fragment = Pick<
  CatalogPodcastEpisode,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogStation_Fragment = Pick<
  CatalogStation,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFields_CatalogTrack_Fragment = Pick<
  CatalogTrack,
  "id" | "kind" | "country" | "name" | "img" | "web_link" | "device_link"
>;

export type CatalogCommonFieldsFragment =
  | CatalogCommonFields_CatalogAlbum_Fragment
  | CatalogCommonFields_CatalogArtist_Fragment
  | CatalogCommonFields_CatalogPlaylist_Fragment
  | CatalogCommonFields_CatalogPodcast_Fragment
  | CatalogCommonFields_CatalogPodcastEpisode_Fragment
  | CatalogCommonFields_CatalogStation_Fragment
  | CatalogCommonFields_CatalogTrack_Fragment;

export type AmpAlbumFragment = Pick<
  CatalogAmpAlbum,
  | "albumId"
  | "artistId"
  | "artistName"
  | "copyright"
  | "image"
  | "title"
  | "displayable"
  | "explicitLyrics"
  | "publisher"
  | "releaseDate"
  | "totalSongs"
>;

export type CatalogAlbumFragment = { amp?: Maybe<AmpAlbumFragment> } & CatalogCommonFields_CatalogAlbum_Fragment;

export type AmpArtistAlbumFragment = Pick<
  CatalogAmpArtistAlbum,
  | "albumId"
  | "artistId"
  | "artistName"
  | "copyright"
  | "image"
  | "title"
  | "displayable"
  | "explicitLyrics"
  | "publisher"
  | "releaseDate"
  | "totalSongs"
>;

export type AmpTrackFragment = Pick<
  CatalogAmpTrack,
  | "albumId"
  | "albumName"
  | "artistId"
  | "artistName"
  | "duration"
  | "explicitLyrics"
  | "lyricsId"
  | "publisher"
  | "title"
  | "trackNumber"
  | "version"
  | "volume"
> & { trackId: CatalogAmpTrack["id"]; trackImageUrl: CatalogAmpTrack["imageUrl"] };

export type CatalogArtistFragment = {
  amp?: Maybe<
    Pick<CatalogAmpArtist, "artistName" | "bio" | "image"> & {
      albums: Pick<CatalogAmpArtistAlbumsResult, "cursor"> & { albums: Array<AmpArtistAlbumFragment> };
      tracks: { tracks: Array<AmpTrackFragment> };
    }
  >;
} & CatalogCommonFields_CatalogArtist_Fragment;

export type CatalogTrackFragment = Pick<CatalogTrack, "artistId" | "artistName"> & {
  amp?: Maybe<AmpTrackFragment>;
} & CatalogCommonFields_CatalogTrack_Fragment;

export type AmpStationFragment = Pick<CatalogAmpStation, "callLetters" | "country" | "name" | "logo" | "isActive"> & {
  stationId: CatalogAmpStation["id"];
};

export type CatalogStationFragment = Pick<CatalogStation, "description"> & {
  amp?: Maybe<AmpStationFragment>;
} & CatalogCommonFields_CatalogStation_Fragment;

export type AmpPodcastFragment = Pick<CatalogAmpPodcast, "brand" | "imageUrl" | "slug"> & {
  podcastId: CatalogAmpPodcast["id"];
  podcastDescription: CatalogAmpPodcast["description"];
};

export type CatalogPodcastFragment = Pick<CatalogPodcast, "description" | "slug"> & {
  amp?: Maybe<AmpPodcastFragment>;
} & CatalogCommonFields_CatalogPodcast_Fragment;

export type AmpCollectionFragment = Pick<
  CatalogAmpCollection,
  "id" | "userId" | "curated" | "premium" | "name" | "description" | "slug"
> & { playListTracks: Array<Pick<CatalogAmpCollectionItem, "id" | "trackId">> };

export type CatalogPlaylistFragment = Pick<CatalogPlaylist, "userId" | "playlistId" | "description"> & {
  amp?: Maybe<AmpCollectionFragment>;
} & CatalogCommonFields_CatalogPlaylist_Fragment;

export type ShowsFragment = { shows: Array<ShowSiteFragment> };

export type ShowSiteFragment = Pick<SitesIndexRecord, "_id" | "slug" | "ownBrand"> & {
  configByLookup?: Maybe<{
    common?: Maybe<{
      branding?: Maybe<
        Pick<SitesBrandingCommon, "name"> & { logo?: Maybe<{ asset?: Maybe<Pick<MediaAsset, "href">> }> }
      >;
    }>;
  }>;
};

export type KeywordContestFragment = Pick<
  SitesKeywordContestBlock,
  "id" | "type" | "ordinal" | "region" | "value" | "disabled" | "tags" | "campaignId"
>;

export type GetSitePagesQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
  lookup: Scalars["String"];
}>;

export type GetSitePagesQuery = {
  sites: {
    find?: Maybe<
      Pick<SitesIndexRecord, "_id"> & {
        configByLookup?: Maybe<
          Pick<SitesConfigRecord, "_id"> & {
            pages: Array<Pick<SitesPageRecord, "_id" | "name" | "displayName" | "path" | "pageConfig">>;
          }
        >;
      }
    >;
  };
};

export type GetFeedContentQueryVariables = Exact<{
  type: Sites_Lookup_By;
  value: Scalars["String"];
  lookup: Scalars["String"];
  usage: Scalars["String"];
  context?: Maybe<Scalars["JSON"]>;
  size?: Maybe<Scalars["Int"]>;
}>;

export type GetFeedContentQuery = {
  sites: {
    find?: Maybe<
      Pick<SitesIndexRecord, "_id"> & {
        configByLookup?: Maybe<
          Pick<SitesConfigRecord, "_id"> & {
            feed?: Maybe<{
              results: Array<
                | FeedResult_SitesFeedAudienceResult_Fragment
                | FeedResult_SitesFeedLeadsResult_Fragment
                | FeedResult_SitesFeedPwsResult_Fragment
                | FeedResult_SitesFeedPubsubResult_Fragment
              >;
            }>;
          }
        >;
      }
    >;
  };
};

export type GetDatasourceBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
  lookup: Scalars["String"];
  resume: SitesFeedResumeInput;
}>;

export type GetDatasourceBySlugQuery = {
  sites: {
    find?: Maybe<
      Pick<SitesIndexRecord, "_id"> & {
        configByLookup?: Maybe<Pick<SitesConfigRecord, "_id"> & { feed?: Maybe<FeedResponseFragment> }>;
      }
    >;
  };
};

export type GetYoutubeVideosByIdQueryVariables = Exact<{
  name: Scalars["String"];
  slug: Scalars["String"];
  lookup: Scalars["String"];
  blockId: Scalars["String"];
  videoIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetYoutubeVideosByIdQuery = {
  sites: {
    find?: Maybe<{
      configByLookup?: Maybe<{
        page?: Maybe<{
          block?: Maybe<{
            channel?: Maybe<{
              youtube: { videos?: Maybe<Array<Maybe<Pick<YoutubeVideo, "id" | "description" | "title">>>> };
            }>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type GetStationQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetStationQuery = { postup: { station?: Maybe<PostUpStationFragment> } };

export type GetRecipientQueryVariables = Exact<{
  emailAddress: Scalars["String"];
  slug: Scalars["String"];
}>;

export type GetRecipientQuery = { postup: { recipient?: Maybe<Array<PostUpRecipientFragment>> } };

export type GetRecipientSubscriptionsQueryVariables = Exact<{
  emailAddress: Scalars["String"];
  slug: Scalars["String"];
}>;

export type GetRecipientSubscriptionsQuery = {
  postup: { recipient?: Maybe<Array<{ subscriptions?: Maybe<Array<SubscriptionsFragment>> }>> };
};

export type CreateRecipientMutationVariables = Exact<{
  slug: Scalars["String"];
  input: PostupRecipientCreateInput;
}>;

export type CreateRecipientMutation = { postup: { createRecipient?: Maybe<PostUpRecipientFragment> } };

export type UpdateRecipientMutationVariables = Exact<{
  slug: Scalars["String"];
  input: PostupRecipientUpdateInput;
}>;

export type UpdateRecipientMutation = { postup: { updateRecipient?: Maybe<PostUpRecipientFragment> } };

export type UpdateSubscriptionsMutationVariables = Exact<{
  input: Array<PostupListSubscriptionCreateInput> | PostupListSubscriptionCreateInput;
}>;

export type UpdateSubscriptionsMutation = { postup: { updateSubscriptions: Array<SubscriptionsFragment> } };

export type SendMailMutationVariables = Exact<{
  slug: Scalars["String"];
  input: PostupSendMailInput;
}>;

export type SendMailMutation = { postup: { sendMail?: Maybe<PostUpMailingFragment> } };

export type PostUpStationFragment = Pick<PostupStation, "slug"> & {
  lists?: Maybe<
    Array<
      Pick<
        PostupStationList,
        "listId" | "title" | "friendlyTitle" | "description" | "publicSignup" | "populated" | "channel" | "externalId"
      >
    >
  >;
};

export type PostUpRecipientFragment = Pick<
  PostupRecipient,
  | "recipientId"
  | "address"
  | "externalId"
  | "channel"
  | "status"
  | "comment"
  | "sourceDescription"
  | "sourceSignupDate"
  | "signupIP"
  | "signupMethod"
  | "thirdPartySource"
  | "thirdPartySignupDate"
  | "dateJoined"
  | "dateUnsub"
  | "demographics"
> & { subscriptions?: Maybe<Array<SubscriptionsFragment>> };

export type SubscriptionsFragment = Pick<
  PostupListSubscription,
  | "mailingId"
  | "listId"
  | "recipientId"
  | "status"
  | "confirmed"
  | "sourceId"
  | "dateUnsub"
  | "dateJoined"
  | "address"
  | "listStatus"
  | "globalStatus"
>;

export type PostUpMailingFragment = Pick<
  PostupMailing,
  | "mailingId"
  | "status"
  | "channel"
  | "title"
  | "brandId"
  | "campaignId"
  | "categoryId"
  | "creator"
  | "clickTrackType"
  | "openTrackType"
  | "externalId"
  | "maxRecips"
  | "realtime"
>;

export type GetSitemapContentQueryVariables = Exact<{
  query: PubsubQueryInput;
}>;

export type GetSitemapContentQuery = { pubsub: { query?: Maybe<{ items: Array<SitemapContentItemFragment> }> } };

export type SitemapContentItemFragment = Pick<PubsubPublishRecord, "slug" | "pub_start" | "payload"> & {
  summary: Pick<PubsubPublishSummary, "title">;
  subscription: Array<Pick<PubsubDistribution, "tags">>;
};

export type GetCoastPreviousNextFeedQueryVariables = Exact<{
  query: PubsubQueryInput;
  type: Scalars["String"];
}>;

export type GetCoastPreviousNextFeedQuery = { pubsub: { query?: Maybe<{ items: Array<PreviousNextItemFragment> }> } };

export type PreviousNextItemFragment = Pick<PubsubPublishRecord, "slug" | "pub_start" | "pub_until" | "pub_changed"> & {
  summary: Pick<PubsubPublishSummary, "title" | "image">;
};

export type GetCoastContentItemQueryVariables = Exact<{
  type: Scalars["String"];
  slug: Scalars["String"];
}>;

export type GetCoastContentItemQuery = {
  pubsub: {
    get?: Maybe<
      Pick<PubsubPublishRecord, "ref_id" | "type" | "payload" | "slug" | "pub_start" | "pub_until" | "pub_changed"> & {
        summary: Pick<PubsubPublishSummary, "image" | "title" | "description" | "author">;
        subscription: Array<Pick<PubsubDistribution, "tags">>;
      }
    >;
  };
};

export type GetPwsSearchResultsQueryVariables = Exact<{
  keyword: Scalars["String"];
  includeArticles?: Maybe<Scalars["Boolean"]>;
  includeGuests?: Maybe<Scalars["Boolean"]>;
  includeShows?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type GetPwsSearchResultsQuery = { pws: { coastAppContent: { search: PwsSearchResponseFragment } } };

export type PwsSearchResponseFragment = {
  value: { items: Array<CoastSearchItemFragment> };
  pageInfo: Pick<PwsPageInfo, "nextCursor">;
};

export type CoastSearchItemFragment = Pick<
  PwsCoastSearchItem,
  "ref_id" | "slug" | "type" | "category" | "source" | "source_url"
> & {
  pub_start: Pick<DateRef, "timestampMs">;
  summary: Pick<PwsCoastSearchItemSummary, "title" | "description" | "image">;
};

export type CoastFeaturedQueryVariables = Exact<{
  sections?: Maybe<Scalars["Int"]>;
  shows?: Maybe<PwsCoastFeaturedItemOptions>;
  photos?: Maybe<PwsCoastFeaturedItemOptions>;
  articles?: Maybe<PwsCoastFeaturedItemOptions>;
}>;

export type CoastFeaturedQuery = {
  pws: { coastAppContent: { featured: { value: Array<Array<CoastFeaturedItemFragment>> } } };
};

export type CoastFeaturedItemFragment = Pick<PwsCoastFeaturedItem, "slug" | "content_type"> & {
  pub_start: Pick<DateRef, "timestampMs">;
  summary: Pick<PwsCoastFeaturedItemSummary, "image" | "title">;
};

export type GetTopicQueryVariables = Exact<{
  topic: Scalars["String"];
  locale: Scalars["String"];
}>;

export type GetTopicQuery = {
  taxonomy?: Maybe<{ topic?: Maybe<Pick<Tag, "name" | "displayName"> & { source?: Maybe<TagSourceFragment> }> }>;
};

export type GetTagQueryVariables = Exact<{
  tag: Scalars["String"];
  locale: Scalars["String"];
}>;

export type GetTagQuery = {
  taxonomy?: Maybe<{
    tag?: Maybe<Pick<Tag, "name" | "memberName" | "displayName" | "ns"> & { source?: Maybe<TagSourceFragment> }>;
  }>;
};

export type TagSourceFragment = Pick<TagSource, "id" | "display_name" | "description" | "source_name" | "type">;

export const PodcastEpisodeFragmentDoc = gql`
  fragment PodcastEpisode on PodcastEpisode {
    id
    podcastId
    podcastSlug
    title
    duration
    isExplicit
    description
    startDate
    imageUrl
  }
`;
export const PodcastEpisodesFragmentDoc = gql`
  fragment PodcastEpisodes on PodcastEpisodes {
    pageInfo {
      nextCursor
    }
    items {
      ...PodcastEpisode
    }
  }
  ${PodcastEpisodeFragmentDoc}
`;
export const PodcastFragmentDoc = gql`
  fragment Podcast on Podcast {
    id
    title
    description
    lastUpdated
    slug
    isExternal
    imageUrl
    episodes(paging: $paging) {
      ...PodcastEpisodes
    }
  }
  ${PodcastEpisodesFragmentDoc}
`;
export const ArtistDetailFragmentDoc = gql`
  fragment ArtistDetail on Artist {
    artistName
    genreId
  }
`;
export const TrackDetailFragmentDoc = gql`
  fragment TrackDetail on PnpTrack {
    artistId
    albumId
    trackId
    title
    artist {
      ...ArtistDetail
    }
    albumName
    trackDuration
    imagePath
    explicitLyrics
    lyricsId
    playbackRights {
      onDemand
    }
  }
  ${ArtistDetailFragmentDoc}
`;
export const TopTrackDetailFragmentDoc = gql`
  fragment TopTrackDetail on TopTrack {
    trackId
    count
    rank
    track {
      ...TrackDetail
    }
  }
  ${TrackDetailFragmentDoc}
`;
export const TopTracksFragmentDoc = gql`
  fragment TopTracks on LiveMetaTopTracks {
    count
    pageInfo {
      nextCursor
    }
    tracks {
      ...TopTrackDetail
    }
  }
  ${TopTrackDetailFragmentDoc}
`;
export const CurrentlyPlayingTrackDetailFragmentDoc = gql`
  fragment CurrentlyPlayingTrackDetail on CombinedPnpTrackHistory {
    artistId
    albumId
    trackId
    title
    artist {
      ...ArtistDetail
    }
    albumName
    trackDuration
    imagePath
    explicitLyrics
    lyricsId
    playbackRights {
      onDemand
    }
    startTime
    endTime
    status
    offset
    trackDurationInSec
    dataSource
    isCurrentlyPlayingTrack
  }
  ${ArtistDetailFragmentDoc}
`;
export const CurrentlyPlayingFragmentDoc = gql`
  fragment CurrentlyPlaying on LiveMetaCurrentlyPlaying {
    count
    pageInfo {
      nextCursor
    }
    tracks {
      ...CurrentlyPlayingTrackDetail
    }
  }
  ${CurrentlyPlayingTrackDetailFragmentDoc}
`;
export const AptivadaAppFragmentDoc = gql`
  fragment AptivadaApp on AptivadaApp {
    appType
    appId
    parentAppId
    title
    shareTitle
    tags
    gridStatus
    gridRedirect
    pageUrl
    primaryImage
    mobileImage
    gridImage
    prize
    sort
    startTime
    endTime
    scheduleStatus
    startDate
    endDate
    description
    active
    subtitle
    type
    displayImage
    accountId
    facebookShareImage
    width
    shareLocation
    timeZone
  }
`;
export const KeywordDetailsFragmentDoc = gql`
  fragment KeywordDetails on KeywordDetails {
    sort
    timeStamp
    endTime
    isComplete
    isLive
    isStarted
    isSubmittable
    active
    title
    countdownKeywordId
    prize
  }
`;
export const AptivadaKeywordAppFragmentDoc = gql`
  fragment AptivadaKeywordApp on AptivadaKeywordApp {
    appType
    appId
    title
    currentKeyword {
      previous {
        ...KeywordDetails
      }
      current {
        ...KeywordDetails
      }
      next {
        ...KeywordDetails
      }
    }
    submittableKeyword {
      previous {
        ...KeywordDetails
      }
      current {
        ...KeywordDetails
      }
      next {
        ...KeywordDetails
      }
    }
  }
  ${KeywordDetailsFragmentDoc}
`;
export const CalendarEventFragmentDoc = gql`
  fragment CalendarEvent on CalendarEvent {
    id
    eventId
    origins
    startDateTime
    endDateTime
    tags
    origins
    venueName
    venueAddress
    content {
      id
      img
      title
      slug
      summary
      author
      publish_date
      publish_origin
      record {
        payload {
          blocks
          canonical_url
        }
        eyebrow {
          topic
          topic_name
        }
        subscription {
          tags
        }
      }
    }
  }
`;
export const ChartYearMonthFragmentDoc = gql`
  fragment ChartYearMonth on ChartsChartYearMonth {
    year
    months
  }
`;
export const ChartDatesListFragmentDoc = gql`
  fragment ChartDatesList on ChartsChartDatesList {
    months {
      ...ChartYearMonth
    }
  }
  ${ChartYearMonthFragmentDoc}
`;
export const ChartDatesFunctionFragmentDoc = gql`
  fragment ChartDatesFunction on ChartsChart {
    dates {
      ...ChartDatesList
    }
  }
  ${ChartDatesListFragmentDoc}
`;
export const ChartListPartialFragmentDoc = gql`
  fragment ChartListPartial on ChartsList {
    topItems
    slug
    title
    image
  }
`;
export const ChartListPartialResultsFragmentDoc = gql`
  fragment ChartListPartialResults on ChartsListResults {
    items {
      ...ChartListPartial
    }
    pageInfo {
      nextCursor
    }
  }
  ${ChartListPartialFragmentDoc}
`;
export const ChartListsPartialsFunctionFragmentDoc = gql`
  fragment ChartListsPartialsFunction on ChartsChart {
    lists(filters: { size: $listsSize, cursor: $listsCursor, startDate: $listsStartDate, endDate: $listsEndDate }) {
      ...ChartListPartialResults
    }
  }
  ${ChartListPartialResultsFragmentDoc}
`;
export const ChartV2FragmentDoc = gql`
  fragment ChartV2 on ChartsChart {
    slug
    id
    title
    subtitle
    ...ChartDatesFunction @include(if: $includeDates)
    ...ChartListsPartialsFunction @include(if: $includeLists)
  }
  ${ChartDatesFunctionFragmentDoc}
  ${ChartListsPartialsFunctionFragmentDoc}
`;
export const ChartListSongFragmentDoc = gql`
  fragment ChartListSong on ChartsChartListSong {
    artist
    delta
    image
    peak
    pos
    timeOnChart
    title
    url
  }
`;
export const ChartsListFragmentDoc = gql`
  fragment ChartsList on ChartsList {
    topItems
    slug
    image
    title
    songs {
      ...ChartListSong
    }
  }
  ${ChartListSongFragmentDoc}
`;
export const CategoryTagFragmentDoc = gql`
  fragment CategoryTag on TagRef {
    id
    taxo {
      memberName
      displayName
    }
  }
`;
export const InfernoSiteAlertFragmentDoc = gql`
  fragment InfernoSiteAlert on SitesTemplateInfernoSiteAlert {
    title
    link
    target
    start_date {
      timestampMs
    }
    end_date {
      timestampMs
    }
  }
`;
export const SocialFragmentDoc = gql`
  fragment Social on SitesTemplateInfernoSiteSocial {
    facebook_switch
    facebook_name
    facebook_app_id
    facebook_fan_page_id
    twitter_switch
    twitter_name
    youtube_switch
    youtube_name
    instagram_switch
    instagram_name
    snapchat_switch
    snapchat_name
    pinterest_switch
    pinterest_name
    tiktok_switch
    tiktok_name
  }
`;
export const ContactFragmentDoc = gql`
  fragment Contact on SitesTemplateInfernoSiteContact {
    page_intro
    show_social_switch
    phone_text_numbers_switch
    request_phone_number
    business_phone_number
    traffic_tip_phone
    newsroom_phone
    sms_number
    prize_pickup_directions_switch
    prize_pickup_info
    location_address1
    location_address2
    location_city
    location_state
    location_zip
    office_hours
    email_form_switch
    employment_info_switch
    contacts {
      type
    }
    eeo_report_asset {
      id
    }
    employment_link_override
    public_file_assistance_link_override
    email_ip_blacklist
    enable_contest_promotions
  }
`;
export const UserConfigFragmentDoc = gql`
  fragment UserConfig on SitesTemplateInfernoSite {
    general {
      name
      positioner
      description
      keywords {
        id
      }
      default_content {
        id
      }
      additional_distributions {
        id
      }
      default_podcasts {
        id
      }
      default_shows {
        id
        sites {
          _id
          slug
          ownBrand
          configByLookup(lookup: $lookup) {
            common {
              branding {
                name
              }
            }
          }
        }
      }
      default_playlists {
        id
      }
      default_event_categories {
        ...CategoryTag
      }
      weather_market
      country {
        id
      }
      geo_latitude
      geo_longitude
      locale {
        taxo {
          name
        }
      }
      additional_copyright
    }
    alert {
      ...InfernoSiteAlert
    }
    navigation {
      contact_menu_switch
      listen_live_switch
      contest_nav_switch
      search_switch
      user_menu_switch
      show_public_file_switch
      advertise_switch
      find_your_station_switch
      enable_podcasts_links_switch
      national_promotions {
        id
        type
        label
        target
        ref
      }
      menu {
        id
        type
        label
        target
        ref
        children {
          id
          type
          label
          target
          ref
        }
      }
      menu_footer {
        id
        type
        label
        target
        ref
        children {
          id
          type
          label
          target
          ref
        }
      }
    }
    design {
      theme
      color_scheme {
        id
      }
      logo_best_res {
        id
        asset {
          bucket
          resourceId
          href
        }
      }
      station_logo {
        id
        asset {
          bucket
          resourceId
          href
        }
      }
      logo_favicon {
        id
        asset {
          bucket
          resourceId
          href
        }
      }
      font {
        id
      }
      accent_color
      header_color
      navigation_color
      navigation_links_color
      ihr_embed_player_bar_stream_override {
        id
        kind
      }
      ihr_embed_player_bar_switch
      ihr_embed_player_bar_stream_theme
      logo_footer {
        id
        asset {
          bucket
          resourceId
          href
        }
      }
      thumbnail_image {
        id
        asset {
          bucket
          resourceId
          href
        }
      }
    }
    social {
      ...Social
    }
    contact {
      ...Contact
    }
    partners {
      iheartradio_switch
      ihr_show_in_iheart_switch
      video_monetization_provider
      postup_switch
      email_format {
        id
      }
      dataskrive_switch
      outbrain_switch
      traffic_market
      aptivada_switch
      aptivada_id
      google_webmaster_verification
      auth_type {
        taxo {
          name
        }
      }
    }
    ads {
      dfp_switch
      header_bidding
      facebook_ad_track_pixel_switch
      facebook_ad_track_pixel_id
    }
    analytics {
      adobe_library
      parsely_switch
      parsely_account_id
    }
  }
  ${CategoryTagFragmentDoc}
  ${InfernoSiteAlertFragmentDoc}
  ${SocialFragmentDoc}
  ${ContactFragmentDoc}
`;
export const ApplicationNoticesFragmentDoc = gql`
  fragment ApplicationNotices on SitesBroadcastFacilityApplicationNotice {
    text
    link
  }
`;
export const BroadcastFacilitiesFragmentDoc = gql`
  fragment BroadcastFacilities on SitesBroadcastFacility {
    facilityId
    broadcastFrequency
    broadcastBand
    broadcastCallLetters
    applicationNotices {
      ...ApplicationNotices
    }
    politicalLinkOverride
  }
  ${ApplicationNoticesFragmentDoc}
`;
export const BroadcastFacilitiesIntegrationFragmentDoc = gql`
  fragment BroadcastFacilitiesIntegration on SitesBroadcastFacilitiesIntegration {
    type
    plugin {
      broadcastFacilities {
        ...BroadcastFacilities
      }
    }
  }
  ${BroadcastFacilitiesFragmentDoc}
`;
export const StationFragmentDoc = gql`
  fragment Station on CatalogStation {
    amp {
      isActive
    }
  }
`;
export const StreamOverrideConfigFragmentDoc = gql`
  fragment StreamOverrideConfig on SitesTemplateInfernoSite {
    design {
      ihr_embed_player_bar_stream_override {
        record {
          ...Station
        }
      }
    }
  }
  ${StationFragmentDoc}
`;
export const SiteConfigFragmentDoc = gql`
  fragment SiteConfig on SitesIndexRecord {
    _id
    canonicalHostname
    hostnames
    facets
    slug
    market
    ownBrand
    timeZone
    keys
    stream {
      id
      primaryPronouncement
      recentlyPlayedEnabled
      isActive
    }
    ownership {
      id
      taxo {
        source {
          display_name
        }
      }
    }
    summary {
      name
      description
    }
    status {
      hide {
        startDate
        endDate
        redirectTo
      }
      isHidden
      isActive
    }
    configByLookup(lookup: $lookup) {
      _id
      adPositions {
        breakpoints {
          breakpoint
          sizes {
            w
            h
          }
        }
        _id
        positionId
        template
        adSplit
        forceLoad
      }
      routes {
        name
        path
      }
      redirects {
        id
        priority
        source
        regex
        schedule {
          begin
          end
        }
        destination {
          type
          value
        }
        disabled
        permanent
        matchQueryString
        queryParamsHandling
      }
      searchOverrides {
        id
        keywords
        destination {
          type
          value
        }
        disabled
        schedule {
          begin
          end
        }
      }
      sections {
        ...UserConfig
      }
    }
    integration(type: "broadcast-facilities") {
      ...BroadcastFacilitiesIntegration
    }
    liveConfig {
      sections {
        ...StreamOverrideConfig
      }
    }
  }
  ${UserConfigFragmentDoc}
  ${BroadcastFacilitiesIntegrationFragmentDoc}
  ${StreamOverrideConfigFragmentDoc}
`;
export const SiteChildRefFragmentDoc = gql`
  fragment SiteChildRef on SitesIndexRecord {
    _id
    slug
    ownBrand
    summary {
      name
      image
    }
    status {
      isActive
    }
  }
`;
export const MicrositeSocialConfigFragmentDoc = gql`
  fragment MicrositeSocialConfig on SitesTemplateInfernoMicrositeSocial {
    facebook_switch
    facebook_name
    twitter_switch
    twitter_name
    youtube_switch
    youtube_name
    instagram_switch
    instagram_name
    snapchat_switch
    snapchat_name
    pinterest_switch
    pinterest_name
    tiktok_switch
    tiktok_name
  }
`;
export const MicrositeGeneralConfigFragmentDoc = gql`
  fragment MicrositeGeneralConfig on SitesTemplateInfernoMicrositeGeneral {
    name
    description
    keywords {
      id
    }
    default_content {
      id
      taxo {
        memberName
        name
        ns
        source {
          display_name
          description
          type
        }
        displayName
      }
    }
    additional_distributions {
      id
    }
    default_podcasts {
      id
    }
    default_playlists {
      id
    }
    thumbnail_image {
      id
      asset {
        bucket
        resourceId
        href
      }
    }
    sixteen_by_nine {
      id
      asset {
        bucket
        resourceId
        href
      }
    }
    recommendations
    status
    active_start {
      timestampMs
      timestamp
    }
    active_end {
      timestampMs
      timestamp
    }
    password_switch
    password
    header_description
  }
`;
export const NavigationMicrositeFragmentDoc = gql`
  fragment NavigationMicrosite on SitesIndexRecord {
    _id
    slug
    ownBrand
    configByLookup(lookup: $lookup) {
      common {
        branding {
          name
        }
      }
    }
  }
`;
export const OnAirShowFragmentDoc = gql`
  fragment OnAirShow on SitesOnAirExtended {
    siteId
    site
    coreShowId
    coreStationId
    name
    show
    station
    link
    onNow
    startTime12
    startDate
    startMs
    startEng
    stopTime12
    stopDate
    stopMs
    stopEng
    showSite {
      slug
      configByLookup(lookup: "site-config-lookups/inferno") {
        sections {
          ... on SitesTemplateInfernoMicrosite {
            general {
              name
              thumbnail_image {
                asset {
                  href
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const OnAirScheduleFragmentDoc = gql`
  fragment OnAirSchedule on SitesOnAirScheduleResponse {
    current {
      ...OnAirShow
    }
    upcoming {
      ...OnAirShow
    }
  }
  ${OnAirShowFragmentDoc}
`;
export const LinkedItemRecordFragmentDoc = gql`
  fragment LinkedItemRecord on SitesPublishRecord {
    ref_id
    slug
    payload
    pub_start
    type
    summary {
      title
      description
      image
    }
  }
`;
export const LinkedItemFragmentDoc = gql`
  fragment LinkedItem on SitesLinksData {
    name
    result {
      items {
        ...LinkedItemRecord
      }
    }
  }
  ${LinkedItemRecordFragmentDoc}
`;
export const PublishRecordFragmentDoc = gql`
  fragment PublishRecord on SitesPublishRecord {
    ref_id
    type
    payload
    slug
    summary {
      image
      title
      description
      author
    }
    pub_start
    pub_until
    pub_changed
    subscription {
      tags
    }
    exclusion {
      tags
    }
    linksData {
      ...LinkedItem
    }
    lookup
  }
  ${LinkedItemFragmentDoc}
`;
export const LeadsCardFragmentDoc = gql`
  fragment LeadsCard on SitesLeadsCard {
    title
    subtitle
    image: img_uri
    img_meta {
      base_id
      bucket
    }
    link {
      target
      urls
      name
      description
    }
    use_catalog_image
    catalog {
      name
      id
      kind
      country
    }
  }
`;
export const PwsGuestRecordFragmentDoc = gql`
  fragment PWSGuestRecord on SitesPWSGuest {
    id
    slug
    summary {
      title
      description
      image
    }
  }
`;
export const AudienceRecordFragmentDoc = gql`
  fragment AudienceRecord on SitesAudienceApp {
    account
    app_id
    display_image
    end_time
    grid_image
    grid_redirect
    grid_status
    mobile_image
    page_url
    parent_app_id
    primary_image
    prize
    schedule_status
    share_title
    sort
    start_time
    subtitle
    tags
    terms
    title
    type
  }
`;
export const FeedResultFragmentDoc = gql`
  fragment FeedResult on SitesFeedResult {
    id
    type
    ... on SitesFeedPubsubResult {
      record {
        ...PublishRecord
      }
    }
    ... on SitesFeedLeadsResult {
      record {
        ...LeadsCard
      }
    }
    ... on SitesFeedPWSResult {
      record {
        ... on SitesPWSGuest {
          ...PWSGuestRecord
        }
      }
    }
    ... on SitesFeedAudienceResult {
      record {
        ...AudienceRecord
      }
    }
  }
  ${PublishRecordFragmentDoc}
  ${LeadsCardFragmentDoc}
  ${PwsGuestRecordFragmentDoc}
  ${AudienceRecordFragmentDoc}
`;
export const FeedResponseFragmentDoc = gql`
  fragment FeedResponse on SitesFeedResponse {
    results {
      ...FeedResult
    }
    resume {
      id
      context
      size
      from
      scopes
    }
  }
  ${FeedResultFragmentDoc}
`;
export const FeedFragmentDoc = gql`
  fragment Feed on SitesFeedBlock {
    feed(context: $context, scopes: $scopes, from: $from) {
      ...FeedResponse
    }
  }
  ${FeedResponseFragmentDoc}
`;
export const ItemFragmentDoc = gql`
  fragment Item on SitesItemBlock {
    item(context: $context) {
      result {
        ...FeedResult
      }
    }
  }
  ${FeedResultFragmentDoc}
`;
export const PodcastsFragmentDoc = gql`
  fragment Podcasts on SitesPodcastsBlock {
    podcasts
  }
`;
export const ShowSiteFragmentDoc = gql`
  fragment ShowSite on SitesIndexRecord {
    _id
    slug
    ownBrand
    configByLookup(lookup: $lookup) {
      common {
        branding {
          name
          logo {
            asset {
              href
            }
          }
        }
      }
    }
  }
`;
export const ShowsFragmentDoc = gql`
  fragment Shows on SitesShowBlock {
    shows {
      ...ShowSite
    }
  }
  ${ShowSiteFragmentDoc}
`;
export const KeywordContestFragmentDoc = gql`
  fragment KeywordContest on SitesKeywordContestBlock {
    id
    type
    ordinal
    region
    value
    disabled
    tags
    campaignId
  }
`;
export const ThumbnailFragmentDoc = gql`
  fragment Thumbnail on YoutubeThumbnail {
    url
    width
    height
  }
`;
export const ThumbnailMapFragmentDoc = gql`
  fragment ThumbnailMap on YoutubeThumbnailMap {
    default {
      ...Thumbnail
    }
    medium {
      ...Thumbnail
    }
    high {
      ...Thumbnail
    }
    standard {
      ...Thumbnail
    }
    maxres {
      ...Thumbnail
    }
  }
  ${ThumbnailFragmentDoc}
`;
export const PlaylistSnippetFragmentDoc = gql`
  fragment PlaylistSnippet on YoutubePlaylistSnippet {
    title
    description
    thumbnails {
      ...ThumbnailMap
    }
    channelId {
      id
    }
    publishedAt {
      timestampMs
      timestamp
      iso8601
    }
  }
  ${ThumbnailMapFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    prevToken
    nextToken
    itemTokens
  }
`;
export const PlaylistItemSnippetFragmentDoc = gql`
  fragment PlaylistItemSnippet on YoutubePlaylistItemSnippet {
    title
    description
    thumbnails {
      ...ThumbnailMap
    }
    channelId {
      id
    }
    publishedAt {
      timestampMs
      timestamp
      iso8601
    }
    videoId {
      id
    }
    playlistId {
      id
    }
    position
    channelTitle
  }
  ${ThumbnailMapFragmentDoc}
`;
export const PlaylistItemResultsFragmentDoc = gql`
  fragment PlaylistItemResults on YoutubePlaylistItemResults {
    count
    pageInfo {
      ...PageInfo
    }
    items {
      id
      snippet {
        ...PlaylistItemSnippet
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${PlaylistItemSnippetFragmentDoc}
`;
export const PlaylistFunctionFragmentDoc = gql`
  fragment PlaylistFunction on YoutubePlaylistFunctions {
    playlist {
      id
      snippet {
        ...PlaylistSnippet
      }
      playlistItems(paging: $paging) {
        ...PlaylistItemResults
      }
    }
  }
  ${PlaylistSnippetFragmentDoc}
  ${PlaylistItemResultsFragmentDoc}
`;
export const PlaylistResultsFragmentDoc = gql`
  fragment PlaylistResults on YoutubePlaylistResults {
    count
    pageInfo {
      ...PageInfo
    }
    items {
      id
      snippet {
        ...PlaylistSnippet
      }
      playlistItems(paging: $paging) {
        ...PlaylistItemResults
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${PlaylistSnippetFragmentDoc}
  ${PlaylistItemResultsFragmentDoc}
`;
export const ChannelFunctionFragmentDoc = gql`
  fragment ChannelFunction on YoutubeChannelFunctions {
    channel {
      id
      snippet {
        title
        description
        customUrl
        publishedAt {
          timestampMs
          timestamp
          iso8601
        }
        thumbnails {
          ...ThumbnailMap
        }
      }
      playlists {
        ...PlaylistResults
      }
      uploadsPlaylist {
        id
        youtube {
          ...PlaylistFunction
        }
      }
    }
  }
  ${ThumbnailMapFragmentDoc}
  ${PlaylistResultsFragmentDoc}
  ${PlaylistFunctionFragmentDoc}
`;
export const YoutubeFragmentDoc = gql`
  fragment Youtube on SitesYoutubeBlock {
    playlist {
      id
      youtube {
        ...PlaylistFunction
      }
    }
    channel {
      id
      youtube {
        ...ChannelFunction
      }
    }
    default_video_id
  }
  ${PlaylistFunctionFragmentDoc}
  ${ChannelFunctionFragmentDoc}
`;
export const CatalogCommonFieldsFragmentDoc = gql`
  fragment CatalogCommonFields on CatalogRecordCommonFields {
    id
    kind
    country
    name
    img
    web_link
    device_link
  }
`;
export const AmpAlbumFragmentDoc = gql`
  fragment AmpAlbum on CatalogAmpAlbum {
    albumId
    artistId
    artistName
    copyright
    image
    title
    displayable
    explicitLyrics
    publisher
    releaseDate
    totalSongs
  }
`;
export const CatalogAlbumFragmentDoc = gql`
  fragment CatalogAlbum on CatalogAlbum {
    ...CatalogCommonFields
    amp {
      ...AmpAlbum
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpAlbumFragmentDoc}
`;
export const AmpArtistAlbumFragmentDoc = gql`
  fragment AmpArtistAlbum on CatalogAmpArtistAlbum {
    albumId
    artistId
    artistName
    copyright
    image
    title
    displayable
    explicitLyrics
    publisher
    releaseDate
    totalSongs
  }
`;
export const AmpTrackFragmentDoc = gql`
  fragment AmpTrack on CatalogAmpTrack {
    trackId: id
    albumId
    albumName
    artistId
    artistName
    duration
    explicitLyrics
    lyricsId
    publisher
    title
    trackImageUrl: imageUrl
    trackNumber
    version
    volume
  }
`;
export const CatalogArtistFragmentDoc = gql`
  fragment CatalogArtist on CatalogArtist {
    ...CatalogCommonFields
    amp {
      artistName
      bio
      image
      albums {
        cursor
        albums {
          ...AmpArtistAlbum
        }
      }
      tracks {
        tracks {
          ...AmpTrack
        }
      }
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpArtistAlbumFragmentDoc}
  ${AmpTrackFragmentDoc}
`;
export const CatalogTrackFragmentDoc = gql`
  fragment CatalogTrack on CatalogTrack {
    ...CatalogCommonFields
    artistId
    artistName
    amp {
      ...AmpTrack
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpTrackFragmentDoc}
`;
export const AmpStationFragmentDoc = gql`
  fragment AmpStation on CatalogAmpStation {
    stationId: id
    callLetters
    country
    name
    logo
    isActive
  }
`;
export const CatalogStationFragmentDoc = gql`
  fragment CatalogStation on CatalogStation {
    ...CatalogCommonFields
    description
    amp {
      ...AmpStation
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpStationFragmentDoc}
`;
export const AmpPodcastFragmentDoc = gql`
  fragment AmpPodcast on CatalogAmpPodcast {
    podcastId: id
    brand
    podcastDescription: description
    imageUrl
    slug
  }
`;
export const CatalogPodcastFragmentDoc = gql`
  fragment CatalogPodcast on CatalogPodcast {
    ...CatalogCommonFields
    description
    slug
    amp {
      ...AmpPodcast
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpPodcastFragmentDoc}
`;
export const AmpCollectionFragmentDoc = gql`
  fragment AmpCollection on CatalogAmpCollection {
    id
    userId
    curated
    premium
    name
    description
    playListTracks: tracks {
      id
      trackId
    }
    slug
  }
`;
export const CatalogPlaylistFragmentDoc = gql`
  fragment CatalogPlaylist on CatalogPlaylist {
    ...CatalogCommonFields
    userId
    playlistId
    description
    amp {
      ...AmpCollection
    }
  }
  ${CatalogCommonFieldsFragmentDoc}
  ${AmpCollectionFragmentDoc}
`;
export const CatalogFragmentDoc = gql`
  fragment Catalog on SitesCatalogsBlock {
    catalogType
    catalogRefs {
      id
      kind
      country
      record {
        ...CatalogAlbum
        ...CatalogArtist
        ...CatalogTrack
        ...CatalogStation
        ...CatalogPodcast
        ...CatalogPlaylist
      }
    }
  }
  ${CatalogAlbumFragmentDoc}
  ${CatalogArtistFragmentDoc}
  ${CatalogTrackFragmentDoc}
  ${CatalogStationFragmentDoc}
  ${CatalogPodcastFragmentDoc}
  ${CatalogPlaylistFragmentDoc}
`;
export const BlockFragmentDoc = gql`
  fragment Block on SitesPageBlock {
    id
    region
    type
    ordinal
    value
    tags
    schedule {
      begin
      end
    }
    ...Feed
    ...Item
    ...Podcasts
    ...Shows
    ...KeywordContest
    ...Youtube
    ...Catalog
  }
  ${FeedFragmentDoc}
  ${ItemFragmentDoc}
  ${PodcastsFragmentDoc}
  ${ShowsFragmentDoc}
  ${KeywordContestFragmentDoc}
  ${YoutubeFragmentDoc}
  ${CatalogFragmentDoc}
`;
export const PageFragmentDoc = gql`
  fragment Page on SitesPageRecord {
    _id
    name
    pageConfig
    displayName
    path
    primaryBlockId
    layoutId
    parentId
    tags {
      id
    }
    blocks {
      ...Block
    }
  }
  ${BlockFragmentDoc}
`;
export const PostUpStationFragmentDoc = gql`
  fragment PostUpStation on PostupStation {
    slug
    lists {
      listId
      title
      friendlyTitle
      description
      publicSignup
      populated
      channel
      externalId
    }
  }
`;
export const SubscriptionsFragmentDoc = gql`
  fragment Subscriptions on PostupListSubscription {
    mailingId
    listId
    recipientId
    status
    confirmed
    sourceId
    dateUnsub
    dateJoined
    address
    listStatus
    globalStatus
  }
`;
export const PostUpRecipientFragmentDoc = gql`
  fragment PostUpRecipient on PostupRecipient {
    recipientId
    address
    externalId
    channel
    status
    comment
    sourceDescription
    sourceSignupDate
    signupIP
    signupMethod
    thirdPartySource
    thirdPartySignupDate
    dateJoined
    dateUnsub
    demographics
    subscriptions(slug: $slug) {
      ...Subscriptions
    }
  }
  ${SubscriptionsFragmentDoc}
`;
export const PostUpMailingFragmentDoc = gql`
  fragment PostUpMailing on PostupMailing {
    mailingId
    status
    channel
    title
    brandId
    campaignId
    categoryId
    creator
    clickTrackType
    openTrackType
    externalId
    maxRecips
    realtime
  }
`;
export const SitemapContentItemFragmentDoc = gql`
  fragment SitemapContentItem on PubsubPublishRecord {
    slug
    pub_start
    summary {
      title
    }
    payload
    subscription {
      tags
    }
  }
`;
export const PreviousNextItemFragmentDoc = gql`
  fragment PreviousNextItem on PubsubPublishRecord {
    slug
    pub_start
    pub_until
    pub_changed
    summary {
      title
      image
    }
  }
`;
export const CoastSearchItemFragmentDoc = gql`
  fragment CoastSearchItem on PWSCoastSearchItem {
    ref_id
    slug
    type
    category
    pub_start {
      timestampMs
    }
    summary {
      title
      description
      image
    }
    source
    source_url
  }
`;
export const PwsSearchResponseFragmentDoc = gql`
  fragment PWSSearchResponse on PWSCoastSearchResponse {
    value {
      ... on PWSCoastSearchResponseMergedValue {
        items {
          ...CoastSearchItem
        }
      }
    }
    pageInfo {
      nextCursor
    }
  }
  ${CoastSearchItemFragmentDoc}
`;
export const CoastFeaturedItemFragmentDoc = gql`
  fragment CoastFeaturedItem on PWSCoastFeaturedItem {
    slug
    pub_start {
      timestampMs
    }
    content_type
    summary {
      image
      title
    }
  }
`;
export const TagSourceFragmentDoc = gql`
  fragment TagSource on TagSource {
    id
    display_name
    description
    source_name
    type
  }
`;
export const GetPodcastEpisodesDocument = gql`
  query GetPodcastEpisodes($id: Int!, $paging: AMPPageInput) {
    amp {
      podcast(id: $id) {
        ...Podcast
      }
    }
  }
  ${PodcastFragmentDoc}
`;
export const GetCurrentTrackDocument = gql`
  query GetCurrentTrack($slug: String!) {
    sites {
      find(type: SLUG, value: $slug) {
        stream {
          amp {
            currentTrack {
              ...TrackDetail
            }
          }
        }
      }
    }
  }
  ${TrackDetailFragmentDoc}
`;
export const GetTopSongsDocument = gql`
  query GetTopSongs($slug: String!, $paging: AMPPageInput) {
    sites {
      find(type: SLUG, value: $slug) {
        stream {
          amp {
            topTracks(paging: $paging) {
              ...TopTracks
            }
          }
        }
      }
    }
  }
  ${TopTracksFragmentDoc}
`;
export const GetCurrentlyPlayingSongsDocument = gql`
  query GetCurrentlyPlayingSongs($slug: String!, $paging: AMPPageInput) {
    sites {
      find(type: SLUG, value: $slug) {
        stream {
          amp {
            currentlyPlaying(paging: $paging) {
              ...CurrentlyPlaying
            }
          }
        }
      }
    }
  }
  ${CurrentlyPlayingFragmentDoc}
`;
export const GetContestsDocument = gql`
  query GetContests($accountId: String!) {
    aptivada(accountId: $accountId) {
      apps {
        ...AptivadaApp
      }
    }
  }
  ${AptivadaAppFragmentDoc}
`;
export const GetContestDocument = gql`
  query GetContest($accountId: String!, $appId: String!) {
    aptivada(accountId: $accountId) {
      app(appId: $appId) {
        ...AptivadaApp
      }
    }
  }
  ${AptivadaAppFragmentDoc}
`;
export const GetKeywordContestDocument = gql`
  query GetKeywordContest($accountId: String!, $appId: String!) {
    aptivada(accountId: $accountId) {
      keyword(appId: $appId) {
        ...AptivadaKeywordApp
      }
    }
  }
  ${AptivadaKeywordAppFragmentDoc}
`;
export const GetEventCategoriesDocument = gql`
  query GetEventCategories($slug: String!) {
    sites {
      find(type: SLUG, value: $slug) {
        eventCategories
      }
    }
  }
`;
export const GetEventsDocument = gql`
  query GetEvents($type: SITES_LOOKUP_BY!, $value: String!, $category: String, $limit: Int, $offset: Int) {
    sites {
      find(type: $type, value: $value) {
        events(category: $category, num: $limit, offset: $offset) {
          ...CalendarEvent
        }
      }
    }
  }
  ${CalendarEventFragmentDoc}
`;
export const GetOccurrenceDocument = gql`
  query GetOccurrence($occurrenceId: String) {
    calendar {
      occurrence(occurrenceId: $occurrenceId) {
        ...CalendarEvent
      }
    }
  }
  ${CalendarEventFragmentDoc}
`;
export const GetChartsForSitemapDocument = gql`
  query GetChartsForSitemap($brand: String!, $listsSize: Int!) {
    charts {
      charts {
        filter(filters: { brand: $brand }) {
          items {
            slug
            lists(filters: { size: $listsSize }) {
              items {
                slug
                published {
                  timestampMs
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetChartV2Document = gql`
  query GetChartV2(
    $slug: String!
    $listsSize: Int
    $listsStartDate: Float
    $listsEndDate: Float
    $listsCursor: String
    $includeDates: Boolean = false
    $includeLists: Boolean = false
  ) {
    charts {
      charts {
        bySlug(slug: $slug) {
          ...ChartV2
        }
      }
    }
  }
  ${ChartV2FragmentDoc}
`;
export const GetChartListsPartialsDocument = gql`
  query GetChartListsPartials($chartSlug: String, $size: Int, $startDate: Float, $endDate: Float, $cursor: String) {
    charts {
      lists {
        filter(
          chartSlug: $chartSlug
          filters: { size: $size, cursor: $cursor, startDate: $startDate, endDate: $endDate }
        ) {
          ...ChartListPartialResults
        }
      }
    }
  }
  ${ChartListPartialResultsFragmentDoc}
`;
export const GetChartListDocument = gql`
  query GetChartList($slug: String!) {
    charts {
      lists {
        bySlug(slug: $slug) {
          ...ChartsList
        }
      }
    }
  }
  ${ChartsListFragmentDoc}
`;
export const GetSiteByQueryDocument = gql`
  query GetSiteByQuery($type: SITES_LOOKUP_BY!, $value: String!, $lookup: String!) {
    sites {
      find(type: $type, value: $value) {
        ...SiteConfig
      }
    }
  }
  ${SiteConfigFragmentDoc}
`;
export const GetContactEmailsDocument = gql`
  query GetContactEmails($type: SITES_LOOKUP_BY!, $value: String!, $lookup: String!) {
    sites {
      find(type: $type, value: $value) {
        configByLookup(lookup: $lookup) {
          sections {
            ... on SitesTemplateInfernoSite {
              contact {
                contacts {
                  type
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetSiteChildrenDocument = gql`
  query GetSiteChildren($children: [SitesFindManyLookup!]!) {
    sites {
      findMany(lookups: $children) {
        ...SiteChildRef
      }
    }
  }
  ${SiteChildRefFragmentDoc}
`;
export const GetMicrositeByQueryDocument = gql`
  query GetMicrositeByQuery($slug: String!, $micrositeSlug: String!, $lookup: String!) {
    sites {
      find(type: SLUG, value: $slug) {
        inboundRef(by: EMBEDDED_IN, type: SLUG, value: $micrositeSlug) {
          _id
          slug
          ownBrand
          timeZone
          summary {
            name
          }
          status {
            hide {
              startDate
              endDate
              redirectTo
            }
            isHidden
            isActive
          }
          keys
          configByLookup(lookup: $lookup) {
            _id
            routes {
              name
              path
            }
            sections {
              ... on SitesTemplateInfernoMicrosite {
                general {
                  ...MicrositeGeneralConfig
                }
                navigation {
                  enable_nav_switch
                  menu {
                    id
                    type
                    label
                    target
                    ref
                    children {
                      id
                      type
                      label
                      target
                      ref
                    }
                  }
                }
                contact {
                  full_bio
                  phone_number
                  txt_number
                  email_address
                }
                social {
                  ...MicrositeSocialConfig
                }
              }
            }
          }
          inboundKeys(by: PRIMARY_PARENT_OF) {
            slug
            canonicalHostname
          }
        }
      }
    }
  }
  ${MicrositeGeneralConfigFragmentDoc}
  ${MicrositeSocialConfigFragmentDoc}
`;
export const GetSlugByBrandTagDocument = gql`
  query GetSlugByBrandTag($type: SITES_LOOKUP_BY!, $value: String!) {
    sites {
      find(type: $type, value: $value) {
        _id
        slug
      }
    }
  }
`;
export const GetNavigationMicrositesDocument = gql`
  query GetNavigationMicrosites($lookups: [SitesFindManyLookup!]!, $lookup: String!) {
    sites {
      findMany(lookups: $lookups) {
        ...NavigationMicrosite
      }
    }
  }
  ${NavigationMicrositeFragmentDoc}
`;
export const GetSlugFromHostnameDocument = gql`
  query GetSlugFromHostname($hostname: String!) {
    sites {
      find(type: HOSTNAME, value: $hostname) {
        slug
      }
    }
  }
`;
export const GetTimelineSitesDocument = gql`
  query GetTimelineSites($active: Boolean!, $take: Int!) {
    sites {
      filter(filter: { active: $active, hidden: false }, paging: { take: $take }) {
        results {
          slug
          ownBrand
          canonicalHostname
        }
      }
    }
  }
`;
export const GetHistoricalContentDocument = gql`
  query GetHistoricalContent($query: PubsubQueryInput!) {
    pubsub {
      query(type: "content", query: $query) {
        items {
          slug
          pub_start
        }
        page {
          nextToken
        }
      }
    }
  }
`;
export const GetOnAirNowDocument = gql`
  query GetOnAirNow($slug: String!, $currentDate: Float) {
    sites {
      find(type: SLUG, value: $slug) {
        onAirSchedule(currentDate: $currentDate) {
          ...OnAirSchedule
        }
      }
    }
  }
  ${OnAirScheduleFragmentDoc}
`;
export const GetOnAirNowByDayDocument = gql`
  query GetOnAirNowByDay($day: SITES_ONAIR_DAY!, $slug: String!, $currentDate: Float) {
    sites {
      find(type: SLUG, value: $slug) {
        timeZone
        onAirSchedule(currentDate: $currentDate) {
          ...OnAirSchedule
        }
        onAirScheduleForDay(day: $day) {
          schedule {
            ...OnAirShow
          }
        }
      }
    }
  }
  ${OnAirScheduleFragmentDoc}
  ${OnAirShowFragmentDoc}
`;
export const GetPageDocument = gql`
  query GetPage(
    $name: String!
    $slug: String!
    $lookup: String!
    $context: JSON!
    $scopes: [String!]
    $from: String
    $paging: PageTokenInput
  ) {
    sites {
      find(type: SLUG, value: $slug) {
        _id
        configByLookup(lookup: $lookup) {
          _id
          page(name: $name) {
            ...Page
          }
        }
      }
    }
  }
  ${PageFragmentDoc}
`;
export const GetSitePagesDocument = gql`
  query GetSitePages($type: SITES_LOOKUP_BY!, $value: String!, $lookup: String!) {
    sites {
      find(type: $type, value: $value) {
        _id
        configByLookup(lookup: $lookup) {
          _id
          pages {
            _id
            name
            displayName
            path
            pageConfig
          }
        }
      }
    }
  }
`;
export const GetFeedContentDocument = gql`
  query GetFeedContent(
    $type: SITES_LOOKUP_BY!
    $value: String!
    $lookup: String!
    $usage: String!
    $context: JSON
    $size: Int
  ) {
    sites {
      find(type: $type, value: $value) {
        _id
        configByLookup(lookup: $lookup) {
          _id
          feed(params: { id: $usage, context: $context, size: $size }) {
            results {
              ...FeedResult
            }
          }
        }
      }
    }
  }
  ${FeedResultFragmentDoc}
`;
export const GetDatasourceBySlugDocument = gql`
  query GetDatasourceBySlug($slug: String!, $lookup: String!, $resume: SitesFeedResumeInput!) {
    sites {
      find(type: SLUG, value: $slug) {
        _id
        configByLookup(lookup: $lookup) {
          _id
          feed(params: $resume) {
            ...FeedResponse
          }
        }
      }
    }
  }
  ${FeedResponseFragmentDoc}
`;
export const GetYoutubeVideosByIdDocument = gql`
  query GetYoutubeVideosByID(
    $name: String!
    $slug: String!
    $lookup: String!
    $blockId: String!
    $videoIds: [String!]!
  ) {
    sites {
      find(type: SLUG, value: $slug) {
        configByLookup(lookup: $lookup) {
          page(name: $name) {
            block(blockId: $blockId) {
              ... on SitesYoutubeBlock {
                channel {
                  youtube {
                    videos(videoIds: $videoIds) {
                      id
                      description
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetStationDocument = gql`
  query GetStation($slug: String!) {
    postup {
      station(slug: $slug) {
        ...PostUpStation
      }
    }
  }
  ${PostUpStationFragmentDoc}
`;
export const GetRecipientDocument = gql`
  query GetRecipient($emailAddress: String!, $slug: String!) {
    postup {
      recipient(emailAddress: $emailAddress) {
        ...PostUpRecipient
      }
    }
  }
  ${PostUpRecipientFragmentDoc}
`;
export const GetRecipientSubscriptionsDocument = gql`
  query GetRecipientSubscriptions($emailAddress: String!, $slug: String!) {
    postup {
      recipient(emailAddress: $emailAddress) {
        subscriptions(slug: $slug) {
          ...Subscriptions
        }
      }
    }
  }
  ${SubscriptionsFragmentDoc}
`;
export const CreateRecipientDocument = gql`
  mutation CreateRecipient($slug: String!, $input: PostupRecipientCreateInput!) {
    postup {
      createRecipient(input: $input) {
        ...PostUpRecipient
      }
    }
  }
  ${PostUpRecipientFragmentDoc}
`;
export const UpdateRecipientDocument = gql`
  mutation UpdateRecipient($slug: String!, $input: PostupRecipientUpdateInput!) {
    postup {
      updateRecipient(input: $input) {
        ...PostUpRecipient
      }
    }
  }
  ${PostUpRecipientFragmentDoc}
`;
export const UpdateSubscriptionsDocument = gql`
  mutation UpdateSubscriptions($input: [PostupListSubscriptionCreateInput!]!) {
    postup {
      updateSubscriptions(input: $input) {
        ...Subscriptions
      }
    }
  }
  ${SubscriptionsFragmentDoc}
`;
export const SendMailDocument = gql`
  mutation SendMail($slug: String!, $input: PostupSendMailInput!) {
    postup {
      sendMail(slug: $slug, input: $input) {
        ...PostUpMailing
      }
    }
  }
  ${PostUpMailingFragmentDoc}
`;
export const GetSitemapContentDocument = gql`
  query GetSitemapContent($query: PubsubQueryInput!) {
    pubsub {
      query(type: "content", query: $query) {
        items {
          ...SitemapContentItem
        }
      }
    }
  }
  ${SitemapContentItemFragmentDoc}
`;
export const GetCoastPreviousNextFeedDocument = gql`
  query GetCoastPreviousNextFeed($query: PubsubQueryInput!, $type: String!) {
    pubsub {
      query(type: $type, query: $query) {
        items {
          ...PreviousNextItem
        }
      }
    }
  }
  ${PreviousNextItemFragmentDoc}
`;
export const GetCoastContentItemDocument = gql`
  query GetCoastContentItem($type: String!, $slug: String!) {
    pubsub {
      get(type: $type, select: { slug: $slug }) {
        ref_id
        type
        payload
        slug
        summary {
          image
          title
          description
          author
        }
        pub_start
        pub_until
        pub_changed
        subscription {
          tags
        }
      }
    }
  }
`;
export const GetPwsSearchResultsDocument = gql`
  query GetPWSSearchResults(
    $keyword: String!
    $includeArticles: Boolean
    $includeGuests: Boolean
    $includeShows: Boolean
    $cursor: String
    $offset: Int
    $limit: Int
  ) {
    pws {
      coastAppContent {
        search(
          term: $keyword
          platform: DESKTOP
          doMerge: true
          includeArticles: $includeArticles
          includeGuests: $includeGuests
          includeShows: $includeShows
          cursor: $cursor
          offset: $offset
          limit: $limit
        ) {
          ...PWSSearchResponse
        }
      }
    }
  }
  ${PwsSearchResponseFragmentDoc}
`;
export const CoastFeaturedDocument = gql`
  query CoastFeatured(
    $sections: Int = 3
    $shows: PWSCoastFeaturedItemOptions = { limit: 2, offset: 0 }
    $photos: PWSCoastFeaturedItemOptions = { limit: 1, offset: 0 }
    $articles: PWSCoastFeaturedItemOptions = { limit: 5, offset: 0 }
  ) {
    pws {
      coastAppContent {
        featured(platform: DESKTOP, sections: $sections, shows: $shows, photos: $photos, articles: $articles) {
          ... on PWSCoastFeaturedDesktopPlatform {
            value {
              ...CoastFeaturedItem
            }
          }
        }
      }
    }
  }
  ${CoastFeaturedItemFragmentDoc}
`;
export const GetTopicDocument = gql`
  query GetTopic($topic: String!, $locale: String!) {
    taxonomy {
      topic(topic: $topic, locale: $locale) {
        name
        source {
          ...TagSource
        }
        displayName
      }
    }
  }
  ${TagSourceFragmentDoc}
`;
export const GetTagDocument = gql`
  query GetTag($tag: String!, $locale: String!) {
    taxonomy {
      tag(tag: $tag, locale: $locale) {
        name
        memberName
        displayName
        ns
        source {
          ...TagSource
        }
      }
    }
  }
  ${TagSourceFragmentDoc}
`;
export function getSdk(factory: GraphQLRequestFactory) {
  return {
    GetPodcastEpisodes: factory<GetPodcastEpisodesQueryVariables, GetPodcastEpisodesQuery>(
      "GetPodcastEpisodes",
      "Query",
      print(GetPodcastEpisodesDocument),
    ),
    GetCurrentTrack: factory<GetCurrentTrackQueryVariables, GetCurrentTrackQuery>(
      "GetCurrentTrack",
      "Query",
      print(GetCurrentTrackDocument),
    ),
    GetTopSongs: factory<GetTopSongsQueryVariables, GetTopSongsQuery>(
      "GetTopSongs",
      "Query",
      print(GetTopSongsDocument),
    ),
    GetCurrentlyPlayingSongs: factory<GetCurrentlyPlayingSongsQueryVariables, GetCurrentlyPlayingSongsQuery>(
      "GetCurrentlyPlayingSongs",
      "Query",
      print(GetCurrentlyPlayingSongsDocument),
    ),
    GetContests: factory<GetContestsQueryVariables, GetContestsQuery>(
      "GetContests",
      "Query",
      print(GetContestsDocument),
    ),
    GetContest: factory<GetContestQueryVariables, GetContestQuery>("GetContest", "Query", print(GetContestDocument)),
    GetKeywordContest: factory<GetKeywordContestQueryVariables, GetKeywordContestQuery>(
      "GetKeywordContest",
      "Query",
      print(GetKeywordContestDocument),
    ),
    GetEventCategories: factory<GetEventCategoriesQueryVariables, GetEventCategoriesQuery>(
      "GetEventCategories",
      "Query",
      print(GetEventCategoriesDocument),
    ),
    GetEvents: factory<GetEventsQueryVariables, GetEventsQuery>("GetEvents", "Query", print(GetEventsDocument)),
    GetOccurrence: factory<GetOccurrenceQueryVariables, GetOccurrenceQuery>(
      "GetOccurrence",
      "Query",
      print(GetOccurrenceDocument),
    ),
    GetChartsForSitemap: factory<GetChartsForSitemapQueryVariables, GetChartsForSitemapQuery>(
      "GetChartsForSitemap",
      "Query",
      print(GetChartsForSitemapDocument),
    ),
    GetChartV2: factory<GetChartV2QueryVariables, GetChartV2Query>("GetChartV2", "Query", print(GetChartV2Document)),
    GetChartListsPartials: factory<GetChartListsPartialsQueryVariables, GetChartListsPartialsQuery>(
      "GetChartListsPartials",
      "Query",
      print(GetChartListsPartialsDocument),
    ),
    GetChartList: factory<GetChartListQueryVariables, GetChartListQuery>(
      "GetChartList",
      "Query",
      print(GetChartListDocument),
    ),
    GetSiteByQuery: factory<GetSiteByQueryQueryVariables, GetSiteByQueryQuery>(
      "GetSiteByQuery",
      "Query",
      print(GetSiteByQueryDocument),
    ),
    GetContactEmails: factory<GetContactEmailsQueryVariables, GetContactEmailsQuery>(
      "GetContactEmails",
      "Query",
      print(GetContactEmailsDocument),
    ),
    GetSiteChildren: factory<GetSiteChildrenQueryVariables, GetSiteChildrenQuery>(
      "GetSiteChildren",
      "Query",
      print(GetSiteChildrenDocument),
    ),
    GetMicrositeByQuery: factory<GetMicrositeByQueryQueryVariables, GetMicrositeByQueryQuery>(
      "GetMicrositeByQuery",
      "Query",
      print(GetMicrositeByQueryDocument),
    ),
    GetSlugByBrandTag: factory<GetSlugByBrandTagQueryVariables, GetSlugByBrandTagQuery>(
      "GetSlugByBrandTag",
      "Query",
      print(GetSlugByBrandTagDocument),
    ),
    GetNavigationMicrosites: factory<GetNavigationMicrositesQueryVariables, GetNavigationMicrositesQuery>(
      "GetNavigationMicrosites",
      "Query",
      print(GetNavigationMicrositesDocument),
    ),
    GetSlugFromHostname: factory<GetSlugFromHostnameQueryVariables, GetSlugFromHostnameQuery>(
      "GetSlugFromHostname",
      "Query",
      print(GetSlugFromHostnameDocument),
    ),
    GetTimelineSites: factory<GetTimelineSitesQueryVariables, GetTimelineSitesQuery>(
      "GetTimelineSites",
      "Query",
      print(GetTimelineSitesDocument),
    ),
    GetHistoricalContent: factory<GetHistoricalContentQueryVariables, GetHistoricalContentQuery>(
      "GetHistoricalContent",
      "Query",
      print(GetHistoricalContentDocument),
    ),
    GetOnAirNow: factory<GetOnAirNowQueryVariables, GetOnAirNowQuery>(
      "GetOnAirNow",
      "Query",
      print(GetOnAirNowDocument),
    ),
    GetOnAirNowByDay: factory<GetOnAirNowByDayQueryVariables, GetOnAirNowByDayQuery>(
      "GetOnAirNowByDay",
      "Query",
      print(GetOnAirNowByDayDocument),
    ),
    GetPage: factory<GetPageQueryVariables, GetPageQuery>("GetPage", "Query", print(GetPageDocument)),
    GetSitePages: factory<GetSitePagesQueryVariables, GetSitePagesQuery>(
      "GetSitePages",
      "Query",
      print(GetSitePagesDocument),
    ),
    GetFeedContent: factory<GetFeedContentQueryVariables, GetFeedContentQuery>(
      "GetFeedContent",
      "Query",
      print(GetFeedContentDocument),
    ),
    GetDatasourceBySlug: factory<GetDatasourceBySlugQueryVariables, GetDatasourceBySlugQuery>(
      "GetDatasourceBySlug",
      "Query",
      print(GetDatasourceBySlugDocument),
    ),
    GetYoutubeVideosByID: factory<GetYoutubeVideosByIdQueryVariables, GetYoutubeVideosByIdQuery>(
      "GetYoutubeVideosByID",
      "Query",
      print(GetYoutubeVideosByIdDocument),
    ),
    GetStation: factory<GetStationQueryVariables, GetStationQuery>("GetStation", "Query", print(GetStationDocument)),
    GetRecipient: factory<GetRecipientQueryVariables, GetRecipientQuery>(
      "GetRecipient",
      "Query",
      print(GetRecipientDocument),
    ),
    GetRecipientSubscriptions: factory<GetRecipientSubscriptionsQueryVariables, GetRecipientSubscriptionsQuery>(
      "GetRecipientSubscriptions",
      "Query",
      print(GetRecipientSubscriptionsDocument),
    ),
    CreateRecipient: factory<CreateRecipientMutationVariables, CreateRecipientMutation>(
      "CreateRecipient",
      "Mutation",
      print(CreateRecipientDocument),
    ),
    UpdateRecipient: factory<UpdateRecipientMutationVariables, UpdateRecipientMutation>(
      "UpdateRecipient",
      "Mutation",
      print(UpdateRecipientDocument),
    ),
    UpdateSubscriptions: factory<UpdateSubscriptionsMutationVariables, UpdateSubscriptionsMutation>(
      "UpdateSubscriptions",
      "Mutation",
      print(UpdateSubscriptionsDocument),
    ),
    SendMail: factory<SendMailMutationVariables, SendMailMutation>("SendMail", "Mutation", print(SendMailDocument)),
    GetSitemapContent: factory<GetSitemapContentQueryVariables, GetSitemapContentQuery>(
      "GetSitemapContent",
      "Query",
      print(GetSitemapContentDocument),
    ),
    GetCoastPreviousNextFeed: factory<GetCoastPreviousNextFeedQueryVariables, GetCoastPreviousNextFeedQuery>(
      "GetCoastPreviousNextFeed",
      "Query",
      print(GetCoastPreviousNextFeedDocument),
    ),
    GetCoastContentItem: factory<GetCoastContentItemQueryVariables, GetCoastContentItemQuery>(
      "GetCoastContentItem",
      "Query",
      print(GetCoastContentItemDocument),
    ),
    GetPWSSearchResults: factory<GetPwsSearchResultsQueryVariables, GetPwsSearchResultsQuery>(
      "GetPWSSearchResults",
      "Query",
      print(GetPwsSearchResultsDocument),
    ),
    CoastFeatured: factory<CoastFeaturedQueryVariables, CoastFeaturedQuery>(
      "CoastFeatured",
      "Query",
      print(CoastFeaturedDocument),
    ),
    GetTopic: factory<GetTopicQueryVariables, GetTopicQuery>("GetTopic", "Query", print(GetTopicDocument)),
    GetTag: factory<GetTagQueryVariables, GetTagQuery>("GetTag", "Query", print(GetTagDocument)),
  };
}
export type SDKInterface = ReturnType<typeof getSdk>;
